import omitBy from 'lodash/omitBy';
import pickBy from 'lodash/pickBy';
import isNil from 'lodash/isNil';
import { InsuranceInsuredCoverage } from '@evidentid/rpweb-api-client/types';
import JsonSchema, { JsonSchemaBasicObject } from '@evidentid/json-schema/interfaces/JsonSchema';
import { BrokerInfo } from '@/modules/dashboard/types';
import { getCoverageAddressObjectString } from '@/modules/insured-details/utils/getCoverageAddressObjectString';
import { formatPhoneNumber } from '@/modules/insured-details/utils/formatPhoneNumber/formatPhoneNumber';
import {
    InsuranceComplianceStatus,
    InsuranceEvaluationResult,
} from '@evidentid/insurance-facing-lib/models/insured-details';

export function isEmptyValue(value: any): boolean {
    return isNil(value) ||
        (
            typeof value === 'object' &&
            Object.values(value).every((x) => isNil(x))
        );
}

export function isNonCompliantField(fieldName: string, evaluationResults: InsuranceEvaluationResult[]): boolean {
    return evaluationResults.some(
        (result) =>
            result.usedFields?.some((usedField) => usedField.split('/').pop() === fieldName) &&
            result.result === InsuranceComplianceStatus.nonCompliant,
    );
}

export function shouldBeOmitted(key: string, value: any, evaluationResults: InsuranceEvaluationResult[]): boolean {
    const notEvaluatedEmpty = !isNonCompliantField(key, evaluationResults) && isEmptyValue(value);
    return key === 'coverageStatus' || notEvaluatedEmpty;
}

export function getBrokerInfo(
    brokerInfo?: BrokerInfo | null,
): { title: string, value: string | null }[] {
    if (!brokerInfo) {
        return [];
    }
    return [
        { title: 'Broker Business Name', value: brokerInfo.name || null },
        {
            title: 'Broker Phone Number',
            value: brokerInfo.phoneNumber ?
                formatPhoneNumber(brokerInfo.phoneNumber)
                : null,
        },
        { title: 'Broker Contact', value: brokerInfo.contactName || null },
    ];
}

export function getProducerInfo(
    coverage: InsuranceInsuredCoverage,
): { title: string, value: string | null }[] {
    return [
        {
            title: 'Producer Business Name',
            value: coverage.details.brokerName || coverage.details.producerName || null,
        },
        { title: 'Producer Contact Name', value: coverage.details.producerContactName || null },
        { title: 'Producer Email', value: coverage.details.producerEmail || null },
        { title: 'Producer Telephone', value: coverage.details.producerTelephone || null },
        {
            title: 'Producer Address',
            value: getCoverageAddressObjectString(coverage.details.producerAddress) || null,
        },
    ];
}

export function isMultiPropsField(property: { [key: string]: JsonSchema } | {}): boolean {
    return 'properties' in property;
}

export function omitMultiPropFieldsFromSchema(schema: JsonSchemaBasicObject): JsonSchemaBasicObject {
    return {
        ...schema,
        properties: 'properties' in schema
            ? omitBy(schema.properties, isMultiPropsField)
            : {},
    };
}

export function pickMultiPropFieldsFromSchema(schema: JsonSchemaBasicObject): JsonSchemaBasicObject {
    return {
        ...schema,
        properties: 'properties' in schema
            ? pickBy(schema.properties, isMultiPropsField)
            : {},
    };
}
