<template>
    <Modal additional-class-name="InsuredsCoisModal InsuredsCoisModal--ready" open @close="close">
        <template v-slot:header>COIs</template>
        <CoiList :certificates="certificates" :coverage-models="coverageModels" @open="open" />
    </Modal>
</template>

<style lang="scss">
    .InsuredsCoisModal--ready {
        .Modal__body {
            width: 750px;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { Modal } from '@evidentid/dashboard-commons/components/Modal';
    import { CoiDetails } from '@/modules/insured-certificates/types';
    import CoiList from '@/modules/insured-certificates/components/CoiList.vue';
    import { InsuranceCoverageModel } from '@evidentid/rpweb-api-client/types';

    @Component({
        components: {
            CoiList,
            Modal,
        },
    })
    export default class InsuredsCoisListModal extends Vue {
        @Prop({ type: String })
        private email!: string;

        @Prop({ type: Array })
        private certificates!: CoiDetails[];

        @Prop({ type: Array })
        private coverageModels!: InsuranceCoverageModel[];

        private close(): void {
            this.$emit('close');
        }

        private open(coi: CoiDetails): void {
            this.$emit('open', coi);
        }
    }
</script>
