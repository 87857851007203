<template>
    <div class="EmptyCoverageCriteriaGroups">
        <img class="EmptyCoverageCriteriaGroups__icon" :src="docWithExclamationMark" alt="docWithExclamationMark">
        <div class="EmptyCoverageCriteriaGroups__titleText">
            The insured has not yet been assigned
            to a coverage criteria group.
        </div>
        <p>
            You can update the insured’s details in the Overview tab to assign it to an existing group based on that
            group’s criteria.
        </p>
        <p>
            <span>Alternatively, you can </span>
            <span class="EmptyCoverageCriteriaGroups__linkText" @click="$emit('goToCriteriaGroupList')">
                go to the Decisioning Criteria page
            </span>
            <span> and create a new coverage criteria group with criteria that includes the insured.</span>
        </p>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from '@evidentid/vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import docWithExclamationMark from '@/modules/dashboard/assets/doc-with-exclamation-mark.svg';

    @Component({
        components: {
            FontAwesomeIcon,
        },
    })
    export default class EmptyCoverageCriteriaGroups extends Vue {
        private docWithExclamationMark = docWithExclamationMark;
    }
</script>
