<template>
    <button type="button" class="CoiButton" v-on="$listeners">
        <FontAwesomeIcon :icon="faFileAlt" />
    </button>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faFileAlt } from '@fortawesome/free-regular-svg-icons';

    @Component({
        components: {
            FontAwesomeIcon,
        },
    })
    export default class CoiButton extends Vue {
        private faFileAlt = faFileAlt;
    }
</script>
