<template>
    <div class="CoverageCriterionSettings">
        <CoverageCriterionInformationBanner
            :template="template"
            :criterion="criterion"
            :insured-fields="insuredFields"
        />
        <div v-if="orderedTemplateReferences.length > 0" class="CoverageCriterionSettings__criterionFields">
            <div
                class="CoverageCriterionSettings__table"
                :class="{'CoverageCriterionSettings__table--hasSubstitutable': hasSubstitutableReference}"
            >
                <CoverageCriterionSettingsDescription :template="template" />
                <div class="CoverageCriterionSettings__tableHeader">
                    <header>Settings</header>
                    <header>Required</header>
                    <header
                        v-if="hasSubstitutableReference"
                        class="CoverageCriterionSettings__tableActionHeader"
                    >
                        Set Limit to Insured Field
                    </header>
                </div>
                <DynamicCriterionSetting
                    class="CoverageCriterionSettings__tableBody"
                    :template="template"
                    :criterion="criterion"
                    :insured-fields="insuredFields"
                    :load-criterion-message="loadCriterionMessage"
                    @input="onInput"
                    @reference-accessed="onReferenceAccessed"
                    @controller-value-update="controllerValueUpdate"
                    @update:visible-schemas="onUpdateVisibleSchemas"
                />
            </div>
        </div>
        <CoverageCriterionEmailMessage
            :template="template"
            :criterion="criterion"
            :insured-fields="insuredFields"
            :criterion-value="criterionCurrentValue"
            :criterion-message-status="criterionMessageStatus"
        />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import {
        InsuranceCoverageCriterion,
        InsuranceCoverageCriterionInput,
        InsuranceCoverageCriterionTemplate,
        InsuranceInsuredField,
    } from '@evidentid/rpweb-api-client/types';
    import CoverageGroupCriteriaTable
        from '@/modules/decisioning-criteria/components/CoverageGroupCriteriaTable/CoverageGroupCriteriaTable.vue';
    import { JsonSchemaObject } from '@evidentid/json-schema/interfaces/JsonSchema';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { CoverageCriterionMessageStatus } from '@/modules/decisioning-criteria/vuex';
    import { JsonFormObject } from '@evidentid/json-schema/interfaces/JsonForm';
    import {
        JsonSchemaCustomForm,
        JsonSchemaCustomFormElement,
    } from '@evidentid/dashboard-commons/components/JsonSchemaForm';
    import { FormController } from '@evidentid/json-schema/FormController';
    import GeneralAggregateLimitBreakdownBanner
        from '@/modules/decisioning-criteria/components/GeneralAggregateLimitBreakdownBanner/GeneralAggregateLimitBreakdownBanner.vue';
    import CoverageCriterionInformationBanner
        from '@/modules/decisioning-criteria/components/CoverageCriterionInformationBanner/CoverageCriterionInformationBanner.vue';
    import CoverageCriterionEmailMessage
        from '@/modules/decisioning-criteria/components/CoverageCriterionEmailMessage/CoverageCriterionEmailMessage.vue';
    import { eidStandardizeCriterionSchema } from '@/modules/decisioning-criteria/utils/eidStandardizeCriterionSchema';
    import { buildCriterionSchema } from '@/modules/decisioning-criteria/schemas/buildCriterionSchema';
    import { getAllSubstitutableSchemas } from '@/modules/decisioning-criteria/utils/getAllSubstitutableSchemas';
    import { isSubstitutableSchema } from '@/modules/decisioning-criteria/utils/isSubstitutableSchema';
    import {
        isSubstitutableSchemaWithValue,
    } from '@/modules/decisioning-criteria/utils/schemaHaveSubstitutableSchemaWithValue';
    import DynamicCriterionSetting
        from '@/modules/decisioning-criteria/components/DynamicCriterionSetting/DynamicCriterionSetting.vue';
    import { fieldsUseNewJsonForm } from '@/modules/decisioning-criteria/components/DynamicCriterionSetting/constants';
    import CoverageCriterionSettingsDescription
        from '@/modules/decisioning-criteria/components/CoverageCriterionSettingsDescription/CoverageCriterionSettingsDescription.vue';
    import PublicProductsLiabilityCriterion
        from '@/modules/decisioning-criteria/components/PublicProductsLiabilityCriterion/PublicProductsLiabilityCriterion.vue';

    @Component({
        components: {
            PublicProductsLiabilityCriterion,
            CoverageCriterionEmailMessage,
            CoverageCriterionInformationBanner,
            CoverageCriterionSettingsDescription,
            CoverageGroupCriteriaTable,
            DynamicCriterionSetting,
            GeneralAggregateLimitBreakdownBanner,
            JsonSchemaCustomForm,
            JsonSchemaCustomFormElement,
        },
    })
    export default class CoverageCriterionSettings extends Vue {
        @Prop({ type: Object, default: () => ({}) })
        private criterion!: InsuranceCoverageCriterion | InsuranceCoverageCriterionInput;

        @Prop({ type: Object, default: () => ({}) })
        private template!: InsuranceCoverageCriterionTemplate;

        @Prop({ type: Array, default: () => [] })
        private insuredFields!: InsuranceInsuredField[];

        @Prop({ type: String, default: 'US' })
        private countryCode!: string;

        private criterionCurrentValue: any | null = null;
        private messageDebounceTimer: any = null;
        private visibleSchemaPaths: string[] = [];
        private substitutableSchemas: Record<string, boolean> = {};

        private get rpName(): string {
            return this.$route.params.rpId;
        }

        private get orderedTemplateReferences(): string[] {
            return Object.keys(this.template.evaluator?.references) || [];
        }

        private get hasSubstitutableReference(): boolean {
            if (!this.insuredFields.length) {
                return false;
            }
            if (this.isNewJsonSchemaForm) {
                return this.visibleSchemaPaths.some((schemaPath) => this.substitutableSchemas[schemaPath]);
            }
            return Object.entries(this.criterion.evaluator.references).some(
                ([ key, reference ]) => {
                    if (isSubstitutableSchema(reference.schema)) {
                        const fieldIsNotVisible = this.criterionCurrentValue &&
                            !Object.keys(this.criterionCurrentValue).includes(key);
                        return !fieldIsNotVisible;
                    }

                    return false;
                },
            );
        }

        private get isNewJsonSchemaForm(): boolean {
            return fieldsUseNewJsonForm.includes(this.template.field);
        }

        private get criterionMessageStatus(): CoverageCriterionMessageStatus {
            const criterionId = 'id' in this.criterion && this.criterion.id
                ? this.criterion.id
                : `${this.criterion.coverageType}-${this.criterion.field}`;
            const messagesPerRp = this.$store.state.decisioningCriteria.coverageCriterionMessage[this.rpName];
            const messagePerCriterion = messagesPerRp ? messagesPerRp[criterionId] : null;
            return messagePerCriterion || { status: OperationStatus.uninitialized, data: null };
        }

        private onInput(updatedCriterion: InsuranceCoverageCriterion | InsuranceCoverageCriterionInput): void {
            this.$emit('input', updatedCriterion);
        }

        private onReferenceAccessed(data: { reference: string, isValid: boolean }): void {
            this.$emit('reference-accessed', data);
        }

        private controllerValueUpdate(controllerValue: any): void {
            this.criterionCurrentValue = { ...controllerValue };
        }

        private loadCriterionMessage(
            controllerOrIsFormValid: FormController | boolean,
            updatedCriterion: InsuranceCoverageCriterion | InsuranceCoverageCriterionInput,
        ) {
            if (typeof controllerOrIsFormValid === 'boolean') {
                if (!controllerOrIsFormValid) {
                    return;
                }
            } else if (!this.isFormValid(controllerOrIsFormValid)) {
                return;
            }
            if (this.criterionMessageStatus.status !== OperationStatus.loading) {
                clearTimeout(this.messageDebounceTimer);
                this.messageDebounceTimer = setTimeout(() => {
                    this.$store.actions.decisioningCriteria.loadCoverageCriterionMessage({
                        rpName: this.rpName,
                        criterion: updatedCriterion,
                        countryCode: this.countryCode,
                    });
                }, 100);
            }
        }

        // TODO (PRODUCT-17188): no need to check conditionalRef once BE move them to schema with if else then
        private isFormValid(controller: FormController): boolean {
            const conditionalRefs = this.template.displayMetadata.conditionalValueReference;
            if (!conditionalRefs) {
                return controller.valid || false;
            }
            const hiddenRef = controller.value[conditionalRefs.flagValueReference]
                ? conditionalRefs.flagDisabledReference
                : conditionalRefs.flagEnabledReference;
            return (controller.form as JsonFormObject).getProperties()
                .every((property) =>
                    (property.name === hiddenRef
                        ? true
                        : property.form.isValid(controller.value[property.name], true)),
                );
        }

        private onUpdateVisibleSchemas(visibleSchemas: string[]) {
            this.visibleSchemaPaths = visibleSchemas;
        }

        private beforeMount() {
            const substitutableSchemas = getAllSubstitutableSchemas(
                eidStandardizeCriterionSchema(
                    buildCriterionSchema(
                        this.criterion as any,
                        Object.keys((this.template as any).evaluator?.references) || [],
                        this.template,
                    ),
                    this.insuredFields,
                ) as JsonSchemaObject,
            );
            const onlySubstitutableSchemasWithValues = substitutableSchemas.reduce((acc, { schemaPath, schema }) => {
                if (isSubstitutableSchemaWithValue(schema)) {
                    acc[schemaPath] = true;
                }
                return acc;
            }, {} as Record<string, boolean>);
            this.substitutableSchemas = onlySubstitutableSchemasWithValues;
        }
    }
</script>
