<template>
    <div class="CoiSubmissionHistoryTable min-w-[800px]">
        <Table>
            <template #head>
                <HeadCell>Request Date</HeadCell>
                <HeadCell>Submission Date</HeadCell>
                <HeadCell>Coverage Type</HeadCell>
                <HeadCell>Document</HeadCell>
            </template>
            <template v-if="submissions.length === 0" #status>
                No submission history for this insured.
            </template>
            <CoiSubmissionHistoryTableRow
                v-for="(submission,i) in submissions"
                :key="`${submission.coverageType}-${submission.submissionDate}-${i}`"
                :submission="submission"
                @show-coi="$emit('show-coi', $event)"
            />
        </Table>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import { HeadCell, Table } from '@evidentid/dashboard-commons/components/Table';
    import CoiSubmissionHistoryTableRow
        from '@/modules/insured-details/components/CoiSubmissionHistoryTableRow/CoiSubmissionHistoryTableRow.vue';
    import { HistoricCoiSubmission } from '@/models/HistoricCoiSubmission.model';

    export default Vue.extend({
        name: 'CoiSubmissionHistoryTable',
        components: {
            Table,
            HeadCell,
            CoiSubmissionHistoryTableRow,
        },
        props: {
            submissions: {
                type: Array as PropType<HistoricCoiSubmission[]>,
                default: () => [] as HistoricCoiSubmission[],
            },
        },
    });
</script>
