<template>
    <div class="PolicyInfoNonInsurance">
        <div v-for="name in renderingList" :key="name">
            <PolicyInfoNonInsuranceRecord
                :coverage="coverage"
                :name="name"
                :values="values"
                :evaluation-errors="evaluationErrors"
                :coverage-fields-schema-map="coverageFieldsSchemaMap"
                :multi-field-criterion-map="multiFieldCriterionMap"
                :show-criterion-index="false"
                :show-criterion-field-index="true"
                :multi-field-order-map="multiFieldsCriterionFieldOrderMap"
                no-object-index
                @goToCriterion="goToCriterion"
                @grantExceptionToCriterion="grantExceptionToCriterion"
                @removeExceptions="removeExceptions"
            />
        </div>
        <PolicyInfoNonExtraction
            :get-evaluation-result="getEvaluationResult"
            :non-extraction-results="nonExtractionResults"
            @grant-exception-to-criterion="grantExceptionToCriterion"
            @remove-exceptions="removeExceptions"
        />
    </div>
</template>

<script lang="ts">
    import { Prop, Vue } from '@evidentid/vue-property-decorator';
    import { PropType } from 'vue';
    import difference from 'lodash/difference';
    import mapValues from 'lodash/mapValues';
    import {
        InsuranceCoverageModel,
        InsuranceInsuredCoverage,
        InsuranceInsuredCoverageDetails,
        NonExtractionResults,
    } from '@evidentid/rpweb-api-client/types';
    import JsonSchema, {
        JsonSchemaBasicObject,
        JsonSchemaType,
    } from '@evidentid/json-schema/interfaces/JsonSchema';
    import {
        InsuranceEvaluationResult,
    } from '@evidentid/insurance-facing-lib/models/insured-details';
    import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
    import { CategorizedEnumLabels } from '@/modules/dashboard/models/CategorizedEnumLabels.model';
    import { InsuredMultiFieldCriterion } from '@/modules/insured-details/types';
    import {
        transformValuesWithIsPresent,
    } from '@/modules/insured-details/utils/transformValuesWithIsPresent/transformValuesWithIsPresent';
    import { getBasicSchemaByValue } from '@/modules/insured-details/utils/jsonSchemaUtilities';
    import {
        getEvaluationResultsFromEvaluationErrors,
        getMultiFieldCoverageCriteria,
        shouldOmitFieldsForRendering,
        getFieldOrderByCoverageType,
        getMultiFieldsOrderByCoverageType,
        parseFieldValue,
    } from '../../utils';
    import PolicyInfoNonExtraction
        from '@/modules/insured-details/components/Policy/sub-components/PolicyInfoNonExtraction/PolicyInfoNonExtraction.vue';
    import PolicyInfoNonInsuranceRecord
        from '../PolicyInfoNonInsuranceRecord/PolicyInfoNonInsuranceRecord.vue';

    export default Vue.extend({
        name: 'PolicyInfoNonInsurance',
        components: {
            PolicyInfoNonInsuranceRecord,
            PolicyInfoNonExtraction,
        },
        props: {
            coverage: {
                type: Object as PropType<InsuranceInsuredCoverage>,
                required: true,
            },
            coverageModel: {
                type: Object as PropType<InsuranceCoverageModel>,
            },
            evaluationErrors: {
                type: Object as PropType<Record<string, FieldEvaluationResultError>>,
                default: () => ({}),
            },
            evaluationResults: {
                type: Array as PropType<InsuranceEvaluationResult[]>,
                default: () => [],
            },
            allCoverages: {
                type: Array as PropType<InsuranceInsuredCoverageDetails[]>,
                default: () => [],
            },
            nonExtractionResults: {
                type: Object as PropType<NonExtractionResults >,
                default: () => ({}),
            },
        },
        computed: {
            defaultFieldAndCriterionList(): string[] {
                return [
                    ...Object.keys(this.coverageFieldsSchemaMap),
                    ...Object.keys(this.multiFieldCriterionMap),
                ];
            },
            sortedList(): string[] {
                return getFieldOrderByCoverageType(this.coverage.coverageType);
            },
            multiFieldsCriterionFieldOrderMap(): Record<string, string[]> {
                return getMultiFieldsOrderByCoverageType(this.coverage.coverageType);
            },
            renderingList(): string[] {
                const unsortedList = difference(this.defaultFieldAndCriterionList, this.sortedList);
                return [ ...this.sortedList, ...unsortedList ];
            },
            categorizedEnumLabels(): CategorizedEnumLabels {
                return this.$store.state.dashboard.categorizedEnumLabels;
            },
            values(): Record<string, any> {
                const _values = Object.entries(this.coverage.details)
                    .reduce<Record<string, any>>(
                        (result, [ key, value ]) => {
                            if (!shouldOmitFieldsForRendering(
                                key,
                                [],
                                Boolean(this.multiFieldCriteriaFieldMap[key],
                                ),
                            )) {
                                result[key] = parseFieldValue(key, value, {
                                    coverage: this.coverage,
                                    coverageModel: this.coverageModel,
                                    evaluationResults: this.evaluationResults,
                                    evaluationErrors: this.evaluationErrors,
                                    multiFieldCriteria: this.multiFieldCoverageCriteria,
                                    categorizedEnumLabels: this.categorizedEnumLabels,
                                });
                            }
                            return result;
                        }, {});
                return transformValuesWithIsPresent(_values);
            },
            multiFieldCriteriaFieldMap(): Record<string, unknown> {
                return this.multiFieldCoverageCriteria.reduce((acc, item) => (
                    { ...acc, ...item.fields }
                ), {});
            },
            multiFieldCriterionMap(): { [key: string]: InsuredMultiFieldCriterion } {
                return this.multiFieldCoverageCriteria
                    .reduce((acc, item) => ({ ...acc, [item.name]: item }), {});
            },
            multiFieldCoverageCriteria(): InsuredMultiFieldCriterion[] {
                return getMultiFieldCoverageCriteria(
                    this.evaluationResults, this.evaluationErrors, this.allCoverages, this.categorizedEnumLabels,
                );
            },
            coverageFieldsSchemaMap(): { [key: string]: JsonSchemaBasicObject } {
                // convert each schema to object, rendered individually as JsonSchemaView expects object schema
                return mapValues(
                    getBasicSchemaByValue(this.values).properties,
                    (property: JsonSchema, key: string) => ({
                        type: JsonSchemaType.object,
                        properties: { [key]: property },
                    }));
            },

        },
        methods: {
            goToCriterion(coverageCriteriaGroupId: string): void {
                this.$emit('goToCriterion', coverageCriteriaGroupId, this.coverage.coverageType);
            },
            grantExceptionToCriterion(criterionId: string): void {
                this.$emit('grantExceptionToCriterion', criterionId);
            },
            removeExceptions(exceptionIds: string[]): void {
                this.$emit('removeExceptions', exceptionIds);
            },
            getEvaluationResult(url: string): FieldEvaluationResultError | null {
                return getEvaluationResultsFromEvaluationErrors(url, this.evaluationErrors, this.coverage);
            },
        },
    });
</script>
