<template>
    <div
        class="InsuredsTable"
        :class="{ 'InsuredsTable--with-insured-fields': displayedInsuredFields.length > 0 }"
    >
        <Table>
            <template #head>
                <HeadCell class="InsuredsTable__selectionHeader">
                    <Checkbox
                        :disabled="insureds.length === 0 || isPerformingBulkAction"
                        :value="areAllSelected"
                        @input="toggleSelection"
                    />
                </HeadCell>
                <HeadCell
                    class="InsuredsTable__nameHeader"
                    sortable
                    sort-type="alpha"
                    :sort="getSort('displayName')"
                    @sort="changeSort('displayName', $event)"
                >
                    Insured
                </HeadCell>
                <HeadCell
                    class="InsuredsTable__effectiveGroupHeader"
                >
                    Effective Group
                </HeadCell>
                <HeadCell
                    v-for="insuredField in displayedInsuredFields"
                    :key="insuredField.id"
                    v-tooltip="{ content: insuredField.name, delay: { show: 1000 } }"
                    class="InsuredsTable__insuredFieldHeader"
                >
                    {{ insuredField.name }}
                </HeadCell>
                <HeadCell
                    class="InsuredsTable__expirationHeader"
                    sortable
                    :sort="getSort('nextExpiration')"
                    @sort="changeSort('nextExpiration', $event)"
                >
                    Expiration Date
                </HeadCell>
                <HeadCell
                    class="InsuredsTable__complianceStatusHeader"
                    sortable
                    :sort="getSort('complianceStatus')"
                    @sort="changeSort('complianceStatus', $event)"
                >
                    Compliance Status
                </HeadCell>
                <HeadCell class="InsuredsTable__verificationStatusHeader">
                    Verification Status
                </HeadCell>
                <HeadCell class="InsuredsTable__actionsHeader">
                    Actions
                </HeadCell>
            </template>

            <template #toolbar>
                <div class="InsuredsTable__toolbar">
                    <BulkActionsExpandable
                        :enable-send-coi-request="isEnabledSendCoiRequest"
                        :disabled="noActiveInsuredsSelected || currentlyUpdatedInsuredsIds.length > 0"
                        :loading="isPerformingBulkAction"
                        :show-pause="showBulkActionsPause"
                        :show-unpause="showBulkActionsUnpause"
                        @bulkDeactivate="bulkDeactivateInsureds"
                        @bulkSetPauseState="bulkSetInsuredsPauseStatus"
                        @sendCoiRequest="sendCoiRequest"
                    />
                    <slot name="toolbar" />
                </div>
            </template>

            <template #toolbarEnd>
                <ExportButton
                    class="InsuredsTable__exportButton"
                    tooltip-message="This may take up to one minute…"
                    :submitting="exporting"
                    v-on="$listeners"
                >
                    Download Data
                </ExportButton>
                <Expandable class="InsuredsTable__importExpandable" label="Connect to Insured" legacy-mode>
                    <template #button>
                        <FontAwesomeIcon :icon="faUsers" />
                    </template>

                    <Menu spaced>
                        <MenuLink
                            :icon="faPlusSquare"
                            additional-class="InsuredsTable__menuLink"
                            label="Add/Update individual insured"
                            @click="$emit('openAddIndividualInsuredModal')"
                        />
                        <MenuLink
                            :icon="faUpload"
                            additional-class="InsuredsTable__menuLink"
                            label="Bulk import/update of insureds"
                            @click="$emit('openBulkImportInsuredsModal')"
                        />
                    </Menu>
                </Expandable>
            </template>

            <InsuredsTableRow
                v-for="insured of insureds"
                :key="insured.contactEmail"
                :insured="insured"
                :is-performing-bulk-action="isPerformingBulkAction"
                :displayed-insured-fields="displayedInsuredFields"
                :currently-updated-insureds-ids="currentlyUpdatedInsuredsIds"
                :selected="selectedIds.has(insured.id)"
                :is-loading-submission-link="isLoadingSubmissionLink"
                :submission-link="getSubmissionLink(insured.id)"
                @selectionChange="changeSelection"
                v-on="$listeners"
            />
            <template v-if="insureds.length === 0 && finished" #message>
                <slot name="message">
                    <NoInsuredsFound @reset="$emit('clearFilters')" />
                </slot>
            </template>
            <template v-else-if="insureds.length > 0 && finished" #status>
                <span>No more insureds available.</span>
            </template>
            <template v-else-if="loading" #status>
                <LineLoader />
                <span>Loading insureds...</span>
            </template>
        </Table>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import {
        InsuranceInsured,
        InsuranceInsuredField,
    } from '@evidentid/rpweb-api-client/types';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faUsers, faPlusSquare, faUpload } from '@fortawesome/free-solid-svg-icons';
    import { LineLoader } from '@evidentid/dashboard-commons/components/LineLoader';
    import { HeadCell, Table } from '@evidentid/dashboard-commons/components/Table';
    import { Todo } from '@evidentid/dashboard-commons/components/Todo';
    import { Menu, MenuLink } from '@evidentid/dashboard-commons/components/Menu';
    import { Expandable } from '@evidentid/dashboard-commons/components/Expandable';
    import { Sorting, SortingDirection } from '@evidentid/universal-framework/sorting';
    import InsuredsTableRow from '../InsuredsTableRow/InsuredsTableRow.vue';
    import ExportButton from '@/modules/dashboard/components/ExportButton/ExportButton.vue';
    import NoInsuredsFound from '@/modules/insured-filtering/components/NoInsuredsFound/NoInsuredsFound.vue';
    import { Checkbox } from '@evidentid/dashboard-commons/components/Checkbox';
    import BulkActionsExpandable
        from '@/modules/insured-management/components/BulkActionsExpandable/BulkActionsExpandable.vue';
    import { shouldEnableCoiRequest } from '@/utils/shouldEnableCoiRequest/shouldEnableCoiRequest';

    @Component({
        components: {
            BulkActionsExpandable, Checkbox, NoInsuredsFound, InsuredsTableRow, HeadCell, Todo, Table, LineLoader,
            ExportButton, Expandable, Menu, MenuLink, FontAwesomeIcon,
        },
    })
    export default class InsuredsTable extends Vue {
        @Prop({ type: Boolean, default: false })
        private finished!: boolean;

        @Prop({ type: Boolean, default: false })
        private loading!: boolean;

        @Prop({ type: Boolean, default: false })
        private exporting!: boolean;

        @Prop({ type: Array, default: () => [] })
        private insureds!: InsuranceInsured[];

        @Prop({ type: Object })
        private sort!: Sorting;

        @Prop({ type: Array, default: () => [] })
        private displayedInsuredFields!: InsuranceInsuredField[];

        @Prop({ type: Array, default: () => [] })
        private currentlyUpdatedInsuredsIds!: string[];

        @Prop({ type: Boolean, default: false })
        private isPerformingBulkAction!: boolean;

        @Prop({ type: Boolean, default: false })
        private isLoadingSubmissionLink!: boolean;

        @Prop({ type: Object, default: {} })
        private submissionLinkPerInsuredId!: Record<string, string>;

        private faUsers = faUsers;
        private faPlusSquare = faPlusSquare;
        private faUpload = faUpload;

        private selectedInsureds: InsuranceInsured[] = [];
        private selectedIds: Set<string> = new Set<string>();

        @Watch('insureds')
        private onInsuredsChange() {
            this.selectedInsureds = this.insureds.filter((insured) => this.selectedIds.has(insured.id));
            this.selectedIds = new Set<string>(this.selectedInsureds.map((insured) => insured.id));
        }

        private changeSort(column: string, direction: SortingDirection): void {
            this.$emit('sort', { column, direction });
        }

        private getSort(column: string): SortingDirection | undefined {
            return this.sort?.column === column ? this.sort?.direction : undefined;
        }

        private toggleSelection(value: boolean): void {
            this.selectedInsureds = value ? [ ...this.insureds ] : [];
            this.selectedIds = new Set<string>(this.selectedInsureds.map((insured) => insured.id));
        }

        private get areAllSelected(): boolean {
            return this.insureds.length > 0 ? this.insureds.length === this.selectedInsureds.length : false;
        }

        private get noActiveInsuredsSelected(): boolean {
            return !this.selectedInsureds.some((insured) => insured.active);
        }

        private get showBulkActionsPause(): boolean {
            return this.selectedInsureds.some((insured) => !insured.paused);
        }

        private get showBulkActionsUnpause(): boolean {
            return this.selectedInsureds.some((insured) => insured.paused);
        }

        private getSubmissionLink(insuredId: string): string {
            return this.submissionLinkPerInsuredId[insuredId] || '';
        }

        private changeSelection(insured: InsuranceInsured): void {
            this.selectedIds = new Set<string>(this.selectedIds);
            this.selectedIds.has(insured.id) ? this.deselectInsured(insured) : this.selectInsured(insured);
        }

        private deselectInsured(insured: InsuranceInsured): void {
            this.selectedIds.delete(insured.id);
            const index = this.selectedInsureds.findIndex((selectedInsured) => selectedInsured.id === insured.id);
            this.selectedInsureds = [
                ...this.selectedInsureds.slice(0, index),
                ...this.selectedInsureds.slice(index + 1),
            ];
        }

        private selectInsured(insured: InsuranceInsured): void {
            this.selectedIds.add(insured.id);
            this.selectedInsureds = [ ...this.selectedInsureds, insured ];
        }

        private bulkDeactivateInsureds(): void {
            this.$emit('deactivateInsureds', { insureds: this.selectedInsureds, isBulk: true });
        }

        private bulkSetInsuredsPauseStatus(value: boolean): void {
            this.$emit('setInsuredsPauseState', { insureds: this.selectedInsureds, isBulk: true, value });
        }

        private get isEnabledSendCoiRequest(): boolean {
            return this.selectedInsureds.some(shouldEnableCoiRequest);
        }

        private sendCoiRequest(): void {
            this.$emit('sendCoiRequest', this.selectedInsureds);
        }
    }
</script>
