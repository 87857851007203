<template>
    <div class="DashboardConfiguration">
        <Page :loading="isLoading" title="Configuration">
            <ActionBar message="Insured Fields Display"
                :disabled="isPristine"
                @submit="submit"
            />
            <InsuredFieldsConfiguration
                :insured-fields="filteredInsuredFields"
                :selected-insured-field-ids="selectedInsuredFieldIds"
                @input="updateSelectedInsuredFields"
            />
            <ConfirmNavigationOnDirtyState :dirty="!isPristine" />
        </Page>
    </div>
</template>

<style lang="scss">
    .DashboardConfiguration {
        .ActionBar {
            margin: -15px -15px 25px;
            padding: 0 20px;
        }
    }
</style>

<script lang="ts">
    import { Component, Vue, Watch } from '@evidentid/vue-property-decorator';
    import isEqual from 'lodash/isEqual';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { InsuranceDashboardConfiguration, InsuranceInsuredField } from '@evidentid/rpweb-api-client/types';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { ConfirmNavigationOnDirtyState } from '@evidentid/dashboard-commons/components/ConfirmNavigationOnDirtyState';
    import ActionBar from '@evidentid/dashboard-commons/components/ActionBar/ActionBar.vue';
    import Page from '@/layouts/Page.vue';
    import InsuredFieldsConfiguration
        from '@/modules/dashboard-configuration/components/insured-fields-configuration/InsuredFieldsConfiguration.vue';
    import { JsonSchemaType, RegularJsonSchema } from '@evidentid/json-schema/interfaces/JsonSchema';

    @Component({
        components: { InsuredFieldsConfiguration, Page, ActionBar, Button, ConfirmNavigationOnDirtyState },
    })
    export default class DashboardConfiguration extends Vue {
        private lastRpName!: string;
        private selectedInsuredFieldIds: string[] = [];

        @Watch('$rp.current', { immediate: true })
        private handleRpChange(rpName: string, prevRpName: string) {
            // Save information about the last resource, which should be cleared during destroy
            this.lastRpName = rpName;
            this.loadInsuredFields();
            this.loadDashboardConfiguration();
            if (prevRpName) {
                this.$store.actions.dashboard.clearInsuredFieldsList({ rpName: prevRpName });
                this.$store.actions.dashboardConfiguration.clearDashboardConfiguration({ rpName: prevRpName });
            }
        }

        @Watch('dashboardConfigurationStatus', { immediate: true })
        private onDashboardConfigurationChange(): void {
            this.selectedInsuredFieldIds = this.dashboardConfigurationStatus.config?.insuredFields || [];
        }

        @Watch('updateDashboardConfigurationStatus')
        private onUpdateConfigurationStatusChange(): void {
            if (this.updateDashboardConfigurationStatus === OperationStatus.success) {
                this.loadDashboardConfiguration();
                this.$store.actions.dashboardConfiguration.clearUpdateDashboardConfigurationStatus(
                    { rpName: this.rpName },
                );
            }
            this.selectedInsuredFieldIds = this.dashboardConfigurationStatus.config?.insuredFields || [];
        }

        private get rpName(): string {
            return this.$rp.current!;
        }

        private get isLoading(): boolean {
            return this.insuredFieldsStatus.status === OperationStatus.loading ||
                this.dashboardConfigurationStatus.status === OperationStatus.loading ||
                this.updateDashboardConfigurationStatus === OperationStatus.loading;
        }

        private get insuredFieldsStatus(): { status: OperationStatus, list: InsuranceInsuredField[] } {
            return this.$store.state.dashboard.insuredFields[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get dashboardConfigurationStatus():
            { status: OperationStatus, config: InsuranceDashboardConfiguration } {
            return this.$store.state.dashboardConfiguration.configuration[this.rpName] || {
                status: OperationStatus.uninitialized,
                config: { insuredFields: [] },
            };
        }

        private get updateDashboardConfigurationStatus(): OperationStatus {
            return this.$store.state.dashboardConfiguration.updateStatus[this.rpName] || OperationStatus.uninitialized;
        }

        private get isPristine(): boolean {
            return isEqual(this.selectedInsuredFieldIds, this.dashboardConfigurationStatus.config?.insuredFields || []);
        }

        private get filteredInsuredFields(): InsuranceInsuredField[] {
            return this.insuredFieldsStatus.list.filter(
                (field) => (field.schema as RegularJsonSchema).type !== JsonSchemaType.array);
        }

        private loadInsuredFields() {
            if (this.insuredFieldsStatus.status !== OperationStatus.loading) {
                this.$store.actions.dashboard.loadInsuredFields({ rpName: this.rpName });
            }
        }

        private loadDashboardConfiguration() {
            if (this.dashboardConfigurationStatus.status !== OperationStatus.loading) {
                this.$store.actions.dashboardConfiguration.loadDashboardConfiguration({ rpName: this.rpName });
            }
        }

        private updateDashboardConfiguration(config: InsuranceDashboardConfiguration) {
            if (this.updateDashboardConfigurationStatus !== OperationStatus.loading) {
                this.$store.actions.dashboardConfiguration.updateDashboardConfiguration({
                    rpName: this.rpName,
                    config,
                });
            }
        }

        private updateSelectedInsuredFields(selectedFieldIds: string[]): void {
            this.selectedInsuredFieldIds = [ ...selectedFieldIds ];
        }

        private submit() {
            this.updateDashboardConfiguration({ insuredFields: this.selectedInsuredFieldIds.filter(Boolean) });
        }

        private destroyed() {
            this.$store.actions.dashboard.clearInsuredFieldsList({ rpName: this.lastRpName });
            this.$store.actions.dashboardConfiguration.clearDashboardConfiguration({ rpName: this.lastRpName });
            this.$store.actions.dashboardConfiguration.clearUpdateDashboardConfigurationStatus(
                { rpName: this.lastRpName },
            );
        }
    }
</script>
