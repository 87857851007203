<template>
    <Row class="CollateralListRow text-eidDarkBlue-75">
        <Cell
            v-for="({ name, field }) in columns"
            :key="name"
            :class="{'text-eidDangerRed':fieldHasEvaluationError(field)}"
            :data-test-id="`CollateralListRow__${name}Cell`"
        >
            <div v-tooltip="getFieldTooltipMessage(field)" class="inline-flex items-center">
                <EidIcon
                    v-if="fieldHasEvaluationError(field)"
                    class="text-eidDangerRed mr-1 text-xs"
                    data-test-id="CollateralListRow__errorIcon"
                    alt="error"
                    :icon-src="faExclamationTriangle"
                />
                <span data-test-id="CollateralListRow__value">
                    {{ getValue(field) }}
                </span>
            </div>
        </Cell>
    </Row>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
    import { Cell, Row } from '@evidentid/dashboard-commons/components/Table';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { getEnumDefaultDisplayValue } from '@/utils/getEnumDefaultDisplayValue';
    import { CoverageFieldCollateralLimit } from '@/modules/insured-details/models/CoverageFieldCollateralLimit.model';
    import { TableColumn } from '@/modules/insured-details/models/TableColumn.model';
    import { CollateralErrorType } from '@/modules/insured-details/models/CollateralErrorType.model';
    import { intersection } from 'lodash';
    import {
        getCollateralShortError,
        getPossibleErrorTypes,
        getTypesFromErrors,
    } from '@/modules/insured-details/utils/getCollateralErrorAndType/getCollateralErrorAndType';
    import { CollateralEntityDisplayField } from '@/modules/insured-details/models/CollateralEntityDisplayField.model';
    import {
        CoverageFieldCollateralLimitKey,
    } from '@/modules/insured-details/models/CoverageFieldCollateralLimitKey.model';
    import { currencyFormatter } from '@/modules/insured-details/utils/currencyFormatter';
    import { CategorizedEnumLabels } from '@/modules/dashboard/models/CategorizedEnumLabels.model';
    import { EnumCategories } from '@/modules/dashboard/models/EnumCategories.model';

    export default Vue.extend({
        name: 'CollateralListRow',
        components: {
            Cell,
            EidIcon,
            Row,
        },
        props: {
            collateralLimitType: {
                type: Object as PropType<CoverageFieldCollateralLimit>,
                required: true,
            },
            columns: {
                type: Array as PropType<TableColumn[]>,
                required: true,
            },
            nonCompliantMessages: {
                type: Array as PropType<string[]>,
                default: () => [],
                required: true,
            },
        },
        data() {
            return {
                faExclamationTriangle,
                cfcKeyAndEntityKeyMap: {
                    limitType: CollateralEntityDisplayField.coverageLimitType,
                    coverageLimit: CollateralEntityDisplayField.coverageLimit,
                    valuation: CollateralEntityDisplayField.coverageValuation,
                    deductible: CollateralEntityDisplayField.coverageDeductible,
                },
            };
        },
        computed: {
            categorizedEnumLabels(): CategorizedEnumLabels {
                // TODO(PRODUCT-18675): remove any after the migration to the newer version.
                return (this as any).$store.state.dashboard.categorizedEnumLabels;
            },
            errorTypes(): CollateralErrorType[] {
                return getTypesFromErrors(this.nonCompliantMessages);
            },
        },
        methods: {
            getValue(field: CoverageFieldCollateralLimitKey): unknown {
                const value = this.collateralLimitType[field];

                if (value == null) {
                    return '-';
                }

                if (field === 'limitType') {
                    return getEnumDefaultDisplayValue(
                        EnumCategories.collateralLimitType,
                        value,
                        this.categorizedEnumLabels,
                    );
                }

                if ([ 'coverageLimit', 'deductible' ].includes(field)) {
                    return currencyFormatter().format(Number(value));
                }

                return value;
            },
            getFieldErrorTypes(field: CoverageFieldCollateralLimitKey): CollateralErrorType[] {
                const entityField = this.cfcKeyAndEntityKeyMap[field] || field;
                const possibleErrorTypesOfField = getPossibleErrorTypes(entityField);
                return intersection(this.errorTypes, possibleErrorTypesOfField);
            },
            fieldHasEvaluationError(field: CoverageFieldCollateralLimitKey): boolean {
                return this.getFieldErrorTypes(field).length > 0;
            },
            getFieldTooltipMessage(field: CoverageFieldCollateralLimitKey): string | null {
                const errorTypes = this.getFieldErrorTypes(field);
                return errorTypes.length > 0
                    ? errorTypes.map(getCollateralShortError).join('\n')
                    : null;
            },
        },
    });
</script>
