import { InsuranceInsuredField } from '@evidentid/rpweb-api-client/types';
import {
    getObjectInsuredFieldPropLabel, getObjectInsuredFieldPropLabels,
} from '@/modules/insured-management/utils/get-object-insured-field-prop-labels/getObjectInsuredFieldPropLabels';
import { isObject } from '@evidentid/json-schema/schemaChecks';

function getObjectPropNames(field: InsuranceInsuredField): string[] {
    return ('propertiesOrder' in field.schema &&
        field.schema.propertiesOrder &&
        field.schema.propertiesOrder.length > 0)
        ? field.schema.propertiesOrder
            .map((key) => `${field.name} ${getObjectInsuredFieldPropLabel(field, key)}`)
        : getObjectInsuredFieldPropLabels(field);
}

export function getFlattenedInsuredFieldNames(insuredFields: InsuranceInsuredField[]): string[] {
    const names = [] as string[];
    insuredFields.forEach((field) => {
        if (isObject(field.schema)) {
            names.push(...getObjectPropNames(field));
        } else {
            names.push(field.name);
        }
    });
    return names;
}
