import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
import { SpecialCombinedCoverageFieldName } from '@/modules/insured-details/models/SpecialCombinedCoverageFieldName.model';
import {
    SpecialCombinedCoverageFieldTypeName,
} from '@/modules/insured-details/models/SpecialCombinedCoverageFieldTypeName.model';

function getCombineCommonNameTypeFieldError(
    fieldName: string,
    fieldType: string,
    evaluationErrors: Record<string, FieldEvaluationResultError>,
): FieldEvaluationResultError | null {
    const nameError = evaluationErrors[fieldName];
    const typeError = evaluationErrors[fieldType];
    if (!nameError && !typeError) {
        return null;
    }
    const nameMsg = nameError?.messages || [];
    const typeMsg = typeError?.messages || [];
    return { ...typeError, ...nameError, messages: [ ...nameMsg, ...typeMsg ] };
}

function combineAdditionalInsuredError(
    idPrefix: string,
    evaluationErrors: Record<string, FieldEvaluationResultError>,
): FieldEvaluationResultError | null {
    const nameKey = `${idPrefix}${SpecialCombinedCoverageFieldName.additionalInsured}`;
    const typeKey = `${idPrefix}${SpecialCombinedCoverageFieldTypeName.additionalInsuredType}`;
    return getCombineCommonNameTypeFieldError(
        nameKey,
        typeKey,
        evaluationErrors,
    );
}

function combineWosCompanyNameError(
    idPrefix: string,
    evaluationErrors: Record<string, FieldEvaluationResultError>,
): FieldEvaluationResultError | null {
    const nameKey = `${idPrefix}${SpecialCombinedCoverageFieldName.waiverOfSubrogationCompanyName}`;
    const typeKey = `${idPrefix}${SpecialCombinedCoverageFieldTypeName.waiverOfSubrogationCompanyNameType}`;
    return getCombineCommonNameTypeFieldError(
        nameKey,
        typeKey,
        evaluationErrors,
    );
}

type GetCombinedErrorFunction =
    (fieldId: string, errorMap: Record<string, FieldEvaluationResultError>) => FieldEvaluationResultError | null;
const fieldFunctionMap: Record<SpecialCombinedCoverageFieldName, GetCombinedErrorFunction> = {
    [SpecialCombinedCoverageFieldName.additionalInsured]: combineAdditionalInsuredError,
    [SpecialCombinedCoverageFieldName.waiverOfSubrogationCompanyName]: combineWosCompanyNameError,
};

export function getEvaluationErrorWithCombinedFields(
    fieldId: string,
    evaluationErrors: Record<string, FieldEvaluationResultError>,
): FieldEvaluationResultError | null {
    const fieldName = fieldId.split('/').pop();
    const prefix = fieldId.replace(new RegExp(/\w+$/gm), '');
    for (const [ key, getter ] of Object.entries(fieldFunctionMap)) {
        if (fieldName === key) {
            return getter(prefix, evaluationErrors);
        }
    }
    return evaluationErrors[fieldId] || null;
}
