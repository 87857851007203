<template>
    <Modal additional-class-name="CoverageCriteriaGroupSetupModal" open @close="abort">
        <template #header>
            Select Decisioning Criteria Group Type
        </template>
        <div class="CoverageCriteriaGroupSetupModal__content">
            <div v-if="collateralEnabled" data-test-id="CoverageCriteriaGroupSetupModal_typeSelection">
                <div
                    class="flex justify-around"
                    data-test-id="CoverageCriteriaGroupSetupModal__coverageTypesCcgContainer"
                >
                    <CcgTypeSelectionCard
                        :selected="groupType === defaultCcgTypeConfig.type"
                        :type="defaultCcgTypeConfig.type"
                        :title="defaultCcgTypeConfig.title"
                        :description="defaultCcgTypeConfig.description"
                        @input="selectCcgType"
                    />
                    <CcgTypeSelectionCard
                        :selected="groupType === collateralsTypeConfig.type"
                        :type="collateralsTypeConfig.type"
                        :title="collateralsTypeConfig.title"
                        :description="collateralsTypeConfig.description"
                        @input="selectCcgType"
                    />
                </div>
            </div>
            <FormElement
                class="CoverageCriteriaGroupSetupModal__groupName"
                label="Enter the name of the group."
            >
                <template #labelHint>
                    This can be edited later.
                    <span class="CoverageCriteriaGroupSetupModal__requiredHint">(Required)</span>
                </template>
                <FormInput>
                    <input v-model="groupName" type="text">
                </FormInput>
            </FormElement>
            <div class="CoverageCriteriaGroupSetupModal__contentText">
                Select the country for this coverage.
            </div>
            <Dropdown
                class="CoverageCriteriaGroupSetupModal__countryDropdown"
                :options="options"
                :selected="selectedOption"
                @input="selectOption"
            />
        </div>
        <template #footer>
            <div class="CoverageCriteriaGroupSetupModal__footer">
                <Button type="secondary" @click="abort">
                    Cancel
                </Button>
                <Button type="primary" :disabled="!canConfirm" @click="confirm">
                    Confirm
                </Button>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts">
    import { PropType } from 'vue';
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { InsuranceCoverageCriteriaGroupType } from '@evidentid/rpweb-api-client/types';
    import { Modal } from '@evidentid/dashboard-commons/components/Modal';
    import Dropdown from '@evidentid/dashboard-commons/components/Dropdown/Dropdown.vue';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { DropdownOption } from '@evidentid/dashboard-commons/components/Dropdown/types';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';
    import { getEnumDefaultDisplayValue } from '@/utils/getEnumDefaultDisplayValue';
    import CcgTypeSelectionCard
        from '@/modules/decisioning-criteria/components/CcgTypeSelectionCard/CcgTypeSelectionCard.vue';
    import { ConfirmEventPayload } from '@/modules/decisioning-criteria/models/ConfirmEventPayload.model';
    import { CategorizedEnumLabels } from '@/modules/dashboard/models/CategorizedEnumLabels.model';
    import { EnumCategories } from '@/modules/dashboard/models/EnumCategories.model';

    @Component({
        components: {
            Button,
            CcgTypeSelectionCard,
            Dropdown,
            EidIcon,
            FontAwesomeIcon,
            Modal,
            FormElement,
            FormInput,
        },
    })
    export default class CoverageCriteriaGroupSetupModal extends Vue {
        @Prop({ type: Array as PropType<string[]>, default: () => [] })
        private countryCodeList!: string[];

        @Prop({ type: Object as PropType<CategorizedEnumLabels>, default: () => ({}) })
        private categorizedEnumLabels!: CategorizedEnumLabels;

        @Prop({ type: Boolean as PropType<boolean>, default: false })
        private collateralEnabled!: boolean;

        private selectedCountryCode: DropdownOption | null = null;
        private groupName: string = '';
        private groupType: InsuranceCoverageCriteriaGroupType = InsuranceCoverageCriteriaGroupType.default;
        private defaultCcgTypeConfig = {
            title: 'Coverage Types',
            type: InsuranceCoverageCriteriaGroupType.default,
            description: 'Set assignment rules for specific insured and conditions for coverage types.',
        };
        private collateralsTypeConfig = {
            title: 'Collateral',
            type: InsuranceCoverageCriteriaGroupType.collateral,
            description: 'Choose coverage types, set assignment rules, map collateral, and set decision criteria.',
        };

        private get selectedOption(): DropdownOption[] {
            return this.selectedCountryCode ? [ this.selectedCountryCode ] : [];
        }

        private get options(): DropdownOption[] {
            return this.countryCodeList.map((code) => ({
                label:
                    getEnumDefaultDisplayValue(EnumCategories.countryCode, code, this.categorizedEnumLabels) as string,
                value: code.toUpperCase(),
            }));
        }

        private get defaultOption(): DropdownOption | null {
            const option = this.options.find((option) => option.value === 'US') || this.options[0] || null;
            return option ?
                {
                    ...option,
                    label: getEnumDefaultDisplayValue(
                        EnumCategories.countryCode, option.value, this.categorizedEnumLabels,
                    ),
                }
                : null;
        }

        private mounted() {
            this.selectedCountryCode = this.defaultOption;
        }

        private selectOption(option: DropdownOption): void {
            this.selectedCountryCode = option;
        }

        private get canConfirm(): boolean {
            return Boolean(this.groupName);
        }

        private confirm(): void {
            this.$emit('confirm', {
                countryCode: this.selectedCountryCode?.value,
                displayName: this.groupName,
                ccgType: this.groupType,
            } as ConfirmEventPayload);
        }

        private selectCcgType(type: InsuranceCoverageCriteriaGroupType): void {
            this.groupType = type;
        }

        private abort(): void {
            this.$emit('abort');
        }
    }
</script>
