import omit from 'lodash/omit';
import { InsuranceCoverageObjectType } from '@evidentid/insurance-facing-lib/models/insured-details';

type CoverageValue = string | null | undefined;

/* eslint-disable camelcase */
interface AddressV1 {
    street_number: CoverageValue;
    street_name: CoverageValue;
    street_unit: CoverageValue;
    city: CoverageValue;
    state: CoverageValue;
    postal_code: CoverageValue;
    postal_code_suffix: CoverageValue;
    country: CoverageValue;
}

interface AddressV2 {
    street_address: CoverageValue;
    street_address_2: CoverageValue;
    city: CoverageValue;
    state: CoverageValue;
    postal_code: CoverageValue;
    country: CoverageValue;
}

/* eslint-enable camelcase */

interface CoverageFieldAddress {
    country: CoverageValue;
    street: CoverageValue;
    aptSuiteOrUnit: CoverageValue;
    city: CoverageValue;
    state: CoverageValue;
    zipCode: CoverageValue;
}

interface CommonAddress extends CoverageFieldAddress {
    streetNumber: CoverageValue;
    zipCodeSuffix: CoverageValue;
}

function addressV2ToCommon(v2: AddressV2): CommonAddress {
    return {
        streetNumber: '',
        street: v2.street_address,
        aptSuiteOrUnit: v2.street_address_2,
        city: v2.city,
        state: v2.state,
        zipCode: v2.postal_code,
        zipCodeSuffix: '',
        country: v2.country,
    };
}

function addressV1ToCommon(v1: AddressV1): CommonAddress {
    return {
        streetNumber: v1.street_number,
        street: v1.street_name,
        aptSuiteOrUnit: v1.street_unit,
        city: v1.city,
        state: v1.state,
        zipCode: v1.postal_code,
        zipCodeSuffix: v1.postal_code_suffix,
        country: v1.country,
    };
}

function parseCountry(address: CommonAddress, countryLabels?: Record<string, any>): CommonAddress {
    if (address.country) {
        const label = countryLabels?.[address.country]?.label;
        return { ...address, country: (label || address.country) as CoverageValue };
    }
    return address;
}

function coverageFieldAddressToCommon(address: CoverageFieldAddress): CommonAddress {
    return {
        ...address,
        streetNumber: '',
        zipCodeSuffix: '',
    };
}

export function getCoverageAddressObjectString(
    addressObject?: Record<string, string | null | undefined>,
    countryLabels?: Record<string, any>,
): string {
    let addressString = '';
    let address = {} as CommonAddress;
    const coverageAddress = omit(addressObject, '$objectType') as unknown;

    if (addressObject) {
        switch (addressObject.$objectType) {
            case InsuranceCoverageObjectType.addressV2:
                address = addressV2ToCommon(coverageAddress as AddressV2);
                break;
            case InsuranceCoverageObjectType.coverageFieldAddress:
                address = coverageFieldAddressToCommon(coverageAddress as CoverageFieldAddress);
                break;
            default:
                address = addressV1ToCommon(coverageAddress as AddressV1);
                break;
        }
        address = parseCountry(address, countryLabels);
        const { streetNumber, aptSuiteOrUnit, street, zipCode, zipCodeSuffix, city, state, country } = address;
        const streetNumberAndName = [ streetNumber, street ].filter(Boolean).join(' ');
        const postal = [ zipCode, zipCodeSuffix ].filter(Boolean).join('-');
        const stateAndPostal = [ state, postal ].filter(Boolean).join(' ');
        addressString = [ streetNumberAndName, aptSuiteOrUnit, city, stateAndPostal, country ].filter(Boolean).join(', ');
    }

    return addressString.trim();
}
