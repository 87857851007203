import { InsuranceComplianceStatusMetadata } from '@evidentid/insurance-facing-lib/models/insured-details';
import { InsuranceInsuredCoverage, InsuranceInsuredCoverageDetails } from '@evidentid/rpweb-api-client/types';

export type AssignmentRuleValue = string | number | boolean;

export interface BasicAssignmentRule {
    operator: string;
    insuredFieldKey: string;
    value: AssignmentRuleValue;
}

export enum CoverageGroupAssignmentOptions {
    allInsureds = 'ALL_INSUREDS',
    subsetOfInsureds = 'SUBSET_OF_INSUREDS',
}

export interface FieldEvaluationResultErrorMessage {
    message: string;
    coverageCriteriaGroupName: string;
}

export interface FieldEvaluationResultError {
    evaluatedEntityId?: string;
    useDuringComplianceCalculation?: boolean;
    messages: FieldEvaluationResultErrorMessage[];
    coverageCriteriaGroupId: string;
    criterionId: string[];
    criterionNames: string[];
    coverageDetailsBelongTo: InsuranceInsuredCoverageDetails;
    complianceStatusMetadata: InsuranceComplianceStatusMetadata[];
    isMultiFieldCriterion: boolean;
}

export const coverageTypeFieldReferenceDelimiter = '-';

export enum GeneralAggregateLimitReferences {
    generalAggregateLimitThreshold = 'generalAggregateLimitThreshold',
    conditionSets = 'conditionSets',
}
