<template>
    <div class="DecisioningCriteria">
        <Page :loading="isLoading" title="Decisioning Criteria" non-destructive-on-loading>
            <CreateEditCoverageCriteriaGroup
                :insured-fields-status="insuredFieldsStatus"
                :loading-insured-fields="loadingInsuredFields"
                :group="localGroup"
                :coverage-types="coverageTypes"
                :coverage-models="getCoverageModelsWithCountryLabel"
                :criteria-templates="templatesStatus.list"
                :criteria="localCriteria"
                :original-criteria="criteriaCopy"
                :current-coverage-type="coverageType"
                :loading-coverage-types-and-countries="loadingCoverageTypesAndCountries"
                :collateral-enabled="isCollateralEnabled"
                @load-insured-fields="loadInsuredFields"
                @selectCoverageType="selectCoverageType"
                @deselectCoverageType="deselectCoverageType"
                @groupInput="onGroupInput"
                @criteriaInput="onCriteriaInput"
                @save="save"
                @revert-ccg-changes="revertChanges"
            />
        </Page>
        <CoverageCriteriaGroupSetupModal
            v-if="showCoverageCriteriaGroupSetupModal && !isLoading"
            :country-code-list="countryCodeList"
            :categorized-enum-labels="categorizedEnumLabels"
            :collateral-enabled="isCollateralEnabled"
            confirm-only
            @confirm="confirmCountryCode"
            @abort="abortCountryCodeConfirmation"
        />
        <ConfirmNavigationOnDirtyState v-if="!isLoading" :dirty="modified" ignore-query />
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Watch } from '@evidentid/vue-property-decorator';
    import noop from 'lodash/noop';
    import omit from 'lodash/omit';
    import isEqual from 'lodash/isEqual';
    import cloneDeep from 'lodash/cloneDeep';
    import Page from '@/layouts/Page.vue';
    import {
        ConfirmNavigationOnDirtyState,
    } from '@evidentid/dashboard-commons/components/ConfirmNavigationOnDirtyState';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { Tab, Tabs } from '@evidentid/dashboard-commons/components/Tabs';
    import { createEmptyRequestsConfigStatus } from '@/modules/dashboard/vuex/dashboard';
    import CoverageCriteriaGroups
        from '@/modules/decisioning-criteria/components/CoverageCriteriaGroups/CoverageCriteriaGroups.vue';
    import CreateEditCoverageCriteriaGroup
        from '@/modules/decisioning-criteria/components/CreateEditCoverageCriteriaGroup/CreateEditCoverageCriteriaGroup.vue';
    import {
        InsuranceCoverageCriteriaGroup,
        InsuranceCoverageCriteriaGroupInput,
        InsuranceCoverageCriteriaGroupType,
        InsuranceCoverageCriterion,
        InsuranceCoverageCriterionInput,
        InsuranceCoverageModel,
        InsuranceInsuredField,
        InsuranceRequestsConfig,
    } from '@evidentid/rpweb-api-client/types';
    import { InsuranceCoverageType } from '@evidentid/insurance-facing-lib/models/insured-details';
    import {
        CoverageCriteriaGroupStatus,
        CoverageCriteriaStatus,
        CoverageCriterionTemplatesStatus,
        CoverageModelsStatus,
        CoverageTypesPerCountryStatus,
    } from '@/modules/decisioning-criteria/vuex';
    import { convertCriteriaChangesToPatches } from '@/modules/decisioning-criteria/utils/coverageCriteria';
    import { syncCriteriaWithTemplates } from '@/modules/decisioning-criteria/utils/syncCriteriaWithTemplates';
    import {
        syncAdditionalInsuredCriteriaWithTemplate,
    } from '@/modules/decisioning-criteria/utils/syncAdditionalInsuredCriteriaWithTemplate';
    import CoverageCriteriaGroupSetupModal
        from '@/modules/decisioning-criteria/components/CoverageCriteriaGroupSetupModal/CoverageCriteriaGroupSetupModal.vue';
    import { ConfirmEventPayload } from '@/modules/decisioning-criteria/models/ConfirmEventPayload.model';
    import { CategorizedEnumLabels } from '@/modules/dashboard/models/CategorizedEnumLabels.model';
    import { getCoverageTypeLabelByCountry } from '@/utils/getCoverageTypeLabelByCountry/getCoverageTypeLabelByCountry';
    import {
        CCG_AUTO_ADD_UMBRELLA_CREATE_GROUP_ID,
        assignCreateGroupAutoUmbrellaStatusId,
        confirmDisablingCcgAutoAddUmbrella,
    } from '@/modules/decisioning-criteria/utils/autoAddUmbrella';

    @Component({
        components: {
            ConfirmNavigationOnDirtyState,
            CoverageCriteriaGroupSetupModal,
            CoverageCriteriaGroups,
            CreateEditCoverageCriteriaGroup,
            Page,
            Tab,
            Tabs,
        },
    })
    export default class CoverageCriteriaGroup extends Vue {
        private lastRpName!: string;
        private localGroup: InsuranceCoverageCriteriaGroupInput | InsuranceCoverageCriteriaGroup =
            this.getDefaultEmptyGroup();
        private isGroupNameInputInModal: boolean = false;
        private syncedCriteria: (InsuranceCoverageCriterion | InsuranceCoverageCriterionInput)[] = [];
        private localCriteria: (InsuranceCoverageCriterion | InsuranceCoverageCriterionInput)[] = [];
        private forcedNavigate: boolean = false;
        private groupCopy = this.getDefaultEmptyGroup();
        private criteriaCopy: (InsuranceCoverageCriterion | InsuranceCoverageCriterionInput)[] = [];

        private get rpName(): string {
            return this.$rp.current!;
        }

        private get showCoverageCriteriaGroupSetupModal(): boolean {
            const { countryCode, displayName } = this.currentGroupStatus.data || {};
            const hasCountryCode = countryCode || this.localGroup.countryCode;
            const hasGroupName = displayName || this.isGroupNameInputInModal;
            return !hasCountryCode || !hasGroupName;
        }

        private get groupId(): string {
            return this.$route.params.id;
        }

        private get coverageType(): string | null {
            const currentCoverageType = this.$route.query?.coverageType || null;
            return currentCoverageType && typeof currentCoverageType === 'string' ? currentCoverageType : null;
        }

        private get group(): InsuranceCoverageCriteriaGroupInput | InsuranceCoverageCriteriaGroup {
            return this.currentGroupStatus.data || this.getDefaultEmptyGroup();
        }

        private get criteria(): InsuranceCoverageCriterion[] {
            const criteria = this.groupId ? this.criteriaStatus.criteria[this.groupId] || [] : [];
            const templateAdditionalInsured = this.templatesStatus.list
                .find((template) => template.field === 'coverage.endorsements.additionalInsured');

            if (!templateAdditionalInsured) {
                return criteria;
            }

            return syncAdditionalInsuredCriteriaWithTemplate(criteria, templateAdditionalInsured);
        }

        private get currentGroupStatus(): CoverageCriteriaGroupStatus {
            return this.$store.state.decisioningCriteria.currentCoverageCriteriaGroup[this.rpName] || {
                status: OperationStatus.uninitialized,
                data: null,
            };
        }

        private get criteriaStatus(): CoverageCriteriaStatus {
            return this.$store.state.decisioningCriteria.coverageCriteria[this.rpName] || {
                status: OperationStatus.uninitialized,
                criteria: {},
            };
        }

        private get templatesStatus(): CoverageCriterionTemplatesStatus {
            return this.$store.state.decisioningCriteria.coverageCriterionTemplates[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get coverageTypesPerCountryStatus(): CoverageTypesPerCountryStatus {
            return this.$store.state.decisioningCriteria.coverageTypesPerCountry[this.rpName] || {
                status: OperationStatus.uninitialized,
                data: {},
            };
        }

        private get creatingGroupStatus(): OperationStatus {
            return this.$store.state.decisioningCriteria.creatingCoverageCriteriaGroupStatus[this.rpName] ||
                OperationStatus.uninitialized;
        }

        private get creatingCriteriaStatus(): OperationStatus {
            return this.$store.state.decisioningCriteria.creatingCoverageCriteriaStatus[this.rpName] ||
                OperationStatus.uninitialized;
        }

        private get patchingCriteriaStatus(): OperationStatus {
            return this.$store.state.decisioningCriteria.patchingCoverageCriteriaStatus[this.rpName] ||
                OperationStatus.uninitialized;
        }

        private get updatingGroupStatus(): OperationStatus {
            return this.$store.state.decisioningCriteria.updatingCoverageCriteriaGroupStatus[this.rpName] ||
                OperationStatus.uninitialized;
        }

        private get coverageModelsStatus(): CoverageModelsStatus {
            return this.$store.state.decisioningCriteria.coverageModelsStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get loadingCoverageTypesAndCountries(): boolean {
            return this.coverageTypesPerCountryStatus.status === OperationStatus.loading;
        }

        private get loadingCurrentGroup(): boolean {
            return this.currentGroupStatus.status === OperationStatus.loading;
        }

        private get loadingCriteria(): boolean {
            return this.criteriaStatus.status === OperationStatus.loading;
        }

        private get loadingTemplates(): boolean {
            return this.templatesStatus.status === OperationStatus.loading;
        }

        private get savingGroup(): boolean {
            return this.creatingGroupStatus === OperationStatus.loading ||
                this.updatingGroupStatus === OperationStatus.loading;
        }

        private get savingCriteria(): boolean {
            return this.creatingCriteriaStatus === OperationStatus.loading ||
                this.patchingCriteriaStatus === OperationStatus.loading;
        }

        private get isLoadingCoverageModels(): boolean {
            return this.coverageModelsStatus.status === OperationStatus.loading;
        }

        private get isLoading(): boolean {
            return this.loadingCoverageTypesAndCountries ||
                this.loadingTemplates ||
                this.loadingCurrentGroup ||
                this.loadingCriteria ||
                this.savingGroup ||
                this.savingCriteria ||
                this.isLoadingCoverageModels;
        }

        private get insuredFieldsStatus(): { status: OperationStatus, list: InsuranceInsuredField[] } {
            return this.$store.state.dashboard.insuredFields[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get loadingInsuredFields(): boolean {
            return this.insuredFieldsStatus.status === OperationStatus.loading;
        }

        private get countryCode(): string {
            return this.currentGroupStatus.data?.countryCode
                || (this.localGroup as InsuranceCoverageCriteriaGroupInput).countryCode
                || 'US';
        }

        private get countryCodeList(): string[] {
            return Object.keys(this.coverageTypesPerCountryStatus.data);
        }

        private get categorizedEnumLabels(): CategorizedEnumLabels {
            return this.$store.state.dashboard.categorizedEnumLabels;
        }

        private get coverageTypes(): InsuranceCoverageType[] {
            return this.coverageTypesPerCountryStatus.data[this.countryCode]?.coverageTypes
                .filter((coverageType) => coverageType !== InsuranceCoverageType.brokerInfo) || [];
        }

        private get modified(): boolean {
            return this.forcedNavigate
                ? false
                : !isEqual(this.group, this.localGroup) || !isEqual(this.syncedCriteria, this.localCriteria);
        }

        private get getCoverageModelsWithCountryLabel(): InsuranceCoverageModel[] {
            const countryCode = this.localGroup.countryCode;
            return this.coverageModelsStatus.list.map((model) => {
                const label = getCoverageTypeLabelByCountry(
                    model.coverageType, countryCode, this.categorizedEnumLabels,
                );
                return { ...model, label };
            });
        }

        @Watch('$route')
        private onRouteChange(route: any, prevRoute: any): void {
            const isSwitchRp = route.params.id === prevRoute.params.id && route.params.rpId !== prevRoute.params.rpId;
            if (isSwitchRp) {
                this.clearPageData(prevRoute.params.rpId);
                this.$router.push({
                    name: 'insuredDecisioningCriteria',
                    params: {
                        rpId: route.params.rpId,
                    },
                }).catch((error) => {
                    if (error.message.includes('Navigation cancelled')) {
                        return;
                    }
                    throw error;
                });
            } else if (!isEqual(route.params, prevRoute.params)) {
                this.loadPageData();
            }
        }

        @Watch('$rp.current', { immediate: true })
        private handleRpChange(rpName: string, prevRpName: string) {
            // Save information about the last resource, which should be cleared during destroy
            this.lastRpName = rpName;
            if (prevRpName) {
                this.clearPageData(prevRpName);
                this.$router.push({
                    name: 'insuredDecisioningCriteria',
                    params: {
                        rpId: this.$route.params.rpId,
                    },
                });
            } else {
                this.loadPageData();
            }
        }

        @Watch('group', { immediate: true })
        private onGroupChange(current: InsuranceCoverageCriteriaGroup, prev: InsuranceCoverageCriteriaGroup) {
            if (!isEqual(current, prev)) {
                this.localGroup = { ...this.group };
                this.groupCopy = cloneDeep(this.localGroup);
            }
        }

        @Watch('criteria', { immediate: true })
        @Watch('templatesStatus.list', { immediate: true })
        private onCriteriaChange(current: InsuranceCoverageCriterion[], prev: InsuranceCoverageCriterion[]) {
            if (!isEqual(current, prev)) {
                // syncedCriteria to not trigger unsaved modal when we sync(due to data issue) as it's not user action
                this.syncedCriteria = syncCriteriaWithTemplates(this.criteria, this.templatesStatus.list);
                this.localCriteria = [ ...this.syncedCriteria ];
                this.criteriaCopy = cloneDeep(this.localCriteria);
            }
        }

        private async loadPageData(): Promise<void> {
            await this.loadCurrentGroup();
            this.loadCriteria();
            this.loadTemplates();
            this.loadCoverageTypesAndCountries();
            this.loadInsuredFields();
            // FIXME: temporary flag, remove once all rp migrated to decisioning engine
            this.loadCoverageModels();
        }

        private async loadCurrentGroup(): Promise<void> {
            if (!this.loadingCurrentGroup && this.groupId != null) {
                await this.$store.actions.decisioningCriteria.loadCoverageCriteriaGroup({
                    rpName: this.rpName,
                    id: this.groupId,
                });
            }
        }

        private loadCriteria(): void {
            if (!this.loadingCriteria && this.groupId != null) {
                this.$store.actions.decisioningCriteria.loadCoverageCriteria({
                    rpName: this.rpName,
                    groupId: this.groupId,
                });
            }
        }

        private loadTemplates(): void {
            if (!this.loadingTemplates && this.localGroup?.countryCode) {
                this.$store.actions.decisioningCriteria.loadCoverageCriterionTemplates({
                    rpName: this.rpName,
                    countryCode: this.localGroup.countryCode,
                });
            }
        }

        private loadCoverageTypesAndCountries(): void {
            if (!this.loadingCoverageTypesAndCountries) {
                this.$store.actions.decisioningCriteria.loadCoverageTypesPerCountry({ rpName: this.rpName });
            }
        }

        private loadInsuredFields() {
            if (!this.loadingInsuredFields) {
                this.$store.actions.dashboard.loadInsuredFields({ rpName: this.rpName });
            }
        }

        private async save(data: {
            group: InsuranceCoverageCriteriaGroup | InsuranceCoverageCriteriaGroupInput;
            criteria: InsuranceCoverageCriterionInput[] | InsuranceCoverageCriterion[];
        }): Promise<void> {
            // TODO: consider not to emit group, and criteria since it's been updated on input. emit active or discovery
            const { group, criteria } = data;
            if ('id' in group && group.id) {
                await this.patchCriteria(
                    (group as InsuranceCoverageCriteriaGroup).id,
                    criteria,
                );
                await this.updateCriteriaGroup(group as InsuranceCoverageCriteriaGroup);
                await this.loadCurrentGroup();
                this.loadCriteria();
                confirmDisablingCcgAutoAddUmbrella(group.id);
            } else {
                await this.createCriteriaGroup(group as InsuranceCoverageCriteriaGroupInput);
                await this.createCriteria(
                    this.currentGroupStatus.data?.id || null,
                    criteria as InsuranceCoverageCriterionInput[],
                );
                if (this.currentGroupStatus.data?.id) {
                    // for auto add umbrella coverage type feature: confirm disabling auto add, and record to session
                    confirmDisablingCcgAutoAddUmbrella(CCG_AUTO_ADD_UMBRELLA_CREATE_GROUP_ID);
                    // carry over autoUmbrellaStatus in session storage since group created
                    assignCreateGroupAutoUmbrellaStatusId(this.currentGroupStatus.data.id);
                    // since create group view does not associate with an id, the data to create group is saved to a
                    // new group thus at this navigation to the new group will always have dirty state, thus reset dirty
                    // state before navigated to newly created group
                    this.forcedNavigate = true;
                    this.$router.push({
                        name: 'insuredDecisioningCriteriaGroup',
                        params: {
                            rpId: this.$route.params.rpId,
                            id: this.currentGroupStatus.data.id,
                        },
                    });
                }
            }
        }

        private async createCriteriaGroup(group: InsuranceCoverageCriteriaGroupInput): Promise<void> {
            if (this.creatingGroupStatus !== OperationStatus.loading) {
                await this.$store.actions.decisioningCriteria.createCoverageCriteriaGroup({
                    rpName: this.rpName,
                    group,
                });
            }
        }

        private async updateCriteriaGroup(group: InsuranceCoverageCriteriaGroup): Promise<void> {
            if (this.updatingGroupStatus !== OperationStatus.loading) {
                await this.$store.actions.decisioningCriteria.updateCoverageCriteriaGroup({
                    rpName: this.rpName,
                    group,
                });
            }
        }

        private async createCriteria(
            groupId: string | null,
            criteria: InsuranceCoverageCriterionInput[],
        ): Promise<void> {
            if (groupId && this.creatingCriteriaStatus !== OperationStatus.loading) {
                await this.$store.actions.decisioningCriteria.createCoverageCriteria({
                    rpName: this.rpName,
                    groupId,
                    criteria,
                });
            }
        }

        private async patchCriteria(
            groupId: string,
            criteria: InsuranceCoverageCriterionInput[] | InsuranceCoverageCriterion[],
        ): Promise<void> {
            const patches = convertCriteriaChangesToPatches(this.criteria, criteria);
            if (this.patchingCriteriaStatus !== OperationStatus.loading) {
                await this.$store.actions.decisioningCriteria.patchCoverageCriteria({
                    rpName: this.rpName,
                    groupId,
                    patches,
                });
            }
        }

        private confirmCountryCode({ countryCode, displayName, ccgType }: ConfirmEventPayload): void {
            this.isGroupNameInputInModal = true;
            this.localGroup = { ...this.localGroup, countryCode, displayName, ccgType };
            this.loadTemplates();
        }

        private abortCountryCodeConfirmation(): void {
            this.$router.push({
                name: 'insuredDecisioningCriteria',
                params: {
                    rpId: this.$route.params.rpId,
                },
            });
        }

        private selectCoverageType(coverageType: InsuranceCoverageType): void {
            this.$router.push({
                ...this.$route,
                query: { ...this.$route.query, coverageType },
            } as any).catch(noop);
        }

        private deselectCoverageType(): void {
            this.$router.push({
                ...this.$route,
                query: {
                    ...omit(this.$route.query, 'coverageType'),
                },
            } as any).catch(noop);
        }

        private onGroupInput(group: InsuranceCoverageCriteriaGroup): void {
            this.localGroup = { ...group };
        }

        private onCriteriaInput(criteria: (InsuranceCoverageCriterion | InsuranceCoverageCriterionInput)[]) {
            this.localCriteria = [ ...criteria ];
        }

        private clearPageData(rp?: string) {
            const rpName = rp || this.lastRpName;
            this.$store.actions.decisioningCriteria.clearCurrentCoverageCriteriaGroups({ rpName });
            this.$store.actions.dashboard.clearCoverageTypeList({ rpName });
            this.$store.actions.decisioningCriteria.clearCreatingCoverageCriteriaGroupStatus({ rpName });
            this.$store.actions.decisioningCriteria.clearUpdatingCoverageCriteriaGroupStatus({ rpName });
            this.$store.actions.decisioningCriteria.clearCreatingCoverageCriteriaStatus({ rpName });
            this.$store.actions.decisioningCriteria.clearPatchingCoverageCriteriaStatus({ rpName });
            this.$store.actions.decisioningCriteria.clearRpCoverageCriteria({ rpName });
            this.$store.actions.decisioningCriteria.clearCoverageCriterionMessage({ rpName });
            this.$store.actions.dashboard.clearInsuredFieldsList({ rpName });
            this.$store.actions.decisioningCriteria.clearCoverageModelsStatus({ rpName: this.lastRpName });
        }

        private get isCollateralEnabled(): boolean {
            return this.requestsConfigStatus.data?.collateralsEvaluationEnabled || false;
        }

        private get requestsConfigStatus(): {
            status: OperationStatus;
            data: InsuranceRequestsConfig | null;
        } {
            return this.$store.state.dashboard.requestsConfig[this.rpName || ''] || createEmptyRequestsConfigStatus();
        }

        private get isLoadingRequestsConfig(): boolean {
            return this.requestsConfigStatus.status === OperationStatus.loading;
        }

        private loadCoverageModels() {
            if (!this.isLoadingCoverageModels) {
                this.$store.actions.decisioningCriteria.loadCoverageModels({ rpName: this.rpName || '' });
            }
        }

        private revertChanges(): void {
            this.localGroup = this.groupCopy;
            this.localCriteria = this.criteriaCopy;
        }

        private getDefaultEmptyGroup(): InsuranceCoverageCriteriaGroupInput {
            return {
                displayName: '',
                active: false,
                ccgType: InsuranceCoverageCriteriaGroupType.default,
                numberOfCoverageTypes: null,
                numberOfInsuredsAssigned: null,
                assignmentRule: true,
                countryCode: null,
            };
        }

        private destroyed() {
            this.clearPageData();
        }
    }
</script>
