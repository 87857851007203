<template>
    <div class="ObjectFilterInput">
        <div class="ObjectFilterInput__title">
            {{ form.schema.title }}
        </div>
        <div
            v-if="form"
            class="ObjectFilterInput__inputContainer"
        >
            <div
                v-for="prop of form.getProperties()"
                :key="prop.name"
                class="ObjectFilterInput__subFieldContainer"
            >
                <CountryFilterInput
                    v-if="isCountrySchema(prop.form.schema)"
                    :value="value ? value[getPropFilterKey(prop.name)] : undefined"
                    :label="prop.form.schema.title"
                    :countries="getCountries(prop.form.schema)"
                    :country-labels="getCountryLabels(prop.form.schema)"
                    @input="onFilterInput($event, prop.name)"
                    @scroll-to="$emit('scroll-to', $event)"
                />
                <BasicFilterInput
                    v-else
                    :value="value ? value[getPropFilterKey(prop.name)] : undefined"
                    :type="prop.form.schema.type"
                    :label="prop.form.schema.title"
                    :placeholder="getPlaceholder(prop.form.schema)"
                    @input="onFilterInput($event, prop.name)"
                    @scroll-to="$emit('scroll-to', $event)"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import JsonSchema, { JsonSchemaEnum } from '@evidentid/json-schema/interfaces/JsonSchema';
    import createForm from '@evidentid/json-schema/createForm';
    import JsonSchemaFormElement
        from '@evidentid/dashboard-commons/components/JsonSchemaForm/JsonSchemaFormElement.vue';
    import { removeNonStandardSchemaFormat } from '@/modules/decisioning-criteria/utils/removeNonStandardSchemaFormat';
    import BasicFilterInput from '@/modules/insured-filtering/components/BasicFilterInput/BasicFilterInput.vue';
    import { InsuranceSchema, InsuranceSchemaDisplayFormat } from '@evidentid/rpweb-api-client/types';
    import CountryFilterInput from '@/modules/insured-filtering/components/CountryFilterInput/CountryFilterInput.vue';
    import { isEnum } from '@evidentid/json-schema/schemaChecks';

    export default Vue.extend({
        name: 'ObjectFilterInput',
        components: {
            CountryFilterInput,
            BasicFilterInput,
        },
        props: {
            type: {
                type: String as PropType<'text' | 'number' | 'integer' | 'boolean'>,
                default: 'text',
            },
            label: {
                type: String as PropType<string>,
                default: '',
            },
            placeholder: {
                type: String as PropType<string>,
                default: '',
            },
            value: {
                type: [ String, Number, Object ] as PropType<string | number | object | null | undefined>,
                default: undefined,
            },
            schema: {
                type: Object as PropType<JsonSchema>,
                required: true,
            },
            filterKey: {
                type: String as PropType<string>,
                required: true,
            },
        },
        data() {
            return {
                form: this.schema ? createForm(removeNonStandardSchemaFormat(this.schema)) : null,
                JsonSchemaFormElement,
            };
        },
        methods: {
            getPlaceholder(schema: JsonSchema): string {
                if ('displayFormat' in schema &&
                    (schema as InsuranceSchema).displayFormat === InsuranceSchemaDisplayFormat.country
                ) {
                    return 'Select Country';
                }
                return schema.description || `Type ${schema.title}`;
            },
            onFilterInput(value: any, propName: string): void {
                this.$emit('input', { filterKey: this.getPropFilterKey(propName), value });
            },
            isCountrySchema(schema: JsonSchema): boolean {
                return isEnum(schema) &&
                    'displayFormat' in schema &&
                    (schema as InsuranceSchema).displayFormat === InsuranceSchemaDisplayFormat.country;
            },
            getCountries(schema: JsonSchema): string[] {
                return (schema as JsonSchemaEnum).enum || [];
            },
            getCountryLabels(schema: JsonSchema): string[] {
                return (schema as JsonSchemaEnum).enumLabels || [];
            },
            getPropFilterKey(propName: string): string {
                return `${this.filterKey}.${propName}`;
            },
        },
    });
</script>
