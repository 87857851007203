import RpWebApiClient from '@evidentid/rpweb-api-client';
import { createStateFactory } from '@evidentid/vue-commons/store';
import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
import omit from 'lodash/omit';
import { InsuranceSendCoiRequestResponse, InsuranceSendCoiRequestSuccess } from '@evidentid/rpweb-api-client/types';

function mergeBatchResults(result: InsuranceSendCoiRequestResponse[]): InsuranceSendCoiRequestResponse {
    return result.reduce((result, partialResult) => ({
        totalCount: result.totalCount + partialResult.totalCount,
        successCount: result.successCount + partialResult.successCount,
        failureCount: result.failureCount + partialResult.failureCount,
        successes: [ ...result.successes, ...partialResult.successes ],
        failures: [ ...result.failures, ...partialResult.failures ],
    }));
}

export interface InsuredRequestRequirement {
    rpweb: RpWebApiClient;
}

const createState = createStateFactory<InsuredRequestRequirement>();

export interface SendRequestStatus {
    status: OperationStatus;
    progress: number;
    insuredIds: string[];
    totalCount: number;
    successCount: number;
    failureCount: number;
    successOnRequestCount: number;
    failedOnRequestCount: number;
    successes: InsuranceSendCoiRequestSuccess[];
    failures: string[];
}

interface SendRequestState {
    sendCoiRequestStatus: Record<string, SendRequestStatus>;
}

const { instantiateState, createMutationsFactories } = createState<SendRequestState>(() => ({
    sendCoiRequestStatus: {},
}));

const { instantiateMutations, createActionFactories } = createMutationsFactories(() => ({

    startSendingCoiRequests(payload: { rpName: string, insuredIds: string[] }) {
        this.sendCoiRequestStatus = {
            ...this.sendCoiRequestStatus,
            [payload.rpName]: {
                status: OperationStatus.loading,
                progress: 0,
                insuredIds: payload.insuredIds,
                totalCount: payload.insuredIds.length,
                successCount: 0,
                failureCount: 0,
                successOnRequestCount: 0,
                failedOnRequestCount: 0,
                failures: [],
                successes: [],
            },
        };
    },
    failSendingCoiRequests(payload: { rpName: string }) {
        this.sendCoiRequestStatus = {
            ...this.sendCoiRequestStatus,
            [payload.rpName]: {
                status: OperationStatus.error,
                progress: 0,
                insuredIds: this.sendCoiRequestStatus[payload.rpName]?.insuredIds || [],
                totalCount: this.sendCoiRequestStatus[payload.rpName]?.totalCount || 0,
                successCount: 0,
                failureCount: this.sendCoiRequestStatus[payload.rpName]?.totalCount || 0,
                successOnRequestCount: 0,
                failedOnRequestCount: 0,
                failures: [],
                successes: [],
            },
        };
    },
    progressSendingCoiRequests(payload: {
        rpName: string;
        progress: number;
    }) {
        this.sendCoiRequestStatus = {
            ...this.sendCoiRequestStatus,
            [payload.rpName]: {
                ...this.sendCoiRequestStatus[payload.rpName],
                progress: payload.progress,
            },
        };
    },
    finishSendingCoiRequests(payload: {
        rpName: string;
        insuredIds: string[];
        totalCount: number;
        successCount: number;
        failureCount: number;
        successOnRequestCount: number;
        failedOnRequestCount: number;
        successes: any[];
        failures: any[];
    }) {
        this.sendCoiRequestStatus = {
            ...this.sendCoiRequestStatus,
            [payload.rpName]: {
                status: OperationStatus.success,
                progress: 100,
                insuredIds: this.sendCoiRequestStatus[payload.rpName]?.insuredIds || [],
                totalCount: payload.totalCount,
                successCount: payload.successCount,
                failureCount: payload.failureCount,
                successOnRequestCount: payload.successOnRequestCount,
                failedOnRequestCount: payload.failedOnRequestCount,
                failures: payload.failures,
                successes: payload.successes,
            },
        };
    },
    clearSendingCoiRequests(rpName: string) {
        this.sendCoiRequestStatus = omit(this.sendCoiRequestStatus, [ rpName ]);
    },
}));

const {
    instantiateActions,
    instantiateModule,
    getActions,
} = createActionFactories(({ rpweb }: InsuredRequestRequirement) => ({

    async sendCoiRequest(payload: {
        rpName: string; insuredIds: string[];
    }) {
        const { rpName, insuredIds } = payload;
        try {
            this.mutations.startSendingCoiRequests({ rpName, insuredIds });
            const updateProgress = (progress: number) => {
                this.mutations.progressSendingCoiRequests({ rpName, progress });
            };
            const batchResults = await rpweb.sendInsuredCoiRequest(rpName, insuredIds, 25, updateProgress);
            const results = mergeBatchResults(batchResults);
            const successOnRequestCount = results.totalCount;
            const failedOnRequestCount = (insuredIds?.length - results.totalCount) || 0;
            if (insuredIds.length > 0 && failedOnRequestCount === insuredIds.length) {
                this.mutations.failSendingCoiRequests({ rpName });
            } else {
                this.mutations.finishSendingCoiRequests({
                    rpName,
                    ...results,
                    insuredIds,
                    totalCount: insuredIds?.length || 0,
                    successOnRequestCount,
                    failedOnRequestCount,
                });
            }
        } catch (error) {
            console.warn('Send request error', error);
            this.mutations.failSendingCoiRequests({ rpName });
        }
    },
    clearSendCoiRequestStatus(payload: { rpName: string }) {
        this.mutations.clearSendingCoiRequests(payload.rpName);
    },
}));

export default {
    instantiateState,
    instantiateActions,
    instantiateMutations,
    instantiateModule,
    getActions,
};
