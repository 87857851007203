<template>
    <Alert class="CoverageCriteriaDangerMessage" type="danger" rounded>
        <EidIcon
            class="CoverageCriteriaDangerMessage__icon"
            alt="icon"
            :icon-src="icon"
            :svg-props="{color: alertRedColor}"
        />
        <slot />
    </Alert>
</template>

<script lang="ts">
    import Vue from 'vue';
    import Alert from '@evidentid/dashboard-commons/components/Alert/Alert.vue';
    import exclamationCircle from '@/modules/insured-details/assets/exclamation-circle.svg';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { tailwindColors as colors } from '@/styles/variables/tailwind-colors/tailwindColors';

    export default Vue.extend({
        name: 'CoverageCriteriaDangerMessage',
        components: {
            Alert,
            EidIcon,
        },
        data() {
            return {
                alertRedColor: colors.eidDangerRed.DEFAULT,
                icon: exclamationCircle,
            };
        },
    });
</script>
