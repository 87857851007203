import {
    InsuranceCoverageCriterion,
    InsuranceCoverageCriterionInput,
    InsuranceCoverageCriterionTemplate,
} from '@evidentid/rpweb-api-client/types';
import { InsuranceCoverageType } from '@evidentid/insurance-facing-lib/models/insured-details';

export const isSpecialConditionSetsCriterion = (
    criterion: InsuranceCoverageCriterion | InsuranceCoverageCriterionInput
): boolean => criterion.coverageType === InsuranceCoverageType.commercialGeneralLiability &&
     criterion.field === 'coverage.generalAggregateLimit' &&
     Boolean(criterion.evaluator.references.conditionSets);

export const isInformationBannerVisible = (
    criterion: InsuranceCoverageCriterion | InsuranceCoverageCriterionInput,
    template: InsuranceCoverageCriterionTemplate
): boolean => Boolean(template.displayMetadata.informationText) || isSpecialConditionSetsCriterion(criterion);
