<template>
    <div v-if="isNonExtractionCriterion"
        class="CoverageCriterionSettingsDescription mb-6"
    >
        {{ descriptionText }}
    </div>
</template>

<script lang="ts">
    import { Vue } from '@evidentid/vue-property-decorator';
    import { PropType } from 'vue';
    import { InsuranceCoverageCriterionTemplate } from '@evidentid/rpweb-api-client/types';
    import {
        NON_EXTRACTION_FIELD_PREFIX, NON_EXTRACTION_SETTINGS_DESCRIPTION,
    } from '@/modules/decisioning-criteria/components/CoverageCriterionSettings/constants';

    export default Vue.extend({
        name: 'CoverageCriterionSettingsDescription',
        props: {
            template: {
                type: Object as PropType<InsuranceCoverageCriterionTemplate>,
                default: () => ({}) as InsuranceCoverageCriterionTemplate,
            },
        },
        computed: {
            isNonExtractionCriterion(): boolean {
                return this.template.field?.startsWith(NON_EXTRACTION_FIELD_PREFIX);
            },
            descriptionText(): string {
                if (this.isNonExtractionCriterion) {
                    return NON_EXTRACTION_SETTINGS_DESCRIPTION;
                }
                return '';
            },
        },
    });
</script>
