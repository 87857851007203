<template>
    <div class="CoverageCriteriaGroupsTable">
        <div class="CoverageCriteriaGroupsTable__headers">
            <header class="CoverageCriteriaGroupsTable__displayNameHeader">
                Coverage Criteria group
            </header>
            <header class="CoverageCriteriaGroupsTable__coverageTypesHeader">
                Coverage Types
            </header>
            <header class="CoverageCriteriaGroupsTable__complianceStatusHeader">
                Compliance Status in Group
            </header>
        </div>
        <div class="CoverageCriteriaGroupsTable__body">
            <CoverageCriteriaGroupsExpandableRows
                v-if="effectiveGroups.length > 0"
                :insured-coverage-criteria-groups="effectiveGroups"
                :insured="insured"
                @goToCriteriaGroup="$emit('goToCriteriaGroup', $event)"
            />
            <CoverageCriteriaGroupsTableRow
                v-for="group in discoveryGroups"
                :key="group.id"
                :insured-coverage-criteria-group="group"
                @goToCriteriaGroup="$emit('goToCriteriaGroup', $event)"
            />
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import partition from 'lodash/partition';
    import { InsuranceInsured, InsuranceInsuredCoverageCriteriaGroup } from '@evidentid/rpweb-api-client/types';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import ComplianceStatusBadge from '@/modules/dashboard/components/ComplianceStatusBadge/ComplianceStatusBadge.vue';
    import CoverageCriteriaGroupsTableRow
        from '@/modules/insured-details/components/CoverageCriteriaGroupsTableRow/CoverageCriteriaGroupsTableRow.vue';
    import CoverageCriteriaGroupsExpandableRows
        from '@/modules/insured-details/components/CoverageCriteriaGroupsExpanableRows/CoverageCriteriaGroupsExpandableRows.vue';

    @Component({
        components: {
            CoverageCriteriaGroupsExpandableRows,
            CoverageCriteriaGroupsTableRow,
            Button,
            ComplianceStatusBadge,
        },
    })
    export default class CoverageCriteriaGroupsTable extends Vue {
        @Prop({ type: Array, default: () => [] })
        private insuredCoverageCriteriaGroups!: InsuranceInsuredCoverageCriteriaGroup[];

        @Prop({ type: Object, required: true })
        private insured!: InsuranceInsured;

        private effectiveGroups: InsuranceInsuredCoverageCriteriaGroup[] = [];
        private discoveryGroups: InsuranceInsuredCoverageCriteriaGroup[] = [];

        @Watch('insuredCoverageCriteriaGroups', { immediate: true })
        private onGroupsChange(): void {
            [ this.effectiveGroups, this.discoveryGroups ] =
                partition(this.insuredCoverageCriteriaGroups, (group) => group.active);
        }
    }
</script>
