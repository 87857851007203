<template>
    <Row class="CollateralTabRow">
        <Cell
            v-for="({ name, field }) in columns"
            :key="name"
            :data-test-id="`CollateralTabRow__${name}Cell`"
        >
            <div v-if="name==='action'" class="flex justify-between items-center">
                <div
                    v-if="isShowingViewCoi"
                    data-test-id="CollateralTabRow__viewCoiButton"
                    class="text-eidQualityBlack-50 text-sm cursor-pointer hover:opacity-70 inline-flex"
                    @click="showCoi"
                >
                    <EidIcon
                        class="text-base"
                        :icon-src="iconViewCoi"
                        alt="view coi"
                    />
                    <span class="inline-block ml-2">View COI</span>
                </div>
                <div v-else-if="isNonCompliantWithoutCoverage" class="text-eidQualityBlack-50 text-sm flex">
                    <EidIcon
                        :icon-src="iconError"
                        class="text-eidDangerRed"
                        alt="error"
                    />
                    <span class="inline-block text-eidDangerRed ml-2">Not Provided</span>
                </div>
                <EvaluationError
                    v-if="showGrandRemoveException "
                    :show-go-to-criterion="false"
                    :show-error-message="false"
                    :show-exception-badge="false"
                    :evaluation-error="evaluationError"
                    data-test-id="CollateralTabRow__evaluationExceptionGrandRemove"
                    @grantExceptionToCriterion="grantEvaluateEntityException"
                    @removeExceptions="removeExceptions"
                >
                    <template #grant-exception-label>
                        Grant exceptions for this collateral.
                    </template>
                    <template #grant-exception-sub-label>
                        All current and future non-compliant criteria
                        <span class="text-eidTrustGreen">will be waived as compliant. This can be undone.</span>
                    </template>
                </EvaluationError>
            </div>
            <div v-else-if="name==='description-uniqIdentifier'" class="flex flex-col">
                <span
                    data-test-id="CollateralTabRow__collateralDescription"
                    :class="{'text-eidDarkBlue': !isNonCompliant, 'text-eidDangerRed': isNonCompliant }"
                >
                    {{ collateral['description'] }}
                    <span v-if="isCompliantStatusPending" class="text-eidDarkBlue-50"> - Pending</span>
                </span>
                <span data-test-id="CollateralTabRow__collateralUniqueIdentifier" class="text-eidDarkBlue-50">
                    {{ collateral['uniqueIdentifier'] }}
                </span>
            </div>
            <template v-else-if="isNonCollateralField(field)">
                <div
                    v-if="isNonCompliantWithoutCoverage || fieldHasEvaluationError(field)"
                    v-tooltip="getFieldTooltipMessage(field)"
                    class="inline-flex"
                >
                    <EidIcon
                        :icon-src="iconError"
                        :class="{ 'text-eidDangerRed': !hasException, 'text-eidImpactOrange': hasException }"
                        alt="error"
                        :data-test-id="hasException ? 'CollateralTabRow__exceptionIcon' : 'CollateralTabRow__errorIcon'"
                    />
                    <span
                        class="inline-block ml-3"
                        :class="{ 'text-eidDangerRed' : !hasException }"
                    >
                        {{ getValue(field) }}
                    </span>
                </div>
                <template v-else>
                    {{ getValue(field) }}
                </template>
            </template>
            <template v-else>
                {{ getValue(field) }}
            </template>
        </Cell>
    </Row>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import { intersection } from 'lodash';
    import { faExclamationCircle, faExclamationTriangle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
    import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
    import { CollateralEntity } from '@evidentid/rpweb-api-client/models/CollateralEntity.model';
    import {
        InsuranceComplianceStatus,
        InsuranceComplianceStatusMetadata,
        InsuranceEvaluationResult,
    } from '@evidentid/insurance-facing-lib/models/insured-details';
    import { Cell, Row } from '@evidentid/dashboard-commons/components/Table';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { CollateralEntityDisplayField } from '@/modules/insured-details/models/CollateralEntityDisplayField.model';
    import { CollateralErrorType } from '@/modules/insured-details/models/CollateralErrorType.model';
    import { currencyFormatter } from '@/modules/insured-details/utils/currencyFormatter';
    import {
        getCollateralShortError,
        getPossibleErrorTypes,
        getTypesFromErrors,
    } from '@/modules/insured-details/utils/getCollateralErrorAndType/getCollateralErrorAndType';
    import EvaluationError from '@/modules/insured-details/components/EvaluationError/EvaluationError.vue';
    import { TableColumn } from '@/modules/insured-details/models/TableColumn.model';
    import { getEnumDefaultDisplayValue } from '@/utils/getEnumDefaultDisplayValue';
    import { createEvaluateEntityLevelException } from '@/modules/insured-details/utils/createException';
    import { EnumCategories } from '@/modules/dashboard/models/EnumCategories.model';
    import { CategorizedEnumLabels } from '@/modules/dashboard/models/CategorizedEnumLabels.model';

    function getFoundTypes(errors: string[], isCollateralNotProvided: boolean): CollateralErrorType[] {
        const foundTypes = getTypesFromErrors(errors);
        if (isCollateralNotProvided) {
            foundTypes.push(CollateralErrorType.collateralNotProvided);
        }
        return foundTypes;
    }

    export default Vue.extend({
        name: 'CollateralTabRow',
        components: {
            Row,
            Cell,
            EidIcon,
            EvaluationError,
        },
        props: {
            collateral: {
                type: Object as PropType<CollateralEntity>,
                required: true,
            },
            columns: {
                type: Array as PropType<TableColumn[]>,
                required: true,
            },
            evaluationResults: {
                type: Array as PropType<InsuranceEvaluationResult[]>,
                required: true,
            },
            insuredContactEmail: {
                type: String as PropType<string>,
                default: '',
            },
        },
        data() {
            return {
                iconViewCoi: faFileAlt,
            };
        },
        computed: {
            iconError(): IconDefinition {
                return this.hasException ? faExclamationCircle : faExclamationTriangle;
            },
            isNonCompliant(): boolean {
                return this.collateral.complianceStatus === InsuranceComplianceStatus.nonCompliant;
            },
            isCompliantStatusPending(): boolean {
                return this.collateral.complianceStatus === InsuranceComplianceStatus.pending;
            },
            isShowingViewCoi(): boolean {
                return !this.isNonCompliantWithoutCoverage &&
                    ![ InsuranceComplianceStatus.pending, InsuranceComplianceStatus.new ]
                        .includes(this.collateral.complianceStatus);
            },
            isNonCompliantWithoutCoverage(): boolean {
                return this.isNonCompliant && !this.collateral.coverageType;
            },
            isCollateralNotProvided(): boolean {
                return this.isNonCompliantWithoutCoverage &&
                    this.collateral.coverageLimit == null &&
                    this.collateral.coverageDeductible == null &&
                    this.collateral.coverageLimitType == null &&
                    this.collateral.coverageValuation == null;
            },
            categorizedEnumLabels(): CategorizedEnumLabels {
                // TODO(PRODUCT-18675): remove any after the migration to the newer version.
                return (this as any).$store.state.dashboard.categorizedEnumLabels;
            },
            errorTypes(): CollateralErrorType[] {
                const errors = [] as string[];
                this.evaluationResults.forEach((result) => {
                    if (result.result === InsuranceComplianceStatus.nonCompliant && result.nonComplianceMessage) {
                        errors.push(result.nonComplianceMessage);
                    }
                });
                return getFoundTypes(errors, this.isCollateralNotProvided);
            },
            errorTypesWithException(): CollateralErrorType[] {
                const errors = [] as string[];
                this.evaluationResults.forEach((result) => {
                    if (
                        result.result === InsuranceComplianceStatus.compliant &&
                        result.failures.length > 0 &&
                        result.nonComplianceMessage
                    ) {
                        errors.push(result.nonComplianceMessage);
                    }
                });
                return getFoundTypes(errors, this.isCollateralNotProvided);
            },
            showGrandRemoveException(): boolean {
                return Boolean(this.evaluationError) && (this.hasException || this.errorTypes.length > 0);
            },
            hasException(): boolean {
                return this.collateral.complianceStatus === InsuranceComplianceStatus.compliant &&
                    this.errorTypesWithException.length > 0;
            },
            evaluationError(): {
                evaluatedEntityId?: string;
                complianceStatusMetadata: InsuranceComplianceStatusMetadata[];
            } | null {
                if (this.evaluationResults.length === 0) {
                    return null;
                }

                return {
                    evaluatedEntityId: this.collateral.evaluatedEntityId,
                    complianceStatusMetadata: this.evaluationResults[0].complianceStatusMetadata ?
                        [ this.evaluationResults[0].complianceStatusMetadata ]
                        : [],
                };
            },
        },
        methods: {
            showCoi(): void {
                // TODO(PRODUCT-18675): fix any after the migration to the newer version.
                (this as any).$procedures.execute('showInsuredCoverageRprDocuments', {
                    rpName: (this as any).$rp.current!,
                    email: this.insuredContactEmail || '',
                    coverageType: this.collateral.coverageType,
                    coverageTypeLabel: getEnumDefaultDisplayValue(
                        EnumCategories.coverageTypes,
                        this.collateral.coverageType,
                        this.categorizedEnumLabels,
                    ),
                });
            },
            isNonCollateralField(field: string): boolean {
                return [ 'coverageLimit', 'coverageDeductible', 'coverageType' ].includes(field);
            },
            getValue(field: string): unknown {
                if (
                    this.isNonCollateralField(field)
                    && this.isCompliantStatusPending
                ) {
                    return 'Pending';
                }

                const value = this.collateral[field as keyof CollateralEntity];

                if (field === 'coverageLimit') {
                    const valuation = this.collateral.coverageValuation || '';
                    const limitValue = value == null ? '' : currencyFormatter().format(Number(value));
                    const separator = value != null && valuation ? ' - ' : '';
                    const finalValue = `${limitValue}${separator}${valuation}`;
                    return finalValue || '-';
                }

                if (value === null || value === undefined) {
                    return '-';
                }

                if (field === 'coverageType') {
                    return getEnumDefaultDisplayValue(
                        EnumCategories.coverageTypes,
                        value,
                        this.categorizedEnumLabels,
                    );
                }

                if ([ 'maximumDeductible', 'limitRequired', 'coverageDeductible', 'coverageLimit' ].includes(field)) {
                    return currencyFormatter().format(Number(value));
                }

                return value;
            },
            isNotProvidedField(field: CollateralEntityDisplayField): boolean {
                const value = this.collateral[field as keyof CollateralEntity];
                let includesNotProvidedError;

                if (this.hasException) {
                    includesNotProvidedError = this.errorTypesWithException
                        .includes(CollateralErrorType.collateralNotProvided);
                } else {
                    includesNotProvidedError = this.errorTypes.includes(CollateralErrorType.collateralNotProvided);
                }

                return includesNotProvidedError && value == null;
            },
            fieldHasEvaluationError(field: CollateralEntityDisplayField): boolean {
                const errors = this.getFieldTooltipMessage(field);
                return Boolean(errors && errors.length > 0);
            },
            getFieldTooltipMessage(field: CollateralEntityDisplayField): string | null {
                if (this.isNotProvidedField(field)) {
                    return getCollateralShortError(CollateralErrorType.collateralNotProvided);
                }

                const possibleErrorTypesOfField = this.getPossibleErrorTypesPerField(field);
                let errorTypes = [];

                if (this.hasException) {
                    errorTypes = intersection(this.errorTypesWithException, possibleErrorTypesOfField);
                } else {
                    errorTypes = intersection(this.errorTypes, possibleErrorTypesOfField);
                }

                return errorTypes.length > 0
                    ? errorTypes.map(getCollateralShortError).join('\n')
                    : null;
            },
            getPossibleErrorTypesPerField(field: CollateralEntityDisplayField): CollateralErrorType[] {
                return field === CollateralEntityDisplayField.coverageLimit
                    ? this.getCoverageLimitPossibleErrorTypes()
                    : getPossibleErrorTypes(field);
            },
            getCoverageLimitPossibleErrorTypes(): CollateralErrorType[] {
                const possibleErrorTypes = [
                    ...getPossibleErrorTypes(CollateralEntityDisplayField.coverageLimit),
                    ...getPossibleErrorTypes(CollateralEntityDisplayField.coverageLimitType),
                    ...getPossibleErrorTypes(CollateralEntityDisplayField.coverageValuation),
                ];
                return [ ...new Set(possibleErrorTypes) ];
            },
            grantEvaluateEntityException(): void {
                const exception = createEvaluateEntityLevelException(this.collateral.evaluatedEntityId);
                this.$emit('grant-evaluate-entity-exception', [ exception ]);
            },
            removeExceptions(exceptionIds: string[]): void {
                this.$emit('remove-exceptions', exceptionIds);
            },
        },
    });
</script>
