import { InsuranceCoverageType } from '@evidentid/insurance-facing-lib/models/insured-details';
import {
    COVERAGE_FIELD_ORDER_MAP,
    COVERAGE_MULTI_FIELD_CRITERION_FIELD_ORDER_MAP,
} from '@/static-data/insured-details/coverageFieldOrders.constants';

export function getFieldOrderByCoverageType(coverageType: InsuranceCoverageType): string[] {
    return COVERAGE_FIELD_ORDER_MAP[coverageType] || [];
}

export function getMultiFieldsOrderByCoverageType(coverageType: InsuranceCoverageType): Record<string, string[]> | {} {
    return COVERAGE_MULTI_FIELD_CRITERION_FIELD_ORDER_MAP[coverageType] || {};
}
