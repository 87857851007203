<template>
    <Badge v-if="label"
        class="ComplianceStatusBadge"
        :class="`ComplianceStatusBadge--${status}`"
        :type="type"
    >
        {{ label }}
    </Badge>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { Badge } from '@evidentid/dashboard-commons/components/Badge';
    import { BadgeType } from '@evidentid/dashboard-commons/components/Badge/types';
    import { InsuranceComplianceStatus } from '@evidentid/insurance-facing-lib/models/insured-details';

    const labels: Record<InsuranceComplianceStatus, string> = {
        [InsuranceComplianceStatus.new]: 'New',
        [InsuranceComplianceStatus.pending]: 'Pending',
        [InsuranceComplianceStatus.compliant]: 'Compliant',
        [InsuranceComplianceStatus.nonCompliant]: 'Non-compliant',
    };

    const types: Record<InsuranceComplianceStatus, BadgeType> = {
        [InsuranceComplianceStatus.new]: BadgeType.gray,
        [InsuranceComplianceStatus.pending]: BadgeType.gray,
        [InsuranceComplianceStatus.compliant]: BadgeType.success,
        [InsuranceComplianceStatus.nonCompliant]: BadgeType.danger,
    };

    @Component({
        components: { Badge },
    })
    export default class ComplianceStatusBadge extends Vue {
        @Prop({ type: String, default: InsuranceComplianceStatus.new })
        private status!: InsuranceComplianceStatus;

        private get label(): string {
            return labels[this.status];
        }

        private get type(): BadgeType {
            return types[this.status];
        }
    }
</script>
