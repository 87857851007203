import get from 'lodash/get';
import { InsuranceInsuredField, InsuranceInsuredInput } from '@evidentid/rpweb-api-client/types';
import { JsonSchemaArray, JsonSchemaType } from '@evidentid/json-schema/interfaces/JsonSchema';
import createForm from '@evidentid/json-schema/createForm';
import { removeNonStandardSchemaFormat } from '@/modules/decisioning-criteria/utils/removeNonStandardSchemaFormat';

function formatPhoneNumber(phoneNumber: string): string {
    const number = phoneNumber.replace(/[^\d]/g, '');
    if (number.length === 10) {
        return number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    } else if (number.length === 11) {
        return number.substr(1).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
    return number;
}

function formatInsuredField(insuredField: InsuranceInsuredField, value?: any): string {
    if (typeof value === 'boolean') {
        return value ? 'Yes' : 'No';
    } else if (Array.isArray(value)) {
        const schema = insuredField.schema as JsonSchemaArray<any>;
        if (schema.items.type !== JsonSchemaType.object) {
            return value.join('|');
        }
        const formValue = createForm(removeNonStandardSchemaFormat(schema)).getValue(value, true);
        return formValue.map((x: object) => JSON.stringify(x)).join('|');
    } else {
        return value?.toString() || '';
    }
}

function insuredKeyToValue(
    key: string,
    insured: InsuranceInsuredInput,
    insuredFields: InsuranceInsuredField[],
): string {
    if (key === 'contactPhoneNumber') {
        return formatPhoneNumber(insured.contactPhoneNumber || '');
    } else if (insured[key as keyof InsuranceInsuredInput] !== undefined) {
        const val = insured[key as keyof InsuranceInsuredInput];
        // currently standard fields only expect array or string. will need to adjust when supporting other types
        return Array.isArray(val)
            ? val.join('|')
            : insured[key as keyof InsuranceInsuredInput] as string;
    } else if (insured.insuredFields && get(insured.insuredFields, key) !== undefined) {
        const insuredField = insuredFields
            .find((insuredField) => insuredField.key === key) as InsuranceInsuredField;
        return formatInsuredField(
            insuredField,
            // get because in case of objectProp column key in a format of objectName.propName
            get(insured.insuredFields, key),
        );
    } else if (insured.exceptions && insured.exceptions[key] !== undefined) {
        return insured.exceptions[key];
    } else {
        return '';
    }
}

// eslint-disable-next-line max-params
export function buildInsuredsCsv(
    csvHeaders: string[],
    csvColumnKeys: string[],
    insureds: InsuranceInsuredInput[],
    insuredFields: InsuranceInsuredField[],
): string[][] {
    return [
        [ ...csvHeaders.map((label) => label || '') ],
        ...insureds.map((insured) =>
            csvColumnKeys.map((key) => insuredKeyToValue(key, insured, insuredFields)),
        ),
    ];
}
