<template>
    <div class="CoverageCriteriaGroups">
        <CoverageCriteriaGroupEmptyState v-if="coverageCriteriaGroups.length === 0" v-on="$listeners"/>
        <CoverageCriteriaGroupTable v-else :groups="coverageCriteriaGroups" v-on="$listeners" />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { InsuranceCoverageCriteriaGroup } from '@evidentid/rpweb-api-client/types';
    import CoverageCriteriaGroupEmptyState
        from '@/modules/decisioning-criteria/components/CoverageCriteriaGroupEmptyState/CoverageCriteriaGroupEmptyState.vue';
    import CoverageCriteriaGroupTable
        from '@/modules/decisioning-criteria/components/CoverageCriteriaGroupTable/CoverageCriteriaGroupTable.vue';

    @Component({
        components: {
            CoverageCriteriaGroupTable,
            CoverageCriteriaGroupEmptyState,
        },
    })
    export default class CoverageCriteriaGroups extends Vue {
        @Prop({ type: Array, default: () => [] })
        private coverageCriteriaGroups!: InsuranceCoverageCriteriaGroup[];
    }
</script>
