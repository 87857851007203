import { isComplexObject } from '@evidentid/json-schema/schemaChecks';
import JsonSchema, { JsonSchemaComplexObject, JsonSchemaEnum } from '@evidentid/json-schema/interfaces/JsonSchema';
import { InsuranceSchema, InsuranceSchemaDisplayFormat } from '@evidentid/rpweb-api-client/types';
import { isSubstitutableSchema } from '@/modules/decisioning-criteria/utils/isSubstitutableSchema';

export function isSchemaInsuredFieldSubstitutable(schema: JsonSchema): boolean {
    if (!isComplexObject(schema)) {
        return false;
    }

    const isBasedOnInsuredField = isSubstitutableSchema(schema);

    if (!isBasedOnInsuredField) {
        return false;
    }

    const thenSchema = Object.values(
        (schema as unknown as JsonSchemaComplexObject).then?.properties || {},
    )[0];
    const elseSchema = Object.values(
        (schema as unknown as JsonSchemaComplexObject).else?.properties || {},
    )[0];
    const thenDisplayFormat = (thenSchema as InsuranceSchema)?.displayFormat;
    const elseDisplayFormat = (elseSchema as InsuranceSchema)?.displayFormat;
    const thenIsInsuredFieldAndEnum = thenDisplayFormat === InsuranceSchemaDisplayFormat.insuredField
        && (thenSchema as JsonSchemaEnum).enum && (thenSchema as JsonSchemaEnum).enum.length > 0;
    const elseIsInsuredFieldAndEnum = elseDisplayFormat === InsuranceSchemaDisplayFormat.insuredField
        && (elseSchema as JsonSchemaEnum).enum && (elseSchema as JsonSchemaEnum).enum.length > 0;

    return thenIsInsuredFieldAndEnum || elseIsInsuredFieldAndEnum;
}
