<template>
    <Cell class="BulkImportCorrectionTableCell" :class="{'BulkImportCorrectionTableCell--focus': focused}">
        <JsonSchemaFormElement
            :id="id"
            :form="formProperty.form"
            :required="formProperty.required"
            :value="formValue"
            :custom-component-input="getCustomComponent"
            :deletable="formProperty.form.type === 'object' && !formProperty.required"
            touched
            v-on="$listeners"
            @focus="onFocus"
            @blur="onBlur"
        >
            <template v-slot:icon>
                <FontAwesomeIcon :icon="faEdit" />
            </template>
        </JsonSchemaFormElement>
    </Cell>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { faEdit } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { Cell } from '@evidentid/dashboard-commons/components/Table';
    import { JsonSchemaFormElement } from '@evidentid/dashboard-commons/components/JsonSchemaForm';
    import { JsonFormProperty, JsonFormType } from '@evidentid/json-schema/interfaces/JsonForm';
    import {
        BulkImportArrayField, BulkImportBooleanField,
    } from '@/modules/insured-management/components/BulkImportCustomForms';
    import { InsuranceInsuredInput, InsuranceSchemaDisplayFormat } from '@evidentid/rpweb-api-client/types';
    import BulkImportStringField
        from '@/modules/insured-management/components/BulkImportCustomForms/BulkImportStringField.vue';
    import JsonSchemaConstForm
        from '@evidentid/dashboard-commons/components/JsonSchemaForm/elements/JsonSchemaConstForm.vue';
    import { getJsonSchemaCompFunction } from '@/utils/getJsonSchemaCustomCompFuntion';
    import BulkImportObjectField
        from '@/modules/insured-management/components/BulkImportCustomForms/BulkImportObjectField.vue';
    import JsonSchemaAddressForm
        from '@evidentid/dashboard-commons/components/JsonSchemaForm/customElements/JsonSchemaAddressForm.vue';
    import JsonSchemaCountryEnumForm
        from '@evidentid/dashboard-commons/components/JsonSchemaForm/customElements/JsonSchemaCountryEnumForm.vue';

    @Component({
        components: { Cell, JsonSchemaFormElement, FontAwesomeIcon },
    })
    export default class BulkImportCorrectionTableCell extends Vue {
        @Prop({ type: String })
        private id!: string;

        @Prop({ type: String, default: null })
        private insuredPropExtension!: 'insuredFields' | 'exceptions' | null;

        @Prop({ type: Object, default: () => ({}) })
        private formProperty!: JsonFormProperty;

        @Prop({ type: Object, default: () => ({}) })
        private value!: InsuranceInsuredInput;

        private faEdit = faEdit;
        private focused: boolean = false;
        private customComponents = {
            [JsonFormType.boolean]: BulkImportBooleanField,
            [JsonFormType.array]: BulkImportArrayField,
            [JsonFormType.string]: BulkImportStringField,
            [JsonFormType.object]: BulkImportObjectField,
            [InsuranceSchemaDisplayFormat.hidden]: JsonSchemaConstForm,
            [InsuranceSchemaDisplayFormat.address]: JsonSchemaAddressForm,
            [InsuranceSchemaDisplayFormat.country]: JsonSchemaCountryEnumForm,
        };
        private getCustomComponent = getJsonSchemaCompFunction(this.customComponents);

        private get formValue() {
            const insuredFields = this.value.insuredFields || {};
            const exceptions = this.value.exceptions || {};
            if (this.insuredPropExtension === 'insuredFields') {
                return insuredFields[this.formProperty.name];
            } else if (this.insuredPropExtension === 'exceptions') {
                return exceptions[this.formProperty.name];
            } else {
                return this.value[this.formProperty.name as keyof InsuranceInsuredInput];
            }
        }

        private onFocus() {
            this.focused = true;
        }

        private onBlur() {
            this.focused = false;
        }
    }
</script>
