<template>
    <div class="InsuredDetailsStatusBar">
        <div v-if="insured.active" class="InsuredDetailsStatusBar__statusContainer">
            <ComplianceStatusBadge
                class="InsuredDetailsStatusBar__headerStatusBadge"
                :status="insured.complianceStatus"
            />
            <VerificationStatusBadge
                v-if="insured.paused"
                class="InsuredDetailsStatusBar__headerVerificationStatusBadge"
            >
                Paused
            </VerificationStatusBadge>
            <VerificationStatusBadge
                v-else
                class="InsuredDetailsStatusBar__headerVerificationStatusBadge"
                :status="insured.verificationStatus"
            />
        </div>
        <div v-else>
            <Badge class="InsuredDetailsStatusBar__headerDeactivatedBadge">
                Deactivated
            </Badge>
        </div>
        <ExceptionBadge
            v-if="insuredException"
            class="InsuredDetailsStatusBar__exceptionBadge"
            label="Exception Granted"
            :exception-type="insuredException.type"
            :until="insuredException.until"
            applied-to="Insured"
        />
        <ActionsButton
            :loading="updatingInsured"
            :insured="insured"
            :exception-override="insuredException"
            :is-loading-submission-link="isLoadingSubmissionLink"
            :is-collateral-enabled="isCollateralEnabled"
            :submission-link="getSubmissionLink(insured.id)"
            @actionsButtonClicked="$emit('loadInsuredRequests', insured.id)"
            @grantExceptionToInsured="grantException"
            @removeExceptions="removeExceptions"
            @setInsuredsPauseState="$emit('setInsuredsPauseState', $event)"
            @deactivateInsureds="$emit('deactivateInsureds', $event)"
            @sendCoiRequest="$emit('sendCoiRequest', $event)"
            @copySubmissionLink="$emit('copySubmissionLink', $event)"
            @open-collateral-mapping-modal="$emit('open-collateral-mapping-modal')"
            @open-historic-coi-modal="$emit('open-historic-coi-modal')"
        />
    </div>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { InsuranceInsured } from '@evidentid/rpweb-api-client/types';
    import { InsuranceStatusOverride } from '@evidentid/insurance-facing-lib/models/insured-details';
    import { Badge } from '@evidentid/dashboard-commons/components/Badge';
    import ActionsButton from '@/modules/dashboard/components/ActionsButton/ActionsButton.vue';
    import ComplianceStatusBadge from '@/modules/dashboard/components/ComplianceStatusBadge/ComplianceStatusBadge.vue';
    import VerificationStatusBadge
        from '@/modules/dashboard/components/VerificationStatusBadge/VerificationStatusBadge.vue';
    import ExceptionBadge from '@/modules/insured-details/components/ExceptionBadge/ExceptionBadge.vue';
    import { createInsuredLevelException } from '@/modules/insured-details/utils/createException';

    @Component({
        components: { ActionsButton, Badge, ComplianceStatusBadge, ExceptionBadge, VerificationStatusBadge },
    })
    export default class InsuredDetailsStatusBar extends Vue {
        @Prop({ type: Object })
        private insured!: InsuranceInsured;

        @Prop({ type: Boolean, default: false })
        private updatingInsured!: boolean;

        @Prop({ type: Boolean, default: false })
        private isLoadingSubmissionLink!: boolean;

        @Prop({ type: Object, default: {} })
        private submissionLinkPerInsuredId!: Record<string, string>;

        @Prop({ type: Object, default: null })
        private insuredException!: InsuranceStatusOverride;

        @Prop({ type: Boolean, default: false })
        private isCollateralEnabled!: boolean;

        private getSubmissionLink(insuredId: string): string {
            return this.submissionLinkPerInsuredId[insuredId] || '';
        }

        private grantException() {
            this.$emit('grantExceptions', [ createInsuredLevelException() ]);
        }

        private removeExceptions(exception: string[]) {
            this.$emit('removeExceptions', exception);
        }
    }
</script>
