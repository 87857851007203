<template>
    <ErrorScreen :warning="warning">
        <template v-slot:toolbar>
            <ScreenToolbar :has-user-panel="hasUserPanel" />
        </template>
        <slot />
    </ErrorScreen>
</template>

<script lang="ts">
    import { Component, Vue, Prop } from '@evidentid/vue-property-decorator';
    import { ErrorScreen } from '@evidentid/dashboard-commons/screens/ErrorScreen';
    import ScreenToolbar from '@/components/screens/ScreenToolbar.vue';

    @Component({
        components: { ScreenToolbar, ErrorScreen },
    })
    export default class InsuranceErrorScreen extends Vue {
        @Prop({ type: Boolean, default: false })
        private warning!: boolean;

        @Prop({ type: Boolean, default: false })
        private hasUserPanel!: boolean;
    }
</script>
