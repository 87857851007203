import { Vue } from '@evidentid/vue-property-decorator';

import JsonForm from '@evidentid/json-schema/interfaces/JsonForm';
import JsonSchema from '@evidentid/json-schema/interfaces/JsonSchema';
import { isArray, isEnum } from '@evidentid/json-schema/schemaChecks';
import { InsuranceSchema } from '@evidentid/rpweb-api-client/types';

export function getJsonSchemaCompFunction(
    customFormElementsMap: Record<string, Vue.Component>,
): (form: JsonForm, defaultComponents?: Record<string, Vue.Component>) => Vue.Component {
    return (form, defaultComponents: Record<string, Vue.Component> = {}) => {
        const compsMap = { ...defaultComponents, ...customFormElementsMap };
        const specialType = isArray(form.schema) && isEnum(form.schema.items as JsonSchema)
            ? 'multi_enum'
            : (form.schema as InsuranceSchema).displayFormat;
        return compsMap[specialType || ''] || compsMap[form.type];
    };
}
