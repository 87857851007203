<template>
    <div class="NavigationalHeader">
        <div class="NavigationalHeader__backButtonWithTitle">
            <div class="NavigationalHeader__backButton" @click="$emit('back')">
                <FontAwesomeIcon :icon="faChevronLeft" />
            </div>
            <div
                v-if="title != null && title !==''"
                class="NavigationalHeader__title"
            >
                <span>{{ combinedTitle }}</span>
            </div>
            <EmptyValueDashSymbol v-else />
        </div>
        <Navigation
            :current-position="currentPosition"
            :max-position="maxPosition"
            @next="$emit('next')"
            @previous="$emit('previous')"
        />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import EmptyValueDashSymbol
        from '@/modules/insured-actions-review/components/EmptyValueDashSymbol/EmptyValueDashSymbol.vue';
    import Navigation from '@/modules/insured-actions-review/components/Navigation/Navigation.vue';

    @Component({
        components: { EmptyValueDashSymbol, Navigation, FontAwesomeIcon },
    })
    export default class NavigationalHeader extends Vue {
        @Prop({ type: String, default: '' })
        private title!: string;

        @Prop({ type: Number, default: 0 })
        private currentPosition!: number;

        @Prop({ type: Number, default: 0 })
        private maxPosition!: number;

        @Prop({ type: String, default: '' })
        private titlePrefix!: string;

        private faChevronLeft = faChevronLeft;

        private get combinedTitle(): string {
            return this.titlePrefix ? `${this.titlePrefix} - ${this.title}` : this.title;
        }
    }
</script>
