var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Menu',{attrs:{"big":""}},[_c('MenuLink',{staticClass:"MainMenu__topLevelItem",attrs:{"href":_vm.localRpUrl('/dashboard'),"icon":_vm.dashboardIcon,"active":_vm.tab === 'dashboard',"label":"Insureds"}}),_c('MenuLink',{staticClass:"MainMenu__topLevelItem",attrs:{"href":_vm.localRpUrl('/decisioningCriteria'),"icon":_vm.decisioningCriteria,"active":_vm.tab === 'decisioningCriteria',"label":"Decisioning Criteria"}}),_c('MenuLink',{staticClass:"MainMenu__topLevelItem",attrs:{"href":_vm.localRpUrl('/actions-review'),"icon":_vm.actionsReview,"active":_vm.tab === 'actionsReview',"label":"Actions Review"}}),_c('MenuLink',{staticClass:"MainMenu__topLevelItem",attrs:{"data-test-id":"MainMenu__configuration","icon":_vm.configurationIcon,"active":_vm.tab === 'configuration',"open":_vm.configTabOpened,"label":"Configuration"},on:{"click":_vm.toggleConfigTab}},[_c('WithPermissions',{staticClass:"MainMenu__configurationItem",attrs:{"component":_vm.MenuLink,"props":{ href: _vm.localRpUrl('/admin/api-settings'),
                      label: 'API Settings',
                      active: _vm.configurationTab === 'apiSettings'},"permissions":[ _vm.permissionSettings ],"adapter":"auth0","accept-not-scoped":""}}),_c('WithPermissions',{staticClass:"MainMenu__configurationItem",attrs:{"component":_vm.MenuLink,"props":{ href: _vm.localRpUrl('/admin/users'),
                      label: 'Manage Users',
                      active: _vm.configurationTab === 'manageUsers'},"permissions":[ _vm.permissionUsers ],"adapter":"auth0","accept-not-scoped":""}}),_c('MenuLink',{staticClass:"MainMenu__configurationItem",attrs:{"href":_vm.localRpUrl('/configuration/insuredFields'),"active":_vm.configurationTab === 'insuredFields',"label":"Insured Fields Display"}}),(_vm.eventManagerEnabled)?_c('WithPermissions',{staticClass:"MainMenu__configurationItem",attrs:{"data-test-id":"MainMenu__notifications","component":_vm.MenuLink,"props":{ href: _vm.localRpUrl('/admin/notifications/branding'),
                      label: 'Notification',
                      active: _vm.configurationTab === 'brandingSettings',
                      open: _vm.notificationsTabOpened},"permissions":[ _vm.permissionSettings ],"accept-not-scoped":""},on:{"click":_vm.toggleNotificationsTab}},[(_vm.eventManagerEnabled)?_c('MenuLink',{staticClass:"MainMenu__notificationItem",attrs:{"href":_vm.localRpUrl('/admin/notifications/branding'),"active":_vm.notificationTab === 'brandingSettings',"label":"Branding Settings"}}):_vm._e(),(_vm.eventManagerEnabled)?_c('MenuLink',{staticClass:"MainMenu__notificationItem",attrs:{"href":_vm.localRpUrl('/admin/notifications/support'),"active":_vm.notificationTab === 'support',"label":"Support Contact Information"}}):_vm._e()],1):_vm._e()],1),_c('MenuLink',{staticClass:"MainMenu__topLevelItem",attrs:{"data-test-id":"MainMenu__emailSettings","icon":_vm.emailSettingsIcon,"active":_vm.tab === 'emailSettings',"open":_vm.emailSettingsTabOpened},on:{"click":_vm.toggleEmailSettingsTab},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"MenuElement__linkText flex items-center"},[_vm._v(" Email Settings "),(_vm.someNotificationsOff)?_c('EidIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                        html: true,
                        content: '<strong>Some notifications are off</strong> <br> See more in Email Templates',
                    }),expression:"{\n                        html: true,\n                        content: '<strong>Some notifications are off</strong> <br> See more in Email Templates',\n                    }"}],staticClass:"inline-block ml-2 text-eidDangerRed",attrs:{"data-test-id":"MainMenu__emailSettingsNotificationsOffIcon","icon-src":_vm.triangleExclamationIcon}}):_vm._e()],1)]},proxy:true}])},[_c('MenuLink',{staticClass:"MainMenu__emailSettingsItem",attrs:{"href":_vm.localRpUrl('/admin/email-settings/configuration'),"active":_vm.emailSettingsTab === 'emailConfiguration',"label":"Email Configuration"}}),_c('MenuLink',{staticClass:"MainMenu__emailSettingsItem",attrs:{"href":_vm.localRpUrl('/admin/email-settings/templates'),"active":_vm.emailSettingsTab === 'emailTemplates'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"MenuElement__linkText flex items-center"},[_vm._v(" Email Templates "),(_vm.someNotificationsOff)?_c('EidIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                            html: true,
                            content: '<strong>Some notifications are off</strong> <br> Set schedules and switch on',
                        }),expression:"{\n                            html: true,\n                            content: '<strong>Some notifications are off</strong> <br> Set schedules and switch on',\n                        }"}],staticClass:"inline-block ml-2 text-eidDangerRed",attrs:{"data-test-id":"MainMenu__emailTemplatesNotificationsOffIcon","icon-src":_vm.triangleExclamationIcon}}):_vm._e()],1)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }