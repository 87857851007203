import { InsuranceCoverageModel, InsuranceInsuredCoverage } from '@evidentid/rpweb-api-client/types';
import { InsuranceEvaluationResult } from '@evidentid/insurance-facing-lib/models/insured-details';
import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
import { InsuredMultiFieldCriterion } from '@/modules/insured-details/types';
import { CategorizedEnumLabels } from '@/modules/dashboard/models/CategorizedEnumLabels.model';
import { parseFieldValuePerUiConversion } from './parseFieldValuePerUiConversion';
import { parseFieldValuePerEnumAndError } from './parseFieldValuePerEnumAndError';

export function parseFieldValue(
    fieldName: string,
    fieldValue: unknown,
    otherData: {
        coverage: InsuranceInsuredCoverage;
        coverageModel?: InsuranceCoverageModel;
        evaluationResults: InsuranceEvaluationResult[];
        evaluationErrors: Record<string, FieldEvaluationResultError>;
        multiFieldCriteria: InsuredMultiFieldCriterion[];
        categorizedEnumLabels: CategorizedEnumLabels;
    }): any {
    let parsedValue = parseFieldValuePerUiConversion(fieldName, fieldValue);
    parsedValue = parseFieldValuePerEnumAndError(fieldName, parsedValue, {
        coverage: otherData.coverage,
        coverageModel: otherData.coverageModel,
        evaluationErrors: otherData.evaluationErrors,
        multiFieldCriteria: otherData.multiFieldCriteria,
        categorizedEnumLabels: otherData.categorizedEnumLabels,
    });
    return parsedValue;
}
