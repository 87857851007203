import { DocumentAttributeRegexType } from '@/modules/insured-certificates/models/DocumentAttributeRegexType.model';

const REGEX_MAP: Record<DocumentAttributeRegexType, RegExp> = {
    [DocumentAttributeRegexType.coi]: /\.certificate_of_insurance_document$/,
    [DocumentAttributeRegexType.businessDocument]: /\.document$/,
    [DocumentAttributeRegexType.all]: /(\.certificate_of_insurance_document|\.document)$/,
};

export function getDocumentAttributeRegex(type: DocumentAttributeRegexType): RegExp {
    return REGEX_MAP[type];
}
