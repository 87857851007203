import RpWebApiClient from '@evidentid/rpweb-api-client';
import { find } from 'lodash';
import { InsuranceCoverageType } from '@evidentid/insurance-facing-lib/models/insured-details';
import { getCoverageTypeByAttribute } from '@/modules/insured-certificates/services/attributeUtilities';
import { DocumentAttributeRegexType } from '@/modules/insured-certificates/models/DocumentAttributeRegexType.model';
import { removeExtraCoiAcrossCoverage } from '@/modules/insured-certificates/services/removeExtraCoiAcrossCoverage';
import { aggregateDocumentsFromRprs } from '@/modules/insured-certificates/services/aggregateDocumentsFromRprs';

function getDocsPerCoverageType(
    docsPerRprAndAttribute: Record<string, Record<string, any[]>>,
    coverageType: InsuranceCoverageType
) {
    // if coverage type passed, getting particular coverage types docs of all rprs
    return Object.values(docsPerRprAndAttribute).reduce((accum, attr) => {
        const coverageTypeDocs =
            find(attr, (_, key) => getCoverageTypeByAttribute(key) === coverageType) || [];
        return [ ...accum, ...coverageTypeDocs ];
    }, [] as any[]);
}

function getDocsForAllCoverageType(
    docsPerRprAndAttribute: Record<string, Record<string, any[]>>,
) {
    // when not needing particular coverage types document, we want all docs across coverage and rpr
    return Object.values(docsPerRprAndAttribute).reduce((accum, rprDocsByAttribute) => {
        // all cois are same across insurance coverage type atm, thus finding one is sufficient and not redundant
        const extraRemoved = removeExtraCoiAcrossCoverage(rprDocsByAttribute);
        const allDocsOfOneRpr = Object.values(extraRemoved).flat();
        return [ ...accum, ...allDocsOfOneRpr ];
    }, [] as any[]);
}

export async function fetchRprDocuments(
    rpweb: RpWebApiClient,
    rpName: string,
    rprIds: string[],
    coverageType?: InsuranceCoverageType,
): Promise<any[]> {
    const promises = rprIds.map((id) => rpweb.getRelyingPartyRequestDetails(rpName, id));
    const rprs = await Promise.all(promises);
    const docsPerRprAndAttribute = aggregateDocumentsFromRprs(rprs, DocumentAttributeRegexType.all);
    return coverageType
        ? getDocsPerCoverageType(docsPerRprAndAttribute, coverageType)
        : getDocsForAllCoverageType(docsPerRprAndAttribute);
}
