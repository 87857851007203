<template>
    <ConfirmSendInsuredRequestModal
        :insureds="insureds"
        :saving="saving"
        @close="close"
        @confirm="sendCoiRequest"
    />
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import { InsuranceInsured } from '@evidentid/rpweb-api-client/types';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import ConfirmSendInsuredRequestModal
        from '@/modules/insured-request/components/ConfirmSendInsuredRequestModal/ConfirmSendInsuredRequestModal.vue';
    import { SendRequestStatus } from '@/modules/insured-request/vuex';
    import { shouldEnableCoiRequest } from '@/utils/shouldEnableCoiRequest/shouldEnableCoiRequest';

    @Component({
        components: { ConfirmSendInsuredRequestModal },
    })

    export default class SendCoiRequestProcedure extends Vue {
        @Prop({ type: String, default: '' })
        private rpName!: string;

        @Prop({ type: Array, default: () => [] })
        private insureds!: InsuranceInsured[];

        private get saving(): boolean {
            return this.sendRequestStatus.status === 'loading';
        }

        private close(): void {
            this.$store.actions.insuredRequest.clearSendCoiRequestStatus({ rpName: this.rpName });
            this.$emit('abort');
        }

        private async sendCoiRequest() {
            const qualifiedInsureds = this.insureds.filter(shouldEnableCoiRequest);
            await this.$store.actions.insuredRequest.sendCoiRequest({
                rpName: this.rpName,
                insuredIds: qualifiedInsureds.map((insured) => insured.id),
            });
            this.$emit('finish', this.insureds);
        }

        private get sendRequestStatus(): SendRequestStatus {
            return this.$store.state.insuredRequest.sendCoiRequestStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                count: 0,
                successCount: 0,
                failureCount: 0,
                successes: [],
                failures: [],
            };
        }
    }
</script>
