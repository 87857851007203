import {
    InsuredFilterCategory,
    InsuredFilters,
    InsuredStandardFiltersObject,
} from '@/modules/insured-filtering/types';
import { InsuranceInsuredField } from '@evidentid/rpweb-api-client/types';
import { InsuranceComplianceStatus } from '@evidentid/insurance-facing-lib/models/insured-details';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import { JsonSchemaObject, JsonSchemaType, RegularJsonSchema } from '@evidentid/json-schema/interfaces/JsonSchema';
import { InsuredCollateralFieldFilters } from '@/modules/insured-filtering/models/InsuredCollateralFieldFilters.model';
import { isObject } from '@evidentid/json-schema/schemaChecks';

const EXPIRING_SOON_DAYS = 30;
const IF_PREFIX = 'if_';

export const categoryFilterMap = {
    [InsuredFilterCategory.compliantAndExpiringSoon]: {
        complianceStatus: InsuranceComplianceStatus.compliant,
        expiresAfterOrOn: moment().format('YYYY-MM-DD'),
        expiresBeforeOrOn: moment().add(EXPIRING_SOON_DAYS, 'days').format('YYYY-MM-DD'),
    },
    [InsuredFilterCategory.compliantAndNotExpiringSoon]: {
        complianceStatus: InsuranceComplianceStatus.compliant,
        expiresAfterOrOn: moment().add(EXPIRING_SOON_DAYS + 1, 'days').format('YYYY-MM-DD'),
    },
    [InsuredFilterCategory.nonCompliant]: {
        complianceStatus: InsuranceComplianceStatus.nonCompliant,
    },
    [InsuredFilterCategory.pendingAndNew]: {
        complianceStatus: `${InsuranceComplianceStatus.pending},${InsuranceComplianceStatus.new}`,
    },
};

export function getInsuredFieldQueryKey(insuredFieldKey: string): string {
    return `${IF_PREFIX}${insuredFieldKey}`;
}

export function getObjInsuredFieldSubKeys(insuredField: InsuranceInsuredField): string[] {
    return Object.keys((insuredField.schema as JsonSchemaObject).properties)
        .map((propKey) => `${IF_PREFIX}${insuredField.key}.${propKey}`);
}

export function getInsuredFieldKeyFromQueryKey(queryKey: string): string {
    return queryKey.replace(IF_PREFIX, '');
}

export function getInsuredCollateralFieldQueryKey(insuredFieldCollaterals: string, filterKey: string): string {
    return `${IF_PREFIX}${insuredFieldCollaterals}.${filterKey}`;
}

export function getInsuredCollateralFieldFromQueryKey(queryKey: string, insuredFieldCollaterals: string): string {
    return queryKey.replace(`${IF_PREFIX}${insuredFieldCollaterals}.`, '');
}

export function getCategoryByFilters(filter: InsuredFilters): InsuredFilterCategory | null {
    const { search, ...filterWithoutSearch } = filter;
    const foundEntry = Object.entries(categoryFilterMap).find(([ , value ]) => isEqual(value, filterWithoutSearch));
    return foundEntry ? foundEntry[0] as InsuredFilterCategory : null;
}

export function getInsuredStandardFilterKeys(): string[] {
    return Object.keys(new InsuredStandardFiltersObject());
}

export function getInsuredFieldFilterKeys(insuredFields: InsuranceInsuredField[]): string[] {
    return insuredFields.flatMap((field) => (
        isObject(field.schema)
            ? getObjInsuredFieldSubKeys(field)
            : [ getInsuredFieldQueryKey(field.key) ]),
    );
}

class InsuredCollateralsFiltersObject implements InsuredCollateralFieldFilters {
    public description = undefined;
    public uniqueIdentifier = undefined;
    public category = undefined;
    public limitRequired = undefined;
    public maximumDeductible = undefined;
}

export function getInsuredCollateralFieldFiltersKeys(insuredFieldCollaterals: string): string[] {
    if (insuredFieldCollaterals === '') {
        return [];
    }

    const filterKeys = Object.keys(new InsuredCollateralsFiltersObject());
    return filterKeys.map((filterKey) => getInsuredCollateralFieldQueryKey(insuredFieldCollaterals, filterKey));
}

export function parseFiltersForApi(
    filters: InsuredFilters,
    insuredFields: InsuranceInsuredField[],
    collateralInsuredFieldKey: string,
    insuredFieldCollaterals: InsuranceInsuredField | null,
): InsuredFilters {
    const { insuredFieldFilters, collateralFieldFilters, ...standardFilters } = filters;
    const parsedInsuredFieldFilters = Object.entries(insuredFieldFilters || {})
        .reduce((acc, [ key, val ]) => {
            const schema = insuredFields.find((field) =>
                field.key === getInsuredFieldKeyFromQueryKey(key))?.schema as RegularJsonSchema;
            let value = val;
            if (!schema || schema.type === JsonSchemaType.string || schema.type === JsonSchemaType.array) {
                value = JSON.stringify(value);
            }
            return { ...acc, [key]: value };
        }, {} as Record<string, string>);
    const parsedInsuredCollateralFieldFilters = Object.entries(collateralFieldFilters || {})
        .reduce((acc, [ key, val ]) => {
            const schema = (insuredFieldCollaterals as any)?.schema.items.properties[
                getInsuredCollateralFieldFromQueryKey(key, collateralInsuredFieldKey)
            ];
            let value = val;

            if (!schema || schema.type === JsonSchemaType.string || schema.type === JsonSchemaType.array) {
                value = JSON.stringify(value);
            }

            acc[key] = value;
            return acc;
        }, {} as Record<string, string>);
    const parsedFilters = { ...standardFilters };

    if (Object.keys(parsedInsuredFieldFilters).length > 0) {
        Object.assign(parsedFilters, { insuredFieldFilters: parsedInsuredFieldFilters });
    }

    if (Object.keys(parsedInsuredCollateralFieldFilters).length > 0) {
        Object.assign(parsedFilters, { collateralFieldFilters: parsedInsuredCollateralFieldFilters });
    }

    return parsedFilters;
}
