<template>
    <Modal
        :additional-class-name="['ActionStatusModal', {'ActionStatusModal--noHeader': hideHeader}]"
        :allow-close="allowClose"
        :open="open"
        @close="$emit('close')"
        @submit="onSubmit"
    >
        <template v-if="$slots.header" #header>
            <slot name="header" />
        </template>
        <slot>
            <div class="ActionStatusModal__content">
                <div v-if="type==='success'"
                    class="ActionStatusModal__successIcon"
                >
                    <FontAwesomeIcon :icon="faCheckCircle" />
                </div>
                <div v-else-if="type==='error'"
                    class="ActionStatusModal__errorIcon"
                >
                    <FontAwesomeIcon :icon="faExclamationTriangle" />
                </div>
                <div class="ActionStatusModal__message">
                    {{ message }}
                </div>
            </div>
        </slot>
        <template v-if="$slots.footer" #footer>
            <slot name="footer" />
        </template>
    </Modal>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { Modal } from '@evidentid/dashboard-commons/components/Modal';
    import { Button } from '@evidentid/dashboard-commons/components/Button';

    @Component({
        components: {
            Button,
            FontAwesomeIcon,
            Modal,
        },
    })
    export default class ActionStatusModal extends Vue {
        @Prop({ type: Boolean, default: false })
        private allowClose!: boolean;

        @Prop({ type: Boolean, default: false })
        private open!: boolean;

        @Prop({ type: String, default: 'success' })
        private type!: 'success' | 'error';

        @Prop({ type: String, default: '' })
        private message!: string;

        private faCheckCircle = faCheckCircle;
        private faExclamationTriangle = faExclamationTriangle;

        private get hideHeader(): boolean {
            return !this.$slots.header && !this.allowClose;
        }
    }
</script>
