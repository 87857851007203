<template>
    <div class="EmptyCoverageTypes">
        <img class="EmptyCoverageTypes__icon" :src="docWithExclamationMark" alt="docWithExclamationMark">
        <div class="EmptyCoverageTypes__titleText">
            No Details Available
        </div>
        <p>
            This insured has not been assigned to a Decisioning Criteria Group.
        </p>
        <p>
            Coverage types will be displayed once they've been assigned.
        </p>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from '@evidentid/vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import docWithExclamationMark from '@/modules/dashboard/assets/doc-with-exclamation-mark.svg';

    @Component({
        components: {
            FontAwesomeIcon,
        },
    })
    export default class EmptyCoverageTypes extends Vue {
        private docWithExclamationMark = docWithExclamationMark;
    }
</script>
