<template>
    <div class="EidCoverageCriterion">
        <EidJsonSchemaForm
            :schema="schema"
            :initial-value="initialValue"
            :default-value="defaultValue"
            :custom-component-input="getJsonSchemaCustomComponentInput"
            :root-properties-wrapper="rootPropertiesWrapper"
            :touched="true"
            :validate-on-mount="true"
            @input="onInput"
            @update:visible-schemas="onUpdateVisibleSchemas"
        />
    </div>
</template>

<style lang="scss">
    // stylelint-disable selector-max-combinators, selector-max-compound-selectors, selector-max-class
    $insured-field-checkbox-column-width: 85px;

    .EidCoverageCriterion {
        .EidJsonSchemaForm {
            .CoverageCriterionSettingsRow {
                &:first-child {
                    @apply pt-2.5;
                }

                @apply pb-2.5;

                &--substitutable {
                    .CriterionObjectInput.CriterionObjectInput--substitutable {
                        .CriterionEnumInput,
                        .CriterionCurrencyInput,
                        .CriterionTextInput {
                            max-width: initial;
                        }
                    }

                    .CriterionEnumInput,
                    .CriterionCurrencyInput,
                    .CriterionTextInput {
                        max-width: calc(100% - $insured-field-checkbox-column-width);
                    }
                }
            }
        }
    }
</style>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import cloneDeep from 'lodash/cloneDeep';
    import EidJsonSchemaForm
        from '@evidentid/dashboard-commons/components/EidJsonSchemaForm/EidJsonSchemaForm.vue';
    import {
        InsuranceCoverageCriterion,
        InsuranceCoverageCriterionInput,
        InsuranceCoverageCriterionTemplate,
        InsuranceInsuredField,
    } from '@evidentid/rpweb-api-client/types';
    import { buildCriterionSchema } from '@/modules/decisioning-criteria/schemas/buildCriterionSchema';
    import { JsonSchemaObject } from '@evidentid/json-schema/interfaces/JsonSchema';
    import { getJsonSchemaCustomComponentInput }
        from '@/modules/decisioning-criteria/components/CoverageCriterionInputs/criterionJsonSchemaFormElementsMap';
    import CoverageCriterionSettingsRow
        from '@/modules/decisioning-criteria/components/CoverageCriterionSettingsRow/CoverageCriterionSettingsRow.vue';
    import { coverageTypeFieldReferenceDelimiter } from '@/modules/decisioning-criteria/types';
    import { FormErrors } from '@evidentid/dashboard-commons/components/EidJsonSchemaForm/types';
    import { eidStandardizeCriterionSchema } from '@/modules/decisioning-criteria/utils/eidStandardizeCriterionSchema';
    import { createValueValidatorAllErrors } from '@evidentid/json-schema/createValueValidator';
    import { LoadCriterionMessage } from '@/modules/decisioning-criteria/models/CriterionSettingLoadMessageFunc.model';

    export default Vue.extend({
        name: 'EidCoverageCriterion',
        components: {
            EidJsonSchemaForm,
        },
        props: {
            criterion: {
                type: Object as PropType<InsuranceCoverageCriterion | InsuranceCoverageCriterionInput>,
                required: true,
            },
            template: {
                type: Object as PropType<InsuranceCoverageCriterionTemplate>,
                required: true,
            },
            insuredFields: {
                type: Array as PropType<InsuranceInsuredField[]>,
                default: () => [] as InsuranceInsuredField[],
            },
            loadCriterionMessage: {
                type: Function as PropType<LoadCriterionMessage>,
                required: true,
            },
        },
        data() {
            return {
                rootPropertiesWrapper: CoverageCriterionSettingsRow,
                schema: eidStandardizeCriterionSchema(
                    buildCriterionSchema(
                        this.criterion as any,
                        Object.keys((this.template as any).evaluator?.references) || [],
                        this.template,
                    ),
                    this.insuredFields,
                ) as JsonSchemaObject,
                initialValue: Object.entries(this.criterion.evaluator.references)
                    .reduce((acc, [ key, { value } ]) => {
                        acc[key] = value;
                        return acc;
                    }, {} as Record<string, any>),
                defaultValue: Object.entries(this.template.evaluator.references)
                    .reduce((acc, [ key, { value } ]) => {
                        acc[key] = value;
                        return acc;
                    }, {} as Record<string, any>),
            };
        },
        created() {
            const validator = createValueValidatorAllErrors(this.schema);
            validator(this.initialValue);
            const isValid = validator.errors ? validator.errors.length > 0 : true;
            this.loadCriterionMessage(isValid, this.criterion);
            this.$emit('controller-value-update', this.initialValue);
        },
        methods: {
            getJsonSchemaCustomComponentInput,
            onInput({
                formValue,
                formErrors,
                propValue,
                propKey,
                propErrors,
            }: {
                formValue: Record<string, any>;
                formErrors: FormErrors;
                propValue: any;
                propKey: string;
                propErrors: FormErrors;
            }): void {
                const updatedCriterion = cloneDeep(this.criterion);
                updatedCriterion.evaluator.references[propKey].value = propValue;
                const reference = [
                    this.criterion.coverageType,
                    this.criterion.field,
                    propKey,
                ].join(coverageTypeFieldReferenceDelimiter);
                this.$emit('input', updatedCriterion);
                this.$emit('reference-accessed', { reference, isValid: !propErrors?.length });
                this.$emit('controller-value-update', formValue);
                this.loadCriterionMessage(!formErrors?.length, updatedCriterion);
            },
            onUpdateVisibleSchemas(visibleSchemas: string[]) {
                this.$emit('update:visible-schemas', visibleSchemas);
            },
        },
    });
</script>
