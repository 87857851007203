import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import { InsuranceInsured, InsuranceInsuredFieldValue } from '@evidentid/rpweb-api-client/types';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';

export function isNullishOrEmptyValue(val: any): boolean {
    return typeof val === 'object' ? isEmpty(val) : val == null || val === '';
}

export function isDeletingInsureFieldItem(
    diffKey: string,
    diffVal: any,
    insuredFields: InsuranceInsuredFieldValue | null): boolean {
    if (!insuredFields) {
        return false;
    }
    const originalValue = insuredFields[diffKey];
    const isDeleteItem = Array.isArray(originalValue)
        && Array.isArray(diffVal)
        && diffVal.length < originalValue.length;
    const isDeleteLastItem = Array.isArray(originalValue) && diffVal === null;
    return isDeleteItem || isDeleteLastItem;
}

export function editingRequiresPutOperation(objToCheck: Record<string, any>, diff: Record<string, any>): boolean {
    return Object.entries(diff).some(([ k, v ]) => {
        const addNew = isNullishOrEmptyValue(objToCheck[k]) && !isNullishOrEmptyValue(v);
        const clear = !isNullishOrEmptyValue(objToCheck[k]) && isNullishOrEmptyValue(v);
        return addNew || clear;
    });
}

export function getInsuredFieldsDiff(oldVal: InsuranceInsuredFieldValue,
    newVal: InsuranceInsuredFieldValue): InsuranceInsuredFieldValue {
    const diffOfChangeOrRemove = Object.entries(oldVal).reduce((acc, [ key, val ]) => {
        const change = !isNil(newVal[key]) && !isEqual(newVal[key], val);
        const clear = !isNullishOrEmptyValue(val) && isNullishOrEmptyValue(newVal[key]);
        return { ...acc, ...((change || clear) && { [key]: newVal[key] }) };
    }, {});
    const diffOfAddNew = Object.entries(newVal).reduce((acc, [ key, val ]) => {
        const add = isNil(oldVal[key]) && !isNullishOrEmptyValue(newVal[key]);
        return { ...acc, ...(add && { [key]: val }) };
    }, {});
    return { ...diffOfChangeOrRemove, ...diffOfAddNew };
}

export function getStandardFieldsDiff(
    oldVal: InsuranceInsured,
    newVal: InsuranceInsured): Record<string, any> {
    const isDiff = (value: any, key: string) =>
        oldVal !== undefined && !isEqual((oldVal as Record<string, any>)[key], value);
    const diff = pickBy(newVal, (newValProp, key) => isDiff(newValProp, key));
    const emptyToNull = (val: any) => (val === '' ? null : val);
    return mapValues(diff, emptyToNull);
}
