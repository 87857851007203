<template>
    <div class="CcgTypeSelectionCard flex flex-col justify-center items-center">
        <div
            class="border border-solid border-eidSecureBlue-25 rounded-lg p-12 mb-2.5 cursor-pointer hover:opacity-75"
            data-test-id="CcgTypeSelectionCard__iconContainer"
            :class="{'CcgTypeSelectionCard__iconContainer--selected bg-eidTrustGreen-10':selected}"
            @click="selectCard"
        >
            <EidIcon
                data-test-id="CcgTypeSelectionCard__icon"
                :icon-src="ccgTypeIconSrc"
                :svg-props="{color: typeIconColor, width: '60px', height: '60px'}"
            />
        </div>
        <div class="flex" data-test-id="CcgTypeSelectionCard__title">
            <span data-test-id="CcgTypeSelectionCard__titleText">{{ title }}</span>
            <EidIcon
                v-tooltip="description"
                class="ml-1 text-eidSecureBlue-50"
                data-test-id="CcgTypeSelectionCard__titleTooltipIcon"
                :icon-src="faQuestionCircle"
            />
        </div>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
    import { InsuranceCoverageCriteriaGroupType } from '@evidentid/rpweb-api-client/types';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { tailwindColors } from '@/styles/variables/tailwind-colors/tailwindColors';
    import { getCcgTypeIconSrc } from '@/modules/decisioning-criteria/utils/get-ccg-type-icon-src/getCcgTypeIconSrc';

    export default Vue.extend({
        name: 'CcgTypeSelectionCard',
        components: {
            EidIcon,
        },
        props: {
            title: {
                type: String as PropType<string>,
                default: '',
            },
            type: {
                type: String as PropType<InsuranceCoverageCriteriaGroupType | null>,
                default: null,
            },
            description: {
                type: String as PropType<string>,
                default: '',
            },
            selected: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },
        data() {
            return {
                faQuestionCircle,
            };
        },
        computed: {
            ccgTypeIconSrc(): string {
                return this.type ? getCcgTypeIconSrc(this.type) : '';
            },
            typeIconColor(): string {
                return this.selected
                    ? tailwindColors.eidTrustGreen.DEFAULT
                    : tailwindColors.eidQualityBlack['50'];
            },
        },
        methods: {
            selectCard(): void {
                this.$emit('input', this.type);
            },
        },
    });
</script>
