<template>
    <div class="PopoverInsuredFieldFilters">
        <div class="PopoverInsuredFieldFilters__sectionTitle">
            Insured Fields
        </div>
        <div>
            <div
                v-for="{ id, name, schema, key, description } in insuredFieldsWithQueryKey"
                :key="id"
                class="PopoverInsuredFieldFilters__container"
                :class="{'PopoverInsuredFieldFilters__container--full': schema.type === 'object'}"
            >
                <InsuredFieldFilterField
                    :key="id"
                    :data-test-id="`PopoverInsuredFieldFilters__${getInsuredFieldKeyFromQueryKey(key)}`"
                    :value="getFilterValue(key, schema)"
                    :type="schema.type"
                    :label="name"
                    :placeholder="description || `Type ${name}`"
                    :schema="schema"
                    :filter-key="key"
                    @input="inputFilter"
                    @scroll-to="emitScrollDistance"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import { InsuranceInsuredField } from '@evidentid/rpweb-api-client/types';
    import {
        getInsuredFieldKeyFromQueryKey,
        getInsuredFieldQueryKey,
    } from '@/modules/insured-filtering/utils/insureFilterUtils';
    import InsuredFieldFilterField
        from '@/modules/insured-filtering/components/InsuredFiltersPopover/InsuredFieldFilterField/InsuredFieldFilterField.vue';
    import JsonSchema from '@evidentid/json-schema/interfaces/JsonSchema';
    import { isObject } from '@evidentid/json-schema/schemaChecks';
    import { InsuredFieldFilterInputData } from '@/modules/insured-filtering/models/InsuredFieldFilterInputData.model';

    export default Vue.extend({
        name: 'PopoverInsuredFieldFilters',
        components: {
            InsuredFieldFilterField,
        },
        props: {
            filters: {
                type: Object as PropType<Record<string, string>>,
                default: () => ({}) as Record<string, string>,
            },
            insuredFields: {
                type: Array as PropType<InsuranceInsuredField[]>,
                default: () => [] as InsuranceInsuredField[],
            },
        },
        data() {
            return {
                insuredFieldsWithQueryKey: [] as InsuranceInsuredField[],
            };
        },
        watch: {
            insuredFields: {
                immediate: true,
                handler(newValue: InsuranceInsuredField[]) {
                    this.insuredFieldsWithQueryKey = newValue.map((field) => ({
                        ...field,
                        key: getInsuredFieldQueryKey(field.key),
                    }));
                },
            },
        },
        methods: {
            getInsuredFieldKeyFromQueryKey,
            inputFilter({ filterKey, value }: InsuredFieldFilterInputData): void {
                this.$emit('input', { ...this.filters, [filterKey]: value });
            },
            emitScrollDistance(distance: number): void {
                this.$emit('scroll-to', distance);
            },
            getFilterValue(key: string, schema: JsonSchema): any {
                if (isObject(schema)) {
                    return Object.keys(schema.properties).reduce((acc, propName) => {
                        const combinedKey = `${key}.${propName}`;
                        const val = this.filters[combinedKey];
                        if (val) {
                            acc[combinedKey] = val;
                        }
                        return acc;
                    }, {} as Record<string, any>);
                } else {
                    return this.filters[key] || null;
                }
            },
        },
    });
</script>
