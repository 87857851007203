<template>
    <Modal open additional-class-name="ConfirmSendInsuredRequestModal" @close="close">
        <template #header>
            Are you sure?
        </template>
        <div
            class="ConfirmSendInsuredRequestModal__body text-center"
            data-test-id="ConfirmSendInsuredRequestModal__body"
        >
            <p data-test-id="ConfirmSendInsuredRequestModal__statement">
                <strong>{{ modalLabels.statement.boldText }}</strong> {{ modalLabels.statement.normalText }}
            </p>
            <p v-if="modalLabels.info" data-test-id="ConfirmSendInsuredRequestModal__info">
                {{ modalLabels.info }}
            </p>
        </div>

        <div class="ConfirmSendInsuredRequestModal__actionSection">
            <Button
                type="primary"
                class="ConfirmSendInsuredRequestModal__submitButton"
                :loading="saving"
                @click="confirm"
            >
                Send Request
            </Button>
        </div>

        <div class="ConfirmSendInsuredRequestModal__footer">
            <div class="ConfirmSendInsuredRequestModal__footerLine">
                <span>To learn more about requesting compliant insurance, visit the </span>
                <a
                    href="https://evidentidsupport.zendesk.com/hc/en-us/articles/4407110845847-On-Demand-Request-Creation"
                    class="ConfirmSendInsuredRequestModal__footerLink"
                    target="_blank"
                >
                    Knowledge Base.
                </a>
            </div>
        </div>
    </Modal>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { InsuranceInsured, InsuranceVerificationStatus } from '@evidentid/rpweb-api-client/types';
    import { InsuranceComplianceStatus } from '@evidentid/insurance-facing-lib/models/insured-details';
    import { Modal } from '@evidentid/dashboard-commons/components/Modal';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    interface ModalLabels {
        statement: {
            boldText: string;
            normalText: string;
        };
        info?: string;
    }

    @Component({
        components: {
            Modal,
            Button,
            FontAwesomeIcon,
        },
    })
    export default class ConfirmSendInsuredRequestModal extends Vue {
        @Prop({ type: Array, default: () => [] })
        private insureds!: InsuranceInsured[];

        @Prop({ type: Boolean, default: false })
        private saving!: boolean;

        private get exhaustedInsureds(): InsuranceInsured[] {
            return this.insureds.filter(this.isExhausted);
        }

        private get modalLabels(): ModalLabels {
            if (this.insureds.length === 1) {
                return {
                    statement: {
                        boldText: this.insureds[0].displayName,
                        normalText: 'will be sent an email to remedy their non-compliant status.',
                    },
                };
            }
            return {
                statement: {
                    boldText: `${this.exhaustedInsureds.length} of ${this.insureds.length} insureds`,
                    normalText: 'selected are currently in the exhausted state.',
                },
            };
        }

        private isExhausted(insured: InsuranceInsured): boolean {
            return !insured.paused &&
                insured.complianceStatus === InsuranceComplianceStatus.nonCompliant &&
                insured.verificationStatus === InsuranceVerificationStatus.exhausted;
        }

        private confirm(): void {
            this.$emit('confirm');
        }

        private close(): void {
            this.$emit('close');
        }
    }
</script>
