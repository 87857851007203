<template>
    <div class="CriterionObjectInput" :class="{'CriterionObjectInput--substitutable': insuredFieldSubstitutable}">
        <template v-if="!hideTitle && form.schema.title">
            <div class="CriterionObjectInput__titleContainer">
                <div class="CriterionObjectInput__title">
                    <h1 v-if="depth === 0">
                        {{ form.schema.title }}
                    </h1>
                    <h2 v-else-if="depth === 1">
                        {{ form.schema.title }}
                    </h2>
                    <h3 v-else-if="depth === 2">
                        {{ form.schema.title }}
                    </h3>
                    <h4 v-else>
                        {{ form.schema.title }}
                    </h4>
                </div>
                <div v-if="deletable" class="CriterionObjectInput__deleteIcon" @click="$emit('delete')">
                    <EidIcon
                        :icon-src="trashIcon"
                        alt="delete"
                        :svg-props="{color: alertRedColor}"
                    />
                </div>
            </div>
        </template>
        <component
            :is="FormElementComponent"
            v-for="property in filteredProperties"
            :id="`${id}_${property.name}`"
            :key="property.form.key"
            :depth="depth + 1"
            :form="property.form"
            :hide-title="hideTitle"
            :show-error="showError"
            :required="property.required"
            :disabled="disabled || property.form.schema.readOnly"
            :custom-component-input="customComponentInput"
            :value="getPropValue(property)"
            :touched="touched"
            :strings="strings"
            :disallowed-values="disallowedValues && disallowedValues[property.name]"
            v-bind="$attrs"
            @input="onInput(property, $event)"
            @change="onChange(property, $event)"
        />
    </div>
</template>

<script lang="ts">
    import { Component, Prop } from 'vue-property-decorator';
    import { JsonFormObject, JsonFormProperty } from '@evidentid/json-schema/interfaces/JsonForm';
    import AbstractCriterionInput from './AbstractCriterionInput';
    import trashIcon from '@/assets/icons/bin-icon.svg';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { tailwindColors } from '@/styles/variables/tailwind-colors/tailwindColors';
    import { isSchemaInsuredFieldSubstitutable }
        from '@/modules/decisioning-criteria/utils/isSchemaInsuredFieldSubstitutable';

    @Component({
        inheritAttrs: false,
        components: {
            EidIcon,
        },
    })
    export default class CriterionObjectInput extends AbstractCriterionInput<JsonFormObject, object> {
        @Prop()
        protected disallowedValues: any;

        private trashIcon = trashIcon;
        private alertRedColor = tailwindColors.eidDangerRed.DEFAULT;

        private get properties(): JsonFormProperty[] {
            return this.form.getProperties(this.value);
        }

        private get filteredProperties(): JsonFormProperty[] {
            if (this.insuredFieldSubstitutable) {
                return this.properties;
            }

            return this.properties.filter((property) => !property.name.includes('BasedOnInsuredField'));
        }

        private getPropValue(property: JsonFormProperty) {
            return property.form.getValue((this.value as any)?.[property.name] || undefined, true);
        }

        private onInput(property: JsonFormProperty, value: any) {
            this.$emit('input', this.form.getValue({
                ...this.value,
                [property.name]: value,
            }, true));
        }

        private onChange(property: JsonFormProperty, value: any) {
            this.$emit('change', this.form.getValue({
                ...this.value,
                [property.name]: value,
            }, true));
        }

        private get insuredFieldSubstitutable(): boolean {
            return isSchemaInsuredFieldSubstitutable(this.form.schema);
        }
    }
</script>
