<template>
    <Menu big>
        <MenuLink
            class="MainMenu__topLevelItem"
            :href="localRpUrl('/dashboard')"
            :icon="dashboardIcon"
            :active="tab === 'dashboard'"
            label="Insureds"
        />
        <MenuLink
            class="MainMenu__topLevelItem"
            :href="localRpUrl('/decisioningCriteria')"
            :icon="decisioningCriteria"
            :active="tab === 'decisioningCriteria'"
            label="Decisioning Criteria"
        />
        <MenuLink
            class="MainMenu__topLevelItem"
            :href="localRpUrl('/actions-review')"
            :icon="actionsReview"
            :active="tab === 'actionsReview'"
            label="Actions Review"
        />
        <MenuLink
            class="MainMenu__topLevelItem"
            data-test-id="MainMenu__configuration"
            :icon="configurationIcon"
            :active="tab === 'configuration'"
            :open="configTabOpened"
            label="Configuration"
            @click="toggleConfigTab"
        >
            <WithPermissions
                class="MainMenu__configurationItem"
                :component="MenuLink"
                :props="{ href: localRpUrl('/admin/api-settings'),
                          label: 'API Settings',
                          active: configurationTab === 'apiSettings'}"
                :permissions="[ permissionSettings ]"
                adapter="auth0"
                accept-not-scoped
            />
            <WithPermissions
                class="MainMenu__configurationItem"
                :component="MenuLink"
                :props="{ href: localRpUrl('/admin/users'),
                          label: 'Manage Users',
                          active: configurationTab === 'manageUsers'}"
                :permissions="[ permissionUsers ]"
                adapter="auth0"
                accept-not-scoped
            />
            <MenuLink
                class="MainMenu__configurationItem"
                :href="localRpUrl('/configuration/insuredFields')"
                :active="configurationTab === 'insuredFields'"
                label="Insured Fields Display"
            />
            <WithPermissions
                v-if="eventManagerEnabled"
                class="MainMenu__configurationItem"
                data-test-id="MainMenu__notifications"
                :component="MenuLink"
                :props="{ href: localRpUrl('/admin/notifications/branding'),
                          label: 'Notification',
                          active: configurationTab === 'brandingSettings',
                          open: notificationsTabOpened}"
                :permissions="[ permissionSettings ]"
                accept-not-scoped
                @click="toggleNotificationsTab"
            >
                <MenuLink
                    v-if="eventManagerEnabled"
                    class="MainMenu__notificationItem"
                    :href="localRpUrl('/admin/notifications/branding')"
                    :active="notificationTab === 'brandingSettings'"
                    label="Branding Settings"
                />
                <MenuLink
                    v-if="eventManagerEnabled"
                    class="MainMenu__notificationItem"
                    :href="localRpUrl('/admin/notifications/support')"
                    :active="notificationTab === 'support'"
                    label="Support Contact Information"
                />
            </WithPermissions>
        </MenuLink>
        <MenuLink
            class="MainMenu__topLevelItem"
            data-test-id="MainMenu__emailSettings"
            :icon="emailSettingsIcon"
            :active="tab === 'emailSettings'"
            :open="emailSettingsTabOpened"
            @click="toggleEmailSettingsTab"
        >
            <template #label>
                <span class="MenuElement__linkText flex items-center">
                    Email Settings
                    <EidIcon
                        v-if="someNotificationsOff"
                        v-tooltip="{
                            html: true,
                            content: '<strong>Some notifications are off</strong> <br> See more in Email Templates',
                        }"
                        data-test-id="MainMenu__emailSettingsNotificationsOffIcon"
                        class="inline-block ml-2 text-eidDangerRed"
                        :icon-src="triangleExclamationIcon"
                    />
                </span>
            </template>
            <MenuLink
                class="MainMenu__emailSettingsItem"
                :href="localRpUrl('/admin/email-settings/configuration')"
                :active="emailSettingsTab === 'emailConfiguration'"
                label="Email Configuration"
            />
            <MenuLink
                class="MainMenu__emailSettingsItem"
                :href="localRpUrl('/admin/email-settings/templates')"
                :active="emailSettingsTab === 'emailTemplates'"
            >
                <template #label>
                    <span class="MenuElement__linkText flex items-center">
                        Email Templates
                        <EidIcon
                            v-if="someNotificationsOff"
                            v-tooltip="{
                                html: true,
                                content: '<strong>Some notifications are off</strong> <br> Set schedules and switch on',
                            }"
                            data-test-id="MainMenu__emailTemplatesNotificationsOffIcon"
                            class="inline-block ml-2 text-eidDangerRed"
                            :icon-src="triangleExclamationIcon"
                        />
                    </span>
                </template>
            </MenuLink>
        </MenuLink>
    </Menu>
</template>

<script lang="ts">
    import { Component, Vue, Watch } from '@evidentid/vue-property-decorator';
    import { Menu, MenuLink } from '@evidentid/dashboard-commons/components/Menu';
    import WithPermissions from '@evidentid/dashboard-commons/modules/auth/components/WithPermissions.vue';
    import dashboardIcon from '@/assets/icons/nav-icon-dashboard.svg';
    import configurationIcon from '@/assets/icons/nav-icon-configuration.svg';
    import decisioningCriteria from '@/assets/icons/nav-icon-decisioning-criteria.svg';
    import actionsReview from '@/assets/icons/nav-icon-actions-review.svg';
    import emailSettingsIcon from '@/assets/icons/nav-icon-email-settings.svg';
    import triangleExclamationIcon from '@/assets/icons/triangle-exclamation.svg';
    import { InsuranceRequestsConfig, RelyingPartySignature } from '@evidentid/rpweb-api-client/types';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { createEmptyRequestsConfigStatus } from '@/modules/dashboard/vuex/dashboard';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';

    function localUrl(routePath: string) {
        return `#${routePath}`;
    }

    function legacyUrl(routePath: string) {
        const rpFacingUrl = deploy.WEB_PUBLIC_RPFACING_PUBLIC_URL;
        const baseUrl = rpFacingUrl ? `${rpFacingUrl}#!` : '#';
        return `${baseUrl}${routePath}`;
    }

    @Component({
        components: { MenuLink, Menu, WithPermissions, EidIcon },
    })
    export default class MainMenu extends Vue {
        private dashboardIcon = dashboardIcon;
        private configurationIcon = configurationIcon;
        private decisioningCriteria = decisioningCriteria;
        private actionsReview = actionsReview;
        private emailSettingsIcon = emailSettingsIcon;
        private triangleExclamationIcon = triangleExclamationIcon;

        private readonly MenuLink = MenuLink;

        private get rpName(): string {
            return this.$rp?.current || '';
        }

        @Watch('rpName', { immediate: true })
        private onRpNameChange() {
            this.loadRequestsConfig();
        }

        private get requestsConfigStatus(): {
            status: OperationStatus;
            data: InsuranceRequestsConfig | null;
        } {
            return this.$store.state.dashboard.requestsConfig[this.rpName] || createEmptyRequestsConfigStatus();
        }

        private get loadingRequestsConfig(): boolean {
            return this.requestsConfigStatus.status === OperationStatus.loading;
        }

        private get currentRp(): RelyingPartySignature | null {
            return this.$store.state.user.relyingParties?.find(({ name }) => (name === this.$rp.current)) || null;
        }

        private get eventManagerEnabled(): boolean {
            return Boolean(this.currentRp?.externalConfigurationEnabled);
        }

        private get permissionUsers() {
            return { service: 'rpweb', permission: 'manage:users', resource: this.$rp.current };
        }

        private get permissionSettings() {
            return { service: 'rpweb', permission: 'manage:configuration', resource: this.$rp.current };
        }

        private configTabOpened = false;
        private notificationsTabOpened = false;
        private emailSettingsTabOpened = false;

        @Watch('tab', { immediate: true })
        private onTabChange(): void {
            this.configTabOpened = this.tab === 'configuration';
            this.notificationsTabOpened = this.configurationTab === 'notifications';
            this.emailSettingsTabOpened = this.tab === 'emailSettings';
        }

        private toggleConfigTab() {
            this.configTabOpened = !this.configTabOpened;
        }

        private toggleNotificationsTab() {
            this.notificationsTabOpened = !this.notificationsTabOpened;
        }

        private toggleEmailSettingsTab() {
            this.emailSettingsTabOpened = !this.emailSettingsTabOpened;
        }

        private localRpUrl(routePath: string) {
            const rpId = this.$rp.current;
            const rpIdUrlPart = rpId ? `/${rpId}` : '';
            return localUrl(`${rpIdUrlPart}${routePath}`);
        }

        private legacyRpUrl(routePath: string) {
            const rpId = this.$route.params?.rpId;
            const rpIdUrlPart = rpId ? `/${rpId}` : '';
            return legacyUrl(`${rpIdUrlPart}${routePath}`);
        }

        private get tab(): string | null {
            const routeName = this.$route.name!;
            const routePath = this.$route.path!;
            if (routeName.startsWith('dashboard')) {
                return 'dashboard';
            }
            if (routePath.includes('/decisioningCriteria')) {
                return 'decisioningCriteria';
            }
            if (routePath.includes('/actions-review')) {
                return 'actionsReview';
            }
            if (routePath.includes('/email-settings')) {
                return 'emailSettings';
            }
            if (routePath.includes('/configuration') || routePath.includes('/admin')) {
                return 'configuration';
            }
            return null;
        }

        private get configurationTab(): string | null {
            if (this.tab !== 'configuration') {
                return null;
            }
            const routeName = this.$route.name!;
            if (routeName.startsWith('insuredFields')) {
                return 'insuredFields';
            } else if (routeName.startsWith('manage-users')) {
                return 'manageUsers';
            } else if (routeName.startsWith('notifications-')) {
                return 'notifications';
            } else if (routeName.startsWith('apiSettings')) {
                return 'apiSettings';
            }
            return null;
        }

        private get notificationTab(): string | null {
            if (this.configurationTab !== 'notifications') {
                return null;
            }
            const routeName = this.$route.name!;
            if (routeName === 'notifications-branding-settings') {
                return 'brandingSettings';
            } else if (routeName === 'notifications-support-contact') {
                return 'support';
            }
            return null;
        }

        private get emailSettingsTab(): string | null {
            if (this.tab !== 'emailSettings') {
                return null;
            }
            const routeName = this.$route.name!;
            if (routeName === 'email-settings-configuration') {
                return 'emailConfiguration';
            } else if (routeName === 'email-settings-templates') {
                return 'emailTemplates';
            }
            return null;
        }

        private get someNotificationsOff(): boolean {
            return Object.values(this.requestsConfigStatus.data?.verificationRequestConfigurations || {}).some(
                ({ initEnabled, remindersEnabled }) => !initEnabled && !remindersEnabled,
            );
        }

        private loadRequestsConfig() {
            if (!this.loadingRequestsConfig) {
                this.$store.actions.dashboard.loadRequestsConfig({ rpName: this.rpName });
            }
        }
    }
</script>
