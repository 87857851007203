<template>
    <div
        class="CoverageTypeCard"
        :class="{
            'CoverageTypeCard--selected': selected,
            'CoverageTypeCard--hasDeprecatedCriteria': hasDeprecatedCriteria,
            'CoverageTypeCard--hasInvalidReferences': hasInvalidReferences,
        }"
        @click="$emit('click')"
    >
        <div class="CoverageTypeCard__titleRow">
            <div class="CoverageTypeCard__iconAndTitle">
                <EidIcon
                    class="CoverageTypeCard__typeIcon"
                    :icon-src="typeIconSrc"
                    :svg-props="{color: evidentGreenColor}"
                />
                <span class="CoverageTypeCard__title">{{ title }}</span>
            </div>
            <div class="CoverageTypeCard__iconContainer">
                <EidIcon
                    :icon-src="deleteIconSrc"
                    alt="delete"
                    class="CoverageTypeCard__deleteIcon"
                    :svg-props="{color: evidentGreenColor}"
                    @click="onDelete"
                />
            </div>
        </div>
        <div class="CoverageTypeCard__criteriaRow">
            <span class="CoverageTypeCard__numberOfCriteria">
                <strong>{{ criteriaCount }}</strong>
                <span> Coverage Criteria</span>
            </span>
            <div v-if="isCoverageTypeInEvaluationRule" class="CoverageTypeCard__iconContainer">
                <img :src="balanceScaleLeftSrc" class="CoverageTypeCard__evaluationRuleIcon" alt="icon">
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import { InsuranceCoverageType } from '@evidentid/insurance-facing-lib/models/insured-details';
    import binIcon from '@/assets/icons/bin-icon.svg';
    import balanceScaleLeft from '@/assets/icons/icon-balance-scale-left.svg';
    import { getCoverageTypeIcon } from '@/modules/insured-details/utils/insuredCoverage';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { tailwindColors } from '@/styles/variables/tailwind-colors/tailwindColors';

    @Component({
        components: {
            EidIcon,
            FontAwesomeIcon,
        },
    })
    export default class CoverageTypeCard extends Vue {
        @Prop()
        private coverageType!: InsuranceCoverageType;

        @Prop({ type: String, default: '' })
        private title!: string;

        @Prop({ type: Number, default: 0 })
        private criteriaCount!: number;

        @Prop({ type: Boolean, default: false })
        private selected!: boolean;

        @Prop({ type: Boolean, default: false })
        private hasDeprecatedCriteria!: boolean;

        @Prop({ type: Boolean, default: false })
        private isCoverageTypeInEvaluationRule!: boolean;

        @Prop({ type: Boolean, default: false })
        private hasInvalidReferences!: boolean;

        private deleteIconSrc = binIcon;
        private typeIconSrc = getCoverageTypeIcon(this.coverageType);
        private balanceScaleLeftSrc = balanceScaleLeft;
        private evidentGreenColor = tailwindColors.eidTrustGreen.DEFAULT;

        @Watch('coverageType', { immediate: true })
        private onTypeChange() {
            this.typeIconSrc = getCoverageTypeIcon(this.coverageType);
        }

        private onDelete(clickEvent: MouseEvent): void {
            clickEvent.stopPropagation();
            this.$emit('delete', this.coverageType);
        }
    }
</script>
