import omit from 'lodash/omit';
import { createStateFactory } from '@evidentid/vue-commons/store';
import type RpWebApiClient from '@evidentid/rpweb-api-client';
import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
import { getPersistingErrorActions } from '@evidentid/dashboard-commons/modules/persisting-error';
import { getInsuranceDetails } from '@/modules/insured-certificates/services/getInsuranceDetails';
import type { RprDocumentDetails } from '@/modules/insured-certificates/types';

interface DocumentResult {
    status: OperationStatus;
    content: RprDocumentDetails[];
}

export interface InsuredDocumentsRequirements {
    rpweb: RpWebApiClient;
}

export interface InsuredDocumentsState {
    rprDocuments: Record<string, Record<string, DocumentResult>>;
}

const createState = createStateFactory<InsuredDocumentsRequirements>();

const { instantiateState, createMutationsFactories } = createState<InsuredDocumentsState>(() => ({
    rprDocuments: {},
}));

const { instantiateMutations, createActionFactories } = createMutationsFactories(() => ({
    startLoadingRprDocument(payload: { rpName: string, email: string }) {
        this.rprDocuments = {
            ...this.rprDocuments,
            [payload.rpName]: {
                ...this.rprDocuments[payload.rpName],
                [payload.email]: {
                    status: OperationStatus.loading,
                    content: [],
                },
            },
        };
    },
    failLoadingRprDocument(payload: { rpName: string, email: string }) {
        this.rprDocuments = {
            ...this.rprDocuments,
            [payload.rpName]: {
                ...this.rprDocuments[payload.rpName],
                [payload.email]: {
                    status: OperationStatus.error,
                    content: [],
                },
            },
        };
    },
    finishLoadingRprDocument(payload: { rpName: string, email: string, content: RprDocumentDetails[] }) {
        this.rprDocuments = {
            ...this.rprDocuments,
            [payload.rpName]: {
                ...this.rprDocuments[payload.rpName],
                [payload.email]: {
                    status: OperationStatus.success,
                    content: payload.content,
                },
            },
        };
    },
    clearDocuments(payload: { rpName: string, email: string }) {
        this.rprDocuments = {
            ...this.rprDocuments,
            [payload.rpName]: omit({
                ...this.rprDocuments[payload.rpName],
            }, payload.email),
        };
    },
}));

const { instantiateActions, instantiateModule, getActions } = createActionFactories(({ rpweb }) => ({
    async loadRprDocumentDetails(
        payload: { rpName: string, email: string, rprId?: string, submissionDate?: string }
    ) {
        const { rpName, email, rprId, submissionDate } = payload;
        try {
            this.mutations.startLoadingRprDocument({ rpName, email });
            const content = await getInsuranceDetails(
                rpweb, rpName, email, undefined, rprId, submissionDate
            );
            this.mutations.finishLoadingRprDocument({ rpName, email, content });
        } catch (error) {
            console.warn('GET Rpr Documents error', error);
            await getPersistingErrorActions(this).showError(error);
            this.mutations.failLoadingRprDocument({ rpName, email });
        }
    },
    async clearRprDocumentDetails(payload: { rpName: string, email: string }) {
        this.mutations.clearDocuments(payload);
    },
}));

export default {
    instantiateState,
    instantiateActions,
    instantiateMutations,
    instantiateModule,
    getActions,
};
