import { NotificationName } from '@evidentid/config-api-client/types';
import { VerificationRequestType } from '@evidentid/rpweb-api-client/types';

export function getVerificationRequestType(notificationName: NotificationName): VerificationRequestType {
    switch (notificationName) {
        case NotificationName.newRequestReminder:
            return VerificationRequestType.new;
        case NotificationName.nonCompliantRequestReminder:
            return VerificationRequestType.nonCompliant;
        case NotificationName.expiringRequestReminder:
            return VerificationRequestType.expiring;
        default:
            throw new Error(`Unknown notification name: ${notificationName}`);
    }
}
