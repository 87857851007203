<template>
    <div class="CoverageCriteriaGroupHeader">
        <div class="CoverageCriteriaGroupHeader__headerStart">
            <div class="InsuredDetails__backButton" @click="onCloseButtonClick">
                <FontAwesomeIcon
                    class="InsuredDetails__headerBackIcon"
                    :icon="faChevronLeft"
                />
            </div>
            <EidIcon
                v-if="ccgType"
                :icon-src="ccgIconSrc"
                :svg-props="{color: eidTrustGreen, width: '25px', height: '25px', margin: '0 5px'}"
                data-test-id="CoverageCriteriaGroupHeader_groupTypeIcon"
            />
            <div
                v-if="viewMode && !isInvalid"
                class="CoverageCriteriaGroupHeader__groupNameText"
                :class="{'CoverageCriteriaGroupHeader__groupNamePlaceholder': !criteriaGroupName}"
                @click="displayInput"
            >
                {{ criteriaGroupName || 'Enter Group Name' }}
            </div>
            <FormInput v-else>
                <input
                    ref="input"
                    class="CoverageCriteriaGroupHeader__input"
                    :class="{'CoverageCriteriaGroupHeader__input--invalid': isInvalid}"
                    :value="criteriaGroupName"
                    placeholder="Enter Group Name"
                    @blur="displayText"
                    @input="onNameInput"
                >
            </FormInput>
            <span class="CoverageCriteriaGroupHeader__country">Country: {{ countryCode }}</span>
        </div>
        <slot name="headerMiddle" />
        <div class="CoverageCriteriaGroupHeader__headerEnd">
            <div class="CoverageCriteriaGroupHeader__headerStatus">
                <span>Status: </span>
                <span
                    class="CoverageCriteriaGroupHeader__headerStatusText"
                    :class="{'CoverageCriteriaGroupHeader__headerStatusText--active': criteriaGroupActive}"
                    data-test-id="CoverageCriteriaGroupHeader__saveStatus"
                >
                    {{ status }}
                </span>
                <div
                    v-if="dirtyState"
                    class="CoverageCriteriaGroupHeader__saveStatus"
                    data-test-id="CoverageCriteriaGroupHeader__saveStatus"
                >
                    Unsaved Changes
                </div>
            </div>
            <div class="CoverageCriteriaGroupHeader__headerSaveButtonWrapper">
                <CoverageCriteriaGroupSaveButton
                    :disabled="!criteriaGroupName || saveDisabled"
                    @saveActive="onSave(true)"
                    @saveDiscover="onSave(false)"
                />
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { Expandable } from '@evidentid/dashboard-commons/components/Expandable';
    import { Menu, MenuLink } from '@evidentid/dashboard-commons/components/Menu';
    import CoverageCriteriaGroupSaveButton
        from '@/modules/decisioning-criteria/components/CoverageCriteriaGroupSaveButton/CoverageCriteriaGroupSaveButton.vue';
    import { getCcgTypeIconSrc } from '@/modules/decisioning-criteria/utils/get-ccg-type-icon-src/getCcgTypeIconSrc';
    import { PropType } from 'vue';
    import { InsuranceCoverageCriteriaGroupType } from '@evidentid/rpweb-api-client/types';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { tailwindColors } from '@/styles/variables/tailwind-colors/tailwindColors';

    @Component({
        components: {
            CoverageCriteriaGroupSaveButton,
            Button,
            EidIcon,
            Expandable,
            Menu,
            MenuLink,
            FontAwesomeIcon,
            FormInput,
            FormElement,
        },
    })
    export default class CoverageCriteriaGroupHeader extends Vue {
        @Prop({ type: String })
        private criteriaGroupName!: string;

        @Prop({ type: String })
        private countryCode!: string;

        @Prop({ type: Boolean, default: false })
        private criteriaGroupActive!: boolean;

        @Prop({ type: Boolean, default: false })
        private dirtyState!: boolean;

        @Prop({ type: Boolean, default: false })
        private saveDisabled!: boolean;

        @Prop({ type: [ String, null ] as PropType<InsuranceCoverageCriteriaGroupType | null>, default: null })
        private ccgType!: InsuranceCoverageCriteriaGroupType | null;

        private faChevronLeft = faChevronLeft;
        private eidTrustGreen = tailwindColors.eidTrustGreen.DEFAULT;
        private isDirty = false;
        private viewMode: boolean = true;

        private get status(): string {
            return this.criteriaGroupActive ? 'Active' : 'Discovery';
        }

        private get isInvalid(): boolean {
            return this.isDirty && !this.criteriaGroupName;
        }

        private get ccgIconSrc(): string {
            return this.ccgType ? getCcgTypeIconSrc(this.ccgType) : '';
        }

        private onCloseButtonClick(): void {
            this.$emit('back');
        }

        private onNameInput(event: InputEvent): void {
            this.isDirty = true;
            this.$emit('input', (event.target as HTMLInputElement).value);
        }

        private async displayInput(): Promise<void> {
            this.viewMode = false;
            await this.$forceUpdate();
            (this.$refs.input as HTMLInputElement).focus();
        }

        private displayText(): void {
            this.viewMode = true;
        }

        private onSave(isActive: boolean): void {
            this.$emit('save', { isActive });
        }
    }
</script>
