<template>
    <Modal additional-class-name="ConfirmUpdateInsuredModal" open @close="close">
        <template v-slot:header>
            Confirm updates of existing insured
        </template>
        <Alert v-if="externalError" type="danger">
            <template v-slot:title>
                <FontAwesomeIcon :icon="faExclamationTriangle" />
                <span> {{ externalError }}</span>
            </template>
        </Alert>
        <Alert type="highWarning">
            <template v-slot:title>
                <FontAwesomeIcon :icon="faExclamationTriangle" />
                The insured exists and the record will be updated. Please confirm to proceed.
            </template>
        </Alert>
        <div class="ConfirmUpdateInsuredModal__infoBox">
            <strong>
                <span>The Insured </span>
                <span class="ConfirmUpdateInsuredModal__insuredEmail">{{ insured.contactEmail }}</span>
                <span> already exists and the record will be updated.</span>
            </strong>
            <p>This will result in a recalculation of compliance with your decisioning criteria.</p>
        </div>

        <div class="ConfirmUpdateInsuredModal__actionSection">
            <Button :disabled="saving" @click="close">
                Cancel update
            </Button>
            <Button
                type="primary"
                :loading="saving"
                :disabled="saving"
                @click="confirm"
            >
                Update Insured
            </Button>
        </div>
    </Modal>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { Modal } from '@evidentid/dashboard-commons/components/Modal';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { Alert } from '@evidentid/dashboard-commons/components/Alert';
    import { InsuranceInsuredInput } from '@evidentid/rpweb-api-client/types';

    @Component({
        components: {
            Alert,
            Modal,
            Button,
            FontAwesomeIcon,
        },
    })
    export default class ConfirmUpdateInsuredModal extends Vue {
        @Prop({ type: Boolean, default: false })
        private saving!: boolean;

        @Prop({ type: Object, default: ({}) })
        private insured!: InsuranceInsuredInput;

        @Prop({ type: String, default: '' })
        private externalError!: string;

        private faExclamationTriangle = faExclamationTriangle;

        private close(): void {
            this.$emit('close');
        }

        private confirm(): void {
            this.$emit('updateInsured', this.insured);
        }
    }
</script>
