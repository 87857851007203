import {
    BusinessDocumentDetails,
    CoiDetails,
    RprDocumentAttribute,
    RprDocumentDetails,
    RprDocumentValue,
} from '@/modules/insured-certificates/types';

function isCoiDoc(document: RprDocumentDetails): document is CoiDetails {
    return 'certificate_of_insurance_document' in document.content;
}

function isBusinessDoc(document: RprDocumentDetails): document is BusinessDocumentDetails {
    return 'document' in document.content;
}

export function hasDocument(rprDocument: RprDocumentDetails): boolean {
    let doc: RprDocumentAttribute<RprDocumentValue> | null;
    if (isCoiDoc(rprDocument)) {
        doc = rprDocument.content.certificate_of_insurance_document;
    } else if (isBusinessDoc(rprDocument)) {
        doc = rprDocument.content.document;
    } else {
        doc = null;
    }
    return Boolean(doc && doc.value && doc.value.content?.length > 0);
}
