import { Component, Mixins, Prop } from 'vue-property-decorator';
import JsonForm from '@evidentid/json-schema/interfaces/JsonForm';
import { getEvaluationError } from '@/modules/insured-details/utils/getEvaluationError';
import AbstractJsonSchemaForm
    from '@evidentid/dashboard-commons/components/JsonSchemaForm/elements/AbstractJsonSchemaForm';
import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';

@Component
class AbstractJsonSchemaView<T extends JsonForm = JsonForm, V = any> extends AbstractJsonSchemaForm<T, V> {
    @Prop({ type: Object, default: () => ({}) })
    protected evaluationErrors!: Record<string, FieldEvaluationResultError>;

    @Prop({ type: String, default: 'USD' })
    protected currency!: string;

    protected get evaluationError(): FieldEvaluationResultError | null {
        return getEvaluationError(this.id, this.evaluationErrors, true);
    }

    private get displayedValueAdditionalClass(): string | null {
        if (!this.evaluationError || this.evaluationError.isMultiFieldCriterion) {
            return null;
        }
        return this.evaluationError.complianceStatusMetadata.length > 0
            ? 'JsonSchemaView--exception'
            : 'JsonSchemaView--invalidValue';
    }
}

export default Mixins(AbstractJsonSchemaView) as typeof AbstractJsonSchemaView;
