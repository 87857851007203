<template>
    <CoiDetailsModal
        v-if="opened"
        :email="email"
        :certificate="opened"
        :coverage-models="coverageModels"
        @close="close"
    />
    <InsuredsCoisModal
        v-else
        :status="status"
        :email="email"
        :certificates="content"
        :coverage-models="coverageModels"
        @close="close"
        @open="open"
    />
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { InsuredDocumentsState } from '@/modules/insured-certificates/vuex';
    import { RprDocumentDetails } from '@/modules/insured-certificates/types';
    import InsuredsCoisModal from '@/modules/insured-certificates/components/InsuredsCoisModal.vue';
    import CoiDetailsModal from '@/modules/insured-certificates/components/CoiDetailsModal.vue';
    import { InsuranceCoverageModel } from '@evidentid/rpweb-api-client/types';

    @Component({
        components: {
            CoiDetailsModal,
            InsuredsCoisModal,
        },
    })
    export default class InsuredCoisListProcedure extends Vue {
        @Prop({ type: String })
        private rpName!: string;

        @Prop({ type: String })
        private email!: string;

        @Prop({ type: Array })
        private coverageModels!: InsuranceCoverageModel[];

        private opened: RprDocumentDetails | null = null;

        private get state(): InsuredDocumentsState {
            return this.$store.state.insuredCertificates;
        }

        private get content(): RprDocumentDetails[] {
            const rpCois = this.state.rprDocuments[this.rpName] || {};
            const emailCois = rpCois[this.email] || {};
            return emailCois.content || [];
        }

        private get status(): OperationStatus {
            const rpCois = this.state.rprDocuments[this.rpName] || {};
            const emailCois = rpCois[this.email] || {};
            return emailCois.status || OperationStatus.uninitialized;
        }

        @Watch('status', { immediate: true })
        private onLoadingStatusChange(status: OperationStatus): void {
            if (status === OperationStatus.uninitialized) {
                this.$store.actions.insuredCertificates.loadRprDocumentDetails({
                    rpName: this.rpName,
                    email: this.email,
                });
            }
        }

        private destroyed() {
            // TODO(PRODUCT-17636): Handle race condition: closing modal during loading will cause data being in memory
            this.$store.actions.insuredCertificates.clearRprDocumentDetails({
                rpName: this.rpName,
                email: this.email,
            });
        }

        private open(coi: RprDocumentDetails): void {
            this.opened = coi;
        }

        private close(): void {
            if (this.opened) {
                this.opened = null;
            } else {
                this.$emit('abort');
            }
        }
    }
</script>
