<template>
    <div class="CoiList">
        <a
            v-for="(coi, index) in certificates"
            :key="index"
            class="CoiList__element"
            href="javascript:void(0)"
            @click="open(coi)"
        >
            <strong class="CoiList__name">{{ getTitle(coi) }}</strong>
            <span class="CoiList__more">See details</span>
        </a>
    </div>
</template>

<style lang="scss">
    .CoiList {
        display: flex;
        flex-direction: column;
        font-size: 0.95em;

        &__element {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid #ddd;
            padding: 0.8em 1em;
            color: #23313a;
            text-decoration: none;

            &:last-child {
                border-bottom: 1px solid #ddd;
            }

            &:hover {
                background: rgba(#000, 0.03);
            }
        }

        &__name {
            font-weight: 500;
        }

        &__more {
            color: #2ab496;
            font-size: 0.9em;
            white-space: nowrap;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { CoiDetails } from '@/modules/insured-certificates/types';
    import { getCoverageTypeLabelByAttribute } from '@/modules/insured-certificates/services/attributeUtilities';
    import { InsuranceCoverageModel } from '@evidentid/rpweb-api-client/types';

    @Component
    export default class CoiList extends Vue {
        @Prop({ type: Array })
        private certificates!: CoiDetails[];

        @Prop({ type: Array })
        private coverageModels!: InsuranceCoverageModel[];

        private open(coi: CoiDetails): void {
            this.$emit('open', coi);
        }

        private getTitle(coi: CoiDetails): string {
            const coverageTypeLabel = getCoverageTypeLabelByAttribute(coi.id, false, this.coverageModels);
            return coi.category || coverageTypeLabel || coi.id;
        }
    }
</script>
