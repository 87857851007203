import JsonSchema from '@evidentid/json-schema/interfaces/JsonSchema';
import { InsuranceInsuredField } from '@evidentid/rpweb-api-client/types';
import { standardizeInsuredFieldSchema } from './standardizeInsuredFieldSchema';
import { removeNonStandardSchemaFormat } from './removeNonStandardSchemaFormat';

// TODO: rename it to standardizeCriterionSchema when we fully migrate to usage of this function
export function eidStandardizeCriterionSchema(
    schema: JsonSchema,
    insuredFields?: InsuranceInsuredField[],
): JsonSchema {
    return removeNonStandardSchemaFormat(
        standardizeInsuredFieldSchema(schema, insuredFields),
    );
}
