<template>
    <div class="GroupAssignment">
        <div class="GroupAssignment__title">
            <strong>Assignment</strong>
            <div
                v-if="showError && showEmptyError"
                class="GroupAssignment__error"
            >
                <FontAwesomeIcon :icon="faExclamationTriangle" />
                <span>
                    At least one rule needs to be added
                </span>
            </div>
        </div>
        <div class="GroupAssignment__body">
            <div class="GroupAssignment__radioOptions">
                <span class="GroupAssignment__radioOptionsPrefix">
                    Assign
                </span>
                <RadioOption
                    class="GroupAssignment__radioOption"
                    name="GroupAssignment"
                    :checked="assignmentOption === allInsureds"
                    @click="$emit('setAssignmentRuleOption', allInsureds)"
                >
                    All Insureds
                </RadioOption>
                <RadioOption
                    class="GroupAssignment__radioOption"
                    name="GroupAssignment"
                    :checked="assignmentOption === subsetOfInsureds"
                    @click="$emit('setAssignmentRuleOption', subsetOfInsureds)"
                >
                    Subset of Insureds
                </RadioOption>
            </div>
            <AssignmentRuleView
                v-if="assignmentOption === subsetOfInsureds"
                :insured-fields="insuredFields"
                :basic-rules="rules"
                @openRuleEditor="$emit('openRuleEditor')"
                @remove="$emit('remove')"
            />
            <Button
                v-if="assignmentOption === subsetOfInsureds && rules.length === 0"
                class="GroupAssignment__addButton"
                @click="$emit('openRuleEditor')"
            >
                + Add Assignment Rule
            </Button>
            <span
                v-else-if="assignmentOption === allInsureds"
                class="GroupAssignment__message"
            >
                All Insureds will be auto-assigned to this group.
            </span>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { RadioOption } from '@evidentid/dashboard-commons/components/RadioOption';
    import { InsuranceInsuredField } from '@evidentid/rpweb-api-client/types';
    import AssignmentRuleView
        from '@/modules/decisioning-criteria/components/AssignmentRuleView/AssignmentRuleView.vue';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { BasicAssignmentRule, CoverageGroupAssignmentOptions } from '@/modules/decisioning-criteria/types';
    import { convertToBasicAssignmentRules } from '@/modules/decisioning-criteria/utils/parseRulesLogic';

    @Component({
        components: { AssignmentRuleView, Button, FontAwesomeIcon, RadioOption },
    })
    export default class GroupAssignment extends Vue {
        @Prop({ type: Object, default: null })
        private coverageGroupAssignment!: any;

        @Prop({ type: String, default: CoverageGroupAssignmentOptions.allInsureds })
        private assignmentOption!: string;

        @Prop({ type: Boolean, default: false })
        private showError!: boolean;

        @Prop({ type: Array, default: () => [] })
        private insuredFields!: InsuranceInsuredField[];
        private faExclamationTriangle = faExclamationTriangle;

        private get rules(): BasicAssignmentRule[][] {
            return this.coverageGroupAssignment
                ? convertToBasicAssignmentRules(this.coverageGroupAssignment)
                : [];
        }

        private get showEmptyError(): boolean {
            return this.assignmentOption === CoverageGroupAssignmentOptions.subsetOfInsureds && this.rules.length === 0;
        }

        private allInsureds: string = CoverageGroupAssignmentOptions.allInsureds;
        private subsetOfInsureds: string = CoverageGroupAssignmentOptions.subsetOfInsureds;
    }
</script>
