<template>
    <Modal
        v-if="corrections.length === 0 || files.length === 0 || alertConfig"
        additional-class-name="BulkImportInsuredsModal"
        form
        open
        :allow-close="!saving"
        @submit.prevent="submit"
        @close="close"
    >
        <template #header>
            Import insureds list
        </template>

        <BulkImportForm
            :disabled="saving || processing"
            :value="files"
            :insured-fields="sortedInsuredFields"
            :coverage-types="coverageTypes"
            :alert-config="alertConfig"
            :build-description="buildFileDescription"
            @input="processFile"
        />

        <div class="BulkImportInsuredsModal__actionSection">
            <Button
                class="BulkImportInsuredsModal__submitButton"
                :disabled="processing || files.length === 0 || isInvalidFile"
                :loading="saving"
                :progress="savingPercentage"
                @click="submit"
            >
                Import
            </Button>
        </div>
    </Modal>
    <Modal
        v-else
        form
        open
        additional-class-name="BulkImportInsuredsCorrectionModal"
        :allow-close="!saving"
        @submit.prevent="submit"
        @close="close"
    >
        <template #header>
            <span> Import results </span>
            <div class="BulkImportInsuredsCorrectionModal__buttonsGroup">
                <Button
                    class="BulkImportInsuredsCorrectionModal__csvButton"
                    :disabled="!valid || saving"
                    @click="exportCsv"
                >
                    <FontAwesomeIcon :icon="faFileExcel" />
                    Download updated CSV worksheet
                </Button>
                <Button
                    class="BulkImportInsuredsCorrectionModal__importButton"
                    :loading="saving"
                    :progress="savingPercentage"
                    :disabled="!valid"
                    type="primary"
                    submit
                >
                    Save and Import
                </Button>
            </div>
        </template>

        <div>
            <BulkImportCorrectionTable
                v-model="corrections"
                :form="form"
                :additional-alert-config="correctionTableSuccessAlertConfig"
                :csv-column-keys="csvColumnKeys"
            />
        </div>
    </Modal>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import moment from 'moment';
    import trim from 'lodash/trim';
    import uniq from 'lodash/uniq';
    import partition from 'lodash/partition';
    import fromPairs from 'lodash/fromPairs';
    import { zipObject } from 'lodash';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { buildCsv, readCsv } from '@evidentid/universal-framework/csv';
    import { InsuranceInsuredField, InsuranceInsuredInput } from '@evidentid/rpweb-api-client/types';
    import createForm from '@evidentid/json-schema/createForm';
    import JsonForm, {
        JsonFormArray,
        JsonFormObject,
        JsonFormProperty,
        JsonFormType,
    } from '@evidentid/json-schema/interfaces/JsonForm';
    import { JsonSchemaObject } from '@evidentid/json-schema/interfaces/JsonSchema';
    import LoadingModal from '@/components/LoadingModal.vue';
    import { Modal } from '@evidentid/dashboard-commons/components/Modal';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { Alert } from '@evidentid/dashboard-commons/components/Alert';
    import { AlertConfig, AlertType } from '@evidentid/dashboard-commons/components/Alert/types';
    import {
        buildBulkInsuredImportJsonSchema,
    } from '@/modules/insured-management/utils/buildInsuredJsonSchema';
    import { BaseInsuredInput } from '@/modules/insured-management/types';
    import BulkImportForm from '../BulkImportForm/BulkImportForm.vue';
    import BulkImportCorrectionTable from '../BulkImportCorrectionTable/BulkImportCorrectionTable.vue';
    import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
    import { downloadFileUsingBinString } from '@evidentid/file-utils/blobs';
    import { buildInsuredsCsv } from '@/modules/insured-management/utils/buildInsuredCsv';
    import { isSampleInsured } from '@/utils/csv-sample/sample-values';
    import {
        filterInsuredEmptyItems,
        hasPastExpirationDate,
        trimInsured,
    } from '@/modules/insured-management/utils/insuredProcessing';
    import {
        getFlattenedInsuredFieldNames,
    } from '@/modules/insured-management/utils/get-flattened-insured-field-names/getFlattenedInsuredFieldNames';
    import {
        getFlattenedInsuredFieldKeys,
    } from '@/modules/insured-management/utils/get-flattened-insured-field-keys/getFlattenedInsuredFieldKeys';
    import {
        getCsvValuesWithCombinedObjects,
    } from '@/modules/insured-management/utils/get-csv-values-with-combined-objects/getCsvValuesWithCombinedObjects';
    import {
        getCsvColumnKeysWithCombinedObjects,
    } from '@/modules/insured-management/utils/get-csv-column-keys-with-combined-objects/getCsvColumnKeysWithCombinedObjects';
    import { isObject } from '@evidentid/json-schema/schemaChecks';
    import { removeNonStandardSchemaFormat } from '@/modules/decisioning-criteria/utils/removeNonStandardSchemaFormat';
    import orderBy from 'lodash/orderBy';

    const booleanValues: Record<string, boolean> = { yes: true, no: false };

    function buildValue(
        _value: string | undefined, form: JsonForm, isInsuredFieldProp?: boolean,
    ): string | object | boolean | string[] | object[] {
        const value = trim(_value || '');
        const type = form.type;
        if (type === JsonFormType.boolean && value.toLowerCase() in booleanValues) {
            return booleanValues[value.toLowerCase()];
        } else if (isInsuredFieldProp && type === JsonFormType.object && _value) {
            return _value;
        } else if (type === JsonFormType.array) {
            if (!value.length || value.length === 0) {
                return [];
            }
            return (form as JsonFormArray).item.type === JsonFormType.object
                ? value.split('|').map((x) => JSON.parse(x))
                : value.split('|').map((x) => trim(x));
        }
        return value;
    }

    function parseDateValue(_value: string | undefined): string | null {
        const momentDate = moment(trim(_value || ''));
        return momentDate.isValid() ? momentDate.format('YYYY-MM-DD') : null;
    }

    function isInsuredFieldEmpty(insuredFieldValue: any) {
        return typeof insuredFieldValue === 'object' && Object.values(insuredFieldValue).every((value) => value === '');
    }

    function nullifyEmptyObjectFields(insuredFields: Record<string, string | boolean | object | object[] | string[]>) {
        return Object.entries(insuredFields)
            .reduce((acc, [ key, insuredFieldValue ]) => {
                acc[key] = isInsuredFieldEmpty(insuredFieldValue) ? null : insuredFieldValue;
                return acc;
            }, {} as Record<string, any>);
    }

    function buildInsuredInput(input: any, form: JsonFormObject): BaseInsuredInput {
        const base = form.getProperties();
        const insuredFieldProperty = base.find((x) => x.name === 'insuredFields');
        const insuredFieldPropList = insuredFieldProperty?.form?.type === JsonFormType.object
            ? insuredFieldProperty.form.getProperties()
            : [];
        const exceptionsProperty = base.find((x) => x.name === 'exceptions');
        const exceptionProperties =
            exceptionsProperty?.form?.type === JsonFormType.object ? exceptionsProperty.form.getProperties() : [];
        const baseValues = fromPairs(base.map((x) => [ x.name, buildValue(input[x.name], x.form) ]));
        const insuredFields =
            fromPairs(insuredFieldPropList.map((x) => [ x.name, buildValue(input[x.name], x.form, true) ]));
        const exceptions =
            fromPairs(exceptionProperties.map((x) => [ x.name, parseDateValue(input[x.name]) || input[x.name] ]));
        const transformedInsuredFields = nullifyEmptyObjectFields(insuredFields);
        return form.getValue({ ...baseValues, insuredFields: transformedInsuredFields, exceptions }, true);
    }

    @Component({
        components: {
            Alert,
            Modal,
            Button,
            FontAwesomeIcon,
            BulkImportForm,
            BulkImportCorrectionTable,
            LoadingModal,
        },
    })
    export default class BulkImportInsuredsModal extends Vue {
        @Prop({ type: Array, default: () => [] })
        private insuredFields!: InsuranceInsuredField[];

        @Prop({ type: Array, default: () => [] })
        private coverageTypes!: string[];

        @Prop({ type: Object, default: null })
        private externalAlertConfig!: { type: AlertType, title: string } | null;

        @Prop({ type: Boolean, default: false })
        private saving!: boolean;

        @Prop({ type: Number, default: null })
        private savingPercentage!: number | null;

        private files: File[] = [];
        private jsonSchema: JsonSchemaObject = null as any;
        private form: JsonFormObject = null as any;
        private fileDescription: { description?: string, error?: boolean } = {};
        private localAlertConfig: AlertConfig | null = null;
        private correctionTableSuccessAlertConfig: { type: AlertType, title: string } | null = null;
        private processing: boolean = false;
        private insureds: InsuranceInsuredInput[] = [];
        private corrections: BaseInsuredInput[] = [];
        private sampleInsureds: InsuranceInsuredInput[] = [];
        private faFileExcel = faFileExcel;
        private csvHeaders: string[] = [];
        private csvColumnKeys: string[] = [];

        @Watch('value')
        private onInternalValueChange(): void {
            this.$emit('input', this.value);
        }

        private get value(): InsuranceInsuredInput[] {
            return this.getValue(true);
        }

        private get isInvalidFile(): boolean {
            return Boolean(this.fileDescription && this.fileDescription.error);
        }

        private isValid(value: BaseInsuredInput): boolean {
            return this.form.isValid(filterInsuredEmptyItems(value, this.sortedInsuredFields), true);
        }

        private get standardProperties(): JsonFormProperty[] {
            return this.form.getProperties().filter((x) => x.name !== 'insuredFields' && x.name !== 'exceptions');
        }

        private get insuredColumnsKeys(): string[] {
            return [
                ...this.standardProperties.map((field) => field.name),
                ...getFlattenedInsuredFieldKeys(this.sortedInsuredFields),
                ...this.coverageTypes,
            ];
        }

        private get insuredColumnsLabel(): string[] {
            return [
                ...this.standardProperties.map((field) => field.form.schema.title || ''),
                ...getFlattenedInsuredFieldNames(this.sortedInsuredFields),
                ...this.coverageColumnLabels,
            ];
        }

        private get insuredColumnsLabelKeyMap(): Record<string, string> {
            return this.insuredColumnsLabel.reduce((acc, label, index) => {
                acc[label] = this.insuredColumnsKeys[index];
                return acc;
            }, {} as Record<string, string>);
        }

        private get coverageColumnLabels(): string[] {
            return this.coverageTypes.map((coverage) => `Existing ${coverage} Policy Expiration Date`);
        }

        private get valid(): boolean {
            return this.corrections.every((value) => this.isValid(value));
        }

        private get alertConfig(): AlertConfig | null {
            return this.files.length === 0 ? null : this.localAlertConfig || this.externalAlertConfig;
        }

        private get sortedInsuredFields(): InsuranceInsuredField[] {
            return orderBy(
                this.insuredFields,
                [ 'required', (insured) => insured.name.toLowerCase() ],
                [ 'desc', 'asc' ],
            );
        }

        private getValue(ignoreEmptyOptional: boolean): InsuranceInsuredInput[] {
            return [ ...this.insureds, ...this.corrections as InsuranceInsuredInput[] ]
                .map((insured) => this.form.getValue(insured, ignoreEmptyOptional));
        }

        @Watch('insuredFields', { immediate: true })
        @Watch('coverageTypes')
        private onInsuredFieldsUpdate(): void {
            this.jsonSchema = buildBulkInsuredImportJsonSchema(
                this.sortedInsuredFields,
                this.coverageTypes,
            );
            this.form = createForm(removeNonStandardSchemaFormat(this.jsonSchema) as JsonSchemaObject);
        }

        private close(): void {
            this.$emit('close');
        }

        private async processFile([ file ]: File[]): Promise<void> {
            this.files = file ? [ file ] : [];
            this.processing = true;
            this.fileDescription = {};
            this.localAlertConfig = null;
            if (file) {
                try {
                    const { rows, csvHeaders, csvColumnKeys } = await this.readInsuredInput(file);
                    this.csvHeaders = csvHeaders;
                    this.csvColumnKeys = csvColumnKeys;

                    const [ sampleInsureds, insureds ] = partition(
                        rows,
                        (x) => isSampleInsured(x),
                    ) as ([ InsuranceInsuredInput[], BaseInsuredInput[] ]);
                    this.sampleInsureds = sampleInsureds;
                    [ this.insureds, this.corrections ] =
                        partition(insureds, (x) => this.isValid(x)) as [ InsuranceInsuredInput[], BaseInsuredInput[] ];
                    this.fileDescription = {
                        description: `${rows.length} insureds found`,
                        error: false,
                    };
                    const insuredText =
                        this.insureds.length > 1 ? 'Insureds successfully validated' : 'Insured successfully validated';
                    this.correctionTableSuccessAlertConfig = this.insureds.length > 0
                        ? { type: 'success', title: `${this.insureds.length} ${insuredText}` }
                        : null;
                } catch (error) {
                    this.localAlertConfig = {
                        title: 'Unable to import! Please fix the errors and upload the CSV file',
                        type: 'danger',
                    };
                    this.fileDescription = { description: (error as Error).message, error: true };
                }
            }
            this.processing = false;
        }

        private submit(): void {
            if (this.valid) {
                const trimAndFilter = (insured: InsuranceInsuredInput) => this.form.getValue(
                    filterInsuredEmptyItems(trimInsured(insured), this.sortedInsuredFields),
                    true);
                const hasPastDate = this.value.some(hasPastExpirationDate);
                this.$emit('submit', this.value.map(trimAndFilter), this.sampleInsureds, hasPastDate);
            }
        }

        private exportCsv(): void {
            if (this.valid) {
                const csv: string[][] = buildInsuredsCsv(
                    this.csvHeaders,
                    this.csvColumnKeys,
                    this.getValue(false),
                    this.sortedInsuredFields,
                );
                downloadFileUsingBinString(buildCsv(csv), this.files[0].name.replace(/\.csv$/, '_updated.csv'));
            }
        }

        private buildFileDescription(): { description?: string, error?: boolean } {
            return this.fileDescription;
        }

        private async readInsuredInput(csv: File): Promise<{
            csvHeaders: string[];
            rows: BaseInsuredInput[];
            csvColumnKeys: string[];
        }> {
            // Get raw parsed CSV
            const rows = await readCsv(csv);
            // Extract header from the results list
            const csvHeaders = (rows.shift() || []).slice(0, this.insuredColumnsLabel.length).map(trim);
            const csvColumnKeys = csvHeaders.map((label) => this.insuredColumnsLabelKeyMap[label]);
            // Check if it's empty
            if (rows.length === 0) {
                throw new Error('Provided CSV file is empty');
            }
            if (rows.some((row) => (row.length !== csvHeaders.length))) {
                throw new Error('The file is not a valid CSV and could not be parsed.');
            }
            // Validate header
            if (csvHeaders.some((label) => !this.insuredColumnsLabel.includes(label))) {
                throw new Error('The file has an extra column that is not currently an Insured field.');
            }
            if (
                uniq(csvHeaders).length !== csvHeaders.length ||
                csvHeaders.length !== this.insuredColumnsLabel.length
            ) {
                throw new Error('The file is missing a standard field or Insured field.');
            }
            const objectTypeFields = this.sortedInsuredFields.filter((field) => isObject(field.schema));
            const objectTypeKeys = objectTypeFields.map((field) => field.key);
            const combinedColumnKeys = getCsvColumnKeysWithCombinedObjects(csvColumnKeys, objectTypeKeys);
            const getCombinedRows =
                (row: string[]) => getCsvValuesWithCombinedObjects(csvColumnKeys, row, objectTypeKeys);
            const formattedRows = rows
                .map((row) => row.map(trim))
                .filter((row) => row.some(Boolean))
                .map((row) => zipObject(combinedColumnKeys, getCombinedRows(row)))
                .map((row) => buildInsuredInput(row, this.form));
            return {
                csvHeaders,
                csvColumnKeys,
                rows: formattedRows,
            };
        }
    }
</script>
