<template>
    <Modal
        additional-class-name="SetRemindersModal"
        :allow-close="!submitting"
        :open="open"
        form
        @close="$emit('close')"
        @submit="onSubmit"
    >
        <template #header>
            <SetRemindersModalHeader :type="type" :value="isMasterNotificationOn" @input="setIsMasterNotification" />
        </template>

        <template v-if="!isMasterNotificationOn && !changed">
            <p>
                You have turned off all new member verification request notifications. Your members will not receive
                any emails from Evident related to new verifications being sent.
            </p>
            <p>
                To turn your notifications back on, toggle the notifications button in the top right hand corner of this
                pop-up.
            </p>
        </template>

        <SetRemindersModalSwitchers
            v-else
            :type="type"
            :value-send-initial-notification="controller.value.initEnabled"
            :value-send-reminder-notification="controller.value.remindersEnabled"
            @input-send-initial-notification="setIsSendInitialNotification"
            @input-send-reminder-notification="setIsSendReminderNotification"
        />

        <JsonSchemaCustomForm
            v-if="controller.value.remindersEnabled"
            v-slot="{ fields }"
            v-model="controller.value"
            class="SetRemindersForm"
            :controller="controller"
        >
            <SetRemindersRow v-if="fields.recurrence" label="Select Reminder Cadence:">
                <SetRemindersFormItem align-right>
                    <InputZoneSelector hide label error>
                        <JsonSchemaCustomFormElement :field="fields.recurrence" />
                    </InputZoneSelector>
                </SetRemindersFormItem>
            </SetRemindersRow>
            <SetRemindersRow v-if="fields.daysOfWeek" label="Days of week:">
                <SetRemindersFormItem>
                    <div class="SetRemindersForm__item SetRemindersForm__item--align-right">
                        <div class="SetRemindersForm__itemContent">
                            <DayOfWeekInput
                                v-model="fields.daysOfWeek.value"
                                @input="fields.daysOfWeek.touch()"
                            />
                            <InputZoneSelector error>
                                <JsonSchemaCustomFormElement touched :field="fields.daysOfWeek" />
                            </InputZoneSelector>
                        </div>
                    </div>
                </SetRemindersFormItem>
            </SetRemindersRow>
            <SetRemindersRow v-if="fields.sendsAt" label="Time:">
                <SetRemindersFormItem>
                    <InputZoneSelector hide label>
                        <div class="SetRemindersForm__time">
                            <JsonSchemaCustomFormElement class="mr-5" touched :field="fields.sendsAt" />
                            <JsonSchemaCustomFormElement :field="fields.timezone" />
                        </div>
                    </InputZoneSelector>
                </SetRemindersFormItem>
            </SetRemindersRow>
            <SetRemindersRow v-if="fields.interval" label="Select Interval:">
                <SetRemindersFormItem label="Every">
                    <InputZoneSelector hide label error>
                        <div class="SetRemindersForm__interval">
                            <JsonSchemaCustomFormElement :field="fields.interval.properties.amount" />
                            <JsonSchemaCustomFormElement :field="fields.interval.properties.unit" />
                        </div>
                    </InputZoneSelector>
                    <InputZoneSelector class="SetRemindersForm__input--no-margin" error>
                        <JsonSchemaCustomFormElement touched :field="fields.interval.properties.amount" />
                        <JsonSchemaCustomFormElement touched :field="fields.interval.properties.unit" />
                    </InputZoneSelector>
                </SetRemindersFormItem>
            </SetRemindersRow>
            <SetRemindersRow v-if="fields.limit" label="Maximum # of Reminders Sent:">
                <SetRemindersFormItem>
                    <InputZoneSelector hide label>
                        <JsonSchemaCustomFormElement :field="fields.limit" />
                    </InputZoneSelector>
                </SetRemindersFormItem>
            </SetRemindersRow>

            <template v-if="fields.daysBefore || fields.daysAfter">
                <SetRemindersRow
                    v-if="fields.daysBeforeCount"
                    :label="
                        type === NotificationName.expiringRequestReminder
                            ? 'Days before expiration:'
                            : 'Days Before Basic Notification:'
                    "
                    small-description
                >
                    <SetRemindersFormItem>
                        <JsonSchemaCustomFormElement :field="fields.daysBeforeCount" />

                        <div class="SetRemindersForm__help">
                            Select day(s) within submission link time frame.
                        </div>

                        <DaysInput
                            v-model="fields.daysBefore.value"
                            :disabled="fields.daysBefore.disabled"
                            :maximum="fields.daysBeforeCount.value - 1"
                            backwards
                            @input="fields.daysBefore.touch()"
                        />

                        <InputZoneSelector error>
                            <JsonSchemaCustomFormElement touched :field="fields.daysBefore" />
                        </InputZoneSelector>
                    </SetRemindersFormItem>
                </SetRemindersRow>

                <SetRemindersRow
                    :label="
                        type === NotificationName.expiringRequestReminder
                            ? 'Days after expiration:'
                            : 'Days Since Added:'
                    "

                    small-description
                >
                    <SetRemindersFormItem>
                        <JsonSchemaCustomFormElement
                            :field="daysAfterCount.structure"
                            :disabled="fields.daysAfter.disabled"
                        />

                        <div class="SetRemindersForm__help">
                            Select day(s) within submission link time frame.
                        </div>

                        <DaysInput
                            v-model="fields.daysAfter.value"
                            :disabled="fields.daysAfter.disabled"
                            :maximum="daysAfterCount.value"
                            @input="fields.daysAfter.touch()"
                        />

                        <InputZoneSelector error>
                            <JsonSchemaCustomFormElement touched :field="fields.daysAfter" />
                        </InputZoneSelector>
                    </SetRemindersFormItem>
                </SetRemindersRow>
            </template>
        </JsonSchemaCustomForm>

        <div
            v-if="!isMasterNotificationOn"
            class="bg-eidTrustGreen-10 p-[30px] mb-2 mt-auto"
            data-test-id="turn-off-notification-note"
        >
            <strong>Note:</strong> If you have turned off your notifications for a period of time, any members who may have
            received notifications during that period will not receive retroactive emails once notifications are turned
            back on.
        </div>

        <template #footer>
            <Button
                class="ml-auto"
                type="primary"
                submit
                :loading="submitting"
                :disabled="!valid || !changed || submitting"
            >
                Save
            </Button>
        </template>
    </Modal>
</template>

<style lang="scss">
    $toggle-switch-size: 55px;

    .SetRemindersModal {
        .Modal__body {
            width: 1051px;
        }

        .Modal__headerContent {
            width: 100%;
        }

        .Modal__content {
            display: flex;
            flex-flow: column;
            height: 500px;
            border-bottom: 1px solid #ddd;
        }
    }

    .SetRemindersForm {
        display: flex;
        flex-direction: column;
        margin-left: $toggle-switch-size;
        max-width: 500px;

        &__header {
            display: flex;
            margin: 15px 0;
            border-bottom: 1px solid #d5d5d5;
            border-bottom: 0;
        }

        &__header > &__description {
            width: 150px;
            margin: 0 30px 0 10px;
        }

        &__header > &__description,
        &__header > &__content {
            font-size: 0.9em;
            font-weight: bold;
            color: #233139;
            margin-bottom: 0;
            padding-bottom: 0;
        }

        &__content {
            flex: 1 auto;
        }

        & &__input {
            &--no-margin {
                .FormElement {
                    margin: 0;
                }
            }
        }

        &__interval {
            display: flex;
            align-items: center;
            margin: 0 -5px;
            width: 220px;

            & > * {
                margin: 0 5px;
                flex: 1 0;
            }

            .JsonSchemaNumberForm {
                min-width: 80px;
                max-width: 80px;
            }

            .JsonSchemaEnumForm {
                flex: 1 auto;
            }
        }

        &__time {
            display: flex;
        }

        &__help {
            font-size: 0.9em;
            color: #778ea1;
            margin: 5px 0;
        }

        .FormElement {
            margin: 5px 0;
        }

        .JsonSchemaArrayForm__addButton {
            background: transparent;
            border: 0;
            justify-content: start;
            padding: 0.5em;
        }

        .DaysInput {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            grid-gap: 5px;
            max-width: calc(20em + 50px);
            margin-right: 5px;
            border: 1px solid #d1d8de;
            padding: 10px;
        }

        .DayOfWeekInput + .InputZoneSelector {
            margin-top: 5px;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import isEqual from 'lodash/isEqual';
    import omitBy from 'lodash/omitBy';
    import isNil from 'lodash/isNil';
    import cloneDeep from 'lodash/cloneDeep';
    import merge from 'lodash/merge';
    import max from 'lodash/max';
    import createForm from '@evidentid/json-schema/createForm';
    import { FormController, Pointer } from '@evidentid/json-schema/FormController';
    import { JsonSchemaEnum } from '@evidentid/json-schema/interfaces/JsonSchema';
    import { NotificationName, SchedulePatternType } from '@evidentid/config-api-client/types';
    import {
        JsonSchemaCustomForm,
        JsonSchemaCustomFormElement,
    } from '@evidentid/dashboard-commons/components/JsonSchemaForm';
    import { SwitchToggle } from '@evidentid/dashboard-commons/components/SwitchToggle';
    import { Modal } from '@evidentid/dashboard-commons/components/Modal';
    import { RadioOption } from '@evidentid/dashboard-commons/components/RadioOption';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { DayOfWeekInput } from '@evidentid/dashboard-commons/components/DayOfWeekInput';
    import { buildReminderSchema } from '../schemas/buildReminderSchema';
    import { getFormValue } from '../utils/getFormValue';
    import { NotificationCadence } from '../types';
    import DaysInput from './DaysInput.vue';
    import InfoCircle from './InfoCircle.vue';
    import InputZoneSelector from './InputZoneSelector.vue';
    import SetRemindersFormItem from './SetRemindersFormItem.vue';
    import SetRemindersRow from './SetRemindersRow.vue';
    import FieldRadioOption from './FieldRadioOption.vue';
    import SetRemindersModalHeader from './SetRemindersModalHeader.vue';
    import SetRemindersModalSwitchers from './SetRemindersModalSwitchers.vue';

    const availableDaysSchema: JsonSchemaEnum = {
        description: 'Select number',
        enum: [
            30,
            60,
            90,
        ],
        enumLabels: [
            '30 days',
            '60 days',
            '90 days',
        ],
    };

    @Component({
        components: {
            FieldRadioOption,
            SetRemindersRow,
            SetRemindersFormItem,
            DaysInput,
            Button,
            InputZoneSelector,
            InfoCircle,
            Modal,
            RadioOption,
            DayOfWeekInput,
            JsonSchemaCustomForm,
            JsonSchemaCustomFormElement,
            SwitchToggle,
            SetRemindersModalHeader,
            SetRemindersModalSwitchers,
        },
    })
    export default class SetRemindersModal extends Vue {
        private SchedulePatternType = SchedulePatternType;
        private NotificationName = NotificationName;

        @Prop({ type: Boolean, default: false })
        private open!: boolean;

        @Prop({ type: Boolean, default: false })
        private submitting!: boolean;

        @Prop({ type: Object, default: () => null })
        private initialValue!: Partial<NotificationCadence> | null;

        @Prop({ type: String })
        private type!: NotificationName;

        private daysAfterCount = new FormController(createForm(availableDaysSchema));

        private controller = new FormController(createForm(buildReminderSchema(
            this.allowedSchedulePatternTypes,
            this.allowDaysBefore,
        )));

        private isMasterNotificationOn = false;

        private setToggleValue(name: string, value: boolean): void {
            if (name in this.controller.value && name in this.properties) {
                this.controller.value[name] = value;
                this.properties[name].touch();
            }
        }

        private setIsMasterNotification(value: boolean) {
            this.isMasterNotificationOn = value;
            if (!value) {
                this.setToggleValue('initEnabled', false);
                this.setToggleValue('remindersEnabled', false);
            } else if (!this.controller.value.initEnabled && !this.controller.value.remindersEnabled) {
                this.setToggleValue('initEnabled', true);
                this.setToggleValue('remindersEnabled', true);
            }
        }

        private updateMasterToggle(): void {
            if (!this.controller.value.initEnabled && !this.controller.value.remindersEnabled) {
                this.isMasterNotificationOn = false;
            } else if (this.controller.value.initEnabled || this.controller.value.remindersEnabled) {
                this.isMasterNotificationOn = true;
            }
        }

        private setIsSendInitialNotification(value: boolean) {
            this.setToggleValue('initEnabled', value);
            this.updateMasterToggle();
        }

        private setIsSendReminderNotification(value: boolean) {
            this.setToggleValue('remindersEnabled', value);
            this.updateMasterToggle();
        }

        private get allowDaysBefore(): boolean {
            return this.type === NotificationName.expiringRequestReminder;
        }

        private get allowedSchedulePatternTypes(): SchedulePatternType[] {
            if (this.type === NotificationName.expiringRequestReminder) {
                return [
                    SchedulePatternType.sequence,
                ];
            } else {
                return [
                    SchedulePatternType.schedule,
                    SchedulePatternType.interval,
                    SchedulePatternType.sequence,
                ];
            }
        }

        private get valid(): boolean {
            return this.controller.structure.valid;
        }

        private get changed(): boolean {
            // controllers value doesn't have empty values, so we need to omit them from initial value
            // to compare them correctly
            const initialValueWithoutEmptyValues = omitBy(this.initialValue, isNil);
            return !isEqual(
                getFormValue(this.controller.form, this.controller.value),
                initialValueWithoutEmptyValues,
            );
        }

        private get properties(): Record<string, Pointer> {
            return this.controller.structure.properties!;
        }

        @Watch('initialValue', { immediate: true })
        private onInitialValueChange(): void {
            this.controller.value = this.initialValue;
            this.isMasterNotificationOn =
                Boolean(this.initialValue?.initEnabled) || Boolean(this.initialValue?.remindersEnabled);
        }

        @Watch('controller.value.daysBeforeCount')
        private onDaysBeforeCountChange(days: number | undefined, prevDays: number | undefined): void {
            if (prevDays == null || days == null || !this.controller.value.daysBefore) {
                return;
            }
            const diff = days - prevDays;
            const daysBefore: number[] = this.controller.value.daysBefore;
            this.properties.daysBefore.value = daysBefore
                .map((x) => x + diff)
                .filter((x) => (x >= 1 && x <= days));
        }

        @Watch('controller.value.recurrence', { immediate: true })
        private populateDefaults(): void {
            const defaultRecurrence = (this.properties.recurrence.form.schema as any).enum[0];
            const value = merge(cloneDeep(this.initialValue), this.controller.value);
            this.controller.value = merge(value, {
                recurrence: value.recurrence ?? defaultRecurrence,
                interval: {
                    amount: value.interval?.amount ?? 3,
                    unit: value.interval?.unit ?? 'hours',
                },
                limit: value.limit ?? 10,
                timezone: value.timezone ?? 'America/New_York',
                daysBeforeCount: value.daysBeforeCount ?? 30,
            });
        }

        @Watch('initialValue.daysAfter', { immediate: true })
        private onDaysAfterSetup(): void {
            const availableDaysAfterOptions: number[] = (this.daysAfterCount.form.schema as JsonSchemaEnum).enum;
            const availableDays = max(this.initialValue?.daysAfter || []) || 0;
            this.daysAfterCount.value = availableDaysAfterOptions.find((x) => (x >= availableDays));
        }

        @Watch('daysAfterCount.value', { immediate: true })
        private onDaysAfterCountChange(): void {
            const daysAfterCount: number = this.daysAfterCount.value;
            const daysAfter: number[] = this.controller.value.daysAfter || [];
            const maxDaysAfter = max(daysAfter) || 0;
            if (maxDaysAfter > daysAfterCount) {
                this.properties.daysAfter.value = daysAfter.filter((x) => (x <= daysAfterCount));
            }
        }

        private onSubmit(event: Event): void {
            event.preventDefault();
            if (this.submitting || !this.valid || !this.changed) {
                return;
            }
            this.$emit('submit', getFormValue(this.controller.form, this.controller.value));
        }
    }
</script>
