import { InsuranceEvaluationResult } from '@evidentid/insurance-facing-lib/models/insured-details';
import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
import { InsuranceInsuredCoverageDetails } from '@evidentid/rpweb-api-client/types';
import { CategorizedEnumLabels } from '@/modules/dashboard/models/CategorizedEnumLabels.model';
import { InsuredMultiFieldCriterion } from '@/modules/insured-details/types';
import getIn from 'lodash/get';
import isNil from 'lodash/isNil';
import { getEnumDefaultDisplayValue } from '@/utils/getEnumDefaultDisplayValue';
import { FIELD_NOT_AVAILABLE_MESSAGE } from '@/modules/insured-details/constant';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';

export function getMultiFieldCoverageCriteria(
    evaluationResults: InsuranceEvaluationResult[],
    evaluationErrors: Record<string, FieldEvaluationResultError>,
    coverageDetailsList: InsuranceInsuredCoverageDetails[],
    categorizedEnumLabels: CategorizedEnumLabels
): InsuredMultiFieldCriterion[] {
    const multiFieldCriteria = evaluationResults.reduce((acc, evaluationResult) => {
        if (evaluationResult.usedFields.length > 1) {
            const insuredMultiFieldCriterion: InsuredMultiFieldCriterion = {
                name: evaluationResult.criterionName,
                fields: evaluationResult.usedFields.reduce((fields, field) => {
                    const parts = field.split('/');
                    const coverageName = parts.at(1);
                    const name = parts.at(-1);
                    const coverageDetails = coverageDetailsList
                        .find((coverage) => coverageName === coverage.coverage?.coverageType)
                        ?.coverage?.details;
                    if (name) {
                        const value = getIn(coverageDetails, parts.slice(3), null);
                        fields[name] = !isNil(value) ?
                            getEnumDefaultDisplayValue(name, value, categorizedEnumLabels) :
                            FIELD_NOT_AVAILABLE_MESSAGE;
                    }
                    return fields;
                }, {} as Record<string, unknown>),
                evaluationError: evaluationErrors[evaluationResult.criterionName],
            };
            acc.push(insuredMultiFieldCriterion);
        }

        return acc;
    }, [] as InsuredMultiFieldCriterion[]);
    return uniqWith(multiFieldCriteria, isEqual);
}
