import { InsuranceCoverageType } from '@evidentid/insurance-facing-lib/models/insured-details';

export const nonInsuranceCoverageTypes: InsuranceCoverageType[] = [
    InsuranceCoverageType.w9Form,
    InsuranceCoverageType.holdHarmlessAgreement,
    InsuranceCoverageType.declarationsPage,
    InsuranceCoverageType.scheduleOfFormsAndEndorsements,
    InsuranceCoverageType.proofOfPayment,
    InsuranceCoverageType.workersCompensationWaiver,
];
