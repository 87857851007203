<template>
    <div class="SetRemindersModalSwitchers">
        <p>
            Set up which notifications you would like to send. Customize your reminder cadence to best fit your needs.
        </p>
        <div
            data-test-id="SetRemindersModalSwitchers__switchSendInitialNotification"
            class="SetRemindersModalSwitchers__switch"
        >
            <SwitchToggle hide-label :value="valueSendInitialNotification" @input="setValueSendInitialNotification" />
            <div>
                <label :class="valueSendInitialNotification ? 'text-eidTrustGreen' : 'text-eidSecureBlue-75'">Send Initial Notification</label>
                <p class="text-eidSecureBlue m-0">
                    {{ typeText.initialNotification }}
                </p>
            </div>
        </div>
        <div
            data-test-id="SetRemindersModalSwitchers__switchSendReminderNotification"
            class="SetRemindersModalSwitchers__switch"
        >
            <SwitchToggle hide-label :value="valueSendReminderNotification" @input="setValueSendReminderNotification" />
            <div>
                <label :class="valueSendReminderNotification ? 'text-eidTrustGreen' : 'text-eidSecureBlue-75'">Send Reminder Notifications </label>
                <p class="text-eidSecureBlue m-0">
                    {{ typeText.reminderNotification }}
                </p>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .SetRemindersModalSwitchers {
        &__switch {
            @apply text-eidTechGray;

            display: flex;
            align-items: center;
            margin-bottom: 20px;
            margin-left: auto;
            margin-right: 20px;
            font-size: 16px;
        }

        .SwitchToggle {
            padding-left: 0;
        }

        .SwitchToggle__container {
            width: 40px;
            height: 22px;
        }

        .SwitchToggle__slider:before {
            height: 15px;
            width: 15px;
            left: 3px;
            bottom: 4px;
        }

        .SwitchToggle__slider {
            @apply bg-eidTechGray;
        }

        // We disable it because of errors selector-max-combinators, selector-max-compound-selectors
        // stylelint-disable
        .SwitchToggle input:checked + .SwitchToggle__slider:before {
            transform: translateX(19px);
            // stylelint-enable
        }

        &__switchLabel {
            @apply text-eidTrustGreen;
        }

        &__switchDescription {
            margin: 0;
            @apply text-eidSecureBlue;
        }
    }
</style>

<script lang="ts">
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import { Prop } from '@evidentid/vue-property-decorator';
    import { SwitchToggle } from '@evidentid/dashboard-commons/components/SwitchToggle';
    import { NotificationName } from '@evidentid/config-api-client/types';

    @Component({ components: { SwitchToggle } })
    export default class SetRemindersModalSwitchers extends Vue {
        @Prop({ type: String, required: true })
        private type!: NotificationName;

        @Prop({ type: Boolean, default: false })
        private valueSendInitialNotification!: boolean;

        @Prop({ type: Boolean, default: false })
        private valueSendReminderNotification!: boolean;

        private setValueSendInitialNotification(newValue: Boolean) {
            this.$emit('input-send-initial-notification', newValue);
        }

        private setValueSendReminderNotification(newValue: Boolean) {
            this.$emit('input-send-reminder-notification', newValue);
        }

        private get typeText(): { initialNotification: string, reminderNotification: string } {
            switch (this.type) {
                case NotificationName.newRequestReminder:
                    return {
                        initialNotification: 'The first email is sent automatically when a new member is added to a coverage criteria group.',
                        reminderNotification: 'Customize your reminder cadence and remind your members of pending verifications.',
                    };
                case NotificationName.expiringRequestReminder:
                    return {
                        initialNotification: 'The first email is sent automatically when a member\'s coverage type nears expiration.',
                        reminderNotification: 'Customize your reminder cadence and remind your members of upcoming expirations.',
                    };
                case NotificationName.nonCompliantRequestReminder:
                    return {
                        initialNotification: 'The first email is sent automatically when a member becomes non-compliant.',
                        reminderNotification: 'Customize your reminder cadence and remind your members when they are non-compliant.',
                    };
                default:
                    return {
                        initialNotification: '',
                        reminderNotification: '',
                    };
            }
        }
    }
</script>
