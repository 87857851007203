<template>
    <div class="CoverageGroupCriteriaTable">
        <Table>
            <template #head>
                <HeadCell class="CoverageGroupCriteriaTable__criteriaNameHeader">
                    Coverage Criteria
                </HeadCell>
                <HeadCell class="CoverageGroupCriteriaTable__verifyHeader">
                    Verify
                </HeadCell>
                <HeadCell class="CoverageGroupCriteriaTable__conditionHeader">
                    Condition
                </HeadCell>
                <HeadCell class="CoverageGroupCriteriaTable__requiredValueHeader">
                    Required Value(if applicable)
                </HeadCell>
                <HeadCell
                    v-if="someInsuredFieldSubstitution"
                    class="CoverageGroupCriteriaTable__substituteInsuredField"
                >
                    Set Limit to Insured Field
                </HeadCell>
                <HeadCell class="CoverageGroupCriteriaTable__actionsHeader" />
            </template>

            <CoverageGroupCriteriaTableRow
                v-for="(criterion, index) of criteriaList"
                :key="index"
                :deletable="criteriaList.length > 1"
                :criterion="criterion"
                :template="templatesByFieldName[criterion.field]"
                :some-insured-field-substitution="someInsuredFieldSubstitution"
                :criteria-can-insured-field-substitution="criteriaCanInsuredFieldSubstitution"
                :insured-fields="insuredFields"
                :accessed-reference-map="accessedReferenceMap"
                :is-reference-starts-with-is-invalid="isReferenceStartsWithIsInvalid"
                show-error
                @delete="onRowDelete(index)"
                @input="onRowInput($event, index)"
                @openSettings="$emit('openSettings', $event)"
                @reference-accessed="$emit('reference-accessed', $event)"
                @validityChange="$emit('validityChange', $event)"
            />
        </Table>
    </div>
</template>

<script lang="ts">
    import { PropType } from 'vue';
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import {
        InsuranceCoverageCriterion,
        InsuranceCoverageCriterionInput,
        InsuranceCoverageCriterionTemplate,
        InsuranceInsuredField,
        InsuranceSchemaDisplayFormat,
    } from '@evidentid/rpweb-api-client/types';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { HeadCell, Table } from '@evidentid/dashboard-commons/components/Table';
    import CoverageGroupCriteriaTableRow
        from '@/modules/decisioning-criteria/components/CoverageGroupCriteriaTableRow/CoverageGroupCriteriaTableRow.vue';
    import {
        IsReferenceStartsWithIsInvalid,
    } from '@/modules/decisioning-criteria/components/CreateEditCoverageCriteriaGroup/types';
    import { filterInsuredFieldsByValueType } from '@/modules/decisioning-criteria/utils/filterInsuredFieldsByValueType';
    import {
        getFlagDisabledReferenceValueType,
    } from '@/modules/decisioning-criteria/utils/getFlagDisabledReferenceValueType';
    import { CriteriaCanInsuredFieldSubstitution } from './types';

    @Component({
        components: {
            Button, HeadCell, Table, CoverageGroupCriteriaTableRow,
        },
    })
    export default class CoverageGroupCriteriaTable extends Vue {
        @Prop({ type: Array, default: () => [] })
        private criteriaList!: (InsuranceCoverageCriterionInput | InsuranceCoverageCriterion)[];

        @Prop({ type: Object, default: () => ({}) })
        private templatesByFieldName!: Record<string, InsuranceCoverageCriterionTemplate>;

        @Prop({ type: Array, default: () => [] })
        private insuredFields!: InsuranceInsuredField[];

        @Prop({ type: Object, default: () => ({}) })
        private accessedReferenceMap!: Record<string, boolean>;

        @Prop({ type: Function as PropType<IsReferenceStartsWithIsInvalid>, required: true })
        private isReferenceStartsWithIsInvalid!: IsReferenceStartsWithIsInvalid;

        private get criteriaCanInsuredFieldSubstitution(): CriteriaCanInsuredFieldSubstitution {
            return this.criteriaList.reduce((result, criterion) => {
                if (this.canCriterionInsuredFieldSubstitution(criterion)) {
                    result[criterion.field] = true;
                }

                return result;
            }, {} as CriteriaCanInsuredFieldSubstitution);
        }

        private get someInsuredFieldSubstitution(): boolean {
            return Object.keys(this.criteriaCanInsuredFieldSubstitution).length > 0;
        }

        private canCriterionInsuredFieldSubstitution(
            criterion: InsuranceCoverageCriterionInput | InsuranceCoverageCriterion,
        ): boolean {
            const template = this.templatesByFieldName[criterion.field];

            if (!template) {
                return false;
            }

            const conditionalRefs = template.displayMetadata.conditionalValueReference;

            if (!conditionalRefs) {
                return false;
            }

            const schema = criterion.evaluator.references[conditionalRefs.flagEnabledReference]?.schema;

            if (schema?.displayFormat !== InsuranceSchemaDisplayFormat.insuredField) {
                return false;
            }

            const type = getFlagDisabledReferenceValueType({
                template,
                criterion,
            });

            return filterInsuredFieldsByValueType(
                this.insuredFields,
                type,
            ).length > 0;
        }

        private onRowDelete(index: number): void {
            this.$emit('input', [
                ...this.criteriaList.slice(0, index),
                ...this.criteriaList.slice(index + 1),
            ]);
        }

        private onRowInput(
            criterion: InsuranceCoverageCriterion,
            index: number,
        ): void {
            this.$emit('input', [
                ...this.criteriaList.slice(0, index),
                criterion,
                ...this.criteriaList.slice(index + 1),
            ]);
        }
    }
</script>
