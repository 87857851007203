<template>
    <div class="BulkImportCorrectionTable">
        <Alert v-if="additionalAlertConfig" :type="additionalAlertConfig.type" :title="additionalAlertConfig.title" />
        <Alert type="danger">
            <template #title>
                <FontAwesomeIcon :icon="faExclamationTriangle" />
                {{ value.length }} insureds could not be validated. Please fix the errors below.
            </template>
        </Alert>
        <Table>
            <template #head>
                <HeadCell v-for="property in flatProperties" :key="property.form.key">
                    <div class="BulkImportCorrectionTable__header">
                        {{ property.form.schema.title }} <span v-if="property.required">(Required)</span>
                    </div>
                    <div class="BulkImportCorrectionTable__headerHint">
                        {{ getHeaderSubText(property.form) }}
                    </div>
                </HeadCell>
            </template>
            <BulkImportCorrectionTableRow
                v-for="(item, index) in pageValue"
                :key="index"
                :form="form"
                :properties="flatProperties"
                :list-of-insured-fields-prop-names="listOfInsuredFieldsPropNames"
                :list-of-exception-prop-names="listOfExceptionPropNames"
                :value="item"
                :index="index"
                @input="valueChange($event, index)"
            />
        </Table>
        <Pagination v-if="value.length > perPage" v-model="paginatedValue" :items="value" :per-page="perPage" />
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
    import {
        JsonForm,
        JsonFormObject,
        JsonFormProperty,
        JsonFormType,
    } from '@evidentid/json-schema/interfaces/JsonForm';
    import { HeadCell, Table } from '@evidentid/dashboard-commons/components/Table';
    import { Alert } from '@evidentid/dashboard-commons/components/Alert';
    import { BaseInsuredInput } from '@/modules/insured-management/types';
    import BulkImportCorrectionTableRow from '../BulkImportCorrectionTableRow/BulkImportCorrectionTableRow.vue';
    import { AlertType } from '@evidentid/dashboard-commons/components/Alert/types';
    import { Pagination } from '@evidentid/dashboard-commons/components/Pagination';
    import orderBy from 'lodash/orderBy';

    const headerHintTexts: Record<string, string> = {
        string: 'Must contain letters',
        email: 'Format as hi@email.com',
        date: 'MM/DD/YYYY',
        phone: 'Format as (201) 555-0123',
        integer: 'Must contain whole numbers',
        number: 'Must contain numbers',
        boolean: 'Must contain Yes/No',
    };

    export default Vue.extend({
        name: 'BulkImportCorrectionTable',
        components: {
            Alert,
            Table,
            HeadCell,
            FontAwesomeIcon,
            BulkImportCorrectionTableRow,
            Pagination,
        },
        props: {
            form: {
                type: Object as PropType<JsonFormObject>,
                required: true,
            },
            additionalAlertConfig: {
                type: Object as PropType<{ type: AlertType, title: string } | null>,
                default: null,
            },
            value: {
                type: Array as PropType<BaseInsuredInput[]>,
                default: () => [] as BaseInsuredInput[],
            },
            csvColumnKeys: {
                type: Array as PropType<string[]>,
                required: true,
            },
        },
        data() {
            return {
                faExclamationTriangle,
                paginatedValue: [] as BaseInsuredInput[],
                perPage: 10,
                nonStandardFieldNames: [ 'insuredFields', 'exceptions' ],
                flatProperties: [] as JsonFormProperty[],
                listOfInsuredFieldsPropNames: [] as string[],
                listOfExceptionPropNames: [] as string[],
            };
        },
        computed: {
            pageValue(): BaseInsuredInput[] {
                return this.value.length > this.perPage ? this.paginatedValue : this.value;
            },
        },
        watch: {
            form: {
                immediate: true,
                handler() {
                    const formProperties = this.form.getProperties();
                    const insuredFieldsForm = formProperties.find((prop) => prop.name === 'insuredFields')?.form;
                    const insuredFieldsProperties = insuredFieldsForm?.type === JsonFormType.object
                        ? insuredFieldsForm.getProperties()
                        : [];
                    this.listOfInsuredFieldsPropNames = insuredFieldsProperties.map((prop) => prop.name);
                    const exceptionForms = formProperties.find((prop) => prop.name === 'exceptions')?.form;
                    const exceptionsProperties = exceptionForms?.type === JsonFormType.object
                        ? exceptionForms.getProperties()
                        : [];
                    this.listOfExceptionPropNames = exceptionsProperties.map((prop) => prop.name);
                    const standardFieldProperties = formProperties
                        .filter((prop) => !this.nonStandardFieldNames.includes(prop.name));
                    const properties = [
                        ...standardFieldProperties,
                        ...insuredFieldsProperties,
                        ...exceptionsProperties,
                    ];
                    this.flatProperties = properties.filter(Boolean);
                },
            },
        },
        methods: {
            valueChange(value: BaseInsuredInput, index: number): void {
                const values = [ ...this.value.slice(0, index), value, ...this.value.slice(index + 1) ];
                this.$emit('input', values);
            },
            getHeaderSubText(form: JsonForm): string {
                const key = form.type === 'string' && form.schema.format ? form.schema.format : form.type;
                return headerHintTexts[key] || '';
            },
        },
    });
</script>
