<template>
    <div
        class="PolicyInfoOtherCellsInfo grid border border-solid border-eidTechGray-25"
        :class="{
            'grid-cols-[20%_15%_15%_15%_35%]': numberOfAccord25Columns === 5,
            'grid-cols-[20%_20%_20%_40%]': numberOfAccord25Columns === 4,
            'grid-cols-[repeat(_auto-fit,_minmax(200px,_1fr))]': numberOfAccord25Columns === 3,
        }"
    >
        <Accord25Cell v-if="typeOfInsurance.length > 0" title="TYPE OF INSURANCE">
            <div
                v-for="attribute in typeOfInsurance"
                :key="attribute.name"
                class="flex flex-col items-start text-eidSecureBlue text-xxs py-1.5 pl-2.5"
                :data-test-id="`PolicyInfoOtherCellsInfo__${attribute.name}TypeOfInsurance`"
            >
                <img
                    v-if="attribute.evaluationError && attribute.evaluationError.isMultiFieldCriterion"
                    class="pr-1.5"
                    :src="foldersIcon"
                >
                <span
                    class="text-left"
                    data-test-id="PolicyInfoOtherCellsInfo__typeOfInsuranceName"
                >
                    {{ formatAttributeName(attribute.name) }}
                </span>
                <span
                    v-for="attributeValueItem in attribute.value"
                    :key="attributeValueItem"
                    data-test-id="PolicyInfoOtherCellsInfo__typeOfInsuranceValue"
                    class="text-eidQualityBlack"
                >
                    {{ attributeValueItem }}
                </span>
                <EvaluationError
                    v-if="attribute.evaluationError && !attribute.evaluationError.isMultiFieldCriterion"
                    class="my-2.5 mx-0"
                    data-test-id="PolicyInfoOtherCellsInfo__typeOfInsuranceError"
                    :evaluation-error="attribute.evaluationError"
                    @goToCriterion="goToCriterion"
                    @grantExceptionToCriterion="grantExceptionToCriterion"
                    @removeExceptions="removeExceptions"
                />
            </div>
        </Accord25Cell>
        <Accord25Cell title="POLICY NUMBER">
            {{ coverage.policy.policyNumber }}
        </Accord25Cell>
        <Accord25Cell title="POLICY EFF">
            {{ coverage.policy.effectiveDate }}
        </Accord25Cell>
        <Accord25Cell title=" POLICY EXP">
            {{ coverage.policy.expirationDate }}
            <EvaluationError
                v-if="expirationDateEvaluationError && !expirationDateEvaluationError.isMultiFieldCriterion"
                data-test-id="PolicyInfoOtherCellsInfo__policyExpError"
                :evaluation-error="expirationDateEvaluationError"
                @goToCriterion="goToCriterion"
                @grantExceptionToCriterion="grantExceptionToCriterion"
                @removeExceptions="removeExceptions"
            />
        </Accord25Cell>
        <PolicyInfoOtherCellsInfoLimit
            v-if="limits.length > 0"
            :limits="limits"
            @go-to-criterion="goToCriterion"
            @grant-exception-to-criterion="grantExceptionToCriterion"
            @remove-exceptions="removeExceptions"
        />
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import startCase from 'lodash/startCase';
    import foldersIcon from '@/modules/insured-details/assets/folders-icon.svg';
    import Accord25Cell from '@/modules/insured-details/components/Accord25Cell/Accord25Cell.vue';
    import EvaluationError from '@/modules/insured-details/components/EvaluationError/EvaluationError.vue';
    import { InsuranceInsuredCoverage } from '@evidentid/rpweb-api-client/types';
    import { InsuranceEvaluationResult } from '@evidentid/insurance-facing-lib/models/insured-details';
    import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
    import { FIELD_NOT_AVAILABLE_MESSAGE } from '@/modules/insured-details/constant';
    import { getEnumDefaultDisplayValue } from '@/utils/getEnumDefaultDisplayValue';
    import { typeOfInsuranceAttributes } from './utils';
    import PolicyInfoOtherCellsInfoLimit from './PolicyInfoOtherCellsInfoLimit/PolicyInfoOtherCellsInfoLimit.vue';
    import { CategorizedEnumLabels } from '@/modules/dashboard/models/CategorizedEnumLabels.model';
    import { shouldBeOmitted } from '../PolicyInfo/utils';

    type Limits = {
        name: string;
        value: number | string | unknown;
        evaluationError: FieldEvaluationResultError | null;
    }[];

    export default Vue.extend({
        name: 'PolicyInfoOtherCellsInfo',
        components: {
            Accord25Cell,
            EvaluationError,
            PolicyInfoOtherCellsInfoLimit,
        },
        props: {
            coverage: {
                type: Object as PropType<InsuranceInsuredCoverage>,
                required: true,
            },
            evaluationResults: {
                type: Array as PropType<InsuranceEvaluationResult[]>,
                default: () => [] as InsuranceEvaluationResult[],
            },
            evaluationErrors: {
                type: Object as PropType<Record<string, FieldEvaluationResultError>>,
                default: () => ({}) as Record<string, FieldEvaluationResultError>,
            },
            limits: {
                type: Array as PropType<Limits>,
                default: () => [] as Limits,
            },
            getEvaluationResult: {
                type: Function as PropType<(url: string) => FieldEvaluationResultError | null>,
                required: true,
            },
        },
        data() {
            return {
                typeOfInsuranceAttributes,
                foldersIcon,
            };
        },
        computed: {
            // TODO(PRODUCT-18675): remove any after the migration to the newer version.
            categorizedEnumLabels(): CategorizedEnumLabels {
                return (this as any).$store.state.dashboard.categorizedEnumLabels;
            },
            typeOfInsurance(): {
                name: string; value: any;
                evaluationError: FieldEvaluationResultError | null;
            }[] {
                if (!this.coverage) {
                    return [];
                }

                return Object.entries(this.coverage.details)
                    .filter(([ key, value ]) => (
                        typeOfInsuranceAttributes.includes(key)
                        && !shouldBeOmitted(key, value, this.evaluationResults)
                    ))
                    .map(([ key, value ]) => {
                        const _value = value === null
                            ? FIELD_NOT_AVAILABLE_MESSAGE
                            : getEnumDefaultDisplayValue(key, value, this.categorizedEnumLabels);
                        return {
                            name: key,
                            value: Array.isArray(_value) ? _value : [ _value ],
                            evaluationError: this.evaluationErrors[`#/${this.coverage.coverageType}/details/${key}`] || null,
                        };
                    }) || [];
            },
            numberOfAccord25Columns(): number {
                const insuranceTypesColumns = this.typeOfInsurance.length > 0 ? 1 : 0;
                const limitsColumns = this.limits.length > 0 ? 1 : 0;
                const defaultNumberOfColumns = 3;
                return defaultNumberOfColumns + insuranceTypesColumns + limitsColumns;
            },
            expirationDateEvaluationError(): FieldEvaluationResultError | null {
                return this.getEvaluationResult('policy/expirationDate');
            },
        },
        methods: {
            formatAttributeName(name: string): string {
                return startCase(name);
            },
            goToCriterion(coverageCriteriaGroupId: string): void {
                this.$emit('go-to-criterion', coverageCriteriaGroupId);
            },
            grantExceptionToCriterion(criterionId: string): void {
                this.$emit('grant-exception-to-criterion', criterionId);
            },
            removeExceptions(exceptionIds: string[]): void {
                this.$emit('remove-exceptions', exceptionIds);
            },
        },
    });
</script>
