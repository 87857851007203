<template>
    <div class="FiltersChipListContainer">
        <ChipList ref="FiltersChipList" class="FiltersChipList" :class="chipListClass">
            <Chip
                v-for="filter in allChips"
                :id="filter.id"
                :ref="filter.id"
                :key="filter.id"
                :class="additionalChipClass"
                :title="filter.text"
                :disabled="disabled"
                :allow-close="filter.closeable"
                @mounted="moveShowAllChipIfNeeded"
                @destroyed="moveShowAllChipIfNeeded"
                @closeChip="onCloseChip"
                @click="filter.onClick"
            />
        </ChipList>
    </div>
</template>

<script lang="ts">
    import { PropType } from 'vue';
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import { startCase, mapKeys, noop } from 'lodash';
    import { Tile, TileList } from '@evidentid/dashboard-commons/components/TileList';
    import { InsuredFilters, InsuredStandardFiltersObject } from '@/modules/insured-filtering/types';
    import { Chip, ChipList } from '@evidentid/dashboard-commons/components/ChipList';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { InsuranceInsuredField } from '@evidentid/rpweb-api-client/types';
    import {
        getInsuredCollateralFieldFromQueryKey,
        getInsuredFieldKeyFromQueryKey,
        getInsuredFieldQueryKey,
        getObjInsuredFieldSubKeys,
    } from '@/modules/insured-filtering/utils/insureFilterUtils';
    import ResizeObserver from 'resize-observer-polyfill';
    import {
        InsuredCollateralFieldFilters,
    } from '@/modules/insured-filtering/models/InsuredCollateralFieldFilters.model';
    import { isObject } from '@evidentid/json-schema/schemaChecks';

    interface InsuredFilterChip {
        id: string;
        text: string;
        closeable: boolean;
        onClick: () => void;
    }

    type CollateralFieldFilterKeys = keyof InsuredCollateralFieldFilters;

    const insuredCollateralsFiltersLabels: Record<CollateralFieldFilterKeys, string> = {
        description: 'Description',
        uniqueIdentifier: 'Unique Identifier',
        category: 'Category',
        limitRequired: 'Limit Required',
        maximumDeductible: 'Maximum Deductible',
    };

    @Component({
        components: { Tile, TileList, ChipList, Chip, Button },
    })
    export default class FiltersChipList extends Vue {
        @Prop({ type: Object as PropType<InsuredFilters>, default: () => ({}) as InsuredFilters })
        private filters!: InsuredFilters;

        @Prop({ type: Array, default: () => [] })
        private insuredFields!: InsuranceInsuredField[];

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        @Prop({ type: String as PropType<string>, default: '' })
        private collateralInsuredFieldKey!: string;

        private unsubscribeResizeObserver: (() => void) | null = null;

        private staticChipNameToFilterName: Record<string, string[]> = {
            'DBA Name(s)': [ 'doingBusinessAs' ],
            'Expiration Date': [ 'expiresBeforeOrOn', 'expiresAfterOrOn' ],
            'Coverage Criteria Group': [ 'coverageCriteriaGroupId', 'coverageCriteriaGroupFilterType' ],
        };

        private chipNameToFilterName = { ...this.staticChipNameToFilterName };

        private ignoredFilters: string[] = [
            'search', 'coverageCriteriaGroupFilterType',
        ];

        private isOpened: boolean = false;
        private isOverflown: boolean = false;
        private chipListClass: string = 'FiltersChipList__Closed';
        private additionalChipClass = 'FiltersChipList__ChipClosed';

        private showAllChipId: string = 'ShowAllChips';
        private showAllChip: InsuredFilterChip | null = null;
        private showAllChipIndex: number = -1;

        @Watch('insuredFields', { immediate: true })
        private onInsuredFieldsChanged(): void {
            const insuredFieldPairs = this.insuredFields.reduce((acc, field) => {
                if (isObject(field.schema)) {
                    acc[field.name] = [ ...getObjInsuredFieldSubKeys(field) ];
                } else {
                    acc[field.name] = [ getInsuredFieldQueryKey(field.key) ];
                }
                return acc;
            }, {} as Record<string, string[]>);
            this.chipNameToFilterName = {
                ...this.staticChipNameToFilterName,
                ...insuredFieldPairs,
            };
        }

        private mounted(): void {
            this.addResizeObserver();
            this.createShowAllChipIfNeeded();
            this.moveShowAllChipIfNeeded();
        }

        private addResizeObserver(): void {
            const element = this.getFiltersChipListElement();

            if (element) {
                const resizeObserver = new ResizeObserver(() => {
                    this.calculateOverflow();
                    this.createShowAllChipIfNeeded();
                    this.moveShowAllChipIfNeeded();
                });
                resizeObserver.observe(element);
                this.unsubscribeResizeObserver = () => {
                    resizeObserver.unobserve(element);
                };
            }
        }

        private createShowAllChipIfNeeded() {
            this.showAllChipIndex = this.isOverflown
                ? this.allChips
                    .filter((filter) => filter.id !== this.showAllChipId)
                    .findIndex((filter, i, array) => {
                        if (i > 0) {
                            const previewsChip = this.$refs[array[i - 1].id] as Chip[];
                            const currentChip = this.$refs[filter.id] as Chip[];
                            if (previewsChip && previewsChip[0] && currentChip && currentChip[0]) {
                                return currentChip[0].$el.getBoundingClientRect().y >
                                    previewsChip[0].$el.getBoundingClientRect().y;
                            }
                        }
                        return false;
                    })
                : -1;
            this.showAllChip = this.isOverflown
                ? this.createShowAllChip()
                : null;
        }

        // Make sure showAllChip is displayed as a last element of the first row.
        private moveShowAllChipIfNeeded() {
            this.$nextTick(() => {
                const showAllChips = this.$refs.ShowAllChips as Chip[];
                if (showAllChips && showAllChips[0] && this.showAllChip !== null && this.showAllChipIndex !== -1) {
                    const firstChip = this.$refs[this.activeFilters[0].id] as Chip[];
                    const nextFilter = this.allChips[this.showAllChipIndex + 1];
                    const nextChip = nextFilter ? this.$refs[nextFilter.id] as Chip[] : null;
                    if (firstChip && firstChip[0]) {
                        if (showAllChips[0].$el.getBoundingClientRect().y >
                            firstChip[0].$el.getBoundingClientRect().y) {
                            this.showAllChipIndex = this.showAllChipIndex > 0 ? this.showAllChipIndex - 1 : 0;
                            // Schedule a next check in case moving by one position is not enough
                            this.$nextTick(() => this.moveShowAllChipIfNeeded());
                        } else if (nextChip && nextChip[0] && showAllChips[0].$el.getBoundingClientRect().y
                            === nextChip[0].$el.getBoundingClientRect().y) {
                            this.showAllChipIndex = this.showAllChipIndex + 1;
                            this.$nextTick(() => this.moveShowAllChipIfNeeded());
                        }
                        this.showAllChip = this.createShowAllChip();
                    }
                }
            });
        }

        private createShowAllChip() {
            return {
                id: this.showAllChipId,
                text: `+ ${this.activeFilters.length - this.showAllChipIndex} more`,
                closeable: false,
                onClick: () => this.showAll(),
            };
        }

        private get allChips(): InsuredFilterChip[] {
            if (this.showAllChip !== null) {
                return [
                    ...this.activeFilters.slice(0, this.showAllChipIndex),
                    this.showAllChip,
                    ...this.activeFilters.slice(this.showAllChipIndex),
                ];
            }
            return this.activeFilters;
        }

        private get activeFilters(): InsuredFilterChip[] {
            return [
                ...this.getBasicFieldsChips(),
                ...this.getInsuredFieldsFilters(),
                ...this.getInsuredCollateralFieldsFilters(),
            ];
        }

        private getInsuredFieldsFilters() {
            // for object type filter
            // will remove all object's sub prop filters but keep one and use only object's key as filter key
            // the value of the filter does not matter here as we only need to know whether the filter is on
            const objectParentKeyRegex = /(if_.+)\./;
            const filtersWithSubKeyRemoved = mapKeys(
                this.filters.insuredFieldFilters,
                (_, key) => key.match(objectParentKeyRegex)?.[1] || key,
            );
            return Object
                .entries(filtersWithSubKeyRemoved || {})
                .map(([ filterKey, filterValue ]) => {
                    const label = this.insuredFields
                        .find((field) => field.key === getInsuredFieldKeyFromQueryKey(filterKey))?.name || filterKey;
                    return this.getChipData(filterKey, filterValue, label);
                });
        }

        private getInsuredCollateralFieldsFilters() {
            return Object
                .entries(this.filters.collateralFieldFilters || {})
                .map(([ filterKey, filterValue ]) => {
                    const key = getInsuredCollateralFieldFromQueryKey(filterKey, this.collateralInsuredFieldKey);
                    const label = `Collateral ${insuredCollateralsFiltersLabels[key as CollateralFieldFilterKeys]}`;
                    return this.getChipData(filterKey, filterValue, label);
                });
        }

        private getBasicFieldsChips() {
            return Object.entries(this.filters)
                .filter(([ key, value ]) => !this.ignoredFilters.includes(key) && value && this.isBasicFilter(key))
                .map(([ key, value ]) => this.getChipData(key, value))
                .filter((x, index, self) => index === self.findIndex((y) => y.text === x.text));
        }

        private isBasicFilter(filterName: string): boolean {
            return Object.keys(new InsuredStandardFiltersObject()).includes(filterName);
        }

        private getChipData(key: string, value: string, label?: string): InsuredFilterChip {
            // find filter name by keys within map first in case of special keys like expiration dates
            let filterName: string | undefined =
                Object.keys(this.chipNameToFilterName)
                    .find((x) => this.chipNameToFilterName[x]?.includes(key));
            filterName = filterName || label;
            const customFilterName = filterName ? this.createCustomFilterNameIfNeeded(filterName, key) : null;
            const filter = customFilterName || key;
            const chipLabel = label || customFilterName || startCase(key);
            return this.createChip(filter, value, chipLabel);
        }

        private createCustomFilterNameIfNeeded(filterName: string, key: string): string {
            if (key === 'coverageCriteriaGroupId' && this.filters.coverageCriteriaGroupFilterType) {
                const label = `${filterName} (${this.filters.coverageCriteriaGroupFilterType})`;
                this.chipNameToFilterName[label] = this.chipNameToFilterName['Coverage Criteria Group'];
                return label;
            }
            return filterName;
        }

        private createChip(filter: string, value: string, label?: string): InsuredFilterChip {
            const filterValues: string[] = value.split(',');
            const valueCount: string = filterValues.length > 1 ? ` : ${filterValues.length}` : '';
            return { text: `${label || filter}${valueCount}`, id: filter, closeable: true, onClick: noop };
        }

        private getFiltersChipListElement(): Element | null {
            return '$el' in this.$refs.FiltersChipList ? this.$refs.FiltersChipList.$el : null;
        }

        private calculateOverflow(): void {
            const element = this.getFiltersChipListElement();
            this.isOverflown = element ? element.scrollHeight > (element.clientHeight + 20) : false;
        }

        private onCloseChip(id: string): void {
            const filtersToClose = this.chipNameToFilterName[id] || [ id ];
            this.$emit('closeFilters', filtersToClose);
        }

        private setClass() {
            this.chipListClass = `FiltersChipList${this.isOpened ? '__Opened' : '__Closed'}`;
            this.additionalChipClass = this.isOpened ? '' : 'FiltersChipList__ChipClosed';
        }

        private showAll(): void {
            if (!this.isOpened) {
                this.isOpened = true;
                requestAnimationFrame(() => {
                    document.documentElement.addEventListener('click', this.showLess, false);
                });
                this.setClass();
            }
        }

        private showLess(): void {
            if (this.isOpened) {
                this.isOpened = false;
                document.documentElement.removeEventListener('click', this.showLess, false);
                this.setClass();
            }
        }

        private removeResizeObserver(): void {
            if (this.unsubscribeResizeObserver) {
                this.unsubscribeResizeObserver();
                this.unsubscribeResizeObserver = null;
            }
        }

        private destroyed(): void {
            this.removeResizeObserver();
        }
    }
</script>
