import { InsuranceInsuredField } from '@evidentid/rpweb-api-client/types';
import JsonSchema, { JsonSchemaObject } from '@evidentid/json-schema/interfaces/JsonSchema';
import { isArray, isObject } from '@evidentid/json-schema/schemaChecks';

function isEqualWhenInsuredFieldObject(insuredFieldSchema: JsonSchemaObject, schemaFilterBy: JsonSchema) {
    const typeEqual = isObject(schemaFilterBy) && insuredFieldSchema.type === schemaFilterBy.type;
    const displayFormatEqual = (insuredFieldSchema as any).displayFormat ?
        (schemaFilterBy as any).displayFormat === (insuredFieldSchema as any).displayFormat
        : true;
    return typeEqual && displayFormatEqual;
}

export function filterInsuredFieldsBySchema(
    insuredFields: InsuranceInsuredField[],
    schemaFilterBy?: JsonSchema | null
): InsuranceInsuredField[] {
    if (!schemaFilterBy) {
        return insuredFields;
    }

    return insuredFields.filter(({ schema: insuredFieldSchema }) => {
        if (isObject(insuredFieldSchema)) {
            return isEqualWhenInsuredFieldObject(insuredFieldSchema, schemaFilterBy);
        } else if (isArray(insuredFieldSchema)) {
            const typeEqual = isArray(schemaFilterBy) && insuredFieldSchema.type === schemaFilterBy.type;
            if (!typeEqual) {
                return false;
            }
            const insuredFieldItemsSchema = insuredFieldSchema.items as any;
            const schemaItemsFilterBy = schemaFilterBy.items as any;
            if (isObject(insuredFieldItemsSchema)) {
                return isEqualWhenInsuredFieldObject(insuredFieldItemsSchema, schemaItemsFilterBy);
            }
            return insuredFieldItemsSchema.type === schemaItemsFilterBy.type;
        }

        return (insuredFieldSchema as any).type === (schemaFilterBy as any).type;
    });
}
