<template>
    <DashboardConfirmationModal
        :header-icon="faStopCircle"
        :header-text="headerText"
        :progress="saving"
        yes="Deactivate"
        no="Cancel"
        v-on="$listeners"
    >
        <div v-if="insureds.length === 1">
            Please confirm you want to deactivate insured <span class="DeactivationConfirmationModal__deactivationDetails">{{ insureds[0].contactEmail }}</span>.
        </div>
        <div v-else-if="insureds.length > 1">
            Please confirm you want to deactivate <span class="DeactivationConfirmationModal__deactivationDetails">{{ insureds.length }} insureds</span>.
        </div>
    </DashboardConfirmationModal>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { InsuranceInsured } from '@evidentid/rpweb-api-client/types';
    import DashboardConfirmationModal
        from '@/modules/dashboard/components/DashboardConfirmationModal/DashboardConfirmationModal.vue';
    import { faStopCircle } from '@fortawesome/free-solid-svg-icons';

    @Component({
        components: {
            DashboardConfirmationModal,
        },
    })
    export default class DeactivationConfirmationModal extends Vue {
        @Prop({ type: Boolean, default: false })
        private saving!: boolean;

        @Prop({ type: Array, default: () => [] })
        private insureds!: InsuranceInsured[];

        private faStopCircle = faStopCircle;

        private get headerText() {
            return `Do you want to deactivate ${this.insureds.length > 1 ? 'selected insureds' : 'insured'}?`;
        }
    }
</script>
