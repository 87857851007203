<template>
    <Modal additional-class-name="InsuredsCoisModal InsuredsCoisModal--empty" open @close="close">
        <template v-slot:header>COIs</template>
        <SubtleAlert type="warning">{{ message }}</SubtleAlert>
    </Modal>
</template>

<style lang="scss">
    .InsuredsCoisModal--empty {
        .Modal__body {
            width: 750px;
        }

        .SubtleAlert {
            margin: 0;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { Modal } from '@evidentid/dashboard-commons/components/Modal';
    import { SubtleAlert } from '@evidentid/dashboard-commons/components/SubtleAlert';

    @Component({
        components: {
            Modal,
            SubtleAlert,
        },
    })
    export default class InsuredsCoisEmptyModal extends Vue {
        @Prop({ type: String })
        private email!: string;

        @Prop({ type: String, default: null })
        private customMessage!: string | null;

        private close(): void {
            this.$emit('close');
        }

        private get message(): string {
            return this.customMessage || `We don't have any Certificate of Insurance available for ${this.email}.`;
        }
    }
</script>
