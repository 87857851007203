import { FIELD_NOT_AVAILABLE_MESSAGE } from '@/modules/insured-details/constant';
import { InsuranceInsuredCoverage } from '@evidentid/rpweb-api-client/types';
import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
import { InsuredMultiFieldCriterion } from '@/modules/insured-details/types';
import { CoverageDetailsKeysWithErrors } from '@/modules/insured-details/models/CoverageDetailsKeysWithErrors.model';

function complexDetailsKeysWithErrors(
    coverage: InsuranceInsuredCoverage,
    evaluationErrors: Record<string, FieldEvaluationResultError>
): CoverageDetailsKeysWithErrors {
    const evaluationErrorDetailsPath = `#/${coverage.coverageType}/details/`;
    const pathDelimiter = '/';
    return Object.keys(evaluationErrors).reduce<CoverageDetailsKeysWithErrors>((result, key) => {
        if (key.startsWith(evaluationErrorDetailsPath)) {
            const [ complexPropKey, complexPropSubKey ] =
                key.replace(evaluationErrorDetailsPath, '').split(pathDelimiter);

            if (complexPropSubKey) {
                if (!result[complexPropKey]) {
                    result[complexPropKey] = {};
                }

                result[complexPropKey][complexPropSubKey] = true;
            }
        }

        return result;
    }, {} as CoverageDetailsKeysWithErrors);
}

export function getCoverageDetailsKeysValueWithErrors(
    { key, value, coverage, evaluationErrors, crossCoverageCriteria }: {
        key: string;
        value: unknown;
        coverage: InsuranceInsuredCoverage;
        evaluationErrors: Record<string, FieldEvaluationResultError>;
        crossCoverageCriteria?: InsuredMultiFieldCriterion[];
    }
): unknown {
    if (value === null) {
        const complexDetailWithErrorsForItsProps =
            complexDetailsKeysWithErrors(coverage, evaluationErrors)[key];

        if (complexDetailWithErrorsForItsProps) {
            return Object.keys(complexDetailWithErrorsForItsProps)
                .reduce<Record<string, string>>(
                    (_result, _key) => {
                        _result[_key] = FIELD_NOT_AVAILABLE_MESSAGE;
                        return _result;
                    },
                    {} as Record<string, string>,
                );
        } else if (evaluationErrors[`#/${coverage.coverageType}/details/${key}`] && crossCoverageCriteria) {
            const keyIsInMultiFieldCriterion = crossCoverageCriteria.some((criterion) =>
                Object.keys(criterion.fields).includes(key),
            );

            if (!keyIsInMultiFieldCriterion) {
                return FIELD_NOT_AVAILABLE_MESSAGE;
            }
        }
    } else if (Array.isArray(value) && value.length === 0 && evaluationErrors[`#/${coverage.coverageType}/details/${key}`]) {
        return FIELD_NOT_AVAILABLE_MESSAGE;
    } else if (!Array.isArray(value) && typeof value === 'object') {
        return Object.entries(value).reduce<Record<string, any>>(
            (newValue, [ propKey, propValue ]) => {
                const hasEvaluationError = evaluationErrors[`#/${coverage.coverageType}/details/${key}/${propKey}`];
                newValue[propKey] = propValue;

                if (!newValue[propKey] && hasEvaluationError) {
                    newValue[propKey] = FIELD_NOT_AVAILABLE_MESSAGE;
                }
                return newValue;
            },
            {} as Record<string, any>,
        );
    }

    return value;
}
