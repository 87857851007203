// reason for two stages with 'pending' and 'disabled' is to prevent refresh, crash, cases where status is not cleared
// when umbrella is removed, recorded it as pending. Change to disabled after saved
// Do not auto add umbrella if pending or disabled
// remove all pending when component that uses this service created, so that all records that did not save get purged
const PENDING = 'pending';
const DISABLED = 'disabled';
export const CCG_AUTO_ADD_UMBRELLA_CREATE_GROUP_ID = 'createGroup';
export const KEY_PREFIX = 'autoAddUmbrellaStatus-';

export function getCcgAutoAddUmbrellaStatusKey(ccgId?: string): string {
    return `${KEY_PREFIX}${ccgId || CCG_AUTO_ADD_UMBRELLA_CREATE_GROUP_ID}`;
}

export function isCcgAutoAddUmbrellaDisabled(ccgId: string): boolean {
    const status = window.sessionStorage.getItem(getCcgAutoAddUmbrellaStatusKey(ccgId));
    return status === DISABLED || status === PENDING;
}

// pending when umbrella coverage type removed
export function startDisablingCcgAutoAddUmbrella(ccgId: string) {
    window.sessionStorage.setItem(getCcgAutoAddUmbrellaStatusKey(ccgId), PENDING);
}

// disabled when ccg saved
export function confirmDisablingCcgAutoAddUmbrella(ccgId: string) {
    if (window.sessionStorage.getItem(getCcgAutoAddUmbrellaStatusKey(ccgId)) === PENDING) {
        window.sessionStorage.setItem(getCcgAutoAddUmbrellaStatusKey(ccgId), DISABLED);
    }
}

export function assignCreateGroupAutoUmbrellaStatusId(ccgId: string) {
    if (!ccgId) {
        return;
    }
    const createGroupStatus =
        window.sessionStorage.getItem(getCcgAutoAddUmbrellaStatusKey(CCG_AUTO_ADD_UMBRELLA_CREATE_GROUP_ID));
    // exclude the cases of undefined and nullclea
    if (createGroupStatus != null) {
        window.sessionStorage.removeItem(getCcgAutoAddUmbrellaStatusKey(CCG_AUTO_ADD_UMBRELLA_CREATE_GROUP_ID));
        window.sessionStorage.setItem(getCcgAutoAddUmbrellaStatusKey(ccgId), createGroupStatus);
    }
}

export function purgeCcgAutoAddUmbrellaPendingItems() {
    const listToRemove = [] as string[];
    for (let i = 0; i < sessionStorage.length; i++) {
        const itemKey = sessionStorage.key(i);
        const itemStatus = itemKey ? sessionStorage.getItem(itemKey) : null;
        if (itemStatus === PENDING && itemKey && itemKey.startsWith(KEY_PREFIX)) {
            listToRemove.push(itemKey);
        }
    }
    listToRemove.forEach((key) => sessionStorage.removeItem(key));
}
