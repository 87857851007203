<template>
    <div class="ScreenToolbar">
        <RelyingPartySelection
            v-if="hasRpPanel && rps.length > 1"
            class="ScreenToolbar__rps"
            :options="rps"
            :value="currentRp"
            @input="changeRp"
        />
        <UserBox v-if="hasUserPanel" class="ScreenToolbar__user" />
    </div>
</template>

<style lang="scss">
    .ScreenToolbar {
        display: flex;
        align-items: center;

        &__rps + &__user {
            margin-left: 15px;
        }
    }
</style>

<script lang="ts">
    import { Vue, Component, Prop } from '@evidentid/vue-property-decorator';
    import UserBox from '@evidentid/dashboard-commons/modules/auth/components/UserBox.vue';
    import RelyingPartySelection from '@/components/screens/RelyingPartySelection.vue';

    @Component({
        components: { RelyingPartySelection, UserBox },
    })
    export default class ScreenToolbar extends Vue {
        @Prop({ type: Boolean, default: false })
        private hasUserPanel!: boolean;

        @Prop({ type: Boolean, default: false })
        private hasRpPanel!: boolean;

        private get rps() {
            return this.$rp.available;
        }

        private get currentRp() {
            return this.$rp.current;
        }

        private changeRp(rpId: string) {
            this.$rp.change(rpId);
        }
    }
</script>
