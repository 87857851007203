<template>
    <div class="DecisioningCriteria">
        <Page :loading="isLoading" title="Decisioning Criteria">
            <DashboardConfirmationModal
                v-if="deleteId"
                additional-class-name="DecisioningCriteria__deleteConfirmation"
                header-text="Are you sure?"
                :progress="deletingGroupStatus === OperationStatus.loading"
                @input="onConfirmationInput"
                @abort="closeDeleteConfirmation"
            >
                <span>Please confirm you want to delete </span>
                <span class="DecisioningCriteria__deleteGroupName">{{ deletingGroupName }}</span>
            </DashboardConfirmationModal>
            <CoverageCriteriaGroups
                :coverage-criteria-groups="sortedCriteriaGroups"
                @create="createGroup"
                @edit="editGroup"
                @copy="copyGroup"
                @delete="deleteGroup"
            />
        </Page>
    </div>
</template>

<style lang="scss">
    .DecisioningCriteria {
        // TODO[PRODUCT-20869]: remove temp solution unblocks evie from view content once long term solution provided
        .MainLayout__content {
            padding-bottom: 100px;
        }

        &__deleteConfirmation.Modal {
            .Modal__body {
                height: 350px;
            }
        }

        &__deleteGroupName {
            color: #2eb495;
        }
    }
</style>

<script lang="ts">
    import { Component, Vue, Watch } from '@evidentid/vue-property-decorator';
    import sortBy from 'lodash/sortBy';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import Page from '@/layouts/Page.vue';
    import GroupAssignment from '@/modules/decisioning-criteria/components/GroupAssignment/GroupAssignment.vue';
    import { CoverageCriteriaGroupsStatus } from '@/modules/decisioning-criteria/vuex';
    import CoverageCriteriaGroups
        from '@/modules/decisioning-criteria/components/CoverageCriteriaGroups/CoverageCriteriaGroups.vue';
    import CreateEditCoverageCriteriaGroup
        from '@/modules/decisioning-criteria/components/CreateEditCoverageCriteriaGroup/CreateEditCoverageCriteriaGroup.vue';
    import { InsuranceCoverageCriteriaGroup } from '@evidentid/rpweb-api-client/types';
    import { PendingScreen } from '@evidentid/dashboard-commons/screens/PendingScreen';
    import DashboardConfirmationModal
        from '@/modules/dashboard/components/DashboardConfirmationModal/DashboardConfirmationModal.vue';

    @Component({
        components: {
            DashboardConfirmationModal,
            CoverageCriteriaGroups,
            CreateEditCoverageCriteriaGroup,
            GroupAssignment,
            Page,
            PendingScreen,
        },
    })
    export default class DecisioningCriteria extends Vue {
        private lastRpName!: string;
        private deleteId: string | null = null;
        private OperationStatus = OperationStatus;

        private get rpName(): string {
            return this.$rp.current!;
        }

        private get criteriaGroupsStatus(): CoverageCriteriaGroupsStatus {
            return this.$store.state.decisioningCriteria.coverageCriteriaGroups[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get deletingGroupStatus(): OperationStatus {
            return this.$store.state.decisioningCriteria.deletingCoverageCriteriaGroupStatus[this.rpName] ||
                OperationStatus.uninitialized;
        }

        private get isLoading(): boolean {
            return this.criteriaGroupsStatus.status === OperationStatus.loading;
        }

        private get deletingGroupName(): string {
            return this.criteriaGroupsStatus.list.find((x) => x.id === this.deleteId)?.displayName || '';
        }

        private get sortedCriteriaGroups(): InsuranceCoverageCriteriaGroup[] {
            return sortBy(this.criteriaGroupsStatus.list, [ (group) => group.displayName.toLowerCase() ]);
        }

        @Watch('$rp.current', { immediate: true })
        private handleRpChange(rpName: string, prevRpName: string) {
            // Save information about the last resource, which should be cleared during destroy
            this.lastRpName = rpName;
            if (prevRpName) {
                this.$store.actions.decisioningCriteria.clearCoverageCriteriaGroups({ rpName: prevRpName });
            }
            this.loadGroups();
        }

        private loadGroups() {
            if (this.criteriaGroupsStatus.status !== OperationStatus.loading) {
                this.$store.actions.decisioningCriteria.loadCoverageCriteriaGroups({ rpName: this.rpName });
            }
        }

        private deleteCoverageCriteriaGroup(id: string) {
            this.deleteId = id;
        }

        private async onConfirmationInput(confirmed: boolean): Promise<void> {
            if (confirmed && this.deleteId && this.criteriaGroupsStatus.status !== OperationStatus.loading) {
                await this.$store.actions.decisioningCriteria.deleteCoverageCriteriaGroup({
                    rpName: this.rpName,
                    groupId: this.deleteId,
                });
            }
            this.closeDeleteConfirmation();
        }

        private closeDeleteConfirmation(): void {
            this.deleteId = null;
        }

        private createGroup() {
            this.$router.push({
                name: 'createInsuredDecisioningCriteriaGroup',
                params: {
                    rpId: this.$route.params.rpId,
                },
            });
        }

        private editGroup(id: string) {
            this.$router.push({
                name: 'insuredDecisioningCriteriaGroup',
                params: {
                    rpId: this.$route.params.rpId,
                    id,
                },
            });
        }

        private copyGroup(id: string) {
            // TODO:
            console.log('copy', id);
        }

        private deleteGroup(id: string) {
            this.deleteCoverageCriteriaGroup(id);
        }

        private destroyed() {
            this.$store.actions.decisioningCriteria.clearCoverageCriteriaGroups({ rpName: this.lastRpName });
            this.$store.actions.decisioningCriteria.clearDeletingCoverageCriteriaGroupStatus(
                { rpName: this.lastRpName });
        }
    }
</script>
