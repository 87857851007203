import {
    InsuranceComplianceStatus,
    InsuranceCoverageType,
    InsuranceExceptionInput,
    InsuranceExceptionLevel,
    InsuranceExceptionType,
} from '@evidentid/insurance-facing-lib/models/insured-details';

export function createInsuredLevelException(
    type?: InsuranceExceptionType,
    status?: InsuranceComplianceStatus,
): InsuranceExceptionInput {
    return {
        level: InsuranceExceptionLevel.insured,
        type: type || InsuranceExceptionType.permanent,
        status: status || InsuranceComplianceStatus.compliant,
    };
}

export function createCoverageLevelException(
    coverageType: InsuranceCoverageType,
    type?: InsuranceExceptionType,
    status?: InsuranceComplianceStatus,
): InsuranceExceptionInput {
    return {
        coverageType,
        level: InsuranceExceptionLevel.coverageType,
        type: type || InsuranceExceptionType.permanent,
        status: status || InsuranceComplianceStatus.compliant,
    };
}

export function createCriterionLevelException(
    coverageType: InsuranceCoverageType,
    criterionId: string,
    type?: InsuranceExceptionType,
    status?: InsuranceComplianceStatus,
): InsuranceExceptionInput {
    return {
        coverageType,
        criterionId,
        level: InsuranceExceptionLevel.coverageCriterion,
        type: type || InsuranceExceptionType.permanent,
        status: status || InsuranceComplianceStatus.compliant,
    };
}

export function createEvaluateEntityLevelException(
    evaluatedEntityId: string,
    type?: InsuranceExceptionType,
    status?: InsuranceComplianceStatus,
): InsuranceExceptionInput {
    return {
        evaluatedEntityId,
        level: InsuranceExceptionLevel.evaluatedEntity,
        type: type || InsuranceExceptionType.permanent,
        status: status || InsuranceComplianceStatus.compliant,
    };
}
