<template>
    <v-component :is="Page" class="UserManagementDashboard" title="Manage Users">
        <template slot="toolbarEnd">
            <Button
                type="primary"
                class="UserManagementDashboard__inviteButton"
                :icon="faPlus"
                @click="startInvite"
            >
                Add new user
            </Button>
        </template>

        <InfiniteScroll @scroll="loadMore">
            <UsersTable
                :users="users"
                :service="options.serviceName"
                :role-labels="options.roleLabels"
                :can-load-more="canLoadMore"
                :loading-more="loadingMore"
                :current-user-email="currentUserEmail"
                @edit="startEdit"
                @delete="startDelete"
                @resendInvite="startResendInvite"
            />
        </InfiniteScroll>
    </v-component>
</template>

<style lang="scss">
    .UserManagementDashboard {
        &__inviteButton {
            padding: 9px 28px;
        }
    }
</style>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';
    import { faPlus } from '@fortawesome/free-solid-svg-icons';
    import type { User } from '@evidentid/user-management-api-client/types';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { Button } from '../../../components/Button';
    import { InfiniteScroll } from '../../../components/InfiniteScroll';
    import UsersTable from '../components/UsersTable.vue';
    import { UserManagementConfig, UserManagementProcedure } from '../types';
    import { emptyUsersListStatus, UserManagementUsersList } from '../vuex';

    // TODO: It should require manage:users permissions (or any other)

    @Component({
        components: { UsersTable, InfiniteScroll, Button },
    })
    export default class UsersDashboard extends Vue {
        private faPlus = faPlus;

        // Configuration
        private get options(): UserManagementConfig {
            return (this as any).$userManagement.config;
        }

        // Component

        private get state(): UserManagementUsersList {
            return (this as any).$store.state.userManagement.usersList[this.rpName] || emptyUsersListStatus;
        }

        private get rpName(): string {
            return this.$route.params.rpId;
        }

        private get rpDisplayName(): string | null {
            const relyingParties = (this as any).$store.state.user?.relyingParties || [];
            const relyingParty = relyingParties.find((x: any) => x.name === this.rpName);
            return relyingParty.displayName || null;
        }

        private get currentUserEmail(): string | null {
            return (this as any).$store.state.user?.data?.email || null;
        }

        private get users(): User[] {
            return this.state.users;
        }

        private get loadingMore(): boolean {
            return this.state.status === OperationStatus.loading;
        }

        private get canLoadMore(): boolean {
            return this.state.canLoadMore;
        }

        private get Page(): Vue {
            return (this as any).$userManagement.Page;
        }

        private mounted(): void {
            this.loadMore();
        }

        private async loadMore(): Promise<void> {
            if (!this.state.canLoadMore || this.state.status === OperationStatus.loading) {
                return;
            }

            await (this as any).$store.actions.userManagement.loadMoreUsers({ rpName: this.rpName });
        }

        private startInvite(): void {
            const options = { rpName: this.rpName, rpDisplayName: this.rpDisplayName };
            (this as any).$procedures.execute(UserManagementProcedure.invite, options, this.finish.bind(this));
        }

        private startDelete(user: User): void {
            const options = { rpName: this.rpName, user };
            (this as any).$procedures.execute(UserManagementProcedure.delete, options, this.finish.bind(this));
        }

        private startEdit(user: User): void {
            const options = { rpName: this.rpName, user };
            (this as any).$procedures.execute(UserManagementProcedure.edit, options, this.finish.bind(this));
        }

        private startResendInvite(user: User): void {
            const options = { rpName: this.rpName, user };
            (this as any).$procedures.execute(UserManagementProcedure.resendInvite, options, this.finish.bind(this));
        }

        private async finish(modified: boolean): Promise<void> {
            if (modified) {
                const rpName = this.rpName;
                await (this as any).$store.actions.userManagement.resetUsersList({ rpName });
                await (this as any).$store.actions.userManagement.loadMoreUsers({ rpName });
            }
        }
    }
</script>
