import JsonSchema, { JsonSchemaComplexObject } from '@evidentid/json-schema/interfaces/JsonSchema';
import {
    InsuranceSchema,
    InsuranceInsuredField,
    InsuranceSchemaDisplayFormat,
} from '@evidentid/rpweb-api-client/types';
import { convertInsuredFieldSchemaToEnumSchema } from '@/modules/decisioning-criteria/utils/criterionSchemaConversion';
import { removeNonStandardSchemaFormat } from '@/modules/decisioning-criteria/utils/removeNonStandardSchemaFormat';

function standardizeInsuredFieldSchema(
    schema: JsonSchema|JsonSchemaComplexObject,
    insuredFields?: InsuranceInsuredField[],
    schemaFilterBy?: JsonSchema | null,
): JsonSchema {
    let moddedSchema = schema;

    if ('if' in schema) {
        const thenSchema = Object.values(schema.then?.properties || {})[0];
        const elseSchema = Object.values(schema.else?.properties || {})[0];
        const thenDisplayFormat = (thenSchema as InsuranceSchema)?.displayFormat;
        const elseDisplayFormat = (elseSchema as InsuranceSchema)?.displayFormat;
        const thenInsuredValueTypeFilterBy = thenDisplayFormat === InsuranceSchemaDisplayFormat.insuredField ?
            elseSchema
            : null;
        const elseInsuredValueTypeFilterBy = elseDisplayFormat === InsuranceSchemaDisplayFormat.insuredField ?
            thenSchema
            : null;

        moddedSchema = {
            ...schema,
            if: standardizeInsuredFieldSchema(schema.if as JsonSchema, insuredFields),
            then: standardizeInsuredFieldSchema(
                schema.then as JsonSchema,
                insuredFields,
                thenInsuredValueTypeFilterBy
            ),
            else: standardizeInsuredFieldSchema(
                schema.else as JsonSchema,
                insuredFields,
                elseInsuredValueTypeFilterBy
            ),
        } as JsonSchemaComplexObject;
    }

    if ('properties' in moddedSchema) {
        return {
            ...moddedSchema,
            properties: Object.keys(moddedSchema.properties).reduce((acc, key) => ({
                ...acc,
                [key]: standardizeInsuredFieldSchema(
                    (moddedSchema as JsonSchemaComplexObject).properties[key],
                    insuredFields,
                    schemaFilterBy
                ),
            }), {}),
        };
    } else if ('items' in moddedSchema) {
        return {
            ...moddedSchema,
            items: standardizeInsuredFieldSchema(
                moddedSchema.items as JsonSchema,
                insuredFields,
            ),
        };
    }

    const isInsuredFieldSchema =
        (moddedSchema as InsuranceSchema).displayFormat === InsuranceSchemaDisplayFormat.insuredField &&
        insuredFields && insuredFields.length > 0;

    if (isInsuredFieldSchema) {
        return convertInsuredFieldSchemaToEnumSchema(moddedSchema, insuredFields, schemaFilterBy);
    }

    return moddedSchema;
}

export function standardizeCriterionSchema(
    schema: JsonSchema,
    insuredFields?: InsuranceInsuredField[],
    schemaFilterBy?: JsonSchema | null,
): JsonSchema {
    return removeNonStandardSchemaFormat(
        standardizeInsuredFieldSchema(schema, insuredFields, schemaFilterBy),
    );
}
