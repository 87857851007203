<template>
    <div class="EffectiveGroupsTooltipLabel">
        <v-popover
            v-if="groupNames.length > 0"
            trigger="hover"
            :delay="{ show: 300, hide: 100 }"
            :placement="placement"
            popover-class="EffectiveGroupsTooltipLabel__tooltip"
        >
            <div class="EffectiveGroupsTooltipLabel__label tooltip-target">
                {{ label }}
            </div>
            <template slot="popover">
                <div class="EffectiveGroupsTooltipLabel__popoverTitle">
                    Effective Group
                </div>
                <div v-for="(name,index) in groupNames"
                    :key="index"
                    class="EffectiveGroupsTooltipLabel__popoverGroupName"
                >
                    {{ name }}
                </div>
            </template>
        </v-popover>
        <div v-else class="EffectiveGroupsTooltipLabel__label">
            {{ showLabelPrefix ? 'Effective Group:' : '' }} none
        </div>
    </div>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { Badge } from '@evidentid/dashboard-commons/components/Badge';

    @Component({
        components: { Badge, FontAwesomeIcon },
    })
    export default class EffectiveGroupsTooltipLabel extends Vue {
        @Prop({ type: Array, default: [] })
        private groupNames!: string[];

        @Prop({ type: String, default: 'left' })
        private placement!: string;

        @Prop({ type: Boolean, default: true })
        private showLabelPrefix!: boolean;

        private get label(): string {
            return `${this.showLabelPrefix ? 'Effective Group:' : ''} ${this.groupNames.join(' + ')}`;
        }
    }
</script>
