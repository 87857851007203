<template>
    <div class="MultiFieldCriterion">
        <div class="MultiFieldCriterion__endorsement">
            <span v-if="showCriterionIndex" class="MultiFieldCriterion__criterionIndex" :class="additionalIndexClass">
                {{ index }}
            </span>
            <div class="MultiFieldCriterion__body">
                <div class="MultiFieldCriterion__header">
                    <span class="MultiFieldCriterion__headerName">
                        {{ criterion.name }}
                    </span>
                    <EvaluationError
                        v-if="criterion.evaluationError"
                        :evaluation-error="criterion.evaluationError"
                        v-on="$listeners"
                    />
                </div>
                <MultiFieldCriterionRecords
                    v-for="(fieldName, fieldIndex) in renderingList"
                    :key="fieldName"
                    :name="fieldName"
                    :value="criterion.fields[fieldName]"
                    :index="fieldIndex"
                    :show-index="showFieldIndex"
                    :additional-index-class="[additionalIndexClass]"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import startCase from 'lodash/startCase';
    import { InsuredMultiFieldCriterion } from '@/modules/insured-details/types';
    import EvaluationError from '@/modules/insured-details/components/EvaluationError/EvaluationError.vue';
    import MultiFieldCriterionRecords
        from './sub-components/MultiFieldCriterionRecords.vue';
    import difference from 'lodash/difference';

    export default Vue.extend({
        name: 'MultiFieldCriterion',
        components: {
            EvaluationError,
            MultiFieldCriterionRecords,
        },
        props: {
            criterion: {
                type: Object as PropType<InsuredMultiFieldCriterion>,
                default: () => ({}) as InsuredMultiFieldCriterion,
            },
            index: {
                type: Number as PropType<number>,
                default: 1,
            },
            fieldOrderList: {
                type: Array as PropType<string[]>,
                default: () => [],
            },
            showCriterionIndex: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            showFieldIndex: {
                type: Boolean as PropType<Boolean>,
                default: false,
            },
        },
        computed: {
            defaultFieldsList(): string[] {
                return Object.keys(this.criterion.fields);
            },
            renderingList(): string[] {
                const unsortedList = difference(this.defaultFieldsList, this.fieldOrderList);
                return [ ...this.fieldOrderList, ...unsortedList ];
            },
            additionalIndexClass(): string | null {
                if (!this.criterion?.evaluationError) {
                    return null;
                }
                return this.criterion.evaluationError.complianceStatusMetadata.length > 0
                    ? 'MultiFieldCriterion--exception'
                    : 'MultiFieldCriterion--error';
            },
        },
        methods: {
            getFieldValue(value: unknown): unknown {
                if (Array.isArray(value)) {
                    return value.join(', ');
                }

                return value;
            },
            startCase,
        },
    });
</script>
