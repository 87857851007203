<template>
    <div
        class="InsuredSearchAndFilter"
        :class="{
            'InsuredSearchAndFilter--open':opened,
            'InsuredSearchAndFilter--active': hasFilters,
            'InsuredSearchAndFilter--disabled': disabled
        }"
    >
        <Popover
            :open="opened"
            @hide="closeFilterPopover"
        >
            <template #base>
                <div class="InsuredSearchAndFilter__container">
                    <div class="InsuredSearchAndFilter__prefix">
                        <FontAwesomeIcon :icon="faSearch" />
                    </div>
                    <input
                        class="InsuredSearchAndFilter__embeddedSearch"
                        type="text"
                        placeholder="Search..."
                        :value="search"
                        :disabled="disabled"
                        @input="scheduleChange"
                    >
                    <div class="InsuredSearchAndFilter__postfix" @click="toggleFilterPopover">
                        <span>Advanced</span>
                        <FontAwesomeIcon :icon="faCaretDown" />
                    </div>
                </div>
            </template>

            <InsuredFiltersPopover
                :filters="filters"
                :insured-fields="insuredFields"
                :coverage-criteria-groups="coverageCriteriaGroups"
                :effective-groups="effectiveGroups"
                :collateral-insured-field-key="collateralInsuredFieldKey"
                :insured-field-collaterals="insuredFieldCollaterals"
                @close="closeFilterPopover"
                @submit="applyFilters"
            />
        </Popover>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import isEqual from 'lodash/isEqual';
    import pickBy from 'lodash/pickBy';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faSearch, faCaretDown } from '@fortawesome/free-solid-svg-icons';
    import { InsuredFilters } from '@/modules/insured-filtering/types';
    import InsuredFiltersPopover
        from '@/modules/insured-filtering/components/InsuredFiltersPopover/InsuredFiltersPopover.vue';
    import {
        InsuranceCoverageCriteriaGroup,
        InsuranceEffectiveGroup,
        InsuranceInsuredField,
    } from '@evidentid/rpweb-api-client/types';
    import Popover from '@evidentid/dashboard-commons/components/Popover/Popover.vue';

    export default Vue.extend({
        name: 'InsuredSearchAndFilter',
        components: {
            Popover,
            FontAwesomeIcon,
            InsuredFiltersPopover,
        },
        props: {
            debounceTime: {
                type: Number as PropType<number>,
                default: 500,
            },
            disabled: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            filters: {
                type: Object as PropType<InsuredFilters>,
                default: () => ({}) as InsuredFilters,
            },
            insuredFields: {
                type: Array as PropType<InsuranceInsuredField[]>,
                default: () => [] as InsuranceInsuredField[],
            },
            coverageCriteriaGroups: {
                type: Array as PropType<InsuranceCoverageCriteriaGroup[]>,
                default: () => [] as InsuranceCoverageCriteriaGroup[],
            },
            effectiveGroups: {
                type: Array as PropType<InsuranceEffectiveGroup[]>,
                default: () => [] as InsuranceEffectiveGroup[],
            },
            collateralInsuredFieldKey: {
                type: String as PropType<string>,
                default: '',
            },
            insuredFieldCollaterals: {
                type: Object as PropType<InsuranceInsuredField|null|undefined>,
                default: undefined,
            },
        },
        data() {
            return {
                search: '',
                faSearch,
                faCaretDown,
                isMounted: false,
                debounceTimeout: undefined as undefined|number,
                opened: false,
            };
        },
        computed: {
            hasFilters(): boolean {
                return Object.keys(this.filters).length > 0;
            },
        },
        watch: {
            filters: {
                immediate: true,
                handler(newValue: InsuredFilters) {
                    this.search = newValue.search || '';
                    this.abortScheduledAction();
                },
            },
        },
        mounted() {
            this.isMounted = true;
        },
        destroyed() {
            this.isMounted = false;
        },
        methods: {
            scheduleChange(inputEvent: InputEvent): void {
                this.search = (inputEvent.target as HTMLInputElement).value;
                this.abortScheduledAction();
                this.debounceTimeout = setTimeout(
                    this.immediateChange.bind(this),
                    this.debounceTime,
                ) as unknown as number;
            },
            immediateChange(): void {
                this.abortScheduledAction();
                if (this.isMounted && !isEqual(this.search, this.filters.search)) {
                    this.$emit('change', pickBy({ ...this.filters, search: this.search }, Boolean));
                }
            },
            abortScheduledAction() {
                clearTimeout(this.debounceTimeout);
            },
            closeFilterPopover() {
                this.opened = false;
            },
            toggleFilterPopover(event: Event) {
                this.opened = !this.opened;
                if (this.opened) {
                    event.stopPropagation();
                }
            },
            applyFilters(filters: InsuredFilters) {
                this.$emit('change', pickBy({
                    ...filters,
                }, Boolean));
                this.closeFilterPopover();
            },
        },
    });
</script>
