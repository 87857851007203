<template>
    <div class="CoverageCriteriaGroupsTableRow">
        <div class="CoverageCriteriaGroupsTableRow__displayNameContentCell">
            <span class="CoverageCriteriaGroupsTableRow__displayNameContentText" @click="$emit('goToCriteriaGroup', insuredCoverageCriteriaGroup.id)">
                {{ insuredCoverageCriteriaGroup.displayName }}
            </span>
        </div>
        <div class="CoverageCriteriaGroupsTableRow__coverageTypesContentCell">
            {{ insuredCoverageCriteriaGroup.coverageTypes.length }}
        </div>
        <div class="CoverageCriteriaGroupsTableRow__complianceStatusContentCell">
            <ComplianceStatusBadge :status="insuredCoverageCriteriaGroup.complianceStatus" />
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { InsuranceInsuredCoverageCriteriaGroup } from '@evidentid/rpweb-api-client/types';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import ComplianceStatusBadge from '@/modules/dashboard/components/ComplianceStatusBadge/ComplianceStatusBadge.vue';

    @Component({
        components: { Button, ComplianceStatusBadge },
    })
    export default class CoverageCriteriaGroupsTableRow extends Vue {
        @Prop({ type: Object, default: () => ({}) })
        private insuredCoverageCriteriaGroup!: InsuranceInsuredCoverageCriteriaGroup;
    }
</script>
