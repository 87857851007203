import uniq from 'lodash/uniq';
import isEqual from 'lodash/isEqual';
import mapValues from 'lodash/mapValues';
import sortBy from 'lodash/sortBy';
import identity from 'lodash/identity';
import groupBy from 'lodash/groupBy';
import { Cadence, SchedulePatternType } from '@evidentid/config-api-client/types';
import { NotificationCadence, SchedulePattern, TimePhrase, TimeUnit } from '../types';
import { InsuranceVerificationRequestConfiguration } from '@evidentid/rpweb-api-client/types';

interface BuilderParams {
    cadence: Cadence;
    expirationNumberOfDays: number | null;
    verificationRequestConfiguration: InsuranceVerificationRequestConfiguration;
}

function parseSchedulePattern(pattern: any): { daysOfWeek: number[], sendsAt: number[] } {
    const hours = groupBy(pattern, 'day');
    return {
        daysOfWeek: uniq(sortBy(Object.keys(hours).map((day) => parseInt(day, 10)), identity)),
        sendsAt: uniq(sortBy((Object.values(hours)[0] || []).map((x) => x.hour), identity)),
    };
}

function isValidSchedulePattern(pattern: any): boolean {
    if (!pattern || typeof pattern !== 'object' || !Array.isArray(pattern)) {
        return false;
    } else if (!pattern.every((item) => (
        item.weekNumber === null &&
        item.minute === 0 &&
        typeof item.hour === 'number' &&
        item.hour >= 0 && item.hour <= 23 &&
        typeof item.day === 'number' &&
        item.day >= 1 && item.day <= 7
    ))) {
        return false;
    } else if (pattern.length === 0) {
        return true;
    }
    const hours = Object.values(mapValues(groupBy(pattern, 'day'))).map((x) => x.map((y) => y.hour).sort());
    return hours.every((x) => isEqual(x, hours[0]));
}

function parseTimePhrase(time: string): TimePhrase | null {
    // deprecating weeks unit since it's not supported in scheduler
    const match = time.match(/^\s*(\d+)\s+(hour|minute|day|week)s?$/);
    if (!match) {
        return null;
    }
    const amount = parseInt(match[1], 10);
    const unit = `${match[2]}s` as TimeUnit;
    if (isNaN(amount)) {
        return null;
    }
    // backward compatibility only, convert previous saved weeks into days.
    return unit === TimeUnit.weeks
        ? { amount: amount * 7, unit: TimeUnit.days }
        : { amount, unit };
}

function buildIntervalNotificationCadence({
    cadence,
    verificationRequestConfiguration,
}: BuilderParams): NotificationCadence {
    const interval = parseTimePhrase(cadence.schedulePattern);
    return {
        recurrence: SchedulePatternType.interval,
        limit: cadence.limit || 0,
        interval: interval!,
        initEnabled: verificationRequestConfiguration.initEnabled,
        remindersEnabled: verificationRequestConfiguration.remindersEnabled,
    };
}

function buildScheduleNotificationCadence({
    cadence,
    verificationRequestConfiguration,
}: BuilderParams): NotificationCadence {
    const rawPattern: SchedulePattern[] = JSON.parse(cadence.schedulePattern);
    const pattern = isValidSchedulePattern(rawPattern)
        ? parseSchedulePattern(rawPattern)
        : { daysOfWeek: null, sendsAt: null };
    return {
        recurrence: SchedulePatternType.schedule,
        limit: cadence.limit || 0,
        daysOfWeek: pattern.daysOfWeek!,
        sendsAt: pattern.sendsAt!,
        timezone: cadence.timezone,
        initEnabled: verificationRequestConfiguration.initEnabled,
        remindersEnabled: verificationRequestConfiguration.remindersEnabled,
    };
}

function parseSequencePeriods(periods: (TimePhrase | null)[]): number[] | null {
    if (!periods.every((x) => x?.unit === TimeUnit.days)) {
        return null;
    }
    const days: number[] = [];
    let lastDay = 0;
    for (const period of periods) {
        lastDay += period!.amount;
        days.push(lastDay);
    }
    return days;
}

function buildSequenceNotificationCadence({
    cadence,
    expirationNumberOfDays: daysBeforeCount,
    verificationRequestConfiguration,
}: BuilderParams): NotificationCadence {
    const recurrence = SchedulePatternType.sequence;
    const periods: (TimePhrase | null)[] = JSON.parse(cadence.schedulePattern).map(parseTimePhrase);
    const days = parseSequencePeriods(periods);
    const daysBefore = daysBeforeCount == null || days == null
        ? null
        : days.filter((x) => (x < daysBeforeCount));
    const daysAfter = days == null
        ? null
        : days.filter((x) => (x > (daysBeforeCount || 0))).map((x) => (x - (daysBeforeCount || 0)));
    const result: any = {
        recurrence,
        daysBeforeCount,
        daysAfter,
        initEnabled: verificationRequestConfiguration.initEnabled,
        remindersEnabled: verificationRequestConfiguration.remindersEnabled,
    };
    if (daysBefore) {
        result.daysBefore = daysBefore;
    }
    return result;
}

const builders: Record<
    SchedulePatternType,
    (params: BuilderParams) => NotificationCadence
> = {
    [SchedulePatternType.interval]: buildIntervalNotificationCadence,
    [SchedulePatternType.schedule]: buildScheduleNotificationCadence,
    [SchedulePatternType.sequence]: buildSequenceNotificationCadence,
};

export function buildNotificationCadence(params: BuilderParams): NotificationCadence {
    return builders[params.cadence.schedulePatternType](params);
}
