import { CollateralMappingStatus } from '@/modules/insured-details/models/CollateralMappingStatus.model';
import { CollateralEntity } from '@evidentid/rpweb-api-client/models/CollateralEntity.model';
import { ConciseCollateral } from '@evidentid/rpweb-api-client/models/ConciseCollateral.model';
import { InsuranceComplianceStatus } from '@evidentid/insurance-facing-lib/models/insured-details';
import { CollateralMappingType } from '@evidentid/rpweb-api-client/models/CollateralMappingType.model';

export function getCollateralMappingOptionStatus(
    mappingOptions: ConciseCollateral[], collateralEntity: CollateralEntity,
): CollateralMappingStatus {
    if (mappingOptions.length === 0) {
        return CollateralMappingStatus.error;
    }
    if (collateralEntity?.complianceStatus === InsuranceComplianceStatus.pending) {
        return CollateralMappingStatus.actionReview;
    } else if (collateralEntity?.mapping?.mappingType === CollateralMappingType.manual) {
        return CollateralMappingStatus.manual;
    }
    return CollateralMappingStatus.auto;
}
