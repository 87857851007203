<template>
    <div class="CoverageCriteriaGroupSaveButton" :class="{'CoverageCriteriaGroupSaveButton--disabled': disabled}">
        <!--TODO(PRODUCT-17631): replace Button with TODO that has save active and discovery mode when ready -->
        <Button class="CoverageCriteriaGroupSaveButton__saveButton"
            type="primary"
            :class="{'CoverageCriteriaGroupSaveButton__saveButton--opened':opened}"
            :disabled="disabled"
            @click="$emit('saveActive')"
        >
            Save
        </Button>
        <Todo>
            <Expandable :disabled="disabled" legacy-mode @open="open" @close="close">
                <template v-slot:button>
                    <Button class="CoverageCriteriaGroupSaveButton__saveButton"
                        :class="{'CoverageCriteriaGroupSaveButton__saveButton--opened':opened}"
                        type="primary"
                    >
                        <span>Save</span>
                        <FontAwesomeIcon class="CoverageCriteriaGroupSaveButton__buttonIcon" :icon="icon" />
                    </Button>
                </template>
                <Menu spaced>
                    <MenuLink
                        @click="$emit('saveActive')"
                    >
                        <template v-slot:label>
                            <div class="CoverageCriteriaGroupSaveButton__menuLink">
                                <div class="CoverageCriteriaGroupSaveButton__menuTitle">
                                    Save and Activate
                                </div>
                                <div class="CoverageCriteriaGroupSaveButton__menuBody">
                                    <div>Activate decisioning for this group.</div>
                                    <div>You will be prompted to confirm your changes</div>
                                </div>
                            </div>
                        </template>
                    </MenuLink>
                    <MenuLink
                        @click="$emit('saveDiscover')"
                    >
                        <template v-slot:label>
                            <div class="CoverageCriteriaGroupSaveButton__menuLink">
                                <div class="CoverageCriteriaGroupSaveButton__menuTitle">Discover Mode</div>
                                <div class="CoverageCriteriaGroupSaveButton__menuBody">
                                    <div>Save your changes before making them active.</div>
                                </div>
                            </div>
                        </template>
                    </MenuLink>
                </Menu>
            </Expandable>
        </Todo>
    </div>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faCaretDown, faCaretUp, IconDefinition } from '@fortawesome/free-solid-svg-icons';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { Expandable } from '@evidentid/dashboard-commons/components/Expandable';
    import { Menu, MenuLink } from '@evidentid/dashboard-commons/components/Menu';
    import { Todo } from '@evidentid/dashboard-commons/components/Todo';

    @Component({
        components: { Button, Expandable, Menu, MenuLink, FontAwesomeIcon, FormInput, FormElement, Todo },
    })
    export default class CoverageCriteriaGroupSaveButton extends Vue {
        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        private opened: boolean = false;

        private get icon(): IconDefinition {
            return this.opened ? faCaretUp : faCaretDown;
        }

        private open(): void {
            this.opened = true;
        }

        private close(): void {
            this.opened = false;
        }
    }
</script>
