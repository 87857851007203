import { InsuranceCoverageModel, InsuranceInsuredCoverage } from '@evidentid/rpweb-api-client/types';
import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
import { InsuredMultiFieldCriterion } from '@/modules/insured-details/types';
import { CategorizedEnumLabels } from '@/modules/dashboard/models/CategorizedEnumLabels.model';
import {
    getCoverageDetailsKeysValueWithErrors,
} from '@/modules/insured-details/utils/getCoverageDetailsKeysValueWithErrors';
import { isEmptyValue } from '@/modules/insured-details/components/Policy/sub-components/PolicyInfo/utils';
import { getEnumDefaultDisplayValue } from '@/utils/getEnumDefaultDisplayValue';
import {
    InsuranceCoverageObjectType,
} from '@evidentid/insurance-facing-lib/models/insured-details';
import { getCoverageAddressObjectString } from '@/modules/insured-details/utils/getCoverageAddressObjectString';

function parseToSingleStringIfAddress(value: unknown, enums: CategorizedEnumLabels): unknown | string {
    const addressTypes = [
        InsuranceCoverageObjectType.address,
        InsuranceCoverageObjectType.addressV2,
        InsuranceCoverageObjectType.coverageFieldAddress,
    ];
    if (typeof value === 'object' &&
        value &&
        '$objectType' in value &&
        addressTypes.includes((value as any).$objectType)
    ) {
        return getCoverageAddressObjectString(value as Record<string, any>, enums.countryCode);
    } else {
        return value;
    }
}

export function parseFieldValuePerEnumAndError(
    fieldName: string,
    fieldValue: any,
    otherData: {
        coverage: InsuranceInsuredCoverage;
        coverageModel?: InsuranceCoverageModel;
        evaluationErrors: Record<string, FieldEvaluationResultError>;
        multiFieldCriteria: InsuredMultiFieldCriterion[];
        categorizedEnumLabels: CategorizedEnumLabels;
    }) {
    let resultKeyValue = getCoverageDetailsKeysValueWithErrors({
        key: fieldName,
        value: fieldValue,
        coverage: otherData.coverage,
        evaluationErrors: otherData.evaluationErrors,
        crossCoverageCriteria: otherData.multiFieldCriteria,
    });
    resultKeyValue = parseToSingleStringIfAddress(resultKeyValue, otherData.categorizedEnumLabels);
    if (!isEmptyValue(resultKeyValue)) {
        return getEnumDefaultDisplayValue(
            fieldName, resultKeyValue, otherData.categorizedEnumLabels, otherData.coverageModel
        );
    }
    return fieldValue;
}
