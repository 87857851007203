<template>
    <CollateralMappingModal
        v-if="mappingModalOpened && !unsavedDataModalOpened"
        :additional-class-name="additionalClasses"
        :progress="loading"
        @input="save"
        @close="closingMappingModal"
    >
        <div v-if="alertConfig">
            <Alert :type="alertConfig.type">
                <FontAwesomeIcon :icon="faExclamationTriangle" />
                <div class="Alert__description">
                    {{ alertConfig.title }}
                </div>
            </Alert>
        </div>
        <CollateralMappingTable
            :collateral-entities="collateralsCopy"
            :collateral-mapping-options="collateralMappingOptionsStatus.list"
            :no-match-list="noMatchList"
            @show-coi="showCoi"
            @update-collaterals="updateCollaterals"
            @update-no-match="updateNoMatch"
        />
    </CollateralMappingModal>
    <DashboardConfirmationModal
        v-else-if="unsavedDataModalOpened"
        additional-class-name="CollateralMappingUnsavedModal"
        header-text="Want to save your changes?"
        yes="Save"
        no="Discard"
        :yes-icon="null"
        :no-icon="null"
        @input="confirmUnSaveData"
    >
        Some changed values could not be validated against the field requirements. Any changes you've made will be
        discarded if you chose not to save them.
    </DashboardConfirmationModal>
</template>

<script lang="ts">
    import { Vue } from 'vue-property-decorator';
    import { PropType } from 'vue';
    import { cloneDeep, isEqual } from 'lodash';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
    import { CollateralEntity } from '@evidentid/rpweb-api-client/models/CollateralEntity.model';
    import CollateralMappingTable
        from '@/modules/insured-details/components/CollateralMappingTable/CollateralMappingTable.vue';
    import CollateralMappingModal
        from '@/modules/insured-details/components/CollateralMappingModal/CollateralMappingModal.vue';
    import { getEnumDefaultDisplayValue } from '@/utils/getEnumDefaultDisplayValue';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { InsuranceCoverageType } from '@evidentid/insurance-facing-lib/models/insured-details';
    import { CollateralMappingType } from '@evidentid/rpweb-api-client/models/CollateralMappingType.model';
    import {
        isCollateralMappingHasNoMatch,
    } from '@/modules/insured-details/utils/isCollateralMappingHasNoMatch/isCollateralMappingHasNoMatch';
    import DashboardConfirmationModal
        from '@/modules/dashboard/components/DashboardConfirmationModal/DashboardConfirmationModal.vue';

    import { CollateralMappingOptions } from '@evidentid/rpweb-api-client/models/CollateralMappingOptions.model';
    import { Alert } from '@evidentid/dashboard-commons/components/Alert';
    import { AlertConfig } from '@evidentid/dashboard-commons/components/Alert/types';
    import { CategorizedEnumLabels } from '@/modules/dashboard/models/CategorizedEnumLabels.model';
    import { EnumCategories } from '@/modules/dashboard/models/EnumCategories.model';

    const componentName = 'CollateralMappingProcedure';
    export default Vue.extend({
        name: componentName,
        components: {
            Alert,
            CollateralMappingModal,
            CollateralMappingTable,
            DashboardConfirmationModal,
            FontAwesomeIcon,
        },
        props: {
            collaterals: {
                type: Array as PropType<CollateralEntity[]>,
                required: true,
                default: () => [],
            },
            insuredContactEmail: {
                type: String as PropType<string>,
                default: '',
            },
            insuredId: {
                type: String as PropType<string>,
                default: '',
            },
        },
        data() {
            return {
                faExclamationTriangle,
                showingCoi: false,
                mappingModalOpened: true,
                unsavedDataModalOpened: false,
                // keeps records of no matched collateral entities id that already existed in db
                initialNoMatchList: [] as string[],
                // initially the same as initialNoMatchList, but also keep tracks on current session noMatch selections
                // the reason to have two lists is to not lose the initial state so that we know when to we actually
                // have no match selection made from current modal session rather than previous saved state.
                noMatchList: [] as string[],
                collateralsCopy: [] as CollateralEntity[],
                alertConfig: null as AlertConfig | null,
            };
        },
        computed: {
            loadingInsuredCoverageCriteriaGroups(): boolean {
                return this.store.state.decisioningCriteria
                    .insuredCoverageCriteriaGroups[this.rpName][this.insuredId].status === OperationStatus.loading;
            },
            loadingCollateralEntities(): boolean {
                return this.store.state.insuredDetails
                    .collateralEntitiesStatus[this.rpName].status === OperationStatus.loading;
            },
            loadingInsuredDetails(): boolean {
                return this.store.state.insuredDetails
                    .coverageDetailsListStatus[this.rpName].status === OperationStatus.loading;
            },
            loadingCollateralMappingOptions(): boolean {
                return this.collateralMappingOptionsStatus.status === OperationStatus.loading;
            },
            patchingCollateralEntity(): boolean {
                return this.patchCollateralsStatus === OperationStatus.loading;
            },
            loading(): boolean {
                return this.loadingCollateralMappingOptions ||
                    this.loadingInsuredCoverageCriteriaGroups ||
                    this.loadingCollateralEntities ||
                    this.loadingInsuredDetails ||
                    this.patchingCollateralEntity;
            },
            rpName(): string {
                // TODO(PRODUCT-18675): remove any after the migration to the newer version. apply to whole file
                return (this as any).$rp.current!;
            },
            store(): any {
                // TODO(PRODUCT-18675): remove any after the migration to the newer version. apply to whole file
                return (this as any).$store;
            },
            categorizedEnumLabels(): CategorizedEnumLabels {
                return this.store.state.dashboard.categorizedEnumLabels;
            },
            collateralMappingOptionsStatus(): { status: OperationStatus, list: CollateralMappingOptions[] } {
                return this.store.state.insuredDetails.collateralMappingOptionsStatus[this.rpName] || {
                    status: OperationStatus.uninitialized,
                    list: [],
                };
            },
            patchCollateralsStatus(): OperationStatus {
                return this.store.state.insuredDetails.patchCollateralStatus[this.rpName] ||
                    OperationStatus.uninitialized;
            },
            additionalClasses(): string {
                const hide = this.showingCoi ? ' invisible' : '';
                return `${componentName}${hide}`;
            },
        },
        watch: {
            collaterals: {
                immediate: true,
                handler() {
                    this.collateralsCopy = cloneDeep(this.collaterals);
                    this.initialNoMatchList = this.collateralsCopy
                        .filter(isCollateralMappingHasNoMatch)
                        .map((collateral) => collateral.evaluatedEntityId);
                    this.noMatchList = [ ...this.initialNoMatchList ];
                },
            },
        },
        mounted() {
            this.store.actions.insuredDetails.loadCollateralMappingOptions({
                rpName: this.rpName,
                insuredId: this.insuredId || '',
            });
        },
        destroyed(): void {
            this.store.actions.insuredDetails.clearCollateralMappingOptions({ rpName: this.rpName });
        },
        methods: {
            async save(): Promise<void> {
                this.alertConfig = null;
                const collateralsToPatch = this.collateralsCopy.map(this.getCollateralsNeedPatch).filter(Boolean);
                if (collateralsToPatch.length === 0) {
                    this.close();
                } else {
                    await this.patchCollaterals(collateralsToPatch as CollateralEntity[]);
                    if (this.patchCollateralsStatus === OperationStatus.error) {
                        this.alertConfig = {
                            title: 'Something went wrong. Please try again.',
                            type: 'danger',
                        };
                        return;
                    }
                    await this.reloadInsuredDetailsData();
                    this.$emit('finish');
                }
            },
            close(): void {
                this.$emit('abort');
                this.mappingModalOpened = false;
            },
            showCoi(coverageType: InsuranceCoverageType): void {
                this.showingCoi = true;
                // TODO(PRODUCT-18675): remove any after the migration to the newer version. apply to whole file
                (this as any).$procedures.execute('showInsuredCoverageRprDocuments', {
                    rpName: this.rpName,
                    email: this.insuredContactEmail || '',
                    coverageType,
                    coverageTypeLabel: getEnumDefaultDisplayValue(
                        EnumCategories.coverageTypes,
                        coverageType,
                        this.categorizedEnumLabels,
                    ),
                }, this.onCoiClosed);
            },
            onCoiClosed(): void {
                this.showingCoi = false;
            },
            updateCollaterals(collaterals: CollateralEntity[]): void {
                this.collateralsCopy = [ ...collaterals ];
            },
            updateNoMatch(evaluatedEntityId: string): void {
                this.noMatchList = this.noMatchList.includes(evaluatedEntityId)
                    ? this.noMatchList.filter((x) => x !== evaluatedEntityId)
                    : [ ...this.noMatchList, evaluatedEntityId ];
            },
            getCollateralsNeedPatch(
                collateralEntity: CollateralEntity,
                originalCollateralIndex: number,
            ): CollateralEntity | null {
                const currentlyNoMatch = this.noMatchList.includes(collateralEntity.evaluatedEntityId);
                const mappingDirtied =
                    !isEqual(collateralEntity.mapping, this.collaterals[originalCollateralIndex].mapping);
                const noMatchOnLoad = this.initialNoMatchList.includes(collateralEntity.evaluatedEntityId);
                const noMatchSetDuringModalSession = !noMatchOnLoad && currentlyNoMatch;
                const needsPatch = mappingDirtied || noMatchSetDuringModalSession;
                const newCollateralMappingOption = noMatchSetDuringModalSession
                    ? null
                    : collateralEntity.mapping?.collateral || null;
                const mapping = !currentlyNoMatch && collateralEntity.mapping === null
                    ? null
                    : {
                        mappingType: CollateralMappingType.manual,
                        collateral: newCollateralMappingOption,
                    };
                return needsPatch ? { ...collateralEntity, mapping } : null;
            },
            async patchCollaterals(collateralsToPatch: CollateralEntity[]): Promise<void> {
                const collateralPatchObjects = collateralsToPatch.map(
                    (collateral) => ({
                        evaluatedEntityId: collateral.evaluatedEntityId,
                        mapping: collateral.mapping,
                    }),
                );
                await this.store.actions.insuredDetails.patchCollaterals(
                    { rpName: this.rpName, insuredId: this.insuredId, collateralPatchObjects },
                );
            },
            async reloadInsuredDetailsData(): Promise<void> {
                await Promise.all([
                    this.store.actions.dashboard.loadInsured({ rpName: this.rpName, id: this.insuredId }),
                    this.store.actions.insuredDetails.loadCoverageDetailsList(
                        { rpName: this.rpName, insuredId: this.insuredId },
                    ),
                    this.store.actions.insuredDetails.loadCollateralEntities(
                        { rpName: this.rpName, insuredId: this.insuredId },
                    ),
                    this.store.actions.decisioningCriteria.loadInsuredCoverageCriteriaGroups({
                        rpName: this.rpName,
                        insuredId: this.insuredId,
                    }),
                ]);
            },
            closingMappingModal(): void {
                const collateralsToPatch = this.collateralsCopy.map(this.getCollateralsNeedPatch).filter(Boolean);
                if (collateralsToPatch.length > 0) {
                    this.unsavedDataModalOpened = true;
                    this.mappingModalOpened = false;
                } else {
                    this.close();
                }
            },
            confirmUnSaveData(confirmed: boolean): void {
                if (!confirmed) {
                    this.close();
                }
                this.unsavedDataModalOpened = false;
                this.mappingModalOpened = true;
            },
        },
    });
</script>
