import {
    InsuranceCoverageCriterion,
    InsuranceCoverageCriterionInput,
    InsuranceCoverageCriterionTemplate,
} from '@evidentid/rpweb-api-client/types';

interface Params {
    template: InsuranceCoverageCriterionTemplate;
    criterion: InsuranceCoverageCriterionInput | InsuranceCoverageCriterion;
}

export function getFlagDisabledReferenceValueSchema({ template, criterion }: Params) {
    const conditionalRefs = template.displayMetadata.conditionalValueReference;

    if (!conditionalRefs) {
        return null;
    }

    const schema = criterion.evaluator.references[conditionalRefs.flagDisabledReference]?.schema;
    return schema;
}
