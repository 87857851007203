<template>
    <JsonSchemaPhoneView v-if="form.schema.format === 'phone'" v-bind="$props" v-on="$listeners" />
    <JsonSchemaTextView v-else v-bind="$props" v-on="$listeners" />
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import { JsonSchemaString } from '@evidentid/json-schema/interfaces/JsonSchema';
    import JsonSchemaTextView from './JsonSchemaTextView.vue';
    import JsonSchemaPhoneView from './JsonSchemaPhoneView.vue';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import AbstractJsonSchemaView
        from '@/modules/insured-details/components/JsonSchemaView/elements/AbstractJsonSchemaView';

    @Component({
        components: { JsonSchemaPhoneView, JsonSchemaTextView },
        inheritAttrs: false,
    })
    export default class JsonSchemaStringView extends AbstractJsonSchemaView<JsonFormBase<JsonSchemaString>, string> {
    }
</script>
