import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
import { VerificationRequestType } from '@evidentid/rpweb-api-client/types';
import { NotificationName, RpFulfillmentConfig, SchedulePatternType } from '@evidentid/config-api-client/types';
import { DayOfWeek } from '@evidentid/dashboard-commons/components/DayOfWeekInput/types';

export enum TimeUnit {
    hours = 'hours',
    days = 'days',
    weeks = 'weeks',
}

export interface TimePhrase {
    amount: number;
    unit: TimeUnit;
}

export interface SchedulePattern {
    weekNumber: number | null;
    day: number;
    hour: number;
    minute: number;
}

export interface EmailTemplate {
    summaryTemplate: string;
    descriptionTemplate: string;
    initEnabled: boolean;
    remindersEnabled: boolean;
}

export interface BrandingConfiguration {
    displayName: string;
    wordmarkUrl: string;
    /** Hexadecimal format **/
    primaryColor: string;
    /** Hexadecimal format **/
    secondaryColor: string;
}

export interface EmailConfiguration {
    fromName: string;
    replyToName: string;
    replyToEmail: string;
    /** URL format **/
    coiRequirementsLink: string | null;
    /** HTML format **/
    certificateHolderAddress: string | null;
    legacyFulfillmentUrl: string | null;
    fulfillmentConfiguration: RpFulfillmentConfig;
}

export interface SupportContactConfiguration {
    supportEmail: string | null;
    supportPhone: string | null;
    supportUrl: string | null;
    supportUrlTitle: string | null;
    supportDaysOfWeek: DayOfWeek[] | null;
    supportStartTime: string | null;
    supportEndTime: string | null;
    supportTimezone: string | null;
}

export type NotificationsConfiguration = (
    BrandingConfiguration &
    EmailConfiguration &
    SupportContactConfiguration
    );

export interface NotificationCadence {
    recurrence: SchedulePatternType;
    limit: number;
    // Interval
    interval?: { amount: number, unit: TimeUnit };
    // Schedule / Pattern
    daysOfWeek?: number[];
    sendsAt?: number[];
    timezone?: string;
    // Sequence / Cadence
    daysBeforeCount?: number;
    daysBefore?: number[];
    daysAfter?: number[];
    initEnabled: boolean;
    remindersEnabled: boolean;
}

export interface NotificationsConfigurationState {
    settings: Record<string, NotificationsConfiguration>;
    loadSettings: Record<string, OperationStatus>;
    updateSettings: Record<string, OperationStatus>;
    emailTemplates: Record<string, Record<VerificationRequestType, EmailTemplate>>;
    loadEmailTemplates: Record<string, OperationStatus>;
    updateEmailTemplates: Record<string, Record<VerificationRequestType, OperationStatus>>;
    loadInsuredFields: Record<string, OperationStatus>;
    insuredFields: Record<string, string[]>;
    cadences: Record<string, Record<NotificationName, NotificationCadence>>;
    loadCadences: Record<string, Record<NotificationName, OperationStatus>>;
    updateCadences: Record<string, Record<NotificationName, OperationStatus>>;
    sendTestEmailStatus: Record<string, OperationStatus>;
}
