<template>
    <div class="NoInsuredsFound">
        <img :src="noResultsImage" alt="no insureds found">
        <strong>No Matching Search results</strong>
        <span>Try again using other filter combinations</span>
        <Button type="primary" @click="$emit('reset')">Clear search</Button>
    </div>
</template>

<style lang="scss">
    .NoInsuredsFound {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #778ea1;

        strong {
            font-size: 16px;
        }

        span {
            font-size: 14px;
        }

        img {
            margin-bottom: 15px;
        }

        .Button {
            margin-top: 20px;
            background-color: #778ea1;
        }
    }
</style>

<script lang="ts">
    import { Component, Vue } from '@evidentid/vue-property-decorator';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import noResultsImage from '@/modules/insured-filtering/assets/insuredFiltering-image-noResults.svg';

    @Component({
        components: { Button },
    })
    export default class NoInsuredsFound extends Vue {
        private noResultsImage = noResultsImage;
    }
</script>
