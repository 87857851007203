<template>
    <Badge
        v-if="label || $slots.default"
        class="VerificationStatusBadge"
        :class="[{'VerificationStatusBadge--borderless': isBorderless}, `VerificationStatusBadge--${status}`]"
    >
        <slot>
            <EidIcon v-if="hasEvidentIcon" class="mr-2" :icon-src="evidentIcon" :svg-props="{ width: '15px', height:'15px' }" />
            {{ label }}
        </slot>
    </Badge>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import transform from 'lodash/transform';
    import { Badge } from '@evidentid/dashboard-commons/components/Badge';
    import {
        InsuranceVerificationStatus,
        InsuranceVerificationStatusLabel,
    } from '@evidentid/rpweb-api-client/types';
    import evidentIcon from '@evidentid/assets-bridge/evident-icon.svg';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';

    const labels = transform(InsuranceVerificationStatus, (result, val, key) => {
        result[val] = (InsuranceVerificationStatusLabel as any)[key];
    }, {} as Record<InsuranceVerificationStatus, InsuranceVerificationStatusLabel>);

    const statusWithBorder = [
        InsuranceVerificationStatus.exhausted,
        InsuranceVerificationStatus.noInsuredResponse,
        InsuranceVerificationStatus.actionsReview,
        InsuranceVerificationStatus.producerOutreach,
        InsuranceVerificationStatus.memberOutreach,
    ];

    const statusWithEvidentIcon = [
        InsuranceVerificationStatus.producerOutreach,
        InsuranceVerificationStatus.memberOutreach,
    ];

    @Component({
        components: { Badge, EidIcon },
    })
    export default class VerificationStatusBadge extends Vue {
        @Prop({ type: String, default: InsuranceVerificationStatus.complete })
        private status!: InsuranceVerificationStatus;

        public evidentIcon = evidentIcon;

        private get label(): string | null {
            return labels[this.status] || null;
        }

        private get isBorderless(): boolean {
            return !statusWithBorder.includes(this.status);
        }

        private get hasEvidentIcon(): boolean {
            return statusWithEvidentIcon.includes(this.status);
        }
    }
</script>
