<template>
    <Row
        class="WebhooksTableRow"
        :class="{
            'WebhooksTableRow--focus': isAnyFieldFocused,
            'WebhooksTableRow--readOnly': !isEditing
        }"
    >
        <Cell data-test-id="WebhooksTableRow__eventCell" class="w-2/5">
            <JsonSchemaFormElement
                v-if="isEditing"
                v-model="localWebhook.hookTypeId"
                :form="fields.hookTypeId.form"
                required
                @focus="onFocusField(true)"
                @blur="onFocusField(false)"
            />
            <div v-else>
                {{ hookTypeLabel }}
            </div>
        </Cell>
        <Cell data-test-id="WebhooksTableRow__urlCell" class="w-2/5">
            <JsonSchemaFormElement
                v-if="isEditing"
                v-model="localWebhook.url"
                :form="fields.url.form"
                required
                @focus="onFocusField(true)"
                @blur="onFocusField(false)"
            />
            <div v-else>
                {{ localWebhook.url }}
            </div>
        </Cell>
        <WebhooksTableActionCell
            :is-editing="isEditing"
            :is-valid="isValidWebhook"
            :updating="updating"
            @delete="$emit('delete')"
            @edit="$emit('edit', true)"
            @confirm-edit="confirmEditWebhook"
        />
    </Row>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
    import { JsonFormProperty } from '@evidentid/json-schema/interfaces/JsonForm';
    import createForm from '@evidentid/json-schema/createForm';
    import { Row, Cell } from '@evidentid/dashboard-commons/components/Table';
    import { JsonSchemaFormElement } from '@evidentid/dashboard-commons/components/JsonSchemaForm';
    import binIcon from '@/assets/icons/bin-icon.svg';
    import pencilIcon from '@/assets/icons/icon-pencil-hollow.svg';
    import { tailwindColors } from '@/styles/variables/tailwind-colors/tailwindColors';
    import { InsuranceWebhook } from '@evidentid/insurance-facing-lib/models/api-settings';
    import { JsonSchemaEnum, JsonSchemaObject } from '@evidentid/json-schema/interfaces/JsonSchema';
    import WebhooksTableActionCell
        from '@/modules/api-settings/components/webhooks-table-action-cell/WebhooksTableActionCell.vue';

    export default Vue.extend({
        name: 'WebhooksTableRow',
        components: {
            WebhooksTableActionCell,
            JsonSchemaFormElement,
            Cell,
            Row,
        },
        props: {
            webhook: {
                type: Object as PropType<InsuranceWebhook>,
                default: () => ({}) as InsuranceWebhook,
            },
            schema: {
                type: Object as PropType<JsonSchemaObject>,
                required: true,
            },
            isEditing: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            hideActions: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            updating: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },
        data() {
            const form = createForm(this.schema);
            const newWebhookData = form.getValue(undefined, true);
            return {
                binIcon,
                faCheck,
                faTimes,
                pencilIcon,
                form,
                newWebhookData,
                isAnyFieldFocused: false,
                localWebhook: {} as InsuranceWebhook,
                trustGreen: tailwindColors.eidTrustGreen.DEFAULT,
            };
        },
        computed: {
            fields(): Record<string, JsonFormProperty> {
                const properties = this.form.getProperties();
                return properties.reduce((acc, property) => {
                    acc[property.name] = property;
                    return acc;
                }, {} as Record<string, JsonFormProperty>);
            },
            isValidWebhook(): boolean {
                return this.form?.isValid(this.form?.getValue(this.localWebhook, true), true);
            },
            hookTypeLabel(): string {
                if (!this.form) {
                    return this.localWebhook.hookTypeId;
                }
                const hookTypeEnum = (this.form.schema.properties.hookTypeId as JsonSchemaEnum).enum;
                const hookTypeEnumLabels = (this.form.schema.properties.hookTypeId as JsonSchemaEnum)?.enumLabels;
                return hookTypeEnumLabels?.[hookTypeEnum.indexOf(this.localWebhook.hookTypeId)] ||
                    this.localWebhook.hookTypeId;
            },
        },
        watch: {
            webhook: {
                immediate: true,
                handler(newValue?: boolean) {
                    if (newValue) {
                        this.localWebhook = { ...this.webhook };
                    }
                },
            },
        },
        methods: {
            onFocusField(focused: boolean): void {
                this.isAnyFieldFocused = focused;
            },
            confirmEditWebhook(confirmed: boolean): void {
                if (confirmed && this.isValidWebhook) {
                    this.$emit('change', this.localWebhook);
                    this.$emit('edit', false);
                } else if (!confirmed) {
                    this.$emit('cancel-edit');
                    this.$emit('edit', false);
                }
            },
        },
    });
</script>
