import { flatten, range, sample, omitBy } from 'lodash';
import moment, { MomentSetObject } from 'moment';
import { InsuranceInsuredField } from '@evidentid/rpweb-api-client/types';
import { downloadFileUsingBinString } from '@evidentid/file-utils/blobs';
import { buildCsv } from '@evidentid/universal-framework/csv';
import { BaseInsuredInput } from '@/modules/insured-management/types';
import JsonSchema, {
    JsonSchemaArray,
    JsonSchemaBase,
    JsonSchemaString,
} from '@evidentid/json-schema/interfaces/JsonSchema';
import { isArray, isString } from '@evidentid/json-schema/schemaChecks';
import { isCollateralInsuredField } from '@/utils/isCollateralInsuredField';
import { InsuredFieldAddress } from '@evidentid/insurance-facing-lib/models/dashboard';
import { isAddressInsuredField } from '@/utils/is-address-insured-field/isAddressInsuredField';
import {
    getAddressInsuredFieldPropOrder,
} from '@/utils/get-address-insured-field-props-order/getAddressInsuredFieldPropOrder';
import {
    getFlattenedInsuredFieldNames,
} from '@/modules/insured-management/utils/get-flattened-insured-field-names/getFlattenedInsuredFieldNames';

interface SampleInsured {
    displayName: string;
    domain: string;
    legalName?: string;
    doingBusinessAs?: string;
}

interface SampleContact {
    name: string;
    emailPrefix: string;
}

interface CollateralSample {
    description: string[];
    category: string[];
    uniqueIdentifier: string[];
    limitRequired: number[];
    maximumDeductible: number[];
}

interface SpecialSampleType {
    collaterals: CollateralSample;
    address: InsuredFieldAddress[];
}

interface SampleValues {
    primitives: Record<string, string[]>;
    arrays: Record<string, string[]>;
    specialTypes: Record<string, string>;
}

const arrayMaxLength = 5;
const dateFormat = 'MM/DD/YYYY';

const insuredNames: SampleInsured[] = [
    {
        displayName: 'Example Corp.',
        legalName: 'Example Corporation LLC',
        doingBusinessAs: 'The Example Group|Examples Incorporated|ExCorp',
        domain: '@example.com',
    },
    { displayName: 'Foo LLC', legalName: 'Foo Enterprises International Ltd', domain: '@foo.com' },
    { displayName: 'Company, Inc.', domain: '@company.com' },
];

const contactNames: SampleContact[] = [
    { name: 'Mary Espinoza', emailPrefix: 'mary' },
    { name: 'John Smith', emailPrefix: 'john.smith' },
    { name: 'Anna Faber', emailPrefix: 'annaf' },
    { name: 'Jimmie Douglas', emailPrefix: 'jimmie.n.douglas' },
    { name: 'Heather Johnson', emailPrefix: 'heather.johnson' },
];

const contactPhoneNumber: string[] = [
    '(559) 221-1838',
    '(719) 729-2449',
    '(984) 202-7721',
    '(586) 223-7450',
];

const expirationDates: string[] = [
    moment().add(1, 'year').format(dateFormat),
    moment().set({ month: '11', date: '31' } as unknown as MomentSetObject).format(dateFormat),
    moment().add(1, 'year').set({ month: '4', date: '15' } as unknown as MomentSetObject).format(dateFormat),
    moment().add(1, 'year').set({ month: '11', date: '31' } as unknown as MomentSetObject).format(dateFormat),
];

const strings: string[] = [
    'Lorem ipsum',
    'Aenean',
    'Vestibulum',
    'Nullam efficitur',
    'Praesent vulputate nibh',
];

const integers: string[] = [
    '1',
    '7',
    '10',
];

const numbers: string[] = [
    '3.5',
    '7.8',
];

const booleans: string[] = [
    'Yes',
    'No',
];

const standardColumnNames: string[] = [
    'Display Name',
    'Legal Name',
    'DBA Name(s)',
    'Primary Contact Email',
    'Primary Contact Name',
    'Primary Contact Phone Number',
];
const specialTypeSamplePool: SpecialSampleType = {
    collaterals: {
        description: [ 'Aquaeductus', 'Aratrum', 'Horreum', 'Plaustrum', 'Villae' ],
        category: [ ...strings ],
        uniqueIdentifier: [ '123456789', '1FVHG3DX3DHFE3747', 'CDEF-123', '7890-123-456' ],
        limitRequired: [ 15000, 50000, 150000, 275000 ],
        maximumDeductible: [ 150, 500, 1500, 2750 ],
    },
    address: [
        { street: '789 Oak Drive', city: 'Smallville', state: 'TX', zipCode: '98765', country: 'US' },
        { street: '321 Pine Lane', city: 'Rivertown', state: 'FL', zipCode: '56789', country: 'US' },
        { street: '987 Maple Street', city: 'Lakeside', state: 'GA', zipCode: '34567', country: 'US' },
        { street: '654 Birch Road', city: 'Mountainville', state: 'PA', zipCode: '76543', country: 'US' },
        { street: '321 Cedar Court', city: 'Seaside', state: 'WA', zipCode: '23456', country: 'US' },
        { street: '1234 First Street', city: 'Toronto', state: 'ON', zipCode: 'M1R 2T3', country: 'CA' },
        { street: '5678 Second Avenue', city: 'Vancouver', state: 'BC', zipCode: 'V6B 1A5', country: 'CA' },
        { street: '9012 Third Boulevard', city: 'Montreal', state: 'QC', zipCode: 'H3Z 2Y7', country: 'CA' },
    ],
};

function rollRandomOptional(): boolean {
    return Math.floor(Math.random() * 2) === 0;
}

function getOptionalData(data: string | number | undefined, required?: boolean): string | number {
    return required || rollRandomOptional()
        ? data || ''
        : '';
}

function getItemType(schema: JsonSchemaArray): string {
    return isString(schema.items as JsonSchema)
        ? (schema.items as JsonSchemaString).format || (schema.items as JsonSchemaString).type
        : (schema.items as JsonSchemaBase).type;
}

function generateSampleValue(field: InsuranceInsuredField, values: SampleValues): string {
    if (isCollateralInsuredField(field)) {
        return getOptionalData(values.specialTypes.collaterals || '') as string;
    } else {
        return sample(isArray(field.schema)
            ? values.arrays[getItemType(field.schema)]
            : values.primitives[(field.schema as JsonSchemaBase).type] || [],
        ) || '';
    }
}

function generateSampleEmails(domain: string): string[] {
    return contactNames.map((contact) => `${contact.emailPrefix}${domain}`);
}

function generateSampleArray(itemType: string, primitiveTypes: Record<string, string[]>): string {
    return [ ...range(1, Math.random() * arrayMaxLength + 1) ]
        .map(() => sample(primitiveTypes[itemType] || []))
        .join('|');
}

function generateSampleCollaterals(): string {
    const sampling = () => JSON.stringify(
        omitBy({
            description: sample(specialTypeSamplePool.collaterals.description),
            category: sample(specialTypeSamplePool.collaterals.category),
            limitRequired: sample(specialTypeSamplePool.collaterals.limitRequired),
            uniqueIdentifier: getOptionalData(sample(specialTypeSamplePool.collaterals.uniqueIdentifier)),
            maximumDeductible: getOptionalData(sample(specialTypeSamplePool.collaterals.maximumDeductible)),
        }, (v) => v == null || v === ''),
    );
    const maxSampleSize = 3;
    return [ ...range(1, Math.random() * maxSampleSize + 1) ]
        .map(sampling)
        .join('|');
}

function generateSampleAddress(): string {
    const sampleAddress = sample(specialTypeSamplePool.address);
    return JSON.stringify(
        omitBy(sampleAddress, (v) => v == null || v === ''),
    );
}

function generateSampleValues(insured: SampleInsured): SampleValues {
    // as of right now this function does not need to be recursive, as insured field types are statically limited
    const samplePrimitives = {
        string: strings,
        integer: integers,
        number: numbers,
        boolean: booleans,
        email: generateSampleEmails(insured.domain || 'example.com'),
    };

    const sampleArrays = {
        string: range(0, 5).map(() => generateSampleArray('string', samplePrimitives)),
        email: range(0, 5).map(() => generateSampleArray('email', samplePrimitives)),
    };

    return {
        primitives: samplePrimitives,
        arrays: sampleArrays,
        specialTypes: {
            collaterals: generateSampleCollaterals(),
            address: generateSampleAddress(),
        },
    };
}

function generateSampleRow(fields: InsuranceInsuredField[], coverageTypes: string[] = []): (string | number)[] {
    const insured = sample(insuredNames);
    const contact = sample(contactNames);
    const phoneNumber = sample(contactPhoneNumber);

    const values = generateSampleValues(insured || { displayName: 'Example Corp.', domain: '@example.com' });
    const valueToStrings = (field: InsuranceInsuredField) => {
        if (isAddressInsuredField(field)) {
            const address = JSON.parse(values.specialTypes.address) as InsuredFieldAddress;
            const showData = field.required || rollRandomOptional();
            return getAddressInsuredFieldPropOrder()
                .map((key) => showData && address[key as keyof InsuredFieldAddress] || '');
        } else {
            return [ getOptionalData(generateSampleValue(field, values), field.required) ];
        }
    };
    return [
        insured?.displayName || 'Example Corp.',
        insured?.legalName || '',
        insured?.doingBusinessAs || '',
        contact && insured ? `${contact.emailPrefix}${insured.domain}` : 'mary@example.com',
        contact?.name || '',
        phoneNumber || '',
        ...flatten(fields.map(valueToStrings)),
        ...flatten(coverageTypes.map(() => getOptionalData(sample(expirationDates)))),
    ];
}

function getCoverageExpirationColumnLabel(coverage: string): string {
    return `Existing ${coverage} Policy Expiration Date`;
}

export function generateSampleCsvData(insuredFields: InsuranceInsuredField[],
    coverageTypes: string[] = []): (string | number)[][] {
    return [
        [
            ...standardColumnNames,
            ...getFlattenedInsuredFieldNames(insuredFields),
            ...coverageTypes.map(getCoverageExpirationColumnLabel),
        ],
        ...range(0, 10).map(() => generateSampleRow(insuredFields, coverageTypes),
        ),
    ];
}

export function downloadSampleCsv(insuredFields: InsuranceInsuredField[], coverageTypes?: string[]) {
    const csv = generateSampleCsvData(insuredFields, coverageTypes);
    downloadFileUsingBinString(buildCsv(csv), 'Import Insured Sample.csv');
}

function containsCollateralsData(value: any): boolean {
    const containsCollateralData = (val: any) => typeof value === 'object' && (
        // limitRequired and maxDeductible are number(all value has chance to be used), don't consider as sample
        specialTypeSamplePool.collaterals.description.includes(val.description) ||
        specialTypeSamplePool.collaterals.category.includes(val.category) ||
        specialTypeSamplePool.collaterals.uniqueIdentifier.includes(val.uniqueIdentifier)
    );
    return Array.isArray(value) && value.some(containsCollateralData);
}

function hasSpecialTypeSampleData(value: any): boolean {
    return containsCollateralsData(value);
}

export function isSampleInsured(insured: BaseInsuredInput): boolean {
    const containsSampleEntity: boolean = insuredNames.some((sampleInsured) =>
        sampleInsured.displayName === insured.displayName?.trim() ||
        insured.contactEmail?.includes(`${sampleInsured.domain}`));
    const containsSamplePhoneNumber: boolean = contactPhoneNumber.some((number) =>
        `+1${number.replace(/\D/g, '')}` === insured.contactPhoneNumber);
    const containsSampleDomainInInsuredFields: boolean = insuredNames.some((sampleInsured) =>
        (insured.insuredFields
            ? Object.values(insured.insuredFields).some((value) =>
                typeof value === 'string' && value.includes(sampleInsured.domain))
            : false));
    const containsSampleStringInInsuredFields: boolean = strings.some((sampleString) =>
        (insured.insuredFields
            ? Object.values(insured.insuredFields).some((value) =>
                typeof value === 'string' && value.includes(sampleString))
            : false));
    const containsSpecialTypeInsuredFields: boolean = insured.insuredFields
        ? Object.values(insured.insuredFields).some(hasSpecialTypeSampleData)
        : false;
    return containsSampleEntity || containsSamplePhoneNumber || containsSampleDomainInInsuredFields
        || containsSampleStringInInsuredFields || containsSpecialTypeInsuredFields;
}
