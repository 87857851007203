import omit from 'lodash/omit';
import JsonSchema from '@evidentid/json-schema/interfaces/JsonSchema';
import { InsuranceInsuredField } from '@evidentid/rpweb-api-client/types';
import { filterInsuredFieldsBySchema } from '@/modules/decisioning-criteria/utils/filterInsuredFieldsBySchema';

export function convertInsuredFieldSchemaToEnumSchema(
    ifSchema: JsonSchema,
    insuredFields: InsuranceInsuredField[],
    schemaFilterBy?: JsonSchema | null,
): JsonSchema {
    const filteredInsureds = filterInsuredFieldsBySchema(insuredFields, schemaFilterBy);

    if (filteredInsureds.length === 0) {
        return ifSchema;
    }

    return {
        ...omit(ifSchema, [ 'format', 'type' ]),
        enum: filteredInsureds.map((field) => field.key),
        enumLabels: filteredInsureds.map((field) => field.name),
    };
}
