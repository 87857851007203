import { CoverageTypeCountrySpecificEnumLabel } from '@evidentid/rpweb-api-client/types';
import { InsuranceCoverageType } from '@evidentid/insurance-facing-lib/models/insured-details';
import { CategorizedEnumLabels } from '@/modules/dashboard/models/CategorizedEnumLabels.model';

export function getCoverageTypeLabelByCountry(
    coverageType: InsuranceCoverageType,
    countryCode: string | null,
    enumLabels: CategorizedEnumLabels
): string {
    const coverageTypeLabels = enumLabels.coverageTypes;
    const coverageTypeEnumLabel = coverageTypeLabels[coverageType] as CoverageTypeCountrySpecificEnumLabel;
    const labelPerCountry =
        countryCode && coverageTypeEnumLabel?.perCountryLabels && coverageTypeEnumLabel?.perCountryLabels[countryCode];
    return labelPerCountry || coverageTypeEnumLabel?.label || coverageType;
}
