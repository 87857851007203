<template>
    <div class="InsuredDetailsCollateralSection text-eidDarkBlue">
        <h2 class="inline-block text-eidSecureBlue">
            Collaterals
        </h2>
        <template v-if="!localCollaterals || localCollaterals.length === 0">
            <div
                data-test-id="InsuredDetailsCollateralSection__noCollateralsText"
                class="InsuredDetailsCollateralSection__emptyContainer"
            >
                You can now add collateral(s) to each insured. Once added, collateral can be evaluated for compliant
                status. You can also set up Decisioning Criteria around each collateral category.
            </div>
        </template>
        <template v-else>
            <Table>
                <template #head>
                    <HeadCell
                        v-for="{ name, label, tooltipData } in columns"
                        :key="name"
                        :data-test-id="`InsuredDetailsCollateralSection__${name}HeadCell`"
                        class="text-eidDarkBlue font-semibold"
                    >
                        <div v-if="label" class="flex">
                            {{ label }}
                            <EidIcon
                                v-if="tooltipData"
                                v-tooltip="tooltipData"
                                class="ml-2 text-eidQualityBlack-50"
                                alt="icon"
                                :icon-src="faQuestionCircle"
                            />
                        </div>
                    </HeadCell>
                </template>
                <InsuredFieldCollateralRow
                    v-for="(collateral, index) in localCollaterals"
                    :key="index"
                    :collateral="collateral"
                    :insured-field-collaterals="insuredFieldCollaterals"
                    :hide-actions="indexOfEditCollateral != null && indexOfEditCollateral !== index"
                    :updating-insured="updatingInsured"
                    :is-editing="indexOfEditCollateral === index"
                    :columns="columns"
                    @input="onCollateralInput($event,index)"
                    @edit="onEditStatusChange($event, index)"
                    @cancel-edit="cancelEdit(index)"
                    @delete="onDeleteCollateral(index)"
                />
            </Table>
        </template>
        <Button
            v-if="indexOfEditCollateral == null && !updatingInsured"
            class="bg-transparent text-eidTrustGreen px-0 py-4"
            data-test-id="InsuredDetailsCollateralSection__addButton"
            type="primary"
            :icon="faPlus"
            @click="onClickAddCollateral"
        >
            Add Collateral
        </Button>
        <ConfirmDeleteCollateralModal
            v-if="indexOfDeletingCollateral != null"
            :collateral="collateralToDelete"
            :insured-display-name="insuredDisplayName"
            :deleting="updatingInsured"
            @input="onConfirmDelete"
        />
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
    import { faPlus } from '@fortawesome/free-solid-svg-icons';
    import { InsuranceInsuredField } from '@evidentid/rpweb-api-client/types';
    import { insuredFieldCollateralColumns } from './config';
    import { HeadCell, Table } from '@evidentid/dashboard-commons/components/Table';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import ConfirmDeleteCollateralModal from './components/ConfirmDeleteCollateralModal/ConfirmDeleteCollateralModal.vue';
    import InsuredFieldCollateralRow from './components/InsuredFieldCollateralRow/InsuredFieldCollateralRow.vue';
    import { InsuredFieldCollateral } from '@evidentid/rpweb-api-client/models/InsuredFieldCollateral.model';

    export default Vue.extend({
        name: 'InsuredDetailsCollateralSection',
        components: {
            Button,
            ConfirmDeleteCollateralModal,
            EidIcon,
            HeadCell,
            InsuredFieldCollateralRow,
            Table,
        },
        props: {
            insuredDisplayName: {
                type: String as PropType<string>,
                required: true,
            },
            insuredFieldCollaterals: {
                type: Object as PropType<InsuranceInsuredField>,
                required: true,
            },
            collaterals: {
                type: Array as PropType<InsuredFieldCollateral[]>,
                default: () => [] as InsuredFieldCollateral[],
            },
            updatingInsured: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },
        data() {
            const fieldsDescription = (this.insuredFieldCollaterals.schema as any).items.properties;
            return {
                faQuestionCircle,
                faPlus,
                columns: insuredFieldCollateralColumns.map((col) => {
                    if (col.field) {
                        col.tooltipData = {
                            content: fieldsDescription[col.field]?.description || '',
                        };
                    }
                    return col;
                }),
                indexOfDeletingCollateral: null as number | null,
                newCollateralData: null as InsuredFieldCollateral | null,
                localCollaterals: [] as InsuredFieldCollateral[],
                addingNewCollateral: false as boolean,
                indexOfEditCollateral: null as number | null,
            };
        },
        computed: {
            collateralToDelete(): InsuredFieldCollateral | null {
                if (this.indexOfDeletingCollateral == null) {
                    return null;
                }
                return this.collaterals[this.indexOfDeletingCollateral] || null;
            },
        },
        watch: {
            updatingInsured(newValue: boolean) {
                if (!newValue) {
                    this.indexOfDeletingCollateral = null;
                }
            },
            collaterals: {
                immediate: true,
                handler(newValue?: boolean) {
                    if (newValue) {
                        this.localCollaterals = [ ...this.collaterals ];
                    }
                },
            },
        },
        methods: {
            emitInput(): void {
                this.$emit('input', this.localCollaterals);
            },
            onClickAddCollateral(): void {
                this.localCollaterals.push({} as InsuredFieldCollateral);
                this.indexOfEditCollateral = this.localCollaterals.length - 1;
            },
            onCollateralInput(collateral: InsuredFieldCollateral, listIndex: number): void {
                this.localCollaterals = [
                    ...this.localCollaterals.slice(0, listIndex),
                    collateral,
                    ...this.localCollaterals.slice(listIndex + 1),
                ];
                this.emitInput();
            },
            onDeleteCollateral(collateralIndex: number): void {
                if (this.localCollaterals[collateralIndex]?.id) {
                    this.indexOfDeletingCollateral = collateralIndex;
                } else {
                    // if no id, it's new input, simply remove from local list
                    this.localCollaterals = [
                        ...this.localCollaterals.slice(0, collateralIndex),
                        ...this.localCollaterals.slice(collateralIndex + 1),
                    ];
                }
            },
            onConfirmDelete(confirmed: boolean): void {
                if (!confirmed) {
                    this.indexOfDeletingCollateral = null;
                } else if (this.indexOfDeletingCollateral != null) {
                    this.localCollaterals = [
                        ...this.localCollaterals.slice(0, this.indexOfDeletingCollateral),
                        ...this.localCollaterals.slice(this.indexOfDeletingCollateral + 1),
                    ];
                    this.emitInput();
                }
            },
            onEditStatusChange(editing: boolean, collateralIndex: number): void {
                this.indexOfEditCollateral = editing ? collateralIndex : null;
            },

            cancelEdit(collateralIndex: number): void {
                const isExistedCollateral = Boolean(this.localCollaterals[collateralIndex]?.id);
                if (isExistedCollateral) {
                    this.localCollaterals = [
                        ...this.localCollaterals.slice(0, collateralIndex),
                        { ...this.localCollaterals[collateralIndex] },
                        ...this.localCollaterals.slice(collateralIndex + 1),
                    ];
                } else {
                    this.localCollaterals.pop();
                }
            },
        },
    });
</script>
