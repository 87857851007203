import { InsuranceInsuredField } from '@evidentid/rpweb-api-client/types';
import { JsonSchemaObject } from '@evidentid/json-schema/interfaces/JsonSchema';
import { isObject } from '@evidentid/json-schema/schemaChecks';

export function getObjectInsuredFieldPropLabels(field: InsuranceInsuredField): string[] {
    if (!isObject(field.schema)) {
        return [];
    }
    const props = Object.values((field.schema as JsonSchemaObject).properties);
    return props.map((prop) => `${field.name} ${prop.title}`);
}

export function getObjectInsuredFieldPropLabel(field: InsuranceInsuredField, key: string): string {
    if (!isObject(field.schema)) {
        return '';
    }
    return (field.schema as JsonSchemaObject).properties[key]?.title || key;
}
