<template>
    <Modal additional-class-name="ConfirmUpdateBulkInsuredsModal" open @close="close">
        <template v-slot:header>
            Confirm updates of existing insureds
        </template>

        <Alert v-if="successCount > 0" type="success">
            <template v-slot:title>
                <FontAwesomeIcon :icon="faCheckCircle" />
                <span v-if="successCount === 1"> 1 new insured were added</span>
                <span v-else>{{ successCount }} new insureds were added</span>
            </template>
        </Alert>
        <Alert type="highWarning">
            <template v-slot:title>
                <FontAwesomeIcon :icon="faExclamationTriangle" />
                <span v-if="insureds.length === 1">
                    1 insured emails already exist and the record will be updated. Please confirm to proceed.
                </span>
                <span v-else>
                    {{ insureds.length }} insureds emails already exist and the record will be updated. Please confirm to proceed.
                </span>
            </template>
        </Alert>

        <Alert v-if="externalError" type="danger">
            <template v-slot:title>
                <FontAwesomeIcon :icon="faExclamationTriangle" />
                <span> {{ externalError }}</span>
            </template>
        </Alert>
        <div class="ConfirmUpdateBulkInsuredsModal__insuredsTable">
            <div class="ConfirmUpdateBulkInsuredsModal__insuredsTableHeaderRow">
                <strong>Insured Name</strong>
                <strong>Primary Contact Email</strong>
            </div>
            <div v-for="(insured, index) of insureds"
                :key="index"
                class="ConfirmUpdateBulkInsuredsModal__insuredsTableRow"
            >
                <strong>{{ insured.displayName }}</strong>
                <p>{{ insured.contactEmail }}</p>
            </div>
        </div>
        <div class="ConfirmUpdateBulkInsuredsModal__actionSection">
            <Button @click="close" :disabled="saving">
                Cancel update
            </Button>
            <Button
                type="primary"
                :loading="saving"
                :progress="savingPercentage"
                :disabled="saving"
                @click="confirm"
            >
                Update Insureds
            </Button>
        </div>
    </Modal>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { faExclamationTriangle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { InsuranceInsuredInput } from '@evidentid/rpweb-api-client/types';
    import { Modal } from '@evidentid/dashboard-commons/components/Modal';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { Alert } from '@evidentid/dashboard-commons/components/Alert';
    import { Cell, HeadCell, Row, Table } from '@evidentid/dashboard-commons/components/Table';

    @Component({
        components: {
            Alert,
            Modal,
            Button,
            FontAwesomeIcon,
            Table,
            HeadCell,
            Row,
            Cell,
        },
    })
    export default class ConfirmUpdateBulkInsuredsModal extends Vue {
        @Prop({ type: Number, default: 0 })
        private successCount!: number;

        @Prop({ type: Boolean, default: false })
        private saving!: boolean;

        @Prop({ type: Number, default: null })
        private savingPercentage!: number | null;

        @Prop({ type: Array, default: [] })
        private insureds!: InsuranceInsuredInput[];

        @Prop({ type: String, default: '' })
        private externalError!: string;

        private faExclamationTriangle = faExclamationTriangle;
        private faCheckCircle = faCheckCircle;

        private close(): void {
            this.$emit('close');
        }

        private confirm(): void {
            this.$emit('updateInsureds', this.insureds);
        }
    }
</script>
