<template>
    <div class="CollateralLimitObjectInput">
        <div class="CollateralLimitObjectInput__limitType">
            <span class="CollateralLimitObjectInput__rowLabel">Limit Type:</span>
            <CriterionMultiEnumInput
                hide-title
                show-error
                class="CollateralLimitObjectInput__rowInput"
                :form="getChildProperty('limitType').form"
                :required="getChildProperty('limitType').required"
                :value="getPropValue(getChildProperty('limitType'))"
                @input="onInput($event, 'limitType')"
            />
        </div>
        <div class="CollateralLimitObjectInput__valuation">
            <span class="CollateralLimitObjectInput__rowLabel">Limit Valuation:</span>
            <CriterionEnumInput
                hide-title
                show-error
                class="CollateralLimitObjectInput__rowInput"
                :form="getChildProperty('limitValuation').form"
                :required="getChildProperty('limitValuation').required"
                :value="getPropValue(getChildProperty('limitValuation'))"
                @input="onInput($event, 'limitValuation')"
            />
        </div>
        <label
            class="CollateralLimitObjectInput__allowScheduledLimit"
        >
            <CriterionCheckboxInput
                :form="getChildProperty('allowScheduledLimits').form"
                :value="getPropValue(getChildProperty('allowScheduledLimits'))"
                :required="getChildProperty('allowScheduledLimits').required"
                @input="onInput($event, 'allowScheduledLimits')"
            />
            <span class="ml-1">Allow Scheduled Limits</span>
            <EidIcon
                v-tooltip="getChildProperty('allowScheduledLimits').form.schema.description"
                class="ml-1 text-eidTechGray-75"
                :icon-src="faQuestionCircle"
            />
        </label>
    </div>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import { JsonFormObject, JsonFormProperty } from '@evidentid/json-schema/interfaces/JsonForm';
    import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
    import AbstractCriterionInput from '../CoverageCriterionInputComponents/AbstractCriterionInput';
    import CriterionEnumInput
        from '@/modules/decisioning-criteria/components/CoverageCriterionInputComponents/CriterionEnumInput.vue';
    import CriterionCheckboxInput
        from '@/modules/decisioning-criteria/components/CoverageCriterionInputComponents/CriterionCheckboxInput.vue';
    import CriterionMultiEnumInput
        from '@/modules/decisioning-criteria/components/CoverageCriterionInputComponents/CriterionMultiEnumInput.vue';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';

    @Component({
        inheritAttrs: false,
        components: {
            CriterionEnumInput,
            CriterionMultiEnumInput,
            CriterionCheckboxInput,
            EidIcon,
        },
    })
    export default class CollateralLimitObjectInput extends AbstractCriterionInput<JsonFormObject, object> {
        private faQuestionCircle = faQuestionCircle;

        private getChildProperty(propName: string): JsonFormProperty | null {
            return this.form.getProperties(this.value).find((x) => x.name === propName) || null;
        }

        private getPropValue(property: JsonFormProperty): unknown {
            return property?.form.getValue(
                (this.value as any)?.[property.name] || undefined, true,
            ) || null;
        }

        private onInput(value: any, key: string): void {
            this.$emit('input', this.form.getValue({
                ...this.value,
                [key]: value,
            }, true));
        }
    }
</script>
