<template>
    <div class="CoverageCriteriaGroupEmptyState">
        <div class="CoverageCriteriaGroupEmptyState__container">
            <span class="CoverageCriteriaGroupEmptyState__title"> Configure your first Coverage Criteria Group</span>
            <div class="CoverageCriteriaGroupEmptyState__body">
                <p>
                    <span>A </span>
                    <span class="CoverageCriteriaGroupEmptyState__bodyHighlight">Coverage Criteria Group</span>
                    <span> is a set of Coverage Types and Coverage Criteria used to determine the compliance of your insured population.</span>
                </p>
                <p>You can create additional groups to capture more nuanced requirements.</p>
            </div>
            <Button class="CoverageCriteriaGroupEmptyState__createButton" type="primary" @click="createGroup">
                Create your first group
            </Button>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from '@evidentid/vue-property-decorator';
    import { Button } from '@evidentid/dashboard-commons/components/Button';

    @Component({
        components: { Button },
    })
    export default class CoverageCriteriaGroupEmptyState extends Vue {
        private createGroup() {
            this.$emit('create');
        }
    }
</script>
