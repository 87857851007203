<template>
    <div
        class="CoverageCriteriaGroupsExpandableRows"
        :class="{'CoverageCriteriaGroupsExpandableRows--expanded': expanded}"
    >
        <div class="CoverageCriteriaGroupsExpandableRows__summaryRowContainer">
            <div class="CoverageCriteriaGroupsExpandableRows__summaryRow">
                <div class="CoverageCriteriaGroupsExpandableRows__summaryBranch">
                    <div class="CoverageCriteriaGroupsExpandableRows__expandableCaret" @click="toggle">
                        <FontAwesomeIcon v-if="expanded" :icon="faCaretDown" />
                        <FontAwesomeIcon v-else :icon="faCaretUp" />
                    </div>
                    <div class="CoverageCriteriaGroupsExpandableRows__summaryBranchLower" />
                </div>
                <div class="CoverageCriteriaGroupsExpandableRows__cells">
                    <div class="CoverageCriteriaGroupsExpandableRows__summaryNameCell">
                        <span @click="toggle">
                            Effective Group:
                        </span>
                    </div>
                    <div class="CoverageCriteriaGroupsExpandableRows__summaryCoverageTypesContentCell">
                        {{ summaryCoverageTypes }}
                    </div>
                    <div class="CoverageCriteriaGroupsExpandableRows__summaryComplianceStatusContentCell">
                        <ComplianceStatusBadge :status="insured.complianceStatus" />
                    </div>
                </div>
            </div>
        </div>
        <template v-if="expanded">
            <div
                v-for="group in insuredCoverageCriteriaGroups"
                :key="group.id"
                class="CoverageCriteriaGroupsExpandableRows__rowContainer"
            >
                <div class="CoverageCriteriaGroupsExpandableRows__row">
                    <div class="CoverageCriteriaGroupsExpandableRows__branch">
                        <div class="CoverageCriteriaGroupsExpandableRows__branchUpper" />
                        <div class="CoverageCriteriaGroupsExpandableRows__branchLower" />
                    </div>
                    <div class="CoverageCriteriaGroupsExpandableRows__cells">
                        <div class="CoverageCriteriaGroupsExpandableRows__displayNameContentCell">
                            <span @click="$emit('goToCriteriaGroup', group.id)">
                                {{ group.displayName }}
                            </span>
                        </div>
                        <div class="CoverageCriteriaGroupsExpandableRows__coverageTypesContentCell">
                            {{ group.coverageTypes.length }}
                        </div>
                        <div class="CoverageCriteriaGroupsExpandableRows__complianceStatusContentCell">
                            <ComplianceStatusBadge :status="group.complianceStatus" />
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import union from 'lodash/union';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
    import { InsuranceInsured, InsuranceInsuredCoverageCriteriaGroup } from '@evidentid/rpweb-api-client/types';
    import { InsuranceCoverageType } from '@evidentid/insurance-facing-lib/models/insured-details';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import ComplianceStatusBadge from '@/modules/dashboard/components/ComplianceStatusBadge/ComplianceStatusBadge.vue';

    @Component({
        components: { Button, ComplianceStatusBadge, FontAwesomeIcon },
    })
    export default class CoverageCriteriaGroupsExpandableRows extends Vue {
        @Prop({ type: Array, default: () => [] })
        private insuredCoverageCriteriaGroups!: InsuranceInsuredCoverageCriteriaGroup[];

        @Prop({ type: Object, required: true })
        private insured!: InsuranceInsured;

        private faCaretDown = faCaretDown;
        private faCaretUp = faCaretUp;
        private expanded = true;

        private get summaryCoverageTypes(): number {
            return this.insuredCoverageCriteriaGroups
                .reduce((accu, group) => union(accu, group.coverageTypes), [] as InsuranceCoverageType[])
                .length;
        }

        private toggle(): void {
            this.expanded = !this.expanded;
        }
    }
</script>
