<template>
    <div v-if="parsedEmailMessage" class="CoverageCriterionEmailMessage text-[0.8rem] mt-10 py-2.5 px-[15px]">
        <div>
            Email Message
        </div>
        <div class="text-eidLightGray text-[0.8rem] my-[15px]">
            If Non-compliant, the Insured will receive the following message:
        </div>
        <div class="p-2.5" data-test-id="CoverageCriterionEmailMessage__emailMessageContent">
            {{ parsedEmailMessage }}
        </div>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import get from 'lodash/get';
    import mapValues from 'lodash/mapValues';
    import {
        InsuranceCoverageCriterion,
        InsuranceCoverageCriterionInput,
        InsuranceCoverageCriterionTemplate,
        InsuranceInsuredField,
    } from '@evidentid/rpweb-api-client/types';
    import { CoverageCriterionMessageStatus } from '@/modules/decisioning-criteria/vuex';
    import JsonSchema, { JsonSchemaArray, JsonSchemaEnum } from '@evidentid/json-schema/interfaces/JsonSchema';
    import { isArray, isEnum } from '@evidentid/json-schema/schemaChecks';
    import { createRpController } from '@evidentid/dashboard-commons/modules/auth/createRpController';

    export default Vue.extend({
        name: 'CoverageCriterionEmailMessage',
        props: {
            criterion: {
                type: Object as PropType<InsuranceCoverageCriterion | InsuranceCoverageCriterionInput>,
                required: true,
            },
            template: {
                type: Object as PropType<InsuranceCoverageCriterionTemplate>,
                required: true,
            },
            insuredFields: {
                type: Array as PropType<InsuranceInsuredField[]>,
                default: () => [] as InsuranceInsuredField[],
            },
            criterionValue: {
                type: Object as PropType<any | null>,
                default: () => null,
            },
            criterionMessageStatus: {
                type: Object as PropType<CoverageCriterionMessageStatus>,
                required: true,
            },
        },
        computed: {
            rpName(): string {
                return this.$route.params.rpId;
            },
            parsedEmailMessage(): string {
                const message = this.criterionMessageStatus.data?.nonComplianceMessage || this.template.emailMessage;
                const rpPlaceholderRegex = /\{\{RELYING_PARTY\:.*?\}\}/g;
                const referencePlaceholderRegex = /\{\{REFERENCE\:.*?\}\}/g;
                const insuredFieldPlaceholderRegex = /\{\{INSURED_FIELD\:.*?\}\}/g;
                const referencePlaceholders = message.match(referencePlaceholderRegex);
                const rpPropertiesPlaceholders = message.match(rpPlaceholderRegex);
                const insuredFieldPlaceholders = message.match(insuredFieldPlaceholderRegex);

                let messageCopy = message;
                if (referencePlaceholders) {
                    messageCopy = this.replaceReferencesInMessage(messageCopy, referencePlaceholders as string[]);
                }
                if (rpPropertiesPlaceholders) {
                    messageCopy = this.replaceRpPropertyInMessage(messageCopy, rpPropertiesPlaceholders as string[]);
                }
                if (insuredFieldPlaceholders) {
                    messageCopy = this.replaceInsuredFieldInMessage(messageCopy, insuredFieldPlaceholders as string[]);
                }
                return messageCopy;
            },
        },
        methods: {
            replaceRpPropertyInMessage(message: string, rpPlaceholders: string[]): string {
                const propKeyRegex = /(?<=\{\{RELYING_PARTY:)(.*?)(?=\}\})/;
                let messageCopy = message;
                // TODO(PRODUCT-18675): remove any after the migration to the newer version.
                const $rp = (this as any).$rp as ReturnType<typeof createRpController>;
                const rp = $rp.available.find((rp) => rp.name === $rp.current) as any;
                rpPlaceholders.forEach((placeholder) => {
                    const match = placeholder.match(propKeyRegex);
                    const propKey = match ? match[0] : null;
                    if (propKey && rp[propKey]) {
                        messageCopy = messageCopy.replaceAll(
                            placeholder,
                            this.getStringValue(rp[propKey]),
                        );
                    }
                });
                return messageCopy;
            },
            replaceReferencesInMessage(message: string, referencePlaceholders: string[]): string {
                // keep checking for insured field as a fallback in case the email message is being used
                const referenceKeyRegex = /(?<=\{\{REFERENCE:)(.*?)(?=\}\})/;
                return this.replaceRefValue(message, referencePlaceholders, referenceKeyRegex);
            },
            replaceInsuredFieldInMessage(message: string, referencePlaceholders: string[]): string {
                const insuredFieldKeyRegex = /(?<=\{\{INSURED_FIELD:)(.*?)(?=\}\})/;
                return this.replaceInsuredFieldName(message, referencePlaceholders, insuredFieldKeyRegex);
            },
            replaceRefValue(message: string, stringsTobeReplaced: string[], keyRegex: RegExp) {
                let messageCopy = message;
                stringsTobeReplaced.forEach((placeholder) => {
                    const match = placeholder.match(keyRegex);
                    const refKey = match ? match[0] : null;
                    if (refKey) {
                        const value = get(this.criterionValue, refKey);
                        const valueOrLabel = this.getPossibleLabel(value, refKey) || value;
                        messageCopy = messageCopy.replaceAll(
                            placeholder,
                            this.getStringValue(valueOrLabel),
                        );
                    }
                });
                return messageCopy;
            },
            replaceInsuredFieldName(message: string, stringsTobeReplaced: string[], keyRegex: RegExp) {
                let messageCopy = message;
                stringsTobeReplaced.forEach((placeholder) => {
                    const match = placeholder.match(keyRegex);
                    const insuredFieldKey = match ? match[0] : null;
                    if (insuredFieldKey) {
                        const insuredField = this.insuredFields.find((field) => field.key === insuredFieldKey);
                        messageCopy = messageCopy.replaceAll(placeholder, insuredField?.name || '');
                    }
                });
                return messageCopy;
            },
            getPossibleLabel(value: any, reference: string): string | null {
                const referenceSchemas = mapValues(this.criterion.evaluator.references, (ref) => ref.schema);
                const schema = get(
                    referenceSchemas,
                    this.convertJsonPathToSchemaPath(reference),
                ) || null;
                // FIXME once BE model move all enum labels into Schema, getLabelWithinTemplate no longer needed.
                return this.getLabelWithinSchema(value, schema) || this.getLabelWithinTemplate(value, reference);
            },
            convertJsonPathToSchemaPath(path: string): string {
                const regexForProperties = /\.(\w+)/;
                const regexForItems = /\[\d+\]/;
                let schemaPath = path.replace(regexForProperties, '.properties.$1');
                schemaPath = schemaPath.replace(regexForItems, '.items');
                return schemaPath;
            },
            getLabelWithinTemplate(value: any, reference: string): string | null {
                if (!this.template.displayMetadata.enumValues) {
                    return null;
                }
                const arrayValue = Array.isArray(value) ? value : [ value ];
                return arrayValue.map((val) => {
                    const valueLabelMap = this.template.displayMetadata.enumValues?.find(
                        (maps: { reference: string, values: Record<string, string> }) => maps.reference === reference,
                    )?.values || {};
                    return valueLabelMap[val] || null;
                }).filter(Boolean).join(', ') || null;
            },
            getLabelWithinSchema(value: any, schema: JsonSchema | null): string | null {
                // only enum schema needs to get label, thus only consider enum and array of enum types
                const isEnumSchema = schema && isEnum(schema);
                const isMultiEnumSchema = schema && isArray(schema) && isEnum(schema.items as JsonSchema);
                if (!isEnumSchema && !isMultiEnumSchema) {
                    return null;
                }
                const enumSchema = ((schema as JsonSchemaArray).items || schema) as JsonSchemaEnum;
                const arrayValue = Array.isArray(value) ? value : [ value ];
                return arrayValue.map((val) => {
                    const index = enumSchema.enum.indexOf(val);
                    return enumSchema.enumLabels?.[index] || null;
                }).filter(Boolean).join(', ') || null;
            },
            getStringValue(value: any): string {
                // object is not going to be expected per current requirement,
                // if it shows up, the view will render [Object Object] as an indication we need to add support for it
                if (value == null) {
                    return '';
                }
                return Array.isArray(value) ? value.join(', ') : String(value);
            },
        },
    });
</script>

