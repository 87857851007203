<template>
    <EidSidePanel
        class="CoverageGroupAssignmentRulePanel"
        :class="{ 'CoverageGroupAssignmentRulePanel--loading': loadingInsuredFields }"
        :click-out-exception-selectors="clickOutExceptions"
        @click-out="close"
    >
        <LogoLoader v-if="loadingInsuredFields" />
        <template v-if="!loadingInsuredFields" #headerText>
            <span>Assignment</span>
        </template>
        <template v-if="!loadingInsuredFields" #content>
            <div
                v-for="(basicRules, rulesGroupIndex) in localRules"
                :key="rulesGroupIndex"
                class="CoverageGroupAssignmentRulePanel__orRule"
            >
                <span class="CoverageGroupAssignmentRulePanel__ruleIndexLetter">
                    {{ getLetterIndex(rulesGroupIndex) }}
                </span>
                <div class="CoverageGroupAssignmentRulePanel__orRuleBody">
                    <div class="CoverageGroupAssignmentRulePanel__header">
                        <span
                            class="CoverageGroupAssignmentRulePanel__conditionFieldHeader"
                        >
                            {{ rulesGroupIndex > 0 ? 'Or if ...' : 'If ...' }}
                        </span>
                        <span class="CoverageGroupAssignmentRulePanel__conditionOperatorHeader">
                            Condition
                        </span>
                        <span class="CoverageGroupAssignmentRulePanel__conditionValueHeader">
                            Value
                        </span>
                    </div>
                    <div
                        v-for="(basicRule, basicRuleIndex) in basicRules"
                        :key="basicRuleIndex"
                        class="CoverageGroupAssignmentRulePanel__andRule"
                    >
                        <Dropdown
                            class="CoverageGroupAssignmentRulePanel__conditionFieldDropdown"
                            placeholder="Select Insured Field"
                            :options="insuredFieldsOptions"
                            :selected="convertInsuredFieldToDropdownOption(basicRule.insuredFieldKey)"
                            @input="onInsuredFieldNameChange(rulesGroupIndex, basicRuleIndex, $event)"
                        />
                        <Dropdown
                            class="CoverageGroupAssignmentRulePanel__conditionOperatorDropdown"
                            :options="getAvailableOperators(basicRule)"
                            :selected="convertToOperatorOption(basicRule.operator)"
                            @input="onOperatorChange(rulesGroupIndex, basicRuleIndex, $event)"
                        />
                        <Dropdown
                            class="CoverageGroupAssignmentRulePanel__conditionValueDropdown
                            CoverageGroupAssignmentRulePanel__conditionValue"
                            v-if="getInsuredFieldType(basicRule) === 'boolean'"
                            :options="booleanOptions"
                            :selected="convertToBooleanOption(basicRule.value)"
                            :disabled="!isInsuredFieldSelected(basicRule)"
                            @input="onBooleanValueChange(rulesGroupIndex, basicRuleIndex, $event)"
                        />
                        <input
                            v-else
                            class="CoverageGroupAssignmentRulePanel__conditionValue
                             CoverageGroupAssignmentRulePanel__input"
                            :type="getInputValueType(basicRule)"
                            :value="basicRule.value"
                            :disabled="!isInsuredFieldSelected(basicRule)"
                            @input="onValueChange(rulesGroupIndex, basicRuleIndex, $event)"
                        >
                        <EidIcon
                            class="CoverageGroupAssignmentRulePanel__binIcon"
                            alt=""
                            :icon-src="binIcon"
                            :svg-props="{color: alertRedColor}"
                            @click="removeBasicRule(rulesGroupIndex, basicRuleIndex)"
                        />
                    </div>
                    <Button
                        class="CoverageGroupAssignmentRulePanel__addAndConditionButton"
                        @click="addBasicRule(rulesGroupIndex)"
                    >
                        + And
                    </Button>
                </div>
            </div>
            <Button
                class="CoverageGroupAssignmentRulePanel__addOrConditionButton"
                :disabled="localRules.length > 25"
                @click="addRulesGroup"
            >
                + Or if ...
            </Button>
        </template>
    </EidSidePanel>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import get from 'lodash/get';
    import EidSidePanel from '@/modules/decisioning-criteria/components/EidSidePanel/EidSidePanel.vue';
    import Dropdown from '@evidentid/dashboard-commons/components/Dropdown/Dropdown.vue';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { AssignmentRuleValue, BasicAssignmentRule } from '@/modules/decisioning-criteria/types';
    import { InsuredFieldsListStatus } from '@/modules/decisioning-criteria/vuex';
    import {
        InsuranceInsuredField,
    } from '@evidentid/rpweb-api-client/types';
    import { DropdownOption } from '@evidentid/dashboard-commons/components/Dropdown/types';
    import {
        allAssignmentOperators,
        booleanAssignmentOperators,
        collateralCategoryOperators,
        JsonRuleOperator,
        stringAssignmentOperators,
    } from '@/modules/decisioning-criteria/utils/assigmentRuleOperators';
    import binIcon from '@/assets/icons/bin-icon.svg';
    import { getLetterIndex } from '@/modules/decisioning-criteria/utils/getLetterIndex';
    import { LogoLoader } from '@evidentid/dashboard-commons/components/LogoLoader';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { tailwindColors } from '@/styles/variables/tailwind-colors/tailwindColors';
    import JsonSchema from '@evidentid/json-schema/interfaces/JsonSchema';
    import { isCollateralInsuredField } from '@/utils/isCollateralInsuredField';
    import { isArray, isObject } from '@evidentid/json-schema/schemaChecks';

    export default Vue.extend({
        name: 'CoverageGroupAssignmentRulePanel',
        components: {
            EidIcon,
            EidSidePanel,
            Button,
            Dropdown,
            LogoLoader,
        },
        props: {
            rules: {
                type: Array as PropType<BasicAssignmentRule[][]>,
                default: () => [],
            },
            insuredFieldsStatus: {
                type: Object as PropType<InsuredFieldsListStatus>,
                required: true,
            },
            loadingInsuredFields: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            collateralsOnly: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },
        data() {
            return {
                alertRedColor: tailwindColors.eidDangerRed.DEFAULT,
                binIcon,
                booleanOptions: [
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                ] as DropdownOption[],
                clickOutExceptions: [
                    '.CoverageCriteriaGroupSaveButton__saveButton',
                    '.CoverageCriteriaGroupSaveButton__saveButton span',
                ],
            };
        },
        computed: {
            insuredFields(): InsuranceInsuredField[] {
                return this.insuredFieldsStatus.list;
            },
            collateralInsuredField(): InsuranceInsuredField | null {
                return this.insuredFields.find(isCollateralInsuredField) || null;
            },
            categoryOption(): DropdownOption | null {
                return this.collateralInsuredField
                    ? { label: 'Category', value: `${this.collateralInsuredField.key}[items].category` }
                    : null;
            },
            insuredFieldsOptions(): DropdownOption[] {
                if (this.collateralsOnly) {
                    const hasCollateralField = Boolean(this.collateralInsuredField);
                    return hasCollateralField && this.categoryOption
                        ? [ this.categoryOption ]
                        : [];
                }
                return this.insuredFields
                    .filter((insuredField) => 'type' in insuredField.schema && this.isAllowedInsuredField(insuredField))
                    .map((insuredField) => ({ label: insuredField.name, value: insuredField.key }));
            },
            localRules(): BasicAssignmentRule[][] {
                if (!this.rules || this.rules.length === 0) {
                    return [ [
                        { operator: JsonRuleOperator.equal, insuredFieldKey: '', value: '' },
                    ] ] as BasicAssignmentRule[][];
                }

                return this.rules;
            },
        },
        methods: {
            getLetterIndex,
            getFieldSchema(rule: BasicAssignmentRule): JsonSchema | null {
                // get top schema, traverse down until the desired schema the key indicates
                // first complete word without special character is insured field key ex. collaterals[items].category
                const insuredFieldKeyFromRule = rule.insuredFieldKey.match(/\w+/)?.[0] || '';
                const topSchema = this.insuredFields
                    .find((insuredField) => insuredField.key === insuredFieldKeyFromRule)?.schema;
                const schemaWrapper = { [insuredFieldKeyFromRule]: topSchema };
                const schemaPath = rule.insuredFieldKey
                    // change dot charater into peroperties as it indicates object type
                    .replaceAll(/\./g, '.properties.')
                    // change [items] into .items to access array items schema
                    .replaceAll(/\[(\w+)\]/g, '.$1');
                return get(schemaWrapper, schemaPath) || null;
            },
            getInsuredFieldType(rule: BasicAssignmentRule): string {
                const schema = this.getFieldSchema(rule);
                return (schema && 'type' in schema ? schema.type : '');
            },
            getAvailableOperators(rule: BasicAssignmentRule): DropdownOption[] {
                const collateralKey = this.insuredFields.find(isCollateralInsuredField)?.key;
                const collateralsKeyRegex = new RegExp(`^${collateralKey}`);
                if (collateralsKeyRegex.test(rule.insuredFieldKey)) {
                    return collateralCategoryOperators;
                }
                const type = this.getInsuredFieldType(rule);
                switch (type) {
                    case 'boolean':
                        return booleanAssignmentOperators;
                    case 'string':
                        return stringAssignmentOperators;
                    default:
                        return allAssignmentOperators;
                }
            },
            convertInsuredFieldToDropdownOption(value: string): DropdownOption[] {
                if (!value) {
                    return [];
                }
                if (this.collateralsOnly && this.categoryOption) {
                    return [ this.categoryOption ];
                }
                return [
                    {
                        value,
                        label: this.insuredFields.find((insuredField) => insuredField.key === value)?.name || '',
                    },
                ];
            },
            convertToOperatorOption(value: string): DropdownOption[] {
                return [ {
                    value,
                    label: allAssignmentOperators.find((option) => option.value === value)?.label || value,
                } ];
            },
            convertToBooleanOption(value: AssignmentRuleValue): DropdownOption[] {
                if (value === true) {
                    return [ { value, label: 'Yes' } ];
                } else if (value === false) {
                    return [ { value, label: 'No' } ];
                } else {
                    return [];
                }
            },
            onInsuredFieldNameChange(groupIndex: number, basicRuleIndex: number, option: DropdownOption): void {
                const updatedRules = this.localRules.map((basicRules) => basicRules.slice());
                updatedRules[groupIndex][basicRuleIndex].insuredFieldKey = option.value;

                if (this.getInsuredFieldType(updatedRules[groupIndex][basicRuleIndex]) === 'boolean') {
                    updatedRules[groupIndex][basicRuleIndex].value = false;
                } else {
                    updatedRules[groupIndex][basicRuleIndex].value = '';
                }
                updatedRules[groupIndex][basicRuleIndex].operator = JsonRuleOperator.equal;
                this.$emit('update-rules', updatedRules);
            },
            onOperatorChange(groupIndex: number, basicRuleIndex: number, option: DropdownOption): void {
                const updatedRules = this.localRules.map((basicRules) => basicRules.slice());
                updatedRules[groupIndex][basicRuleIndex].operator = option.value;
                this.$emit('update-rules', updatedRules);
            },
            onValueChange(groupIndex: number, basicRuleIndex: number, event: InputEvent): void {
                const updatedRules = this.localRules.map((basicRules) => basicRules.slice());
                updatedRules[groupIndex][basicRuleIndex].value = (event.target as HTMLInputElement).value;
                this.$emit('update-rules', updatedRules);
            },
            onBooleanValueChange(groupIndex: number, basicRuleIndex: number, option: DropdownOption): void {
                const updatedRules = this.localRules.map((basicRules) => basicRules.slice());
                updatedRules[groupIndex][basicRuleIndex].value = option.value;
                this.$emit('update-rules', updatedRules);
            },
            addBasicRule(groupIndex: number): void {
                const updatedRules = this.localRules.map((basicRules) => basicRules.slice());
                updatedRules[groupIndex].push({ operator: JsonRuleOperator.equal, insuredFieldKey: '', value: '' });
                this.$emit('update-rules', updatedRules);
            },
            addRulesGroup(): void {
                const updatedRules = this.localRules.map((basicRules) => basicRules.slice());
                updatedRules.push([ { operator: JsonRuleOperator.equal, insuredFieldKey: '', value: '' } ]);
                this.$emit('update-rules', updatedRules);
            },
            removeBasicRule(groupIndex: number, basicRuleIndex: number): void {
                const updatedRules = this.localRules.map((basicRules, index) => (index === groupIndex
                    ? [ ...basicRules.slice(0, basicRuleIndex), ...basicRules.slice(basicRuleIndex + 1) ]
                    : basicRules.slice()));

                if (updatedRules[groupIndex].length === 0) {
                    updatedRules.splice(groupIndex, 1);
                }

                if (updatedRules.length === 0) {
                    updatedRules.push([ { operator: JsonRuleOperator.equal, insuredFieldKey: '', value: '' } ]);
                }

                this.$emit('update-rules', updatedRules);
            },
            isInsuredFieldSelected(rule: BasicAssignmentRule): boolean {
                return rule.insuredFieldKey.length > 0;
            },
            getInputValueType(basicRule: BasicAssignmentRule): string {
                return [ 'number', 'integer' ].includes(this.getInsuredFieldType(basicRule)) ? 'number' : 'text';
            },
            close(): void {
                this.$emit('close');
            },
            isAllowedInsuredField(insuredField: InsuranceInsuredField): boolean {
                return !isArray(insuredField.schema) && !isObject(insuredField.schema);
            },
        },
    });
</script>
