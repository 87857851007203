<template>
    <div class="SetRemindersModalHeader">
        <span>Schedule Notifications</span>
        <span class="SetRemindersModalHeader__typeText">{{ typeText }}</span>
        <div class="SetRemindersModalHeader__switch">
            <SwitchToggle hide-label :value="value" @input="onChange" />
            <label
                :class="value ? 'text-eidTrustGreen' : 'text-eidSecureBlue-75'"
                @click="onChange(!value)"
            >
                Notifications On
            </label>
        </div>
    </div>
</template>

<style lang="scss">
    .SetRemindersModalHeader {
        display: flex;

        &__typeText {
            @apply text-eidTrustGreen;

            position: relative;
            margin-left: 30px;

            &:before {
                @apply bg-eidTechGray;

                content: "";
                position: absolute;
                top: 1px;
                left: -15px;
                width: 1px;
                height: 100%;
            }
        }

        &__switch {
            @apply text-eidTechGray;

            display: flex;
            align-items: center;
            margin-left: auto;
            margin-right: 20px;
            font-size: 16px;
        }

        .SwitchToggle__container {
            width: 40px;
            height: 22px;
        }

        .SwitchToggle__slider:before {
            height: 15px;
            width: 15px;
            left: 3px;
            bottom: 4px;
        }

        .SwitchToggle__slider {
            @apply bg-eidTechGray;
        }

        // We disable it because of errors selector-max-combinators, selector-max-compound-selectors
        // stylelint-disable
        .SwitchToggle input:checked + .SwitchToggle__slider:before {
            transform: translateX(19px);
            // stylelint-enable
        }
    }
</style>

<script lang="ts">
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import { SwitchToggle } from '@evidentid/dashboard-commons/components/SwitchToggle';
    import { Prop } from '@evidentid/vue-property-decorator';
    import { NotificationName } from '@evidentid/config-api-client/types';

    @Component({
        components: { SwitchToggle },
    })
    export default class SetRemindersModalHeader extends Vue {
        @Prop({ type: String })
        private type?: NotificationName;

        @Prop({ type: Boolean, default: false })
        private value!: boolean;

        private get typeText(): string {
            switch (this.type) {
                case NotificationName.newRequestReminder:
                    return 'New Member Verification Request';
                case NotificationName.expiringRequestReminder:
                    return 'Expiring Verification Request';
                case NotificationName.nonCompliantRequestReminder:
                    return 'Non-Compliant Verification Request';
                default:
                    return 'Basic Notification';
            }
        }

        private onChange(newValue: Boolean) {
            this.$emit('input', newValue);
        }
    }
</script>
