<template>
    <div class="PolicyInfoCarrierInfo">
        <span class="text-eidLightGray text-base">
            CARRIER
        </span>
        <PolicyRecord
            v-if="isShowingName"
            title="Name"
            :value="getOptionalPolicyRecord(carrier.name)"
            :evaluation-error="getEvaluationResult('policy/carrier/name')"
            @goToCriterion="goToCriterion"
            @grantExceptionToCriterion="grantExceptionToCriterion"
            @removeExceptions="removeExceptions"
        />
        <PolicyRecord
            v-if="isShowingNaicNumber"
            title="NAIC #"
            :value="getOptionalPolicyRecord(carrier.naicNumber)"
            :evaluation-error="getEvaluationResult('policy/carrier/naicNumber')"
            @goToCriterion="goToCriterion"
            @grantExceptionToCriterion="grantExceptionToCriterion"
            @removeExceptions="removeExceptions"
        />
        <PolicyRecord
            v-if="isShowingAmBestRatingRecord"
            :value="getOptionalPolicyRecord(carrier.amBestFinancialStrengthRating)"
            :evaluation-error="getEvaluationResult('policy/carrier/amBestFinancialStrengthRating')"
            @goToCriterion="goToCriterion"
            @grantExceptionToCriterion="grantExceptionToCriterion"
            @removeExceptions="removeExceptions"
        >
            <template #title>
                <span
                    data-test-id="PolicyInfoCarrierInfo__amBestRatingText"
                    class="cursor-pointer"
                    @click="openAMBestRatingDisclaimer"
                >
                    AM Best Rating <span class="text-eidTrustGreen">*</span>
                </span>
            </template>
        </PolicyRecord>
        <PolicyRecord
            v-if="isShowingAmBestFinancialSizeCategory"
            title="AM Best Financial Size"
            :value="numberToAmBestFinancialSize"
            :evaluation-error="getEvaluationResult('policy/carrier/amBestFinancialSizeCategory')"
            @goToCriterion="goToCriterion"
            @grantExceptionToCriterion="grantExceptionToCriterion"
            @removeExceptions="removeExceptions"
        />
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import PolicyRecord from '@/modules/insured-details/components/PolicyRecord/PolicyRecord.vue';
    import { InsuranceCarrier } from '@evidentid/rpweb-api-client/types';
    import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
    import { FIELD_NOT_AVAILABLE_MESSAGE } from '@/modules/insured-details/constant';
    import {
        isShowingAmBestRatingRecord,
        isShowingAmBestFinancialSizeCategory,
        isShowingName,
        isShowingNaicNumber,
    } from './utils';

    const amBestCategories: Record<number, string> = {
        1: 'I',
        2: 'II',
        3: 'III',
        4: 'IV',
        5: 'V',
        6: 'VI',
        7: 'VII',
        8: 'VIII',
        9: 'IX',
        10: 'X',
        11: 'XI',
        12: 'XII',
        13: 'XIII',
        14: 'XIV',
        15: 'XV',
    };

    export default Vue.extend({
        name: 'PolicyInfoCarrierInfo',
        components: {
            PolicyRecord,
        },
        props: {
            carrier: {
                type: Object as PropType<InsuranceCarrier>,
                required: true,
            },
            getEvaluationResult: {
                type: Function as PropType<(url: string) => FieldEvaluationResultError | null>,
                required: true,
            },
        },
        computed: {
            isShowingAmBestRatingRecord(): boolean {
                return isShowingAmBestRatingRecord(this.carrier, this.getEvaluationResult);
            },
            isShowingAmBestFinancialSizeCategory(): boolean {
                return isShowingAmBestFinancialSizeCategory(this.carrier, this.getEvaluationResult);
            },
            isShowingNaicNumber(): boolean {
                return isShowingNaicNumber(this.carrier, this.getEvaluationResult);
            },
            isShowingName(): boolean {
                return isShowingName(this.carrier, this.getEvaluationResult);
            },
            numberToAmBestFinancialSize(): string {
                const amBest = this.carrier.amBestFinancialSizeCategory;
                return amBest ? amBestCategories[amBest] || amBest.toString() : FIELD_NOT_AVAILABLE_MESSAGE;
            },
        },
        methods: {
            getOptionalPolicyRecord(value: string | null): string {
                return value ?? FIELD_NOT_AVAILABLE_MESSAGE;
            },
            goToCriterion(coverageCriteriaGroupId: string): void {
                this.$emit('go-to-criterion', coverageCriteriaGroupId);
            },
            grantExceptionToCriterion(criterionId: string): void {
                this.$emit('grant-exception-to-criterion', criterionId);
            },
            removeExceptions(exceptionIds: string[]): void {
                this.$emit('remove-exceptions', exceptionIds);
            },
            // TODO(PRODUCT-18675): remove any after the migration to the newer version.
            openAMBestRatingDisclaimer(): void {
                (this as any).$procedures.execute('aMBestRatingDisclaimerProcedure', {});
            },
        },
    });
</script>
