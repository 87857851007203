<template>
    <v-component
        :is="component"
        :template="template"
        :criterion="criterion"
        :insured-fields="insuredFields"
        :load-criterion-message="loadCriterionMessage"
        @input="$emit('input', $event)"
        @reference-accessed="$emit('reference-accessed', $event)"
        @controller-value-update="$emit('controller-value-update', $event)"
        @update:visible-schemas="$emit('update:visible-schemas', $event)"
    />
</template>

<script lang="ts">
    import { Vue } from '@evidentid/vue-property-decorator';
    import {
        InsuranceCoverageCriterion,
        InsuranceCoverageCriterionInput,
        InsuranceCoverageCriterionTemplate,
        InsuranceInsuredField,
    } from '@evidentid/rpweb-api-client/types';
    import { PropType } from 'vue';
    import EidCoverageCriterion
        from '@/modules/decisioning-criteria/components/EidCoverageCriterion/EidCoverageCriterion.vue';
    import CoverageCriterion from '@/modules/decisioning-criteria/components/CoverageCriterion/CoverageCriterion.vue';
    import PublicProductsLiabilityCriterion
        from '@/modules/decisioning-criteria/components/PublicProductsLiabilityCriterion/PublicProductsLiabilityCriterion.vue';
    import { LoadCriterionMessage } from '@/modules/decisioning-criteria/models/CriterionSettingLoadMessageFunc.model';
    import {
        isNewJsonSchemaForm,
        isPublicOrProductsLiability,
    } from './helpers';

    interface CriterionCompCondition {
        conditionFunction: Function;
        args: any[];
        component: Vue.Component;
    }

    export default Vue.extend({
        name: 'DynamicCriterionSetting',
        components: {
            PublicProductsLiabilityCriterion,
            CoverageCriterion,
            EidCoverageCriterion,
        },
        props: {
            criterion: {
                type: Object as PropType<InsuranceCoverageCriterion | InsuranceCoverageCriterionInput>,
                required: true,
            },
            template: {
                type: Object as PropType<InsuranceCoverageCriterionTemplate>,
                required: true,
            },
            loadCriterionMessage: {
                type: Function as PropType<LoadCriterionMessage>,
                required: true,
            },
            insuredFields: {
                type: Array as PropType<InsuranceInsuredField[]>,
                default: () => [] as InsuranceInsuredField[],
            },
        },
        computed: {
            rpName(): string {
                return this.$route.params.rpId;
            },
            component(): Vue.Component {
                return this.getComponent(this.criterionCompConditions);
            },
            criterionCompConditions(): CriterionCompCondition[] {
                return [
                    this.getCondition(isNewJsonSchemaForm, EidCoverageCriterion),
                    this.getCondition(isPublicOrProductsLiability, PublicProductsLiabilityCriterion),
                ];
            },
        },
        methods: {
            getCondition(
                conditionFunction: Function,
                component: Vue.Component,
                optionalArgs?: any[],
            ): CriterionCompCondition {
                const args = optionalArgs || [ this.template ];
                return { conditionFunction, args, component };
            },
            getComponent(criterionConditions: CriterionCompCondition[]): Vue.Component {
                for (const { conditionFunction, args, component } of criterionConditions) {
                    if (conditionFunction(...args)) {
                        return component;
                    }
                }
                return CoverageCriterion;
            },
        },
    });
</script>
