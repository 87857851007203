<template>
    <div class="InsuredFieldFilterField">
        <ObjectFilterInput
            v-if="type === 'object' && schema"
            ref="objectRef"
            :value="value"
            :schema="schema"
            :filter-key="filterKey"
            @input="$emit('input', $event)"
            @scroll-to="$emit('scroll-to', $event)"
        />
        <BasicFilterInput
            v-else
            :value="value"
            :type="type"
            :label="label"
            :placeholder="placeholder"
            @input="$emit('input', { filterKey, value:$event })"
            @scroll-to="$emit('scroll-to', $event)"
        />
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import JsonSchema from '@evidentid/json-schema/interfaces/JsonSchema';
    import BasicFilterInput from '@/modules/insured-filtering/components/BasicFilterInput/BasicFilterInput.vue';
    import ObjectFilterInput from '@/modules/insured-filtering/components/ObjectFilterInput/ObjectFilterInput.vue';

    export default Vue.extend({
        name: 'InsuredFieldFilterField',
        components: {
            ObjectFilterInput,
            BasicFilterInput,
        },
        props: {
            type: {
                type: String as PropType<'text' | 'number' | 'integer' | 'boolean'>,
                default: 'text',
            },
            label: {
                type: String as PropType<string>,
                default: '',
            },
            placeholder: {
                type: String as PropType<string>,
                default: '',
            },
            value: {
                type: [ String, Number, Object ] as PropType<string | number | object | null | undefined>,
                default: undefined,
            },
            schema: {
                type: Object as PropType<JsonSchema>,
                default: undefined,
            },
            filterKey: {
                type: String as PropType<string>,
                required: true,
            },
        },
    });
</script>
