<template>
    <div class="EntityNameMatchingEntityNames">
        <div class="EntityNameMatchingEntityNames__valueError">
            <FontAwesomeIcon :icon="faExclamationTriangle" />
            <span>Name must appear as:</span>
        </div>
        <div v-if="entityNames.displayName" class="EntityNameMatchingEntityNames__valueError">
            <span>Display Name: {{ entityNames.displayName }}</span>
        </div>
        <div v-if="entityNames.legalName" class="EntityNameMatchingEntityNames__valueError">
            <span>Legal Name: {{ entityNames.legalName }}</span>
        </div>
        <div
            v-if="entityNames.doingBusinessAs.length > 0"
            class="EntityNameMatchingEntityNames__valueError"
        >
            <span>DBA(s): {{ entityNames.doingBusinessAs.join(', ') }}</span>
        </div>
    </div>
</template>

<style lang="scss">
    .EntityNameMatchingEntityNames__valueError {
        margin-top: 5px;
        color: #f05b46;
        font-size: 0.8rem;

        svg {
            margin-right: 5px;
        }
    }
</style>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
    import { ActionReviewEntityNames } from '@evidentid/insurance-facing-lib/models/insured-actions-review';

    export default Vue.extend({
        name: 'EntityNameMatchingEntityNames',
        components: {
            FontAwesomeIcon,
        },
        props: {
            entityNames: {
                type: Object as PropType<ActionReviewEntityNames>,
                required: true,
            },
        },
        data() {
            return { faExclamationTriangle };
        },
    });
</script>
