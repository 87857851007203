<template>
    <div class="WebhooksSection">
        <div
            data-test-id="WebhooksSection__sectionTitle"
            class="font-medium mb-2"
        >
            Webhooks
        </div>
        <div
            data-test-id="WebhooksSection__sectionContainer"
            class="py-4 bg-white"
        >
            <WebhookSectionsTable
                :webhooks="webhooks"
                :webhook-types="webhookTypes"
                :updating="updating"
                :deleting="deleting"
                @change="$emit('change', $event)"
                @delete="$emit('delete', $event)"
            />
        </div>
    </div>
</template>

<script lang="ts">
    import { Vue } from '@evidentid/vue-property-decorator';
    import WebhookSectionsTable from '@/modules/api-settings/components/webhooks-table/WebhooksTable.vue';
    import { PropType } from 'vue';
    import { InsuranceWebhook, InsuranceWebhookType } from '@evidentid/insurance-facing-lib/models/api-settings';

    export default Vue.extend({
        name: 'WebhooksSection',
        components: { WebhookSectionsTable },
        props: {
            webhooks: {
                type: Array as PropType<InsuranceWebhook[]>,
                default: () => [] as InsuranceWebhook[],
            },
            webhookTypes: {
                type: Array as PropType<InsuranceWebhookType[]>,
                default: () => [] as InsuranceWebhook[],
            },
            updating: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            deleting: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },
    });
</script>
