import {
    ActionMatchType,
    ActionType,
    EntityNameMatchingActionDetails,
} from '@evidentid/insurance-facing-lib/models/insured-actions-review';
import { ActionDetails } from '@evidentid/insurance-facing-lib/models/insured-actions-review/commons/ActionDetails.model';
import { InsuredActionScopeType } from '@/modules/insured-actions-review/types';

const actionScopeMap: Partial<Record<ActionType, InsuredActionScopeType>> = {
    [ActionType.endorsementFormNumberV1]: InsuredActionScopeType.global,
    [ActionType.evaluatedEntityMappingV1]: InsuredActionScopeType.insured,
    [ActionType.nonExtractionCriterionResolutionV1]: InsuredActionScopeType.insured,
    [ActionType.complianceWithCategoryResolution]: InsuredActionScopeType.global,
};

export function getActionScopeType(insuredAction: ActionDetails): InsuredActionScopeType | null {
    if (insuredAction.$action === ActionType.entityNameMatchingV1) {
        return (insuredAction as EntityNameMatchingActionDetails).matchType === ActionMatchType.insured
            ? InsuredActionScopeType.insured
            : InsuredActionScopeType.global;
    }

    return actionScopeMap[insuredAction.$action] || null;
}
