<template>
    <Row class="CollateralMappingTableRow" hoverable>
        <Cell
            data-test-id="CollateralMappingTableRow__identifier"
        >
            <div class="flex flex-col">
                <span
                    data-test-id="CollateralMappingTableRow__description"
                    class="text-eidDarkBlue"
                >
                    {{ collateralEntity.description }}
                </span>
                <span data-test-id="CollateralMappingTableRow__uniqueIdentifier" class="text-eidDarkBlue-50">
                    {{ collateralEntity.uniqueIdentifier }}
                </span>
            </div>
        </Cell>
        <Cell class="w-0" data-test-id="CollateralMappingTableRow__providedOptions">
            <div
                class="flex justify-center items-center w-[400px]"
                data-test-id="CollateralMappingTableRow__providedCollateralContainer"
            >
                <div
                    v-if="!noMappingIcon"
                    class="flex justify-center item-center mr-2.5 w-5"
                    data-test-id="CollateralMappingTableRow__iconContainer"
                >
                    <EidIcon
                        v-if="statusIcon"
                        :class="[
                            'CollateralMappingTableRow__statusIcon',
                            `CollateralMappingTableRow__statusIcon--${statusState}`,
                            { 'CollateralMappingTableRow__statusIcon--basedOnAction': optionsBasedOnAction }
                        ]"
                        :data-test-id="`CollateralMappingTableRow__statusIcon--${statusState}`"
                        :icon-src="statusIcon"
                    />
                </div>
                <Dropdown
                    class="w-full"
                    data-test-id="CollateralMappingTableRow__providedOptionDropdown"
                    :placeholder="dropdownPlaceholder"
                    :options="options"
                    :selected="selectedOptions"
                    :disabled="isNoMatch"
                    @input="onCollateralSelection"
                />
            </div>
        </Cell>
        <Cell data-test-id="CollateralMappingTableRow__noMatch">
            <div
                class="flex justify-center items-center"
            >
                <Checkbox
                    :value="isNoMatch"
                    @input="toggleNoMatch"
                >
                    No Match
                </Checkbox>
                <div class="w-[20px]">
                    <EidIcon
                        v-if="shouldShowResetButton"
                        v-tooltip="'Clear selections made. You may save after clearing a selection.'"
                        class="w-[20px] text-eidDarkBlue-75 ml-2 cursor-pointer hover:opacity-70"
                        data-test-id="CollateralMappingTableRow__resetMappingIcon"
                        :icon-src="faRotateLeft"
                        :icon-props="{size: 'lg'}"
                        @click="resetMapping"
                    />
                </div>
            </div>
        </Cell>
        <Cell data-test-id="CollateralMappingTableRow__viewCoi">
            <div
                v-if="shouldShowCoi"
                data-test-id="CollateralTabRow__viewCoiButton"
                class="text-eidQualityBlack-50 text-sm cursor-pointer hover:opacity-70 inline-flex"
                @click="showCoi"
            >
                <EidIcon
                    class="text-base"
                    :icon-src="viewCoiIcon"
                    alt="view coi"
                />
                <span class="inline-block ml-2">View COI</span>
            </div>
        </Cell>
    </Row>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import isEqual from 'lodash/isEqual';
    import { faFileAlt, faUser } from '@fortawesome/free-regular-svg-icons';
    import { faExclamationTriangle, faRotateLeft } from '@fortawesome/free-solid-svg-icons';
    import { CollateralEntity } from '@evidentid/rpweb-api-client/models/CollateralEntity.model';
    import { ConciseCollateral } from '@evidentid/rpweb-api-client/models/ConciseCollateral.model';
    import { InsuranceComplianceStatus } from '@evidentid/insurance-facing-lib/models/insured-details';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { Cell, Row } from '@evidentid/dashboard-commons/components/Table';
    import { Checkbox } from '@evidentid/dashboard-commons/components/Checkbox';
    import { Dropdown } from '@evidentid/dashboard-commons/components/Dropdown';
    import { DropdownOption } from '@evidentid/dashboard-commons/components/Dropdown/types';
    import actionReviewSvg from '@/assets/icons/nav-icon-actions-review.svg';
    import {
        getCollateralMappingOptionStatus,
    } from '@/modules/insured-details/utils/getCollateralMappingOptionStatus/getCollateralMappingOptionStatus';
    import { CollateralMappingStatus } from '@/modules/insured-details/models/CollateralMappingStatus.model';
    import { CollateralMappingType } from '@evidentid/rpweb-api-client/models/CollateralMappingType.model';

    export default Vue.extend({
        name: 'CollateralMappingTableRow',
        components: {
            Cell,
            Checkbox,
            Dropdown,
            EidIcon,
            Row,
        },
        props: {
            collateralEntity: {
                type: Object as PropType<CollateralEntity>,
                default: () => ({}) as CollateralEntity,
            },
            providedCollaterals: {
                type: Array as PropType<ConciseCollateral[]>,
                default: () => [] as ConciseCollateral[],
            },
            // the reason this prop needed is that we want to preserve icon space for any row that has no so
            // the dropdown will be aligned with the rows that has icon. however when all rows has no icon
            // we want to remove this preserved space so columns will align with header
            noMappingIcon: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            isNoMatch: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            optionsBasedOnAction: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },
        data() {
            return {
                faRotateLeft,
                selectedOptions: [] as DropdownOption[],
                viewCoiIcon: faFileAlt,
            };
        },
        computed: {
            statusState(): CollateralMappingStatus {
                if (this.isNoMatch) {
                    return CollateralMappingStatus.manual;
                }
                return getCollateralMappingOptionStatus(this.providedCollaterals, this.collateralEntity);
            },
            statusIcon(): any {
                if (this.optionsBasedOnAction && this.statusState === CollateralMappingStatus.auto) {
                    return actionReviewSvg;
                }

                switch (this.statusState) {
                    case CollateralMappingStatus.actionReview:
                        return actionReviewSvg;
                    case CollateralMappingStatus.manual:
                        return faUser;
                    case CollateralMappingStatus.error:
                        return faExclamationTriangle;
                    default:
                        return null;
                }
            },
            dropdownPlaceholder(): string {
                return this.isNoMatch ? 'N/A' : 'Choose Mapping';
            },
            isNonCompliantWithoutCoverage(): boolean {
                return this.collateralEntity?.complianceStatus === InsuranceComplianceStatus.nonCompliant
                    && !this.collateralEntity.coverageType;
            },
            shouldShowCoi(): boolean {
                return Boolean(this.providedCollaterals?.length > 0 &&
                    !this.isNonCompliantWithoutCoverage &&
                    ![ InsuranceComplianceStatus.pending, InsuranceComplianceStatus.new ]
                        .includes(this.collateralEntity.complianceStatus),
                );
            },
            options(): DropdownOption[] {
                return this.providedCollaterals.map((collateral) => {
                    const label = this.getCollateralLabel(collateral);
                    return {
                        value: { ...collateral },
                        label,
                    };
                });
            },
            selectedCollateral(): ConciseCollateral | null {
                return this.selectedOptions[0]?.value || null;
            },
            shouldShowResetButton(): boolean {
                return this.statusState === CollateralMappingStatus.manual;
            },
        },
        watch: {
            collateralEntity: {
                immediate: true,
                handler() {
                    const option =
                        this.options.find((x) => isEqual(x.value, this.collateralEntity.mapping?.collateral));
                    this.selectedOptions = option ? [ option ] : [];
                },
            },
        },
        methods: {
            resetMapping(): void {
                this.selectedOptions = [];
                this.$emit('update-collateral', {
                    ...this.collateralEntity,
                    mapping: null,
                });
                if (this.isNoMatch) {
                    this.$emit('update-no-match', this.collateralEntity?.evaluatedEntityId);
                }
            },
            onCollateralSelection(option?: DropdownOption): void {
                if (option) {
                    this.selectedOptions = [ option ];
                }
                this.$emit('update-collateral', {
                    ...this.collateralEntity,
                    mapping: { mappingType: CollateralMappingType.manual, collateral: this.selectedCollateral },
                });
            },
            toggleNoMatch(checked: boolean): void {
                const mapping = !checked && !this.selectedCollateral
                    ? null
                    : { mappingType: CollateralMappingType.manual, collateral: this.selectedCollateral };

                this.$emit('update-collateral', {
                    ...this.collateralEntity,
                    mapping,
                });
                this.$emit('update-no-match', this.collateralEntity?.evaluatedEntityId);
            },
            showCoi(): void {
                this.$emit('show-coi', this.collateralEntity.coverageType);
            },
            getCollateralLabel(collateral: ConciseCollateral): string {
                const notProvided = 'No description/identifier provided';
                const separator = collateral.description && collateral.uniqueIdentifier ? ' - ' : '';
                return !collateral.description && !collateral.uniqueIdentifier
                    ? notProvided
                    : `${collateral.description || ''}${separator}${collateral.uniqueIdentifier || ''}`;
            },
        },
    });
</script>
