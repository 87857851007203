import {
    InsuranceInsuredCoverageCriteriaGroup,
    InsuranceInsuredCoverageDetails,
} from '@evidentid/rpweb-api-client/types';
import {
    InsuranceComplianceStatus,
    InsuranceEvaluationResult,
} from '@evidentid/insurance-facing-lib/models/insured-details';
import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';

function getCoverageCriteriaGroupName(
    ccgId: string,
    insuredCoverageCriteriaGroups: InsuranceInsuredCoverageCriteriaGroup[],
): string {
    return insuredCoverageCriteriaGroups.find((ccg) => ccgId === ccg.id)?.displayName || '';
}

// eslint-disable-next-line max-params
function getFieldEvaluationResultError(
    evaluationResult: InsuranceEvaluationResult,
    fieldEvaluationError: FieldEvaluationResultError,
    insuredCoverageCriteriaGroups: InsuranceInsuredCoverageCriteriaGroup[],
    { errorMessage, isMultiFieldCriterion }: { errorMessage: string, isMultiFieldCriterion: boolean },
    coverageDetails: InsuranceInsuredCoverageDetails,
): FieldEvaluationResultError {
    const evaluationError = fieldEvaluationError || {
        messages: [],
        criterionId: [],
        criterionNames: [],
        complianceStatusMetadata: [],
        evaluatedEntityId: evaluationResult.evaluatedEntityId,
        useDuringComplianceCalculation: evaluationResult.useDuringComplianceCalculation,
    };
    evaluationError.messages.push({
        message: errorMessage,
        coverageCriteriaGroupName: getCoverageCriteriaGroupName(
            evaluationResult.coverageCriteriaGroupId, insuredCoverageCriteriaGroups),
    });
    evaluationError.coverageCriteriaGroupId = evaluationResult.coverageCriteriaGroupId;
    evaluationError.criterionId.push(evaluationResult.criterionId);
    evaluationError.criterionNames.push(evaluationResult.criterionName);
    evaluationError.isMultiFieldCriterion = isMultiFieldCriterion;

    evaluationError.messages = uniqWith(evaluationError.messages, isEqual);
    evaluationError.criterionId = [ ...new Set(evaluationError.criterionId) ];
    evaluationError.criterionNames = [ ...new Set(evaluationError.criterionNames) ];
    evaluationError.coverageDetailsBelongTo = coverageDetails;
    if (evaluationResult.complianceStatusMetadata) {
        evaluationError.complianceStatusMetadata.push(evaluationResult.complianceStatusMetadata);
    }

    return evaluationError;
}

function mapErrorFromFailures({
    evaluationResult,
    evaluationErrors,
    insuredCoverageCriteriaGroups,
    coverageDetails,
    nonCompliantError,
}: {
    evaluationResult: InsuranceEvaluationResult;
    evaluationErrors: Record<string, FieldEvaluationResultError>;
    insuredCoverageCriteriaGroups: InsuranceInsuredCoverageCriteriaGroup[];
    coverageDetails: InsuranceInsuredCoverageDetails;
    nonCompliantError?: string;
  }
): void {
    evaluationResult.failures.forEach((failure) => {
        const match = /#.*?:/.exec(failure);
        if (match && match.length > 0) {
            const key = `${match[0].slice(0, -1)}`;
            const errorMessage = nonCompliantError || failure.split(':')[1].trim();
            if (key && errorMessage) {
                evaluationErrors[key] = getFieldEvaluationResultError(
                    evaluationResult,
                    evaluationErrors[key],
                    insuredCoverageCriteriaGroups,
                    {
                        errorMessage,
                        isMultiFieldCriterion: false,
                    },
                    coverageDetails,
                );
            }
        }
    });
}

function addOrAdjustErrorEntry(
    evaluationResult: InsuranceEvaluationResult,
    insuredCoverageCriteriaGroups: InsuranceInsuredCoverageCriteriaGroup[],
    evaluationErrors: Record<string, FieldEvaluationResultError>,
    coverageDetails: InsuranceInsuredCoverageDetails,
): void {
    evaluationResult.usedFields.forEach((field) => {
        if (field) {
            evaluationErrors[field] = getFieldEvaluationResultError(
                evaluationResult,
                evaluationErrors[field],
                insuredCoverageCriteriaGroups,
                {
                    errorMessage: evaluationResult.nonComplianceMessage || '',
                    isMultiFieldCriterion: evaluationResult.usedFields.length > 1,
                },
                coverageDetails,
            );
        }
    });
}

function addMultiCoverageFieldEntry(
    evaluationResult: InsuranceEvaluationResult,
    insuredCoverageCriteriaGroups: InsuranceInsuredCoverageCriteriaGroup[],
    evaluationErrors: Record<string, FieldEvaluationResultError>,
    coverageDetailsBelongTo: InsuranceInsuredCoverageDetails,
): void {
    evaluationErrors[evaluationResult.criterionName] = {
        messages: [ {
            message: evaluationResult.nonComplianceMessage || '',
            coverageCriteriaGroupName: getCoverageCriteriaGroupName(
                evaluationResult.coverageCriteriaGroupId, insuredCoverageCriteriaGroups),
        } ],
        complianceStatusMetadata: evaluationResult.complianceStatusMetadata
            ? [ evaluationResult.complianceStatusMetadata ]
            : [],
        coverageCriteriaGroupId: evaluationResult.coverageCriteriaGroupId,
        criterionId: [ evaluationResult.criterionId ],
        criterionNames: [ evaluationResult.criterionName ],
        coverageDetailsBelongTo,
        isMultiFieldCriterion: evaluationResult.usedFields.length > 1,
    };
}

export function mapEvaluationErrors(
    evaluationResults: InsuranceEvaluationResult[],
    insuredCoverageCriteriaGroups: InsuranceInsuredCoverageCriteriaGroup[],
    coverageDetails: InsuranceInsuredCoverageDetails,
): Record<string, FieldEvaluationResultError> {
    // TODO[PRODUCT-19526] whole function and flow may need revisit, may seperate single field and multi into two maps
    return evaluationResults
        .filter((x) => (x.result === InsuranceComplianceStatus.nonCompliant
            || Boolean(x.complianceStatusMetadata)) && x.useDuringComplianceCalculation)
        .reduce((evaluationErrors, evaluationResult) => {
            if ((!evaluationResult.usedFields || evaluationResult.usedFields.length === 0) &&
                evaluationResult.failures.length > 0
            ) {
                mapErrorFromFailures(
                    {
                        evaluationResult,
                        evaluationErrors,
                        insuredCoverageCriteriaGroups,
                        coverageDetails,
                        nonCompliantError: evaluationResult.nonComplianceMessage,
                    }
                );
            } else if (evaluationResult.usedFields.length > 1) {
                addMultiCoverageFieldEntry(
                    evaluationResult, insuredCoverageCriteriaGroups, evaluationErrors, coverageDetails,
                );
            } else {
                addOrAdjustErrorEntry(
                    evaluationResult, insuredCoverageCriteriaGroups, evaluationErrors, coverageDetails,
                );
            }
            return evaluationErrors;
        }, {} as Record<string, FieldEvaluationResultError>);
}
