import {
    getCsvObjectPropIndexesMap,
} from '@/modules/insured-management/utils/get-csv-values-with-combined-objects/getCsvValuesWithCombinedObjects';

/**
 * takes in CSV headers that has object flattened columns and turns into headerKeys with combined object name
 * for example ['someStr', 'obj.a', 'obj.b', 'obj.c', 'someNum' ] => ['someStr', 'obj', 'someNum']
 * @param {string[]} headerKeys Keys of each insured/insuredField property
 * @param {string[]} objectKeys Keys of object field where they were broken down in to multiple columns in the csv
 * @return {string[]} The result new header keys that has single column for each object property
 */
export function getCsvColumnKeysWithCombinedObjects(
    headerKeys: string[], objectKeys: string[],
): string[] {
    const objsPropIndexes = getCsvObjectPropIndexesMap(headerKeys, objectKeys, '.');
    const skippingIndexes = Object.values(objsPropIndexes).reduce(
        (acc, i) => [ ...acc, ...i.slice(1) ],
        [],
    );
    const newHeaders = [ ...headerKeys ];
    Object.entries(objsPropIndexes).forEach(([ key, indexes ]) => {
        newHeaders[indexes[0]] = key;
    });
    return newHeaders.filter((_, i) => !skippingIndexes.includes(i));
}
