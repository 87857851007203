<template>
    <div class="CoverageCriteriaGroupTable">
        <Table>
            <template #head>
                <HeadCell class="CoverageCriteriaGroupTable__nameHeader">
                    Group Name
                </HeadCell>
                <HeadCell class="CoverageCriteriaGroupTable__decisionHeader">
                    Decisioning
                </HeadCell>
                <HeadCell class="CoverageCriteriaGroupTable__coverageTypesHeader">
                    Coverage Types
                </HeadCell>
                <HeadCell class="CoverageCriteriaGroupTable__insuredsAssignedHeader">
                    Insureds Assigned
                </HeadCell>
                <HeadCell class="CoverageCriteriaGroupTable__actionsHeader" />
            </template>

            <template #toolbarEnd>
                <Button
                    class="CoverageCriteriaGroupTable__addGroupButton"
                    type="primary"
                    @click="$emit('create')"
                >
                    Add Group
                </Button>
            </template>
            <CoverageCriteriaGroupTableRow
                v-for="group of groups"
                :key="group.id"
                :group="group"
                :show-ccg-type-icon="isCollateralEnabled"
                v-on="$listeners"
            />
        </Table>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { InsuranceCoverageCriteriaGroup, InsuranceRequestsConfig } from '@evidentid/rpweb-api-client/types';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { HeadCell, Table } from '@evidentid/dashboard-commons/components/Table';
    import CoverageCriteriaGroupTableRow
        from '@/modules/decisioning-criteria/components/CoverageCriteriaGroupTableRow/CoverageCriteriaGroupTableRow.vue';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { createEmptyRequestsConfigStatus } from '@/modules/dashboard/vuex/dashboard';

    @Component({
        components: {
            Button, HeadCell, Table, CoverageCriteriaGroupTableRow,
        },
    })
    export default class CoverageCriteriaGroupTable extends Vue {
        @Prop({ type: Array, default: () => [] })
        private groups!: InsuranceCoverageCriteriaGroup[];

        private get rpName(): string {
            return this.$rp.current!;
        }

        private get requestsConfigStatus(): {
            status: OperationStatus;
            data: InsuranceRequestsConfig | null;
        } {
            return this.$store.state.dashboard.requestsConfig[this.rpName || ''] || createEmptyRequestsConfigStatus();
        }

        private get isCollateralEnabled(): boolean {
            return this.requestsConfigStatus.data?.collateralsEvaluationEnabled || false;
        }
    }
</script>
