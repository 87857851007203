import orderBy from 'lodash/orderBy';
import startCase from 'lodash/startCase';
import { Many, ListIteratee } from 'lodash';
import { InsuranceInsuredField } from '@evidentid/rpweb-api-client/types';
import JsonSchema, {
    JsonSchemaArray,
    JsonSchemaBasicObject,
    JsonSchemaType,
    RegularJsonSchema,
} from '@evidentid/json-schema/interfaces/JsonSchema';
import { isCollateralInsuredField } from '@/utils/isCollateralInsuredField';

const insuredFieldCollateralOrder = [
    'description',
    'category',
    'uniqueIdentifier',
    'limitRequired',
    'maximumDeductible',
];

const schemas: Record<string, JsonSchema> = {
    displayName: {
        type: JsonSchemaType.string,
        minLength: 1,
        title: 'Display Name',
        description: 'The display name is a custom name unique to this entity.',
        placeholder: 'Enter product display name',
        // FIXME: a proper way to validate trimmed string for empty check
        pattern: '^(?!\\s+$)',
    },
    legalName: {
        type: JsonSchemaType.string,
        title: 'Legal Name',
        description: 'The legal business name is the official name of the entity that appears on legal and government forms.',
        placeholder: 'Enter the name found on the COI if applicable',
        pattern: '^(?!\\s+$)',
    },
    doingBusinessAs: {
        type: JsonSchemaType.array,
        title: 'DBA Name(s)',
        addItemTitle: 'ADD DBA',
        items: {
            type: JsonSchemaType.string,
            description: 'The “doing business as” (DBA) name is a different name the entity uses publicly instead of the legal name. ',
            placeholder: 'Enter the name the entity uses if applicable',
            minLength: 1,
            pattern: '^(?!\\s+$)',
        } as JsonSchema,
    },
    contactEmail: {
        type: JsonSchemaType.string,
        format: 'email',
        minLength: 1,
        title: 'Primary Contact Email',
        placeholder: 'Enter entity email',
    },
    contactName: {
        type: JsonSchemaType.string,
        title: 'Primary Contact Name',
        placeholder: 'Enter contact name',
    },
    contactPhoneNumber: {
        type: JsonSchemaType.string,
        format: 'phone',
        title: 'Primary Contact Phone Number',
    },
};

const commonPropertiesOrder = [
    'contactEmail',
    'contactName',
    'contactPhoneNumber',
    'insuredFields',
    'exceptions',
];
const commonRequiredFields = [
    'contactEmail',
    'insuredFields',
    'exceptions',
];

function getInsuredFieldSchema(
    insuredFields: InsuranceInsuredField[],
    insuredFieldsSortingIteratees: Many<ListIteratee<InsuranceInsuredField>> = (_, index) => index,
    insuredFieldOrders: Parameters<typeof orderBy>[2] = [],
): JsonSchema {
    return {
        type: JsonSchemaType.object,
        properties: {
            ...insuredFields.reduce((accu, field) => {
                let property;
                if (isCollateralInsuredField(field)) {
                    property = {
                        ...field.schema,
                        items: {
                            ...(field.schema as any).items,
                            propertiesOrder: insuredFieldCollateralOrder,
                        },
                    } as JsonSchemaArray;
                } else {
                    const notSpaceOnlyRegex = '^(?!\\s+$)';
                    property = {
                        ...field.schema,
                        // FIXME: a proper way to validate trimmed string for empty check
                        ...(field.required && (field.schema as RegularJsonSchema).type === 'string' && { pattern: notSpaceOnlyRegex }),
                    };
                }
                if ((field.schema as RegularJsonSchema).type === JsonSchemaType.array && field.required) {
                    (property as JsonSchemaArray).minItems = 1;
                }
                accu[field.key] = property;
                return accu;
            }, {} as JsonSchemaBasicObject['properties']),
        },
        propertiesOrder: orderBy(insuredFields, insuredFieldsSortingIteratees, insuredFieldOrders)
            .map((x) => x.key),
        required: insuredFields.filter((x) => x.required).map((x) => x.key),
    };
}

function getExceptionsSchema(coverageTypes: string[]): JsonSchema {
    const coverageTypeKeyLabels = coverageTypes.map((x) => (
        { key: x, label: startCase(x.toLowerCase().replace(/_/g, ' ')) }),
    );
    return {
        type: JsonSchemaType.object,
        properties: coverageTypeKeyLabels.reduce((accu, coverageType) => ({
            ...accu, [coverageType.key]: {
                type: JsonSchemaType.string,
                format: 'date',
                title: `Existing ${coverageType.label} Policy Expiration Date`,
            },
        }), {} as JsonSchemaBasicObject['properties']),
        propertiesOrder: [ ...coverageTypes ],
        required: [],
    };
}

export function buildSingleInsuredImportJsonSchema(
    insuredFields: InsuranceInsuredField[],
    coverageTypes: string[] = [],
    insuredFieldsSortingIteratees: Many<ListIteratee<InsuranceInsuredField>> = (_, index) => index,
    insuredFieldOrders: Parameters<typeof orderBy>[2] = []
): JsonSchemaBasicObject {
    return {
        type: JsonSchemaType.object,
        properties: {
            insuredName: {
                type: JsonSchemaType.object,
                title: 'Insured Name',
                properties: {
                    displayName: schemas.displayName,
                    legalName: schemas.legalName,
                    doingBusinessAs: schemas.doingBusinessAs,
                },
                required: [ 'displayName' ],
                propertiesOrder: [ 'displayName', 'legalName', 'doingBusinessAs' ],
            },
            contactEmail: schemas.contactEmail,
            contactName: schemas.contactName,
            contactPhoneNumber: schemas.contactPhoneNumber,
            insuredFields: getInsuredFieldSchema(insuredFields, insuredFieldsSortingIteratees, insuredFieldOrders),
            exceptions: getExceptionsSchema(coverageTypes),
        },
        propertiesOrder: [ 'insuredName', ...commonPropertiesOrder ],
        required: [ 'insuredName', ...commonRequiredFields ],
    };
}

export function buildBulkInsuredImportJsonSchema(
    insuredFields: InsuranceInsuredField[],
    coverageTypes: string[] = [],
): JsonSchemaBasicObject {
    return {
        type: JsonSchemaType.object,
        properties: {
            displayName: schemas.displayName,
            legalName: schemas.legalName,
            doingBusinessAs: schemas.doingBusinessAs,
            contactEmail: schemas.contactEmail,
            contactName: schemas.contactName,
            contactPhoneNumber: schemas.contactPhoneNumber,
            insuredFields: getInsuredFieldSchema(insuredFields),
            exceptions: getExceptionsSchema(coverageTypes),
        },
        propertiesOrder: [ 'displayName', 'legalName', 'doingBusinessAs', ...commonPropertiesOrder ],
        required: [ 'displayName', ...commonRequiredFields ],
    };
}
