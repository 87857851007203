import cloneDeep from 'lodash/cloneDeep';
import JsonSchema, { JsonSchemaComplexObject } from '@evidentid/json-schema/interfaces/JsonSchema';
import { InsuranceInsuredField } from '@evidentid/rpweb-api-client/types';
import { getAllSubstitutableSchemas } from '@/modules/decisioning-criteria/utils/getAllSubstitutableSchemas';
import { convertInsuredFieldSchemaToEnumSchema } from '@/modules/decisioning-criteria/utils/criterionSchemaConversion';

export function standardizeInsuredFieldSchema(
    schema: JsonSchema|JsonSchemaComplexObject,
    insuredFields?: InsuranceInsuredField[],
): JsonSchema {
    const resultSchema = cloneDeep(schema);
    if (!insuredFields) {
        return resultSchema;
    }
    const substitutableSchemas = getAllSubstitutableSchemas(resultSchema);
    substitutableSchemas.forEach(({ schema: substitutableSchema }) => {
        const valueSchema = substitutableSchema.else.properties!.value;
        const insuredFieldSchema = substitutableSchema.then.properties!.insuredField;
        substitutableSchema.then.properties!.insuredField
            = convertInsuredFieldSchemaToEnumSchema(insuredFieldSchema, insuredFields, valueSchema);
    });
    return resultSchema;
}

