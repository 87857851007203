<template>
    <v-popover
        trigger="hover"
        :delay="{ show: 100, hide: 100 }"
        :disabled="disablePopover"
        placement="top"
        popover-class="ExceptionBadge__tooltip"
    >
        <Badge class="ExceptionBadge">
            <FontAwesomeIcon :icon="faInfoCircle" />
            {{ label }}
        </Badge>
        <template slot="popover">
            <div class="ExceptionBadge__popoverContent">
                <span v-if="exceptionTypeLabel" class="ExceptionBadge__popoverContentLine">
                    <strong>Type: </strong>
                    <span>{{ exceptionTypeLabel }}</span>
                </span>
                <span v-if="until" class="ExceptionBadge__popoverContentLine">
                    <strong>Expiration: </strong>
                    <span>{{ until || 'none' }}</span>
                </span>
                <span v-if="appliedToLabel" class="ExceptionBadge__popoverContentLine">
                    <strong>Applied to: </strong>
                    <span>{{ appliedToLabel }}</span>
                </span>
                <span v-if="requiredMessage" class="ExceptionBadge__popoverContentLine">
                    <strong>Required: </strong>
                    <span>{{ requiredMessage }}</span>
                </span>
            </div>
        </template>
    </v-popover>
</template>
<script lang="ts">
    import { Vue } from 'vue-property-decorator';
    import startCase from 'lodash/startCase';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
    import { Badge } from '@evidentid/dashboard-commons/components/Badge';
    import { PropType } from 'vue';
    import { InsuranceExceptionType } from '@evidentid/insurance-facing-lib/models/insured-details';

    export default Vue.extend({
        name: 'ExceptionBadge',
        components: {
            Badge, FontAwesomeIcon,
        },
        props: {
            label: {
                type: String as PropType<string>,
                default: 'Exception',
            },
            requiredMessage: {
                type: String as PropType<string>,
                default: '',
            },
            exceptionType: {
                type: String as PropType<string>,
                default: '',
            },
            appliedTo: {
                type: String as PropType<string>,
                default: '',
            },
            until: {
                type: [ String, null ] as PropType<string | null>,
                default: null,
            },
            disablePopover: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },
        data() {
            return {
                faInfoCircle,
            };
        },
        computed: {
            exceptionTypeLabel(): string {
                if (this.exceptionType === InsuranceExceptionType.untilCoverageExpiration) {
                    return 'Applies to Current Policy';
                }
                return startCase(this.exceptionType.toLowerCase());
            },
            appliedToLabel(): string {
                return startCase(this.appliedTo.toLowerCase());
            },
        },
    });

</script>
