import { reduce } from 'lodash';
import { DocumentAttributeRegexType } from '@/modules/insured-certificates/models/DocumentAttributeRegexType.model';
import { getDocumentAttributeRegex } from '@/modules/insured-certificates/services/getDocumentAttributeRegex';

// keeping only one coi doc per request as we only assume same coi used for all insurance coverage types per request
export function removeExtraCoiAcrossCoverage(rprDocsByAttribute: Record<string, any[]>) {
    let coiFound = false;
    const regex = getDocumentAttributeRegex(DocumentAttributeRegexType.coi);
    return reduce(rprDocsByAttribute, (result, value, key) => {
        if (regex.test(key)) {
            if (!coiFound) {
                result[key] = value;
                coiFound = true;
            }
        } else {
            result[key] = value;
        }
        return result;
    }, {} as Record<string, any[]>);
}
