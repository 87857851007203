<template>
    <div class="InsuredCoverageCriteriaGroups">
        <EmptyCoverageCriteriaGroups
            v-if="insuredCoverageCriteriaGroups.length === 0"
            class="InsuredCoverageCriteriaGroups__emptyView"
            @goToCriteriaGroupList="$emit('goToCriteriaGroupList')"
        />
        <CoverageCriteriaGroupsTable
            v-else
            :insured-coverage-criteria-groups="insuredCoverageCriteriaGroups"
            :insured="insured"
            @goToCriteriaGroup="$emit('goToCriteriaGroup', $event)"
        />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import {
        InsuranceInsured,
        InsuranceInsuredCoverageCriteriaGroup,
    } from '@evidentid/rpweb-api-client/types';
    import EmptyCoverageCriteriaGroups
        from '@/modules/insured-details/components/EmptyCoverageCriteriaGroups/EmptyCoverageCriteriaGroups.vue';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import CoverageCriteriaGroupsTable
        from '@/modules/insured-details/components/CoverageCriteriaGroupsTable/CoverageCriteriGroupsTable.vue';

    @Component({
        components: {
            CoverageCriteriaGroupsTable,
            EmptyCoverageCriteriaGroups,
        },
    })
    export default class InsuredCoverageCriteriaGroups extends Vue {
        @Prop({ type: Array, default: () => [] })
        private insuredCoverageCriteriaGroups!: InsuranceInsuredCoverageCriteriaGroup[];

        @Prop({ type: Object, required: true })
        private insured!: InsuranceInsured;
    }
</script>
