<template>
    <div class="CreateEditCoverageCriteriaGroup">
        <CoverageCriteriaGroupHeader
            :criteria-group-name="localGroup.displayName"
            :country-code="localGroup.countryCode"
            :criteria-group-active="localGroup.active"
            :ccg-type="ccgType"
            :save-disabled="saveDisabled"
            @input="onHeaderInput"
            @back="onBackAction"
            @save="attemptSave"
        >
            <template #headerMiddle>
                <Alert v-if="headerAlertData.text" type="success">
                    <EidIcon v-if="headerAlertData.icon" :icon-src="headerAlertData.icon" />
                    {{ headerAlertData.text }}
                </Alert>
            </template>
        </CoverageCriteriaGroupHeader>
        <div class="CreateEditCoverageCriteriaGroup__content">
            <div class="CreateEditCoverageCriteriaGroup__coverageTypeColumn">
                <CoverageTypeList
                    :coverage-types="coverageTypesWithTemplates"
                    :selected-coverage-type="currentCoverageType"
                    :coverage-models="coverageModels"
                    :criterion-templates-by-type="criteriaTemplatesByCoverageType"
                    :criteria-by-type="criteriaByCoverageType"
                    :has-deprecated-criteria="groupHasDeprecatedCriteria"
                    :has-deprecated-criteria-by-coverage-type="hasDeprecatedCriteriaByCoverageType"
                    :get-coverage-type-label="getCoverageTypeLabel"
                    :has-evaluation-rule="isEvaluationRuleCoverageTypesSelected"
                    :is-coverage-type-in-evaluation-rule="isCoverageTypeInEvaluationRule"
                    :is-reference-starts-with-is-invalid="isReferenceStartsWithIsInvalid"
                    :invalid-references="invalidReferences"
                    :disable-evaluation-rule="disableEvaluationRule"
                    @add="addCoverageType"
                    @delete="tryDeleteCoverageType"
                    @select="selectCoverageType"
                    @toggle-evaluation-rule-modal="toggleEvaluationRuleModal"
                />
            </div>
            <GroupAssignment
                :coverage-group-assignment="assignmentRule"
                :insured-fields="insuredFields"
                :assignment-option="assignmentOption"
                :show-error="startShowingAssignmentRuleError"
                @setAssignmentRuleOption="setAssignmentRuleOption"
                @remove="removeAssignmentRule"
                @openRuleEditor="openEditGroupAssignmentRulePanel"
            />
        </div>
        <CoverageGroupAssignmentRulePanel
            v-if="isEditGroupAssignmentRulePanelOpen"
            :rules="basicAssignmentRules"
            :insured-fields-status="insuredFieldsStatus"
            :loading-insured-fields="loadingInsuredFields"
            :collaterals-only="isCollateralGroup"
            @close="finishEditGroupAssignmentProcedure"
            @update-rules="updateRules"
        />
        <CoverageGroupCriteriaPanel
            v-if="currentCoverageType"
            :coverage-type="currentCoverageType"
            :coverage-models="coverageModels"
            :criteria-templates="currentCriteriaTemplates"
            :criteria="currentCriteria"
            :insured-fields="insuredFields"
            :accessed-reference-map="accessedMap"
            :has-deprecated-criteria="hasDeprecatedCriteriaByCoverageType(currentCoverageType)"
            :get-coverage-type-label="getCoverageTypeLabel"
            :evaluation-rule-coverage-types="evaluationRuleCoverageTypes"
            :is-reference-starts-with-is-invalid="isReferenceStartsWithIsInvalid"
            :invalid-references="invalidReferences"
            :country-code="group.countryCode"
            @close="deselectCoverageType"
            @input="updateCriteria"
            @reference-accessed="onReferenceAccessed"
            @validityChange="onValidityChanged"
        />
        <EvaluationRuleModal
            v-if="showEvaluationRuleModal"
            :evaluation-rule-coverage-types="evaluationRuleCoverageTypes"
            :get-coverage-type-label="getCoverageTypeLabel"
            :criteria-by-coverage-type="criteriaByCoverageType"
            :is-evaluation-rule-coverage-types-selected="isEvaluationRuleCoverageTypesSelected"
            @save="onSaveEvaluationRule"
            @delete="showEvaluationRuleConfirmDeleteModal"
            @close="toggleEvaluationRuleModal"
        />
        <ConfirmationModal
            v-if="confirmationModalData.text"
            additional-class-name="CreateEditCoverageCriteriaGroup__confirmation-modal"
            size="optimal"
            theme="default"
            yes="Delete"
            no="Cancel"
            :yes-icon="null"
            :no-icon="null"
            :destructive="true"
            @input="onConfirmDelete"
        >
            <template #header>
                Are you sure?
            </template>
            {{ confirmationModalData.text }}
        </ConfirmationModal>

        <CcgChangesWarningModal
            v-if="showCcgChangesWarningModal"
            :change-details="changeDetails"
            @revert="revertCcgChanges"
            @save="confirmSave"
        />
    </div>
</template>

<script lang="ts">
    import { PropType } from 'vue';
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import { flatten, intersectionWith, isEqual, omit, omitBy, startCase } from 'lodash';
    import differenceWith from 'lodash/differenceWith';
    import difference from 'lodash/difference';
    import orderBy from 'lodash/orderBy';
    import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
    import {
        InsuranceCoverageCriteriaGroup,
        InsuranceCoverageCriteriaGroupInput,
        InsuranceCoverageCriteriaGroupType,
        InsuranceCoverageCriterion,
        InsuranceCoverageCriterionBase,
        InsuranceCoverageCriterionInput,
        InsuranceCoverageCriterionTemplate,
        InsuranceCoverageModel,
        InsuranceInsuredField,
    } from '@evidentid/rpweb-api-client/types';
    import { InsuranceCoverageType } from '@evidentid/insurance-facing-lib/models/insured-details';
    import Alert from '@evidentid/dashboard-commons/components/Alert/Alert.vue';
    import Dropdown from '@evidentid/dashboard-commons/components/Dropdown/Dropdown.vue';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { ConfirmationModal } from '@evidentid/dashboard-commons/components/ConfirmationModal';
    import { Expandable } from '@evidentid/dashboard-commons/components/Expandable';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';
    import { Menu, MenuLink } from '@evidentid/dashboard-commons/components/Menu';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import CoverageCriteriaGroupHeader
        from '@/modules/decisioning-criteria/components/CoverageCriteriaGroupHeader/CoverageCriteriaGroupHeader.vue';
    import CoverageGroupAssignmentRulePanel
        from '@/modules/decisioning-criteria/components/CoverageGroupAssignmentRulePanel/CoverageGroupAssignmentRulePanel.vue';
    import CoverageGroupCriteriaPanel
        from '@/modules/decisioning-criteria/components/CoverageGroupCriteriaPanel/CoverageGroupCriteriaPanel.vue';
    import CoverageTypeList from '@/modules/decisioning-criteria/components/CoverageTypeList/CoverageTypeList.vue';
    import { InsuredFieldsListStatus } from '@/modules/decisioning-criteria/vuex';
    import GroupAssignment from '@/modules/decisioning-criteria/components/GroupAssignment/GroupAssignment.vue';
    import { BasicAssignmentRule, CoverageGroupAssignmentOptions } from '@/modules/decisioning-criteria/types';
    import { groupCriteriaByType } from '@/modules/decisioning-criteria/utils/coverageCriteria';
    import {
        convertToBasicAssignmentRules,
        encodeAssignmentRules,
    } from '@/modules/decisioning-criteria/utils/parseRulesLogic';
    import { CriterionBases } from '@/modules/decisioning-criteria/models/CriterionBases.model';
    import CcgChangesWarningModal
        from '@/modules/decisioning-criteria/components/CcgChangesWarningModal/CcgChangesWarningModal.vue';
    import { getCoverageTypeIcon } from '@/modules/insured-details/utils/insuredCoverage';
    import {
        CCG_AUTO_ADD_UMBRELLA_CREATE_GROUP_ID,
        isCcgAutoAddUmbrellaDisabled,
        startDisablingCcgAutoAddUmbrella,
        purgeCcgAutoAddUmbrellaPendingItems,
    } from '@/modules/decisioning-criteria/utils/autoAddUmbrella';

    const EvaluationRuleModal = () => import('@/modules/decisioning-criteria/components/EvaluationRuleModal/EvaluationRuleModal.vue');

    type CoverageTypesWithDeprecatedCriteria = Partial<Record<InsuranceCoverageType, boolean>>;
    type CoverageTypeCriteriaMap = Partial<Record<InsuranceCoverageType, InsuranceCoverageCriterionBase[]>>;

    interface ConfirmationModalData {
        text?: string;
        confirmDeleteFor?: 'coverageType' | 'evaluationRule';
        coverageType?: InsuranceCoverageType;
        isCoverageTypeInEvaluationRule?: boolean;
    }

    interface HeaderAlertData {
        text?: string;
        icon?: any;
    }

    function extractCoverageTypeFromCombinedReference(combinedRef: string): InsuranceCoverageType | null {
        return combinedRef.split('-')[0] as InsuranceCoverageType || null;
    }

    function isCriteriaEqual(a: InsuranceCoverageCriterionBase, b: InsuranceCoverageCriterionBase) {
        return a.coverageType === b.coverageType && a.field === b.field;
    }

    @Component({
        components: {
            Button,
            CcgChangesWarningModal,
            CoverageCriteriaGroupHeader,
            CoverageGroupCriteriaPanel,
            CoverageTypeList,
            Dropdown,
            Expandable,
            FormElement,
            FormInput,
            Menu,
            MenuLink,
            GroupAssignment,
            EvaluationRuleModal,
            ConfirmationModal,
            Alert,
            CoverageGroupAssignmentRulePanel,
            EidIcon,
        },
    })
    export default class CreateEditCoverageCriteriaGroup extends Vue {
        @Prop({ type: Object, default: null })
        private group!: InsuranceCoverageCriteriaGroup | InsuranceCoverageCriteriaGroupInput;

        @Prop({ type: Array as PropType<InsuranceCoverageType[]>, default: () => [] })
        private coverageTypes!: InsuranceCoverageType[];

        @Prop({ type: Array, default: () => [] })
        private coverageModels!: InsuranceCoverageModel[];

        @Prop({ type: Array, default: () => [] })
        private criteriaTemplates!: InsuranceCoverageCriterionTemplate[];

        @Prop({ type: Array, default: () => [] })
        private criteria!: InsuranceCoverageCriterion[];

        @Prop({ type: Array, default: () => [] })
        private originalCriteria!: InsuranceCoverageCriterion[];

        @Prop({ type: String, default: null })
        private currentCoverageType!: InsuranceCoverageType | null;

        @Prop({ type: Object as PropType<InsuredFieldsListStatus>, required: true })
        private insuredFieldsStatus!: InsuredFieldsListStatus;

        @Prop({ type: Boolean as PropType<boolean>, default: false })
        private loadingInsuredFields!: boolean;

        @Prop({ type: Boolean as PropType<boolean>, default: false })
        private loadingCoverageTypesAndCountries!: boolean;

        @Prop({ type: Boolean as PropType<boolean>, default: false })
        private collateralEnabled!: boolean;

        private invalidReferences: string[] = [];
        private accessedMap: Record<string, boolean> = {};
        private criteriaTemplatesByCoverageType: Record<string, InsuranceCoverageCriterionTemplate[]> =
            this.groupAndFilterCriteriaTemplates(this.criteriaTemplates);

        private criteriaByCoverageType: CoverageTypeCriteriaMap =
            groupCriteriaByType(this.criteria);

        private originalCriteriaByCoverageType: CoverageTypeCriteriaMap = groupCriteriaByType(this.originalCriteria);

        private localGroup: InsuranceCoverageCriteriaGroup | InsuranceCoverageCriteriaGroupInput = { ...this.group };
        private assignmentOption: CoverageGroupAssignmentOptions =
            this.localGroup.assignmentRule === true
                ? CoverageGroupAssignmentOptions.allInsureds
                : CoverageGroupAssignmentOptions.subsetOfInsureds;
        private startShowingAssignmentRuleError: boolean = false;
        private showEvaluationRuleModal: boolean = false;
        private confirmationModalData: ConfirmationModalData = {};
        private headerAlertData: HeaderAlertData = {};
        private faCheckCircle = faCheckCircle;
        private isEditGroupAssignmentRulePanelOpen: boolean = false;
        private changeDetails: string[] = [];
        private showCcgChangesWarningModal: boolean = false;
        private saveAsActive: boolean = true;
        private canAutoAddUmbrella: boolean = true;

        private get insuredFields(): InsuranceInsuredField[] {
            return this.insuredFieldsStatus.list;
        }

        private get groupIdForAutoUmbrella(): string {
            return 'id' in this.localGroup ? this.localGroup.id : CCG_AUTO_ADD_UMBRELLA_CREATE_GROUP_ID;
        }

        private get currentCriteriaTemplates(): InsuranceCoverageCriterionTemplate[] {
            return this.currentCoverageType ? this.criteriaTemplatesByCoverageType[this.currentCoverageType] || [] : [];
        }

        private get currentCriteria(): InsuranceCoverageCriterion[] | InsuranceCoverageCriterionInput[] {
            const criteria = this.currentCoverageType
                ? this.criteriaByCoverageType[this.currentCoverageType] || []
                : [];
            return [ ...criteria ];
        }

        private get assignmentRule() {
            return this.localGroup.assignmentRule === true ? null : this.localGroup.assignmentRule || null;
        }

        private get saveDisabled(): boolean {
            const isNotAssignmentRules = this.startShowingAssignmentRuleError &&
                (!this.localGroup.assignmentRule || this.notEmptyBasicAssignmentRules.length === 0);
            return Boolean(!this.localGroup.displayName ||
                isNotAssignmentRules ||
                this.invalidReferences.length > 0,
            );
        }

        private get coverageTypesWithDeprecatedCriteria(): CoverageTypesWithDeprecatedCriteria {
            return intersectionWith(
                this.criteriaTemplates,
                this.criteria,
                (a, b) => a.field === b.field && a.coverageType === b.coverageType,
            )
                .filter((x) => x.deprecated)
                .reduce((acc, x) => ({ ...acc, [x.coverageType]: true }), {});
        }

        private get groupHasDeprecatedCriteria(): boolean {
            return Object.keys(this.coverageTypesWithDeprecatedCriteria).length > 0;
        }

        private get coverageTypesWithTemplates(): string[] {
            // filter by this.coverageTypes due to it' a list filtered by country while templates isn't
            return Object.keys(this.criteriaTemplatesByCoverageType).filter(
                (coverageTypeWithTemplate) => this.coverageTypes.includes(
                    coverageTypeWithTemplate as InsuranceCoverageType,
                ),
            );
        }

        private get evaluationRuleCoverageTypes(): InsuranceCoverageType[] {
            return this.localGroup.evaluationRule?.coverageTypes || [];
        }

        private get isEvaluationRuleCoverageTypesSelected(): boolean {
            const [ coverageOne, coverageTwo ] = this.evaluationRuleCoverageTypes;
            return Boolean(coverageOne) && Boolean(coverageTwo);
        }

        private get basicAssignmentRules(): BasicAssignmentRule[][] {
            if (!this.localGroup.assignmentRule) {
                return [];
            }

            return convertToBasicAssignmentRules(this.localGroup.assignmentRule);
        }

        private get notEmptyBasicAssignmentRules(): BasicAssignmentRule[][] {
            return this.basicAssignmentRules
                .map((basicRule) => basicRule
                    .filter((rule) => rule.insuredFieldKey.length > 0 && rule.operator.length > 0),
                )
                .filter((basicRule) => basicRule.length > 0);
        }

        private get disableEvaluationRule(): boolean {
            return this.localGroup.ccgType === InsuranceCoverageCriteriaGroupType.collateral;
        }

        private get ccgType(): InsuranceCoverageCriteriaGroupType | null {
            return this.collateralEnabled
                ? this.localGroup.ccgType || InsuranceCoverageCriteriaGroupType.default
                : null;
        }

        private get isCollateralGroup(): boolean {
            return this.ccgType === InsuranceCoverageCriteriaGroupType.collateral;
        }

        private get baselineFields(): string[] {
            return [
                'carrier.amBestFinancialSizeCategory',
                'carrier.amBestFinancialStrengthRating',
                'carrier.naicNumber',
                'carrier.name',
                'coverage.certificateIssueDate',
                'coverage.coverageStatus',
                'coverage.endorsements.followFormBasis',
                'coverage.policiesFollowingForm',
                'coverage.producerName',
                'policy.currency',
                'policy.effectiveDate',
                'policy.expirationDate',
                'policy.insured',
                'policy.policyNumber',
            ];
        }

        private get isEditingGroup(): boolean {
            return Boolean('id' in this.localGroup && this.localGroup.id);
        }

        private get hasUmbrellaCriterion(): boolean {
            return this.criteria
                .some((criterion) => criterion.coverageType === InsuranceCoverageType.umbrellaExcessLiability);
        }

        private getCcgChangeWarningDetails(): string[] {
            const newTypes = difference(
                Object.keys(this.criteriaByCoverageType),
                Object.keys(this.originalCriteriaByCoverageType),
            ) as InsuranceCoverageType[];
            const newCriteria =
                this.getNewCriteriaOfExistedCoverageTypes(newTypes, this.originalCriteria, this.criteria);
            const newCriteriaStrings = this.convertCriteriaToChangeStrings(newCriteria, this.criteriaTemplates);
            const newCoverageTypeString = newTypes.map(
                (type) => `${this.getCoverageTypeLabel(type as InsuranceCoverageType)} - New Coverage Type Added`,
            );
            return orderBy([ ...newCoverageTypeString, ...newCriteriaStrings ]);
        }

        @Watch('group')
        private onGroupChange(current: InsuranceCoverageCriteriaGroup, previous: InsuranceCoverageCriteriaGroup): void {
            if (!isEqual(current, previous)) {
                this.localGroup = { ...this.group };
                this.assignmentOption = this.localGroup.assignmentRule === true
                    ? CoverageGroupAssignmentOptions.allInsureds
                    : CoverageGroupAssignmentOptions.subsetOfInsureds;
                this.criteriaTemplatesByCoverageType = this.groupAndFilterCriteriaTemplates(this.criteriaTemplates);
                this.criteriaByCoverageType = groupCriteriaByType(this.criteria);
            }
        }

        @Watch('criteriaTemplates')
        private onTemplateChange(
            current: InsuranceCoverageCriterionTemplate[],
            previous: InsuranceCoverageCriterionTemplate[],
        ): void {
            if (!isEqual(current, previous)) {
                this.criteriaTemplatesByCoverageType = this.groupAndFilterCriteriaTemplates(this.criteriaTemplates);
            }
        }

        @Watch('criteria')
        private onCriteriaChange(current: InsuranceCoverageCriterion[], previous: InsuranceCoverageCriterion[]): void {
            if (!isEqual(current, previous)) {
                this.criteriaByCoverageType = groupCriteriaByType(this.criteria);
            }
        }

        @Watch('originalCriteria')
        private onOriginalCriteriaChange(
            current: InsuranceCoverageCriterion[],
            previous: InsuranceCoverageCriterion[],
        ): void {
            if (!isEqual(current, previous)) {
                this.originalCriteriaByCoverageType = groupCriteriaByType(this.originalCriteria);
            }
        }

        @Watch('coverageTypes', { immediate: true })
        private onTypesChange(): void {
            if (
                this.loadingCoverageTypesAndCountries &&
                this.currentCoverageType &&
                this.coverageTypes &&
                !this.coverageTypes.includes(this.currentCoverageType)
            ) {
                this.deselectCoverageType();
            }
        }

        @Watch('currentCoverageType', { immediate: true })
        private onCurrentCoverageTypeChange(): void {
            if (this.currentCoverageType && !this.criteriaByCoverageType[this.currentCoverageType]) {
                // FIXME: we need an error handling for this case when coming from deeplink
                this.criteriaByCoverageType[this.currentCoverageType] =
                    this.getCriteriaTemplatesForNewType(this.currentCoverageType);
            }
        }

        @Watch('collateralEnabled', { immediate: true })
        private onCollateralEnabledChange(): void {
            // update criteria due to collateral enabling flag is async by api, it should be updated when done
            this.criteriaTemplatesByCoverageType = this.groupAndFilterCriteriaTemplates(this.criteriaTemplates);
        }

        private created(): void {
            purgeCcgAutoAddUmbrellaPendingItems();
            this.canAutoAddUmbrella = !isCcgAutoAddUmbrellaDisabled(this.groupIdForAutoUmbrella);
        }

        private isReferenceStartsWithIsInvalid(searchString: string): boolean {
            return this.invalidReferences.some((reference) => reference.startsWith(searchString));
        }

        private onBackAction() {
            this.$router.push({
                name: 'insuredDecisioningCriteria',
                params: {
                    rpId: this.$route.params.rpId,
                },
            }).catch((err) => {
                // ignoring the navigation abort "error" as it should not be an error and should not pollute console
                if (!/Navigation aborted from .+ via a navigation guard/.test(err)) {
                    console.error(err);
                }
            });
        }

        private onHeaderInput(name: string): void {
            this.localGroup = { ...this.localGroup, displayName: name };
            this.$emit('groupInput', this.localGroup);
        }

        private setAssignmentRuleOption(assignmentRuleOption: CoverageGroupAssignmentOptions): void {
            this.assignmentOption = assignmentRuleOption;
            this.localGroup = {
                ...this.localGroup,
                assignmentRule: this.assignmentOption === CoverageGroupAssignmentOptions.allInsureds ? true : null,
            };
            this.$emit('groupInput', this.localGroup);
        }

        private removeAssignmentRule(): void {
            this.startShowingAssignmentRuleError = true;
            this.localGroup = { ...this.localGroup, assignmentRule: null };
            this.$emit('groupInput', this.localGroup);
        }

        private updateRules(rules: BasicAssignmentRule[][]): void {
            this.startShowingAssignmentRuleError = true;
            this.localGroup = {
                ...this.localGroup,
                assignmentRule: encodeAssignmentRules(rules, this.insuredFields),
            };
        }

        private finishEditGroupAssignmentProcedure(): void {
            this.isEditGroupAssignmentRulePanelOpen = false;
            this.localGroup = {
                ...this.localGroup,
                assignmentRule: encodeAssignmentRules(this.notEmptyBasicAssignmentRules, this.insuredFields),
            };
            this.$emit('groupInput', this.localGroup);
        }

        private openEditGroupAssignmentRulePanel(): void {
            this.isEditGroupAssignmentRulePanelOpen = true;
        }

        private attemptSave(data: { isActive: boolean }): void {
            if (this.isEditingGroup &&
                this.localGroup.numberOfInsuredsAssigned &&
                this.localGroup.numberOfInsuredsAssigned > 0
            ) {
                this.changeDetails = this.getCcgChangeWarningDetails();
                this.saveAsActive = data.isActive;
                if (this.changeDetails.length > 0) {
                    this.showCcgChangesWarningModal = true;
                } else {
                    this.saveGroup(data);
                }
            } else {
                this.saveGroup(data);
            }
        }

        private saveGroup(data: { isActive: boolean }): void {
            if (!this.localGroup.assignmentRule) {
                this.startShowingAssignmentRuleError = true;
            } else {
                this.localGroup = {
                    ...this.localGroup,
                    assignmentRule:
                        encodeAssignmentRules(this.notEmptyBasicAssignmentRules, this.insuredFields) || true,
                    active: data.isActive,
                    numberOfCoverageTypes: Object.keys(this.criteriaByCoverageType).length,
                };
                const criteriaList = flatten(Object.values(this.criteriaByCoverageType));
                this.$emit('save', { group: this.localGroup, criteria: criteriaList });
            }
        }

        private selectCoverageType(coverageType: InsuranceCoverageType): void {
            this.$emit('selectCoverageType', coverageType);
        }

        private deselectCoverageType(currentCoverageTypeAllCriteriaNotVerify?: boolean): void {
            if (currentCoverageTypeAllCriteriaNotVerify) {
                this.deleteEvaluationRule();
            }

            this.$emit('deselectCoverageType');
        }

        private addCoverageType(type: InsuranceCoverageType): void {
            const coverageCriteria = this.getCriteriaTemplatesForNewType(type);
            this.criteriaByCoverageType[type] = coverageCriteria;
            this.tryAutoAddUmbrella(coverageCriteria, [], type);
            this.$emit('criteriaInput', flatten(Object.values(this.criteriaByCoverageType)));
            // workaround for the issue that looks like due to the CriteriaPanel Component gets created before
            // the click event is bubble up to body causing the clickOut event detected earlier than it should
            // which closes the panel immediately.
            setTimeout(() => {
                this.selectCoverageType(type);
            });
        }

        private getCriteriaTemplatesForNewType(type: InsuranceCoverageType): InsuranceCoverageCriterionInput[] {
            return this.criteriaTemplatesByCoverageType[type]
                ? this.criteriaTemplatesByCoverageType[type].filter((x) => x.default).map((x) => omit(x, 'default'))
                : [];
        }

        private updateCriteria(criteria: InsuranceCoverageCriterion[], coverageType: InsuranceCoverageType): void {
            const originCoverageCriteria = this.criteriaByCoverageType[coverageType] || [];
            this.criteriaByCoverageType = {
                ...this.criteriaByCoverageType,
                [coverageType]: criteria,
            };
            this.tryAutoAddUmbrella(criteria, originCoverageCriteria, coverageType);
            this.$emit('criteriaInput', flatten(Object.values(this.criteriaByCoverageType)));
        }

        private tryDeleteCoverageType(coverageType: InsuranceCoverageType) {
            if (!this.isCoverageTypeInEvaluationRule(coverageType)) {
                this.deleteCoverageType(coverageType);
                return;
            }

            this.confirmationModalData = {
                text: `This coverage type is currently assigned to an Evaluation Rule.
                Insureds currently assigned to this group may be affected.`,
                confirmDeleteFor: 'coverageType',
                coverageType,
                isCoverageTypeInEvaluationRule: true,
            };
        }

        private deleteCoverageType(type: InsuranceCoverageType): void {
            this.criteriaByCoverageType = omit(this.criteriaByCoverageType, type);
            this.clearInvalidListPerCoverageType(type);
            if (type === this.currentCoverageType) {
                this.deselectCoverageType();
            }
            if (type === InsuranceCoverageType.umbrellaExcessLiability) {
                startDisablingCcgAutoAddUmbrella(this.groupIdForAutoUmbrella);
                this.canAutoAddUmbrella = false;
            }
            this.$emit('criteriaInput', flatten(Object.values(this.criteriaByCoverageType)));
        }

        private setCollectOnlyForCriteria(
            criteria: InsuranceCoverageCriterionInput[],
        ): InsuranceCoverageCriterionInput[] {
            return criteria.map((c) => ({ ...c, verify: false }));
        }

        private tryAutoAddUmbrella(
            criteria: (InsuranceCoverageCriterion | InsuranceCoverageCriterionInput)[],
            originalCoverageCriteria: InsuranceCoverageCriterionBase[],
            coverageType: InsuranceCoverageType,
        ) {
            if (this.hasUmbrellaCriterion ||
                !this.canAutoAddUmbrella ||
                coverageType === InsuranceCoverageType.umbrellaExcessLiability) {
                return;
            }

            const criteriaWithAllowUmbrella: InsuranceCoverageCriterionBase[] = criteria
                .filter((criterion) => Boolean(criterion.evaluator?.references?.allowUmbrellaExcess?.value));
            const newCriteriaWithAllowUmbrella = differenceWith(
                criteriaWithAllowUmbrella,
                originalCoverageCriteria,
                isCriteriaEqual,
            );
            const criteriaWithChangedAllowUmbrellaTrue = criteriaWithAllowUmbrella.filter(
                (criterion) => originalCoverageCriteria.find(
                    (original) =>
                        isCriteriaEqual(criterion, original) &&
                        original.evaluator?.references?.allowUmbrellaExcess?.value === false &&
                        criterion.evaluator?.references?.allowUmbrellaExcess?.value === true,
                ),
            );

            if (newCriteriaWithAllowUmbrella.length > 0 || criteriaWithChangedAllowUmbrellaTrue.length > 0) {
                const umbrellaCriteria = this.getCriteriaTemplatesForNewType(
                    InsuranceCoverageType.umbrellaExcessLiability,
                );
                this.criteriaByCoverageType[InsuranceCoverageType.umbrellaExcessLiability] =
                    this.setCollectOnlyForCriteria(umbrellaCriteria);
                this.showAlert(
                    {
                        text: 'Commercial Umbrella & Excess Policy added to group.',
                        icon: getCoverageTypeIcon(InsuranceCoverageType.umbrellaExcessLiability),
                    },
                    4000,
                );
            }
        }

        private onReferenceAccessed(data: { reference: string, isValid: boolean }): void {
            this.accessedMap[data.reference] = true;
            this.onValidityChanged(data);
        }

        private onValidityChanged(data: { reference: string, isValid: boolean }): void {
            const index = this.invalidReferences.indexOf(data.reference);
            const refStartsWithRegex = new RegExp(`^${data.reference}`);
            if (data.isValid) {
                this.invalidReferences = this.invalidReferences.filter((ref) => !refStartsWithRegex.test(ref));
            } else if (!data.isValid && index < 0) {
                this.invalidReferences = [ ...this.invalidReferences, data.reference ];
            }
        }

        private clearInvalidListPerCoverageType(coverageType: InsuranceCoverageType): void {
            this.invalidReferences = this.invalidReferences.filter((combinedReference) =>
                extractCoverageTypeFromCombinedReference(combinedReference) !== coverageType);
        }

        private hasDeprecatedCriteriaByCoverageType(coverageType: InsuranceCoverageType): boolean {
            return Boolean(this.coverageTypesWithDeprecatedCriteria[coverageType]);
        }

        private toggleEvaluationRuleModal() {
            this.showEvaluationRuleModal = !this.showEvaluationRuleModal;
        }

        private showAlert(data: HeaderAlertData, disappearAfterMs = 2000) {
            this.headerAlertData = data;
            window.setTimeout(() => {
                this.headerAlertData = {};
            }, disappearAfterMs);
        }

        private onSaveEvaluationRule({ coverageTypes }: { coverageTypes: InsuranceCoverageType[] }) {
            this.localGroup = {
                ...this.localGroup,
                evaluationRule: { coverageTypes },
            };
            this.$emit('groupInput', this.localGroup);
            this.showAlert({ text: 'Evaluation rule added.', icon: faCheckCircle });
        }

        private showEvaluationRuleConfirmDeleteModal(): void {
            this.confirmationModalData = {
                text: 'Insureds currently assigned to this group may be affected.',
                confirmDeleteFor: 'evaluationRule',
            };
        }

        private onConfirmDelete(
            confirmed: boolean,
        ) {
            const { confirmDeleteFor, coverageType, isCoverageTypeInEvaluationRule } = this.confirmationModalData;
            this.confirmationModalData = {};
            if (!confirmed) {
                return;
            }
            if (confirmDeleteFor === 'evaluationRule') {
                this.deleteEvaluationRule();
            } else {
                this.deleteCoverageType(coverageType as InsuranceCoverageType);
                if (isCoverageTypeInEvaluationRule) {
                    this.deleteEvaluationRule();
                }
            }
        }

        private deleteEvaluationRule(): void {
            this.localGroup = {
                ...this.localGroup,
                evaluationRule: undefined,
            };
            this.$emit('groupInput', this.localGroup);
            this.showAlert({ text: 'Evaluation rule deleted.', icon: faCheckCircle });
        }

        private getCoverageTypeLabel(coverageType: InsuranceCoverageType): string {
            return this.coverageModels.find((model) => model.coverageType === coverageType)?.label
                || startCase(coverageType.toLowerCase());
        }

        private isCoverageTypeInEvaluationRule(coverageType: InsuranceCoverageType): boolean {
            return this.evaluationRuleCoverageTypes.includes(coverageType);
        }

        private groupAndFilterCriteriaTemplates<T extends CriterionBases>(
            templates: InsuranceCoverageCriterionTemplate[],
        ): Record<string, InsuranceCoverageCriterionTemplate[]> {
            if (this.collateralEnabled && this.group.ccgType === InsuranceCoverageCriteriaGroupType.collateral) {
                return omitBy(
                    groupCriteriaByType(templates),
                    (criteria) => !criteria.some((template) => template.field === 'coverage.collateral'),
                );
            }
            return groupCriteriaByType(templates.filter((template) => template.field !== 'coverage.collateral'));
        }

        private getNewCriteriaOfExistedCoverageTypes(
            newCoverageTypes: InsuranceCoverageType[],
            originalCriteria: InsuranceCoverageCriterion[],
            currentCriteria: InsuranceCoverageCriterion[],
        ): InsuranceCoverageCriterion[] {
            const newCriteria = differenceWith(currentCriteria, originalCriteria, isCriteriaEqual);
            return newCriteria.filter(
                (criterion) =>
                    !newCoverageTypes.includes(criterion.coverageType) &&
                    !this.baselineFields.includes(criterion.field),
            );
        }

        private convertCriteriaToChangeStrings(
            criteria: InsuranceCoverageCriterion[],
            criteriaTemplates: InsuranceCoverageCriterionTemplate[],
        ): string[] {
            const uniqIdTemplateMap: Record<string, InsuranceCoverageCriterionTemplate> =
                criteriaTemplates.reduce(
                    (accu, template) => ({ ...accu, [`${template.coverageType}-${template.field}`]: template }),
                    {},
                );
            return criteria.map(
                (criterion: InsuranceCoverageCriterion) => {
                    const template = uniqIdTemplateMap[`${criterion.coverageType}-${criterion.field}`];
                    const coverageTypeLabel = this.getCoverageTypeLabel(criterion.coverageType);
                    return template
                        ? `${coverageTypeLabel} - ${template.displayMetadata.title} Added`
                        : `${coverageTypeLabel} - ${startCase(criterion.field.split('.').pop())} Added`;
                },
            );
        }

        private revertCcgChanges(): void {
            this.showCcgChangesWarningModal = false;
            this.$emit('revert-ccg-changes');
        }

        private confirmSave(): void {
            this.saveGroup({ isActive: this.saveAsActive });
            this.showCcgChangesWarningModal = false;
        }
    }
</script>
