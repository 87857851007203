<template>
    <form class="InsuredFiltersPopover" @submit.prevent="applyFilters">
        <div class="InsuredFiltersPopover__headerBar">
            <div class="InsuredFiltersPopover__headerText">
                Advanced Filters
            </div>
            <div class="InsuredFiltersPopover__clearButton" @click="clearFilters">
                CLEAR
            </div>
        </div>
        <div ref="scrollContainer" class="InsuredFiltersPopover__filterSection">
            <PopoverStandardFilters
                :filters="standardFilters"
                :coverage-criteria-groups="coverageCriteriaGroups"
                :effective-groups="effectiveGroups"
                @input="standardFiltersChanged"
                @scrollTo="scrollTo"
            />
            <PopoverInsuredFieldFilters
                v-if="insuredFields.length > 0"
                :filters="insuredFieldFilters"
                :insured-fields="insuredFields"
                @input="insuredFieldFiltersChanged"
                @scroll-to="scrollTo"
            />
            <PopoverCollateralFilters
                v-if="collateralInsuredFieldKey !== '' && insuredFieldCollaterals"
                :filters="collateralFieldFilters"
                :collateral-insured-field-key="collateralInsuredFieldKey"
                :insured-field-collaterals="insuredFieldCollaterals"
                @scroll-to="scrollTo"
                @input="insuredCollateralFieldFiltersChanged"
            />
        </div>
        <div class="InsuredFiltersPopover__footerBar">
            <div class="InsuredFiltersPopover__cancelButton" @click="$emit('close')">
                Cancel
            </div>
            <Button
                class="InsuredFiltersPopover__applyButton"
                type="primary"
                submit
                :disabled="disableApply"
            >
                Apply
            </Button>
        </div>
    </form>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import isEqual from 'lodash/isEqual';
    import omit from 'lodash/omit';
    import pickBy from 'lodash/pickBy';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import {
        InsuredFilters,
        InsuredStandardFilters,
    } from '@/modules/insured-filtering/types';
    import PopoverStandardFilters
        from '@/modules/insured-filtering/components/InsuredFiltersPopover/PopoverStandardFilters.vue';
    import PopoverInsuredFieldFilters
        from '@/modules/insured-filtering/components/InsuredFiltersPopover/PopoverInsuredFieldFilters.vue';
    import PopoverCollateralFilters
        from '@/modules/insured-filtering/components/InsuredFiltersPopover/PopoverCollateralFilters/PopoverCollateralFilters.vue';
    import {
        InsuranceCoverageCriteriaGroup,
        InsuranceEffectiveGroup,
        InsuranceInsuredField,
    } from '@evidentid/rpweb-api-client/types';

    export default Vue.extend({
        name: 'InsuredFiltersPopover',
        components: {
            PopoverStandardFilters,
            PopoverInsuredFieldFilters,
            Button,
            PopoverCollateralFilters,
        },
        props: {
            filters: {
                type: Object as PropType<InsuredFilters>,
                default: () => ({}) as InsuredFilters,
            },
            insuredFields: {
                type: Array as PropType<InsuranceInsuredField[]>,
                default: () => [] as InsuranceInsuredField[],
            },
            coverageCriteriaGroups: {
                type: Array as PropType<InsuranceCoverageCriteriaGroup[]>,
                default: () => [] as InsuranceCoverageCriteriaGroup[],
            },
            effectiveGroups: {
                type: Array as PropType<InsuranceEffectiveGroup[]>,
                default: () => [] as InsuranceEffectiveGroup[],
            },
            collateralInsuredFieldKey: {
                type: String as PropType<string>,
                default: '',
            },
            insuredFieldCollaterals: {
                type: Object as PropType<InsuranceInsuredField|null|undefined>,
                default: undefined,
            },
        },
        data() {
            return {
                standardFilters: {} as InsuredStandardFilters,
                insuredFieldFilters: {} as Record<string, string>,
                collateralFieldFilters: {} as Record<string, string>,
            };
        },
        computed: {
            currentFilters(): InsuredFilters {
                const nonEmptyInsuredFilters = pickBy(this.insuredFieldFilters, Boolean);
                const nonEmptyCollateralFilters = pickBy(this.collateralFieldFilters, Boolean);
                const filters = { ...this.standardFilters };

                if (Object.keys(nonEmptyInsuredFilters).length > 0) {
                    Object.assign(filters, { insuredFieldFilters: nonEmptyInsuredFilters });
                }

                if (Object.keys(nonEmptyCollateralFilters).length > 0) {
                    Object.assign(filters, { collateralFieldFilters: nonEmptyCollateralFilters });
                }

                return filters;
            },
            disableApply(): boolean {
                return isEqual(this.filters, this.currentFilters);
            },
        },
        watch: {
            filters: {
                immediate: true,
                handler(newValue: InsuredFilters) {
                    this.standardFilters = omit(newValue, [ 'insuredFieldFilters', 'collateralFieldFilters' ]);
                    this.insuredFieldFilters = newValue.insuredFieldFilters || {};
                    this.collateralFieldFilters = newValue.collateralFieldFilters || {};
                },
            },
        },
        methods: {
            clearFilters(): void {
                this.standardFilters = {};
                this.insuredFieldFilters = {};
                this.collateralFieldFilters = {};
            },
            standardFiltersChanged(standardFilters: InsuredStandardFilters): void {
                this.standardFilters = { ...standardFilters };
            },
            insuredFieldFiltersChanged(insuredFieldFilters: Record<string, string>): void {
                this.insuredFieldFilters = { ...insuredFieldFilters };
            },
            insuredCollateralFieldFiltersChanged(insuredCollateralFieldFilters: Record<string, string>): void {
                this.collateralFieldFilters = insuredCollateralFieldFilters;
            },
            applyFilters(): void {
                this.$emit('submit', this.currentFilters);
            },
            scrollTo(scrollTo: number): void {
                if (this.$refs.scrollContainer) {
                    (this.$refs.scrollContainer as HTMLDivElement).scrollTop = scrollTo;
                }
            },
        },
    });
</script>
