import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
import { InsuranceInsuredCoverage } from '@evidentid/rpweb-api-client/types';

export function getEvaluationResultsFromEvaluationErrors(
    url: string,
    evaluationErrors: Record<string, FieldEvaluationResultError>,
    coverage: InsuranceInsuredCoverage
): FieldEvaluationResultError | null {
    return evaluationErrors[`#/${coverage.coverageType}/${url}`] || null;
}
