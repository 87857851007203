<template>
    <LoadingModal
        v-if="loading"
        header="Import insureds list"
        open
        @close="close"
    />
    <ConfirmUpdateBulkInsuredsModal
        v-else-if="existedInsureds.length > 0"
        :insureds="existedInsureds"
        :saving="saving"
        :saving-percentage="updateInsuredsStatus.progress"
        :success-count="successCount"
        :external-error="updateError"
        @close="abortUpdate"
        @updateInsureds="updateInsureds"
    />
    <BulkImportInsuredsModal
        v-else-if="opened"
        :insured-fields="insuredFieldsStatus.list"
        :coverage-types="coverageTypesStatus.list"
        :saving="saving"
        :saving-percentage="addInsuredsStatus.progress"
        :external-alert-config="alertConfig"
        @close="close"
        @submit="addInsureds"
        @input="clearAlert"
    />
</template>
<style lang="scss">
    .BulkImportInsuredsModal, .BulkImportCorrectionTable, .ConfirmUpdateBulkInsuredsModal {
        .Alert {
            min-height: 50px;
            padding: 12px 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            > h4:first-child {
                font-size: 1em;
            }

            svg {
                font-size: 1em;
                margin-right: 5px;
            }

            &__description {
                font-weight: 500;
            }
        }
    }
</style>
<script lang="ts">
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import { InsuranceInsuredField, InsuranceInsuredInput, OperationError } from '@evidentid/rpweb-api-client/types';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { AlertType } from '@evidentid/dashboard-commons/components/Alert/types';
    import LoadingModal from '@/components/LoadingModal.vue';
    import { BatchAddInsuredsStatus, BatchUpdateInsuredsStatus } from '@/modules/insured-management/vuex';
    import BulkImportInsuredsModal from '../components/BulkImportInsuredsModal/BulkImportInsuredsModal.vue';
    import ConfirmUpdateBulkInsuredsModal
        from '@/modules/insured-management/components/ConfirmUpdateBulkInsuredsModal/ConfirmUpdateBulkInsuredsModal.vue';
    import { convertInsuredInputDataToView } from '@/modules/insured-management/utils/insuredProcessing';

    @Component({
        components: { BulkImportInsuredsModal, LoadingModal, ConfirmUpdateBulkInsuredsModal },
    })

    export default class BulkImportInsuredsProcedure extends Vue {
        @Prop({ type: String, default: '' })
        private rpName!: string;

        private opened: boolean = true;
        private saving: boolean = false;
        private successCount: number = 0;
        private failedOnRequestCount: number = 0;
        private alertConfig: { type: AlertType, title: string } | null = null;
        private existedInsureds: InsuranceInsuredInput[] = [];
        private sampleInsureds: InsuranceInsuredInput[] = [];
        private updateError: string = '';
        private needCollectEvidence: boolean = false;

        private clearAlert(): void {
            this.alertConfig = null;
        }

        private showUploadModalAlert(title: string): void {
            this.alertConfig = { type: 'danger', title };
        }

        @Watch('addInsuredsStatus')
        private async onAddInsuredsStatusChange(addInsuredsStatus: BatchAddInsuredsStatus) {
            this.saving = addInsuredsStatus.status === OperationStatus.loading;
            if (addInsuredsStatus.status === OperationStatus.success) {
                if (addInsuredsStatus.failures.length === 0) {
                    this.$emit('finish', {
                        sampleInsureds: this.sampleInsureds,
                        collectEvidence: this.needCollectEvidence,
                    });
                } else if (addInsuredsStatus.failures.every(
                    (failure) => failure.error.error === OperationError.unknown)) {
                    this.showUploadModalAlert('Cannot connect the insureds, unknown errors occurred.');
                } else {
                    this.existedInsureds = addInsuredsStatus.failures
                        .filter((failure) => failure.error.error === OperationError.keyConflict)
                        .map((failure) => convertInsuredInputDataToView(failure.element));
                    this.successCount = addInsuredsStatus.successCount;
                }
                this.failedOnRequestCount = addInsuredsStatus.failedOnRequestCount;
                if (this.failedOnRequestCount > 0) {
                    const insuredWord = this.failedOnRequestCount > 1 ? 'insureds' : 'insured';
                    this.updateError = `${this.failedOnRequestCount} ${insuredWord} cannot be connected due to unknown errors.`;
                }
            } else if (addInsuredsStatus.status === OperationStatus.error) {
                this.showUploadModalAlert('Sorry, something went wrong during add insureds. Please try again later.');
            }
        }

        @Watch('updateInsuredsStatus')
        private async onUpdateInsuredsStatusChange(updateInsuredsStatus: BatchAddInsuredsStatus) {
            this.saving = updateInsuredsStatus.status === OperationStatus.loading;
            if (updateInsuredsStatus.status === OperationStatus.success) {
                if (updateInsuredsStatus.failures.length > 0 &&
                    updateInsuredsStatus.failures.every((x) => x.error.error === OperationError.unknown)) {
                    this.updateError = 'Cannot update the insureds, unknown errors occurred.';
                } else {
                    this.$emit('finish', {
                        sampleInsureds: this.sampleInsureds,
                        collectEvidence: this.needCollectEvidence,
                    });
                }
            } else if (updateInsuredsStatus.status === OperationStatus.error) {
                this.updateError = 'Sorry, something went wrong during update insureds. Please try again later.';
            }
        }

        private get addInsuredsStatus(): BatchAddInsuredsStatus {
            return this.$store.state.insuredManagement.addInsuredsStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                progress: 0,
                count: 0,
                successCount: 0,
                successes: [],
                failures: [],
            };
        }

        private get updateInsuredsStatus(): BatchUpdateInsuredsStatus {
            return this.$store.state.insuredManagement.updateInsuredsStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                progress: 0,
                successCount: 0,
                failureCount: 0,
                totalCount: 0,
                successes: [],
                failures: [],
            };
        }

        private get insuredFieldsStatus(): { status: OperationStatus, list: InsuranceInsuredField[] } {
            return this.$store.state.dashboard.insuredFields[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get coverageTypesStatus(): { status: OperationStatus, list: string[] } {
            return this.$store.state.dashboard.coverageTypes[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get loading(): boolean {
            return this.insuredFieldsStatus.status === OperationStatus.loading
                || this.coverageTypesStatus.status === OperationStatus.loading;
        }

        private mounted() {
            if (this.insuredFieldsStatus.status !== OperationStatus.loading) {
                this.$store.actions.dashboard.loadInsuredFields({ rpName: this.rpName });
            }
            if (this.coverageTypesStatus.status !== OperationStatus.loading) {
                this.$store.actions.dashboard.loadCoverageTypes({ rpName: this.rpName });
            }
        }

        private close(): void {
            this.opened = false;
            this.$emit('abort');
        }

        private abortUpdate(): void {
            this.$emit('finish', this.sampleInsureds);
        }

        private updateInsureds(insureds: InsuranceInsuredInput[]) {
            this.$store.actions.insuredManagement.updateInsureds({
                rpName: this.rpName,
                insureds,
            });
        }

        private addInsureds(
            insureds: InsuranceInsuredInput[],
            sampleInsureds: InsuranceInsuredInput[],
            hasPastExpiration: boolean,
        ) {
            this.sampleInsureds = sampleInsureds;
            this.needCollectEvidence = hasPastExpiration;
            this.$store.actions.insuredManagement.addInsureds({ rpName: this.rpName, insureds });
        }
    }
</script>
