import { render, staticRenderFns } from "./ActionReviewDetails.vue?vue&type=template&id=2e05c184&"
import script from "./ActionReviewDetails.vue?vue&type=script&lang=ts&"
export * from "./ActionReviewDetails.vue?vue&type=script&lang=ts&"
import style0 from "./ActionReviewDetails.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports