<template>
    <div class="PolicyRecord">
        <header class="PolicyRecord__title">
            <slot name="title">
                {{ title }}
            </slot>
        </header>
        <div class="PolicyRecord__valueContainer">
            <span class="PolicyRecord__value" :class="valueClass">
                {{ value }}
            </span>
            <EvaluationError v-if="evaluationError" :evaluation-error="evaluationError" v-on="$listeners" />
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
    import EvaluationError from '@/modules/insured-details/components/EvaluationError/EvaluationError.vue';

    @Component({
        components: { EvaluationError },
    })
    export default class PolicyRecord extends Vue {
        @Prop({ type: String })
        private title?: string;

        @Prop({ type: String })
        private value!: string;

        @Prop({ type: Object, default: null })
        private evaluationError!: FieldEvaluationResultError | null;

        private get valueClass(): string | null {
            if (!this.evaluationError || this.evaluationError.isMultiFieldCriterion) {
                return null;
            }
            return (
                this.evaluationError?.complianceStatusMetadata.length > 0
                    ? 'PolicyRecord--exception'
                    : 'PolicyRecord--invalidValue'
            );
        }
    }
</script>
