<template>
    <div class="CollateralDeductibleObjectInput">
        <div data-test-id="CollateralDeductibleObjectInput__selectionRow" class="flex items-center mb-1">
            <span class="CollateralLimitObjectInput__rowLabel">Deductible:</span>
            <CriterionEnumInput
                v-if="requirementProperty"
                :id="`${id}_${requirementProperty.name}`"
                :key="requirementProperty.form.key"
                :form="requirementProperty.form"
                :required="requirementProperty.required"
                :value="getPropValue(requirementProperty)"
                :touched="touched"
                class="CollateralLimitObjectInput__rowInput"
                hide-title
                show-error
                @input="onInput(requirementProperty, $event)"
            />
        </div>
        <div
            class="CollateralDeductibleObjectInput__deductibleThreshold"
            :class="{'CollateralDeductibleObjectInput__deductibleThreshold--disabled': disableThreshold}"
        >
            <RadioOption
                class="CollateralDeductibleObjectInput__radioOption"
                :value="DeductibleRequirementValue.lessOrEqual"
                :checked="getPropValue(thresholdSelectionProperty, 'threshold.selection') === DeductibleRequirementValue.lessOrEqual"
                :disabled="disableThreshold"
                @input="onRadioInput(thresholdSelectionProperty, $event)"
            >
                Must be less than or equal to the Maximum Deductible Required
            </RadioOption>
            <RadioOption
                class="CollateralDeductibleObjectInput__radioOption"
                :value="DeductibleRequirementValue.notExceed"
                :checked="getPropValue(thresholdSelectionProperty, 'threshold.selection') === DeductibleRequirementValue.notExceed"
                :disabled="disableThreshold"
                @input="onRadioInput(thresholdSelectionProperty, $event)"
            >
                <div
                    class="flex items-center"
                    data-test-id="CollateralDeductibleObjectInput__radioOptionLabelContainer"
                >
                    <span>Must not exceed </span>
                    <CriterionNumberInput
                        v-if="limitPercentageProperty"
                        :id="`${id}_${limitPercentageProperty.name}`"
                        :key="limitPercentageProperty.form.key"
                        class="CollateralDeductibleObjectInput__percentageInput"
                        :form="limitPercentageProperty.form"
                        :required="isPercentageRequired"
                        :value="getPropValue(limitPercentageProperty, 'threshold.limitPercentage')"
                        :touched="touched"
                        :disabled="disableThreshold"
                        hide-title
                        show-error
                        @input="onThresholdInput(limitPercentageProperty, $event)"
                    />
                    <span>% of the collateral's Required Limit</span>
                </div>
            </RadioOption>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import { get } from 'lodash';
    import { JsonFormObject, JsonFormProperty } from '@evidentid/json-schema/interfaces/JsonForm';
    import AbstractCriterionInput from '../CoverageCriterionInputComponents/AbstractCriterionInput';
    import { Dropdown } from '@evidentid/dashboard-commons/components/Dropdown';
    import { RadioOption } from '@evidentid/dashboard-commons/components/RadioOption';
    import CriterionEnumInput
        from '@/modules/decisioning-criteria/components/CoverageCriterionInputComponents/CriterionEnumInput.vue';
    import CriterionNumberInput
        from '@/modules/decisioning-criteria/components/CoverageCriterionInputComponents/CriterionNumberInput.vue';

    enum DeductibleRequirementValue {
        notRequired = 'NOT_REQUIRED',
        notExceed = 'MUST_NOT_EXCEED_SPECIFIED_AMOUNT',
        lessOrEqual = 'LESS_THAN_OR_EQUAL_MAX_DEDUCTIBLE',
    }

    @Component({
        inheritAttrs: false,
        components: {
            CriterionNumberInput,
            CriterionEnumInput,
            Dropdown,
            RadioOption,
        },
    })
    export default class CollateralDeductibleObjectInput extends AbstractCriterionInput<JsonFormObject, object> {
        private DeductibleRequirementValue = DeductibleRequirementValue;

        private get requirementProperty(): JsonFormProperty | null {
            return this.form.getProperties(this.value).find((x) => x.name === 'deductibleRequirement') || null;
        }

        private get limitPercentageProperty(): JsonFormProperty | null {
            return (this.form.getProperties(this.value)
                .find((x) => x.name === 'threshold')
                ?.form as any)
                .getProperties((this.value as any).threshold)
                .find((x: JsonFormProperty) => x.name === 'limitPercentage') || null;
        }

        private get thresholdSelectionProperty(): JsonFormProperty | null {
            return (this.form.getProperties(this.value)
                .find((x) => x.name === 'threshold')
                ?.form as any)
                .getProperties()
                .find((x: JsonFormProperty) => x.name === 'selection') || null;
        }

        private get isPercentageRequired(): boolean {
            return this.thresholdSelectionProperty
                ?.form
                .getValue((this.value as any).threshold?.selection) === DeductibleRequirementValue.notExceed || false;
        }

        private getPropValue(property: JsonFormProperty, valuePath?: string): any {
            const propValue = valuePath
                ? get(this.value, valuePath)
                : (this.value as any)?.[property.name] || undefined;
            return property.form.getValue(propValue, true);
        }

        private get disableThreshold(): boolean {
            return Boolean(
                this.requirementProperty &&
                    this.getPropValue(this.requirementProperty) === DeductibleRequirementValue.notRequired,
            );
        }

        private onInput(property: JsonFormProperty, value: any): void {
            this.$emit('input', this.form.getValue({
                ...this.value,
                [property.name]: value,
            }, true));
        }

        private onThresholdInput(property: JsonFormProperty, value: any): void {
            if (!this.disableThreshold) {
                this.$emit('input', this.form.getValue({
                    ...this.value,
                    threshold: {
                        ...((this.value as any) || {}).threshold,
                        [property.name]: value,
                    },
                }, true));
            }
        }

        private onRadioInput(property: JsonFormProperty, event: InputEvent): void {
            this.onThresholdInput(property, (event.target as HTMLInputElement).value);
        }
    }
</script>
