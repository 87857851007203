<template>
    <Row
        hoverable
        class="InsuredsTableRow"
        :class="{'InsuredsTableRow--alert': expiringSoon}"
    >
        <Cell class="InsuredsTableRow__selectionCell">
            <Checkbox :value="selected" :disabled="isPerformingBulkAction" @input="selectionChange" />
        </Cell>
        <Cell
            class="InsuredsTableRow__nameCell"
            @click="selectInsured"
        >
            <v-popover
                trigger="hover"
                :delay="{ show: 300, hide: 100 }"
                placement="right"
                popover-class="InsuredsTableRow__tooltip"
            >
                <div class="InsuredsTableRow__insuredNameEmailContainer tooltip-target">
                    <span class="InsuredsTableRow__nameText">{{ insured.displayName }}</span>
                    <span class="InsuredsTableRow__emailText">{{ insured.contactEmail }}</span>
                </div>
                <template slot="popover">
                    <InsuredsTooltipDetails :insured="insured" />
                </template>
            </v-popover>
        </Cell>
        <Cell
            class="InsuredsTableRow__insuredEffectiveGroupCell"
            :insured="insured"
            @click="selectInsured"
        >
            <EffectiveGroupsTooltipLabel
                :group-names="insuredGroupNames"
                :show-label-prefix="false"
                placement="right"
            />
        </Cell>
        <Cell
            v-for="insuredField in displayedInsuredFields"
            :key="insuredField.id"
            class="InsuredsTableRow__insuredField"
            @click="selectInsured"
        >
            {{ getValue(insuredField) }}
        </Cell>

        <Cell
            class="InsuredsTableRow__expirationCell"
            @click="selectInsured"
        >
            <div v-if="insured.nextExpiration">
                <div class="InsuredsTableRow__expirationDate">
                    {{ formattedDate }}
                </div>
                <template v-if="expired">
                    <div class="InsuredsTableRow__daysBeforeExpired">
                        Expired
                    </div>
                </template>
                <template v-else-if="daysTilExpired === 0">
                    <div class="InsuredsTableRow__daysBeforeExpired">
                        Expires today
                    </div>
                </template>
                <template v-else>
                    <div class="InsuredsTableRow__daysBeforeExpired">
                        {{ daysTilExpiredString }}
                    </div>
                </template>
            </div>
        </Cell>
        <Cell class="InsuredsTableRow__complianceStatusCell" @click="selectInsured">
            <ComplianceStatusBadge
                v-if="insured.active"
                class="InsuredsTableRow__badge"
                :status="insured.complianceStatus"
            />
        </Cell>
        <Cell class="InsuredsTableRow__verificationStatus" @click="selectInsured">
            <div v-if="insured.active">
                <VerificationStatusBadge
                    v-if="insured.paused"
                    class="InsuredsTableRow__badge"
                >
                    Paused
                </VerificationStatusBadge>
                <VerificationStatusBadge
                    v-else
                    class="InsuredsTableRow__badge"
                    :status="insured.verificationStatus"
                />
            </div>
            <div v-else>
                <Badge class="InsuredsTableRow__deactivatedBadge">
                    Deactivated
                </Badge>
            </div>
        </Cell>
        <Cell class="InsuredsTableRow__actionsCell">
            <ActionsButton
                :insured="insured"
                :loading="isUpdating"
                :is-loading-submission-link="isLoadingSubmissionLink"
                :submission-link="submissionLink"
                hide-exception-options
                hide-view-mapping
                v-on="eventListeners"
            />
        </Cell>
    </Row>
</template>

<script lang="ts">
    import moment from 'moment';
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { LocalDate } from '@evidentid/dashboard-commons/components/LocalDate';
    import { Cell, Row } from '@evidentid/dashboard-commons/components/Table';
    import {
        InsuranceInsured,
        InsuranceInsuredField,
    } from '@evidentid/rpweb-api-client/types';
    import ComplianceStatusBadge from '../ComplianceStatusBadge/ComplianceStatusBadge.vue';
    import CoiButton from '../CoiButton/CoiButton.vue';
    import InsuredsTooltipDetails from '@/modules/dashboard/components/InsuredTooltipDetails/InsuredTooltipDetails.vue';
    import { Checkbox } from '@evidentid/dashboard-commons/components/Checkbox';
    import ActionsButton from '@/modules/dashboard/components/ActionsButton/ActionsButton.vue';
    import { Badge } from '@evidentid/dashboard-commons/components/Badge';
    import VerificationStatusBadge
        from '@/modules/dashboard/components/VerificationStatusBadge/VerificationStatusBadge.vue';
    import EffectiveGroupsTooltipLabel
        from '@/modules/insured-details/components/EffectiveGroupsTooltipLabel/EffectiveGroupsTooltipLabel.vue';
    import { isAddressInsuredField } from '@/utils/is-address-insured-field/isAddressInsuredField';
    import { getDisplayAddress } from '@/modules/insured-details/utils/getDisplayAddress';
    import { InsuredFieldAddress } from '@evidentid/insurance-facing-lib/models/dashboard';

    @Component({
        components: {
            VerificationStatusBadge, CoiButton, LocalDate, Row, Cell, ComplianceStatusBadge, InsuredsTooltipDetails,
            ActionsButton, Badge, Checkbox, EffectiveGroupsTooltipLabel,
        },
    })
    export default class InsuredsTableRow extends Vue {
        @Prop({ type: Object })
        private insured!: InsuranceInsured;

        @Prop({ type: Array, default: () => [] })
        private displayedInsuredFields!: InsuranceInsuredField[];

        @Prop({ type: Array, default: () => [] })
        private currentlyUpdatedInsuredsIds!: string[];

        @Prop({ type: Boolean, default: false })
        private selected!: boolean;

        @Prop({ type: Boolean, default: false })
        private isPerformingBulkAction!: boolean;

        @Prop({ type: Boolean, default: false })
        private isLoadingSubmissionLink!: boolean;

        @Prop({ type: String, default: '' })
        private submissionLink!: string;

        private eventListeners: any = {
            ...this.$listeners,
            'actionsButtonClicked': () => {
                this.$emit('loadInsuredRequests', this.insured.id);
            },
            'removeExceptions': (exceptionId: string) => {
                this.$emit('removeExceptions', this.insured, [ exceptionId ]);
            },
            'grantExceptionToInsured': () => {
                this.$emit('grantExceptionToInsured', this.insured);
            },
            'open-historic-coi-modal': () => {
                this.$emit('open-historic-coi-modal', this.insured);
            },
        };

        private get daysTilExpired() {
            const expirationDate = moment(this.insured.nextExpiration).startOf('day');
            return expirationDate.diff(moment().startOf('day'), 'days');
        }

        private get expiringSoon(): boolean {
            return this.daysTilExpired >= 0 && this.daysTilExpired <= 30;
        }

        private get expired(): boolean {
            return this.daysTilExpired < 0;
        }

        private get daysTilExpiredString(): string {
            if (!this.insured.nextExpiration) {
                return '';
            }
            const days = this.daysTilExpired;
            return `${days} ${this.daysTilExpired > 1 ? 'days' : 'day'}`;
        }

        private get formattedDate(): string {
            return moment(this.insured.nextExpiration).format('MMM DD, YYYY');
        }

        private get isUpdating(): boolean {
            return this.currentlyUpdatedInsuredsIds.includes(this.insured.id);
        }

        private get insuredGroupNames(): string[] {
            return (this.insured.effectiveGroup || [])
                .map((group) => group.displayName)
                .sort((a, b) => a.localeCompare(b));
        }

        private getValue(field: InsuranceInsuredField): string {
            const insuredFieldValue = this.insured.insuredFields ? this.insured.insuredFields[field.key] : null;
            if (isAddressInsuredField(field)) {
                return getDisplayAddress({
                    schema: field.schema,
                    value: insuredFieldValue as InsuredFieldAddress,
                });
            }
            switch (typeof insuredFieldValue) {
                case 'boolean':
                    return insuredFieldValue ? 'Yes' : 'No';
                case 'number':
                    // eventually we might consider take out en-us to support different countries when i18n needed
                    return insuredFieldValue.toLocaleString(
                        'en-US',
                        { maximumFractionDigits: 0 },
                    );
                default:
                    return insuredFieldValue?.toString() || '';
            }
        }

        private selectionChange(): void {
            this.$emit('selectionChange', this.insured);
        }

        private selectInsured(): void {
            this.$emit('selectInsured', this.insured);
        }

        private grantExceptionToInsured(): void {
            this.$emit('grantExceptionToInsured', this.insured);
        }
    }
</script>
