import { fieldsUseNewJsonForm } from '@/modules/decisioning-criteria/components/DynamicCriterionSetting/constants';
import { InsuranceCoverageCriterionTemplate } from '@evidentid/rpweb-api-client/types';

export function isNewJsonSchemaForm(template: InsuranceCoverageCriterionTemplate): boolean {
    return fieldsUseNewJsonForm.includes(template.field);
}

export function isPublicOrProductsLiability(template: InsuranceCoverageCriterionTemplate): boolean {
    return [
        'coverage.publicLiability',
        'coverage.productsLiability',
    ].includes(template.field);
}
