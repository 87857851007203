<template>
    <div>
        <CoiDetailsModal
            v-if="content"
            :email="email"
            :certificate="content"
            :custom-title="coverageTypeLabel"
            @close="close"
        />
        <LoadingModal
            v-if="isLoading"
            additional-class-name="InsuredsCoisModal InsuredsCoisModal--loading"
            header="COIs"
            @close="close"
        />
        <InsuredsCoisErrorModal v-else-if="status === 'error'" @close="close" />
        <InsuredsCoisEmptyModal
            v-else-if="!content"
            :custom-message="customEmptyMessage"
            :email="email"
            @close="close"
        />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import maxBy from 'lodash/maxBy';
    import startCase from 'lodash/startCase';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { InsuredDocumentsState } from '@/modules/insured-certificates/vuex';
    import { RprDocumentDetails } from '@/modules/insured-certificates/types';
    import CoiDetailsModal from '@/modules/insured-certificates/components/CoiDetailsModal.vue';
    import {
        getCoverageTypeByAttribute,
        getCoverageTypeVersionByAttribute,
    } from '@/modules/insured-certificates/services/attributeUtilities';
    import { hasDocument } from '@/modules/insured-certificates/services/rprDocumentUtilities';
    import InsuredsCoisErrorModal from '@/modules/insured-certificates/components/InsuredsCoisErrorModal.vue';
    import InsuredsCoisEmptyModal from '@/modules/insured-certificates/components/InsuredsCoisEmptyModal.vue';
    import LoadingModal from '@/components/LoadingModal.vue';
    import { InsuranceCoverageType } from '@evidentid/insurance-facing-lib/models/insured-details';
    import { InsuranceVerificationRequest } from '@evidentid/rpweb-api-client/types';

    @Component({
        components: {
            CoiDetailsModal,
            LoadingModal,
            InsuredsCoisErrorModal,
            InsuredsCoisEmptyModal,
        },
    })
    export default class InsuredCoverageDocumentProcedure extends Vue {
        @Prop({ type: String })
        private rpName!: string;

        @Prop({ type: String })
        private email!: string;

        @Prop({ type: String })
        private coverageTypeLabel!: string;

        @Prop({ type: String })
        private coverageType!: InsuranceCoverageType;

        @Prop({ type: String })
        private rprId?: string;

        @Prop({ type: String })
        private insuredId?: string;

        @Prop({ type: String })
        private verificationId?: string;

        // TODO(PRODUCT-15289): workaround until we have correct doc uploaded date from rpr
        @Prop({ type: String })
        private submissionDate?: string;

        private get state(): InsuredDocumentsState {
            return this.$store.state.insuredCertificates;
        }

        private get content(): RprDocumentDetails | null {
            const rpDocs = this.state.rprDocuments[this.rpName] || {};
            const emailDocs = rpDocs[this.email] || {};
            const docs = emailDocs.content || [];
            return this.getLatestVersionList(docs.filter(this.isDocumentOfCoverage));
        }

        private get status(): OperationStatus {
            const rpDocs = this.state.rprDocuments[this.rpName] || {};
            const emailDocs = rpDocs[this.email] || {};
            return emailDocs.status || OperationStatus.uninitialized;
        }

        private get isLoadingVerification(): boolean {
            return this.verificationsStatus.status === OperationStatus.loading;
        }

        private get verificationsStatus(): { status: OperationStatus, list: InsuranceVerificationRequest[] } {
            return this.$store.state.insuredDetails.verificationRequestsStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get isLoading(): boolean {
            return this.isLoadingVerification || this.status === 'loading' || this.status === 'uninitialized';
        }

        private get needsVerification(): boolean {
            return Boolean(this.insuredId && !this.rprId);
        }

        @Watch('status', { immediate: true })
        private onLoadingStatusChange(): void {
            this.conditionallyLoadDocument();
        }

        @Watch('isLoadingVerification', { immediate: true })
        private onLoadingVerificationChange(): void {
            this.conditionallyLoadDocument();
        }

        private conditionallyLoadDocument(): void {
            const docNotLoaded = this.status === OperationStatus.uninitialized;
            const hasVerification = this.verificationsStatus.status === OperationStatus.success;
            if (this.needsVerification && this.verificationsStatus.status === OperationStatus.uninitialized) {
                this.$store.actions.insuredDetails.loadVerificationRequests({
                    rpName: this.rpName,
                    insuredId: this.insuredId as string,
                });
            }
            if ((this.needsVerification && hasVerification && docNotLoaded) ||
                (!this.needsVerification && docNotLoaded)) {
                this.loadDocument();
            }
        }

        private loadDocument(): void {
            let rprId;
            let submissionDate;
            if (this.verificationId) {
                const verification = this.verificationsStatus.list
                    .find(({ verificationId }) => verificationId === this.verificationId);

                if (verification) {
                    rprId = verification.requestId;
                    submissionDate = verification.inputReceivedAt || undefined;
                }
            } else {
                rprId = this.rprId;
                submissionDate = this.submissionDate;
            }
            this.$store.actions.insuredCertificates.loadRprDocumentDetails({
                rpName: this.rpName,
                email: this.email,
                rprId,
                submissionDate,
            });
        }

        private isDocumentOfCoverage(doc: RprDocumentDetails): boolean {
            return getCoverageTypeByAttribute(doc.id) === this.coverageType && hasDocument(doc);
        }

        private getLatestVersionList(docs: RprDocumentDetails[]): RprDocumentDetails | null {
            return maxBy(docs, (doc) => parseInt(getCoverageTypeVersionByAttribute(doc.id)?.split('v')[1] || '0', 10 || 0)) || null;
        }

        private get customEmptyMessage(): string | null {
            return this.coverageType ? `We don't have any Certificate of Insurance available for ${this.typeLabel}` : null;
        }

        private get typeLabel(): string {
            const combinatorPattern = /[_-]/g;
            const whiteSpace = ' ';
            return this.coverageTypeLabel ||
                startCase(this.coverageType.replace(combinatorPattern, whiteSpace).toLowerCase());
        }

        private destroyed() {
            // TODO: Handle race condition: closing modal during loading will cause data being in memory
            this.$store.actions.insuredCertificates.clearRprDocumentDetails({
                rpName: this.rpName,
                email: this.email,
            });
            if (this.needsVerification && this.verificationsStatus.status !== OperationStatus.uninitialized) {
                // only clear the verification if there was request from this procedure
                this.$store.actions.insuredDetails.clearVerificationRequests({
                    rpName: this.rpName,
                });
            }
        }

        private close(): void {
            this.$emit('abort');
        }
    }
</script>
