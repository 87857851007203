<template>
    <div class="InsuredFieldsConfiguration">
        <div class="InsuredFieldsConfiguration__description">
            <div class="InsuredFieldsConfiguration__title">Insured Fields Table Configuration</div>
            <div class="InsuredFieldsConfiguration__body">
                <span>Define up to </span>
                <strong>3 additional insured fields </strong>
                <span>that will be displayed in the Insured Details table. Order will be shown left to right as in the table below, drag the fields to adjust order.</span>
            </div>
        </div>
        <div class="InsuredFieldsConfiguration__actions">
            <div class="InsuredFieldsConfiguration__insuredFieldSelectors">
                <Draggable v-model="insuredFieldSelectors"
                    handle=".DraggableItem__handle"
                    @input="updateSelectedFields"
                >
                    <DraggableItem v-for="(selector, index) in insuredFieldSelectors" :key="selector.id">
                        <Dropdown :options="selector.options"
                            :selected="selector.selected ? [selector.selected] : []"
                            empty-message="No more insured fields"
                            placeholder="Select insured field"
                            @input="selectField(index, $event)"
                        />
                        <template v-slot:actionable>
                            <div class="InsuredFieldsConfiguration__deleteIcon"
                                @click="deleteSelector(index)"
                            >
                                <FontAwesomeIcon :icon="faTimes" />
                            </div>
                        </template>
                    </DraggableItem>
                </Draggable>
            </div>

            <div v-tooltip="noInsuredFieldsMsg">
                <Button v-if="showAddButton"
                    class="InsuredFieldsConfiguration__addButton"
                    :icon="faPlus"
                    :disabled="insuredFields.length === 0"
                    @click="addInsuredFieldSelector"
                >
                    <strong>ADD</strong>
                </Button>
            </div>

        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import differenceWith from 'lodash/differenceWith';
    import intersectionWith from 'lodash/intersectionWith';
    import isEqual from 'lodash/isEqual';
    import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { InsuranceInsuredField } from '@evidentid/rpweb-api-client/types';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import Dropdown from '@evidentid/dashboard-commons/components/Dropdown/Dropdown.vue';
    import Draggable from '@evidentid/dashboard-commons/components/Draggable/Draggable.vue';
    import DraggableItem from '@evidentid/dashboard-commons/components/Draggable/DraggableItem.vue';
    import { DropdownOption } from '@evidentid/dashboard-commons/components/Dropdown/types';

    const insuredFieldToOption = (field: InsuranceInsuredField): DropdownOption => ({
        value: field.id,
        label: field.name,
    });
    const insuredsFieldsToOptions =
        (fields: InsuranceInsuredField[]): DropdownOption[] => fields.map(insuredFieldToOption);

    const randomId = (): string => `${Math.random()}.${Math.random()}`;

    interface InsuredFieldSelector {
        id: string;
        selected?: DropdownOption;
        options: DropdownOption[];
    }

    @Component({
        components: { Button, Dropdown, Draggable, DraggableItem, FontAwesomeIcon },
    })
    export default class InsuredFieldsConfiguration extends Vue {
        @Prop({ type: Array, default: () => [] })
        private insuredFields!: InsuranceInsuredField[];

        @Prop({ type: Array, default: () => [] })
        private selectedInsuredFieldIds!: string[];

        private faPlus = faPlus;
        private faTimes = faTimes;
        private insuredFieldSelectors: InsuredFieldSelector[] = [];
        private localSelectedFields: (InsuranceInsuredField | null)[] = [];
        private insuredFieldMap: Record<string, InsuranceInsuredField> = {};

        @Watch('insuredFields', { immediate: true })
        private onInsuredFieldsChange(): void {
            this.insuredFieldMap = this.insuredFields.reduce((acc, field) => ({ ...acc, [field.id]: field }), {});
            this.initLocalVariables();
        }

        @Watch('selectedInsuredFieldIds', { immediate: true })
        private onSelectedInsuredFieldsChange(): void {
            this.initLocalVariables();
        }

        private get availableInsuredFields(): InsuranceInsuredField[] {
            return differenceWith(this.insuredFields, this.localSelectedFields, isEqual);
        }

        private get availableInsuredFieldOptions(): DropdownOption[] {
            return this.availableInsuredFields.map(insuredFieldToOption);
        }

        private get noInsuredFieldsMsg(): string {
            return this.insuredFields.length === 0 ? 'There are no available InsuredFields' : '';
        }

        private get showAddButton(): boolean {
            const maxCount = Math.min(this.insuredFields.length, 3);
            // showing add button when insured fields === 0 as there is disabled style and tooltip to handle it
            return this.insuredFields.length === 0 || this.insuredFieldSelectors.length < maxCount;
        }

        private insuredFieldToSelector(field: InsuranceInsuredField | null): InsuredFieldSelector {
            if (field === null) {
                return this.createInsuredFieldSelector();
            }
            const fields = intersectionWith(
                this.insuredFields, [ field, ...this.availableInsuredFields ], isEqual);
            return {
                id: randomId(),
                selected: insuredFieldToOption(field),
                options: insuredsFieldsToOptions(fields),
            };
        }

        private createInsuredFieldSelector(): InsuredFieldSelector {
            return { id: randomId(), options: [ ...this.availableInsuredFieldOptions ] };
        }

        private addInsuredFieldSelector() {
            if (this.insuredFieldSelectors.length < 3) {
                this.insuredFieldSelectors = [ ...this.insuredFieldSelectors, this.createInsuredFieldSelector() ];
                this.localSelectedFields = [ ...this.localSelectedFields, null ];
            }
        }

        private selectField(index: number, option: DropdownOption): void {
            const modifiedSelector = { ...this.insuredFieldSelectors[index], selected: option };
            this.insuredFieldSelectors = [
                ...this.insuredFieldSelectors.slice(0, index),
                modifiedSelector,
                ...this.insuredFieldSelectors.slice(index + 1) ];
            this.updateSelectedFields();
        }

        private updateSelectedFields(): void {
            this.localSelectedFields = this.insuredFieldSelectors
                .map((selector) => this.insuredFieldMap[selector.selected?.value] || null);
            this.$emit('input', this.localSelectedFields.map((field) => (field ? field.id : '')));
        }

        private deleteSelector(index: number): void {
            this.insuredFieldSelectors = [
                ...this.insuredFieldSelectors.slice(0, index),
                ...this.insuredFieldSelectors.slice(index + 1),
            ];
            this.updateSelectedFields();
        }

        private initLocalVariables(): void {
            this.localSelectedFields = this.selectedInsuredFieldIds.map((id) => this.insuredFieldMap[id] || null);
            this.insuredFieldSelectors = this.localSelectedFields.map(this.insuredFieldToSelector);
        }
    }
</script>
