<template>
    <v-component
        :is="Page"
        class="NotificationsEmailTemplatesPage"
        title="Email Templates"
        :loading="loading"
    >
        <p>
            Set up your notification preferences, customize messaging and send sample test emails to view your changes.
        </p>

        <div class="NotificationsEmailTemplatesPage__list">
            <EmailTemplateItem
                title="New Member Verification Request"
                description="Automated email to newly added members with their set of requirements for compliance."
                :icon="userPlusIcon"
                :init-enabled="verificationRequestConfigurations.NEW.initEnabled"
                :reminders-enabled="verificationRequestConfigurations.NEW.remindersEnabled"
                @setReminders="openReminders(NotificationName.newRequestReminder)"
                @edit="editTemplate(VerificationRequestType.new)"
                @sendTestEmail="sendTestEmail(VerificationRequestType.new)"
            />
            <EmailTemplateItem
                title="Expiring Verification Request"
                description="Automated email to a member with an expiring compliance status."
                :icon="alarmClockIcon"
                :init-enabled="verificationRequestConfigurations.EXPIRING.initEnabled"
                :reminders-enabled="verificationRequestConfigurations.EXPIRING.remindersEnabled"
                @setReminders="openReminders(NotificationName.expiringRequestReminder)"
                @edit="editTemplate(VerificationRequestType.expiring)"
                @sendTestEmail="sendTestEmail(VerificationRequestType.expiring)"
            />
            <EmailTemplateItem
                title="Non-Compliant Verification Request"
                description="Automated email to a member with a non-compliant submission requesting new verification."
                :icon="circleXIcon"
                :init-enabled="verificationRequestConfigurations.NON_COMPLIANT.initEnabled"
                :reminders-enabled="verificationRequestConfigurations.NON_COMPLIANT.remindersEnabled"
                @setReminders="openReminders(NotificationName.nonCompliantRequestReminder)"
                @edit="editTemplate(VerificationRequestType.nonCompliant)"
                @sendTestEmail="sendTestEmail(VerificationRequestType.nonCompliant)"
            />
        </div>
    </v-component>
</template>

<style lang="scss">
    .NotificationsEmailTemplatesPage {
        .Alert {
            justify-content: start;
            border-radius: 6px;
            font-weight: 500;
            font-size: 0.95em;
            padding: 15px 20px;
        }
    }
</style>

<script lang="ts">
    import { Component, Vue, Watch } from '@evidentid/vue-property-decorator';
    import { Alert } from '@evidentid/dashboard-commons/components/Alert';
    import {
        InsuranceRequestsConfig, InsuranceVerificationRequestConfiguration,
        RelyingPartySignature,
        VerificationRequestType,
    } from '@evidentid/rpweb-api-client/types';
    import { NotificationName } from '@evidentid/config-api-client/types';
    import EmailTemplateItem from '../components/EmailTemplateItem.vue';
    import userPlusIcon from '../assets/user-plus.svg';
    import alarmClockIcon from '../assets/alarm-clock.svg';
    import circleXIcon from '../assets/circle-x.svg';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { createEmptyRequestsConfigStatus } from '@/modules/dashboard/vuex/dashboard';

    @Component({
        components: {
            EmailTemplateItem,
            Alert,
        },
    })
    export default class NotificationsEmailTemplates extends Vue {
        private NotificationName = NotificationName;
        private VerificationRequestType = VerificationRequestType;
        private userPlusIcon = userPlusIcon;
        private alarmClockIcon = alarmClockIcon;
        private circleXIcon = circleXIcon;

        private get loading(): boolean {
            return false;
        }

        private get rpName(): string {
            return this.$route.params.rpId;
        }

        private get verificationRequestConfigurations(): Record<
            VerificationRequestType,
            { initEnabled?: boolean, remindersEnabled?: boolean }
        > {
            const { data } = this.$store.state.dashboard.requestsConfig[this.rpName]
                || createEmptyRequestsConfigStatus();
            return {
                [VerificationRequestType.new]: {
                    initEnabled: data?.verificationRequestConfigurations?.NEW.initEnabled,
                    remindersEnabled: data?.verificationRequestConfigurations?.NEW.remindersEnabled,
                },
                [VerificationRequestType.expiring]: {
                    initEnabled: data?.verificationRequestConfigurations?.EXPIRING.initEnabled,
                    remindersEnabled: data?.verificationRequestConfigurations?.EXPIRING.remindersEnabled,
                },
                [VerificationRequestType.nonCompliant]: {
                    initEnabled: data?.verificationRequestConfigurations?.NON_COMPLIANT.initEnabled,
                    remindersEnabled: data?.verificationRequestConfigurations?.NON_COMPLIANT.remindersEnabled,
                },
            };
        }

        private get currentRp(): RelyingPartySignature | null {
            return this.$store.state.user.relyingParties?.find(({ name }) => (name === this.rpName)) || null;
        }

        private get externalConfigurationEnabled(): boolean {
            return Boolean(this.currentRp?.externalConfigurationEnabled);
        }

        private get Page(): Vue.Component {
            return (this as any).$notificationsConfiguration.Page;
        }

        private async openReminders(type: NotificationName): Promise<void> {
            await this.$procedures.execute('setNotificationReminders', {
                rpName: this.rpName,
                type,
            });
        }

        private async editTemplate(type: VerificationRequestType): Promise<void> {
            await this.$procedures.execute('editNotificationEmailTemplate', {
                rpName: this.rpName,
                type,
            });
        }

        private async sendTestEmail(type: VerificationRequestType): Promise<void> {
            await this.$procedures.execute('sendTestEmail', {
                rpName: this.rpName,
                type,
            });
        }

        @Watch('externalConfigurationEnabled', { immediate: true })
        private onExternalConfigurationStatus(): void {
            if (!this.externalConfigurationEnabled && this.$store.state.user?.data) {
                this.$router.replace({ name: 'error', params: { reason: 'page-not-found' } });
            }
        }
    }
</script>
