<template>
    <div class="Policy">
        <div class="Policy__headerBar" @click="toggle">
            <div class="Policy__coverageTypeLabel">
                <FontAwesomeIcon class="Policy__headerBarToggle" :icon="caretIcon" />
                <FontAwesomeIcon v-if="icon" class="Policy__headerBarIcon" :icon="icon" />
                <EidIcon
                    v-if="showCoverageTypeIcon"
                    class="Policy__coverageIcon"
                    :icon-src="coverageTypeIcon"
                    :svg-props="{color: evidentGreenColor}"
                />
                <span class="Policy__headerBarCoverageName">{{ coverageTypeLabel }}</span>
                <EvaluationRuleCoverageIcon v-if="isEvaluationRuleCoverage" />
            </div>
            <div v-if="showComplianceStatus" class="Policy__complianceStatus">
                <ComplianceStatusBadge
                    class="InsuredDetails__headerStatusBadge"
                    :status="coverageDetails.complianceStatus"
                />
            </div>
            <ExceptionBadge
                v-if="isStatusOverridePresent"
                class="EvaluationError__exceptionBadge"
                :label="exceptionLabel"
                :exception-type="coverageDetails.complianceStatusMetadata.statusOverride.type"
                :until="exceptionUntil"
                :applied-to="coverageDetails.complianceStatusMetadata.statusOverride.level"
                :disable-popover="false"
            />
            <Expandable v-if="hasAnyAction" ref="actionMenu">
                <template #button>
                    <FontAwesomeIcon
                        :icon="faEllipsisH"
                        class="Policy__actionsButton"
                    />
                </template>

                <Menu v-if="isStatusOverridePresent" spaced>
                    <MenuLink
                        class="Policy__removeExceptionMenu"
                        :disabled="hasInsuredLevelException"
                        @click="$emit('removeExceptions', [ coverageDetails.complianceStatusMetadata.statusOverride.id ])"
                    >
                        <template #label>
                            <div class="Policy__menuLink">
                                <span class="Policy__label">
                                    Remove Exception
                                </span>
                            </div>
                        </template>
                    </MenuLink>
                </Menu>
                <Menu v-else-if="coverageDetails.complianceStatus === 'NON_COMPLIANT'" spaced>
                    <MenuLink class="Policy__grantCoverageTypeExceptionMenu" @click="grantExceptionForCoverage">
                        <template #label>
                            <div class="Policy__menuLink">
                                <span class="Policy__label">
                                    Grant exceptions for entire coverage type
                                </span>
                                <div class="Policy__menuBody">
                                    <strong>All current and future non-compliant criteria</strong>
                                    <span> will be waived as compliant. This can be undone.</span>
                                </div>
                            </div>
                        </template>
                    </MenuLink>
                    <MenuLink
                        v-if="coverage"
                        class="Policy__grantCriteriaExceptionsMenu"
                        @click="grantExceptionForNonCompliantCriteria"
                    >
                        <template #label>
                            <div class="Policy__menuLink">
                                <span class="Policy__label">
                                    Grant exceptions for non-compliant criteria only
                                </span>
                                <div class="Policy__menuBody">
                                    <strong>Current non-compliant criteria</strong>
                                    <span> will be waived as compliant. This can be undone.</span>
                                </div>
                            </div>
                        </template>
                    </MenuLink>
                </Menu>
            </Expandable>
            <div
                v-if="coverage && coverage.complianceStatus !== ComplianceStatus.new && !coverageProcessing"
                class="Policy__viewCoiButton"
                @click.stop="showCoi"
            >
                <FontAwesomeIcon :icon="faFileAlt" />
                <span style="margin-left: 5px">View COI</span>
            </div>
        </div>
        <div v-if="opened">
            <NoPolicy
                v-if="coverageProcessing || coverageDetails.declineReason || coverage === null"
                :coverage-details="coverageDetails"
                :coverage-processing="coverageProcessing"
                @grant-exception-for-coverage="grantExceptionForCoverage"
            />
            <PolicyInfoNonInsurance
                v-else-if="isNonInsuranceRequirements"
                :coverage="coverage"
                :coverage-model="coverageModel"
                :broker-info="brokerInfo"
                :evaluation-errors="evaluationErrors"
                :all-coverages="allCoverages"
                :evaluation-results="coverageDetails.evaluationResults"
                :collateral-entities="collateralEntities"
                :non-extraction-results="coverageDetails.nonExtraction"
                @goToCriterion="goToCriterion"
                @grantExceptionToCriterion="grantExceptionToCriterion"
                @grantEvaluateEntityException="grantEvaluateEntityException"
                @removeExceptions="$emit('removeExceptions', $event)"
            />
            <PolicyInfo
                v-else
                :coverage="coverage"
                :coverage-model="coverageModel"
                :broker-info="brokerInfo"
                :evaluation-errors="evaluationErrors"
                :all-coverages="allCoverages"
                :evaluation-results="coverageDetails.evaluationResults"
                :collateral-entities="collateralEntities"
                :non-extraction-results="coverageDetails.nonExtraction"
                @goToCriterion="goToCriterion"
                @grantExceptionToCriterion="grantExceptionToCriterion"
                @grantEvaluateEntityException="grantEvaluateEntityException"
                @removeExceptions="$emit('removeExceptions', $event)"
            />
        </div>
    </div>
</template>

<script lang="ts">
    import { PropType } from 'vue';
    import { Component, Prop, Ref, Vue } from '@evidentid/vue-property-decorator';
    import startCase from 'lodash/startCase';
    import {
        InsuranceInsured,
        InsuranceCoverageModel,
        InsuranceInsuredCoverageCriteriaGroup,
        InsuranceInsuredCoverageDetails,
        InsuranceVerificationStatus,
        InsuranceInsuredCoverage,
    } from '@evidentid/rpweb-api-client/types';
    import {
        InsuranceComplianceStatus,
        InsuranceCoverageType,
        InsuranceExceptionInput,
        InsuranceExceptionLevel,
        InsuranceExceptionType,
    } from '@evidentid/insurance-facing-lib/models/insured-details';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faCaretDown, faCaretRight, faEllipsisH, IconDefinition } from '@fortawesome/free-solid-svg-icons';
    import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
    import { nonInsuranceCoverageTypes } from '@/static-data/insured-details/nonInsuranceCoverageTypes.constant';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import ComplianceStatusBadge from '@/modules/dashboard/components/ComplianceStatusBadge/ComplianceStatusBadge.vue';
    import { BrokerInfo } from '@/modules/dashboard/types';
    import NoPolicy from '@/modules/insured-details/components/NoPolicy/NoPolicy.vue';
    import { getCoverageTypeIcon } from '@/modules/insured-details/utils/insuredCoverage';
    import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
    import { Expandable } from '@evidentid/dashboard-commons/components/Expandable';
    import { Menu, MenuLink } from '@evidentid/dashboard-commons/components/Menu';
    import ExceptionBadge from '@/modules/insured-details/components/ExceptionBadge/ExceptionBadge.vue';
    import {
        createCoverageLevelException,
        createCriterionLevelException,
    } from '@/modules/insured-details/utils/createException';
    import EvaluationRuleCoverageIcon
        from '@/modules/insured-details/components/EvaluationRuleCoverageIcon/EvaluationRuleCoverageIcon.vue';
    import { tailwindColors } from '@/styles/variables/tailwind-colors/tailwindColors';
    import { CollateralEntity } from '@evidentid/rpweb-api-client/models/CollateralEntity.model';
    import PolicyInfo from './sub-components/PolicyInfo/PolicyInfo.vue';
    import PolicyInfoNonInsurance
        from '@/modules/insured-details/components/Policy/sub-components/PolicyInfoNonInsurance/PolicyInfoNonInsurance.vue';

    @Component({
        components: {
            PolicyInfoNonInsurance,
            EidIcon,
            EvaluationRuleCoverageIcon,
            ExceptionBadge,
            Expandable,
            Menu,
            MenuLink,
            PolicyInfo,
            FontAwesomeIcon,
            ComplianceStatusBadge,
            NoPolicy,
        },
    })
    export default class Policy extends Vue {
        @Prop({ type: Object, required: true })
        private insured!: InsuranceInsured;

        @Prop({ type: Object, required: true })
        private coverageDetails!: InsuranceInsuredCoverageDetails;

        @Prop({ type: Array, default: () => [] })
        private allCoverages!: InsuranceInsuredCoverageDetails[];

        @Prop({ type: Object, default: null })
        private brokerInfo!: BrokerInfo | null;

        @Prop({ default: null })
        private icon!: IconDefinition | null;

        @Prop({ type: Boolean, default: true })
        private showCoverageTypeIcon!: boolean;

        @Prop({ type: Array, default: () => [] })
        private insuredCoverageCriteriaGroups!: InsuranceInsuredCoverageCriteriaGroup[];

        @Prop({ type: Array, default: () => [] })
        private coverageModels!: InsuranceCoverageModel[];

        @Prop({ type: Object as PropType<Record<string, FieldEvaluationResultError>>, default: () => ({}) })
        private evaluationErrors!: Record<string, FieldEvaluationResultError>;

        @Prop({ type: Array as PropType<CollateralEntity[]>, default: () => [] })
        private collateralEntities!: CollateralEntity[];

        @Ref()
        private actionMenu!: Expandable;

        private faCaretRight = faCaretRight;
        private faCaretDown = faCaretDown;
        private evidentGreenColor = tailwindColors.eidTrustGreen.DEFAULT;

        private opened: boolean = false;

        private faFileAlt = faFileAlt;
        private faEllipsisH = faEllipsisH;
        private ComplianceStatus = InsuranceComplianceStatus;

        private get caretIcon(): IconDefinition {
            return this.opened ? this.faCaretDown : this.faCaretRight;
        }

        private get coverageModel(): InsuranceCoverageModel | undefined {
            return this.coverageModels
                .find((model) => model.coverageType === this.coverageDetails.coverageType);
        }
        private get coverageTypeLabel(): string {
            return this.coverageModel?.label || startCase(this.coverageDetails.coverageType.toLowerCase());
        }

        private get coverage(): InsuranceInsuredCoverage | null {
            return this.coverageDetails.coverage || null;
        }

        private get coverageTypeIcon(): string {
            return getCoverageTypeIcon(this.coverageDetails.coverageType as InsuranceCoverageType);
        }

        private get hasCoverageLevelException(): boolean {
            const statusOverride = this.coverageDetails.complianceStatusMetadata?.statusOverride;
            const level = statusOverride?.level;
            return Boolean(level && level === InsuranceExceptionLevel.coverageType);
        }

        private get hasInsuredLevelException(): boolean {
            const statusOverride = this.coverageDetails.complianceStatusMetadata?.statusOverride;
            const level = statusOverride?.level;
            return Boolean(level && level === InsuranceExceptionLevel.insured);
        }

        private get exceptionLabel(): string {
            return this.hasCoverageLevelException ? 'Exception Granted' : 'Exception';
        }

        private get hasAnyAction(): boolean {
            return Boolean((
                this.coverageDetails.complianceStatusMetadata?.statusOverride ||
                this.coverageDetails.complianceStatus === InsuranceComplianceStatus.nonCompliant
            ));
        }

        private get showComplianceStatus(): boolean {
            const hasNonNewStatus = Boolean(this.coverageDetails.complianceStatus) &&
                this.coverageDetails.complianceStatus !== InsuranceComplianceStatus.new;
            const hasNonCompliantStatus =
                this.coverageDetails.complianceStatus === InsuranceComplianceStatus.nonCompliant;
            const hasEva = this.coverageDetails.evaluationResults?.length > 0;
            if (!hasEva && hasNonCompliantStatus) {
                return true;
            }
            const nonCompliantResults = this.coverageDetails.evaluationResults
                .filter((result) => result.result === InsuranceComplianceStatus.nonCompliant);
            const allNonCompliantResultsAreNotUsedInCalculation = nonCompliantResults.length > 0
                && nonCompliantResults.every((result) => !result.useDuringComplianceCalculation);
            if (hasEva && allNonCompliantResultsAreNotUsedInCalculation) {
                return false;
            }
            return hasEva && hasNonNewStatus && !this.coverageProcessing;
        }

        private get isEvaluationRuleCoverage(): boolean {
            return this.insuredCoverageCriteriaGroups.some(
                (group) => group.evaluationRule?.coverageTypes.includes(this.coverageDetails.coverageType),
            );
        }

        private get coverageProcessing(): boolean {
            if (
                this.insured.complianceStatus === InsuranceComplianceStatus.pending
                && [ InsuranceVerificationStatus.processing, InsuranceVerificationStatus.actionsReview ]
                    .includes(this.insured.verificationStatus)
            ) {
                return true;
            }

            return !this.coverageDetails.complianceStatus
                && this.coverageDetails.verificationStatus === InsuranceVerificationStatus.processing;
        }

        private get exceptionUntil(): string | null {
            const exception = this.coverageDetails.complianceStatusMetadata?.statusOverride;
            if (!exception) {
                return null;
            }
            if (exception.type === InsuranceExceptionType.untilCoverageExpiration) {
                return this.coverageDetails.coverage?.policy.expirationDate || null;
            } else {
                return exception.until || null;
            }
        }

        private toggle(): void {
            this.opened = !this.opened;
        }

        private showCoi(): void {
            this.$procedures.execute('showInsuredCoverageRprDocuments', {
                rpName: this.$rp.current!,
                email: this.insured.contactEmail || '',
                coverageType: this.coverageDetails.coverageType,
                coverageTypeLabel: this.coverageTypeLabel,
                insuredId: this.insured.id,
                verificationId: this.coverage?.requestId,
            });
        }

        private goToCriterion(coverageCriteriaGroupId: string, coverageType: InsuranceCoverageType): void {
            this.$emit('goToCriterion', coverageCriteriaGroupId, coverageType);
        }

        private grantExceptionForCoverage(): void {
            this.$emit(
                'grantExceptions',
                [ createCoverageLevelException(this.coverageDetails.coverageType) ],
            );
        }

        private grantEvaluateEntityException(exception: InsuranceExceptionInput[]): void {
            this.$emit('grantExceptions', exception);
        }

        private grantExceptionToCriterion(criterionId: string[]): void {
            this.$emit(
                'grantExceptions',
                criterionId.map((id) => createCriterionLevelException(this.coverageDetails.coverageType, id)),
            );
        }

        private grantExceptionForNonCompliantCriteria(): void {
            const criterionIds = Object.entries(this.evaluationErrors)
                .filter(([ key, value ]) => (
                    key.includes(this.coverageDetails.coverageType) &&
                    value.complianceStatusMetadata.length === 0
                ))
                .map(([ _, value ]) =>
                    value.criterionId,
                )
                .reduce((acc, exceptions) => [ ...acc, ...exceptions ], [] as string[]);
            const distinctIds = [ ...new Set(criterionIds) ];
            const exceptions =
                distinctIds.map((id) => createCriterionLevelException(this.coverageDetails.coverageType, id));
            this.$emit('grantExceptions', exceptions);
        }

        private get isStatusOverridePresent(): boolean {
            return Boolean(this.coverageDetails.complianceStatusMetadata?.statusOverride);
        }

        private get isNonInsuranceRequirements(): boolean {
            return nonInsuranceCoverageTypes.includes(this.coverageDetails.coverageType);
        }
    }
</script>
