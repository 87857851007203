<template>
    <EidSidePanel
        class="CoverageGroupCriteriaPanel"
        :click-out-exception-selectors="clickOutExceptions"
        @click-out="close"
    >
        <template #headerText>
            <template v-if="currentCriterion">
                <span
                    class="CoverageGroupCriteriaPanel__headerPath--active"
                    @click="deselectCriterion"
                >
                    {{ title }}
                </span>
                <div class="CoverageGroupCriteriaPanel__headerPathSeparator">
                    <FontAwesomeIcon :icon="faChevronRight" />
                </div>
                <span>
                    {{ criterionTitle }}
                </span>
            </template>
            <span v-else>{{ title }}</span>
        </template>

        <template #headerOther>
            <CoverageCriteriaWarningMessage v-if="hasDeprecatedCriteria">
                One or more coverage criteria has been deprecated and may result in incorrect insured compliance.
            </CoverageCriteriaWarningMessage>
            <CoverageCriteriaWarningMessage v-if="isEvaluationRuleCoverageTypeAllCriteriaNotVerify">
                At least 1 Coverage Criteria must be verified for the Evaluation Rule to stay valid.
                If not, the Evaluation Rule will be removed.
            </CoverageCriteriaWarningMessage>
            <CoverageCriteriaDangerMessage v-if="isInvalidCriteriaMessage">
                One or more fields could not be validated.
            </CoverageCriteriaDangerMessage>
        </template>

        <template #content>
            <div class="CoverageGroupCriteriaPanel__content">
                <CollateralCriterionSetting
                    v-if="currentCriterion && isCollateralCriterion"
                    :criterion="currentCriterion"
                    :template="templatesByFieldName[currentCriterion.field]"
                    @input="updateCriterion"
                    @reference-accessed="$emit('reference-accessed', $event)"
                />
                <CoverageCriterionSettings
                    v-else-if="currentCriterion"
                    :coverage-type-title="title"
                    :criterion="currentCriterion"
                    :template="templatesByFieldName[currentCriterion.field]"
                    :insured-fields="insuredFields"
                    :country-code="countryCode"
                    @input="updateCriterion"
                    @reference-accessed="$emit('reference-accessed', $event)"
                />
                <CoverageGroupCriteriaTable
                    v-else-if="criteriaTemplates.length > 0"
                    :criteria-list="sortedCriteria"
                    :templates-by-field-name="templatesByFieldName"
                    :insured-fields="insuredFields"
                    :accessed-reference-map="accessedReferenceMap"
                    :is-reference-starts-with-is-invalid="isReferenceStartsWithIsInvalid"
                    @openSettings="openCriterionSettings"
                    @input="$emit('input',$event, coverageType)"
                    @reference-accessed="$emit('reference-accessed', $event)"
                    @validityChange="$emit('validityChange', $event)"
                />
            </div>
            <div class="CoverageGroupCriteriaPanel__footerAction">
                <CoverageCriterionAddButtonWithPopover
                    v-if="!currentCriterion"
                    :criterion-templates="availableCriterionTemplate"
                    :disabled="availableCriterionTemplate.length===0"
                    @add="addCriterion"
                />
                <Alert class="CoverageGroupCriteriaPanel__footer-alert" rounded>
                    <img
                        class="CoverageCriteriaWarningMessage__icon"
                        :src="exclamationCircleGray"
                        alt="icon"
                    >
                    All changes will save automatically.
                </Alert>
            </div>
        </template>
    </EidSidePanel>
</template>
<script lang="ts">
    import { PropType } from 'vue';
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import differenceBy from 'lodash/differenceBy';
    import sortBy from 'lodash/sortBy';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
    import infoCircle from '@/assets/icons/info-circle-transparent-background-icon.svg';
    import {
        InsuranceCoverageCriterion,
        InsuranceCoverageCriterionInput,
        InsuranceCoverageCriterionTemplate,
        InsuranceCoverageModel,
        InsuranceInsuredField,
    } from '@evidentid/rpweb-api-client/types';
    import { InsuranceCoverageType } from '@evidentid/insurance-facing-lib/models/insured-details';
    import Popover from '@evidentid/dashboard-commons/components/Popover/Popover.vue';
    import CoverageGroupCriteriaTable
        from '@/modules/decisioning-criteria/components/CoverageGroupCriteriaTable/CoverageGroupCriteriaTable.vue';
    import CoverageCriterionSettings
        from '@/modules/decisioning-criteria/components/CoverageCriterionSettings/CoverageCriterionSettings.vue';
    import CoverageCriterionAddButtonWithPopover
        from '@/modules/decisioning-criteria/components/CoverageCriterionAddButtonWithPopover/CoverageCriterionAddButtonWithPopover.vue';
    import { isCriterionTypeAndFieldEqual } from '@/modules/decisioning-criteria/utils/coverageCriteria';
    import CoverageCriteriaWarningMessage
        from '@/modules/decisioning-criteria/components/CoverageCriteriaWarningMessage/CoverageCriteriaWarningMessage.vue';
    import {
        GetCoverageTypeLabel,
        IsReferenceStartsWithIsInvalid,
    } from '@/modules/decisioning-criteria/components/CreateEditCoverageCriteriaGroup/types';
    import Alert from '@evidentid/dashboard-commons/components/Alert/Alert.vue';
    import CoverageCriteriaDangerMessage
        from '@/modules/decisioning-criteria/components/CoverageCriteriaDangerMessage/CoverageCriteriaDangerMessage.vue';
    import EidSidePanel from '@/modules/decisioning-criteria/components/EidSidePanel/EidSidePanel.vue';
    import { coverageTypeFieldReferenceDelimiter } from '@/modules/decisioning-criteria/types';
    import CollateralCriterionSetting
        from '@/modules/decisioning-criteria/components/CollateralCriterionSetting/CollateralCriterionSetting.vue';

    @Component({
        components: {
            Alert,
            CollateralCriterionSetting,
            CoverageCriteriaDangerMessage,
            CoverageCriteriaWarningMessage,
            CoverageCriterionAddButtonWithPopover,
            CoverageCriterionSettings,
            CoverageGroupCriteriaTable,
            EidSidePanel,
            FontAwesomeIcon,
            Popover,
        },
    })
    export default class CoverageGroupCriteriaPanel extends Vue {
        @Prop()
        private coverageType!: InsuranceCoverageType;

        @Prop({ type: Array, default: () => [] })
        private criteria!: (InsuranceCoverageCriterionInput | InsuranceCoverageCriterion)[];

        @Prop({ type: Array, default: () => [] })
        private criteriaTemplates!: InsuranceCoverageCriterionTemplate[];

        @Prop({ type: Array, default: () => [] })
        private insuredFields!: InsuranceInsuredField[];

        @Prop({ type: Array, default: () => [] })
        private coverageModels!: InsuranceCoverageModel[];

        @Prop({ type: Object, default: () => ({}) })
        private accessedReferenceMap!: Record<string, boolean>;

        @Prop({ type: Boolean, default: false })
        private hasDeprecatedCriteria!: boolean;

        @Prop({ type: Function as PropType<GetCoverageTypeLabel>, required: true })
        private getCoverageTypeLabel!: GetCoverageTypeLabel;

        @Prop({ type: Array as PropType<InsuranceCoverageType[]>, default: () => ([]) })
        private evaluationRuleCoverageTypes!: InsuranceCoverageType[];

        @Prop({ type: Function as PropType<IsReferenceStartsWithIsInvalid>, required: true })
        private isReferenceStartsWithIsInvalid!: IsReferenceStartsWithIsInvalid;

        @Prop({ type: String as PropType<string> })
        private countryCode?: string;

        @Prop({ type: Array as PropType<string[]>, default: () => [] })
        private invalidReferences!: string[];

        private currentCriterion: InsuranceCoverageCriterion | null = null;
        private faChevronRight = faChevronRight;
        private exclamationCircleGray = infoCircle;
        private clickOutExceptions =
            [ '.CoverageCriteriaGroupSaveButton__saveButton', '.CoverageCriteriaGroupSaveButton__saveButton span' ];

        @Watch('criteria')
        private onCriteriaChange() {
            // update the current criterion
            if (this.currentCriterion != null) {
                const found = this.criteria.find(
                    (criterion) =>
                        isCriterionTypeAndFieldEqual(criterion, this.currentCriterion as InsuranceCoverageCriterion),
                );
                this.currentCriterion = found as InsuranceCoverageCriterion;
            }
        }

        private get isInvalidCriteriaMessage(): boolean {
            if (this.currentCriterion) {
                const criterionFieldReferenceStart = [ this.currentCriterion.coverageType, this.currentCriterion.field ]
                    .join(coverageTypeFieldReferenceDelimiter);
                return this.isReferenceStartsWithIsInvalid(criterionFieldReferenceStart);
            }

            return this.isReferenceStartsWithIsInvalid(this.coverageType);
        }

        private get title(): string {
            return this.getCoverageTypeLabel(this.coverageType);
        }

        private get criterionTitle(): string {
            if (!this.currentCriterion) {
                return '';
            }

            return this.templatesByFieldName[this.currentCriterion.field].displayMetadata.title;
        }

        private get sortedCriteria(): (InsuranceCoverageCriterionInput | InsuranceCoverageCriterion)[] {
            return sortBy(this.criteria, (criterion) =>
                this.criteriaTemplates
                    .findIndex((template) => isCriterionTypeAndFieldEqual(template, criterion)),
            );
        }

        private get availableCriterionTemplate(): InsuranceCoverageCriterionTemplate[] {
            return differenceBy(this.criteriaTemplates, this.criteria, 'field').filter((template) => !template.deprecated);
        }

        private get isEvaluationRuleCoverageTypeAllCriteriaNotVerify(): boolean {
            const isCoverageTypeInEvaluationRule = this.evaluationRuleCoverageTypes.includes(
                this.coverageType,
            );
            const allCriteriaAreNotVerify = this.criteria.every((criterion) => !criterion.verify);
            return isCoverageTypeInEvaluationRule && allCriteriaAreNotVerify;
        }

        private get templatesByFieldName(): Record<string, InsuranceCoverageCriterionTemplate> {
            return this.criteriaTemplates
                .reduce((acc, temp) => ({ ...acc, [temp.field]: temp }), {});
        }

        private get isCollateralCriterion(): boolean {
            return Boolean(this.currentCriterion && this.currentCriterion.field === 'coverage.collateral');
        }

        @Watch('coverageType')
        private onCoverageTypeChange(): void {
            this.currentCriterion = null;
        }

        private openCriterionSettings(criterion: InsuranceCoverageCriterion): void {
            this.currentCriterion = criterion;
        }

        private deselectCriterion(): void {
            this.currentCriterion = null;
        }

        private addCriterion(criterion: InsuranceCoverageCriterion): void {
            this.$emit('input', [ ...this.criteria, criterion ], this.coverageType);
        }

        private updateCriterion(criterion: InsuranceCoverageCriterion): void {
            const index = this.criteria.findIndex((fromList) => isCriterionTypeAndFieldEqual(fromList, criterion));
            const updatedList = [ ...this.criteria.slice(0, index), criterion, ...this.criteria.slice(index + 1) ];
            this.$emit('input', updatedList, this.coverageType);
        }

        private close() {
            this.$emit('close', this.isEvaluationRuleCoverageTypeAllCriteriaNotVerify);
        }
    }
</script>
