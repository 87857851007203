<template>
    <div class="CollateralMappingRulesTable">
        <Table :class="{'border-solid border-1 border-eidDangerRed': !valid}">
            <template #head>
                <HeadCell
                    class="CollateralMappingRulesTable__mapHeader"
                    data-test-id="CollateralMappingRulesTable__mapHeader"
                >
                    Map
                </HeadCell>
                <HeadCell data-test-id="CollateralMappingRulesTable__decisionHeader">
                    Description
                </HeadCell>
                <HeadCell data-test-id="CollateralMappingRulesTable__coverageTypesHeader">
                    Unique Identifier
                </HeadCell>
            </template>
            <Row
                v-for="(option, i) in mappingOptions"
                :key="i"
                class="CollateralMappingRulesTable__row"
                data-test-id="CollateralMappingRulesTable__row"
                hoverable
                @click="onInput(option.value, $event)"
            >
                <Cell data-test-id="CollateralMappingRulesTable__map">
                    <Checkbox :value="isSelected(option.value)" />
                </Cell>
                <Cell data-test-id="CollateralMappingRulesTable__description">
                    {{ option.description }}
                </Cell>
                <Cell data-test-id="CollateralMappingRulesTable__uniqueIdentifier">
                    {{ option.uniqueIdentifier }}
                </Cell>
            </Row>
        </Table>
        <div v-if="(showError || accessed) && error" class="flex text-sm mt-1 text-eidDangerRed">
            <EidIcon :icon-src="faExclamationTriangle" class="mr-2.5" />
            {{ error }}
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Watch } from 'vue-property-decorator';
    import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
    import { Cell, HeadCell, Row, Table } from '@evidentid/dashboard-commons/components/Table';
    import { Checkbox } from '@evidentid/dashboard-commons/components/Checkbox';
    import AbstractCriterionInput
        from '@/modules/decisioning-criteria/components/CoverageCriterionInputComponents/AbstractCriterionInput';
    import { JsonFormArray, JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import { JsonSchemaEnum } from '@evidentid/json-schema/interfaces/JsonSchema';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';

    interface MappingOption {
        value: string;
        description: string;
        uniqueIdentifier: string;
    }

    const DELIMITER = '__TABLE_COLUMN_SEPARATOR__';

    @Component({
        components: { Cell, Checkbox, EidIcon, HeadCell, Row, Table },
        inheritAttrs: false,
    })
    export default class CriterionMultiEnumInput extends AbstractCriterionInput<JsonFormArray, any[]> {
        private mappingOptions: MappingOption[] = [];
        private faExclamationTriangle = faExclamationTriangle;

        @Watch('enumSchema', { immediate: true })
        private onEnumChange(): void {
            const labels = this.enumSchema.enumLabels && this.enumSchema.enumLabels.length > 0
                ? this.enumSchema.enumLabels
                : null;
            this.mappingOptions = labels
                ? this.enumSchema.enum.map((value, i) => {
                    const [ description, uniqueIdentifier ] = labels[i].split(DELIMITER);
                    return { value, description, uniqueIdentifier };
                })
                : this.enumSchema.enum.map(this.buildOptionFromValue);
        }

        private get enumSchema(): JsonSchemaEnum {
            return (this.form.item as JsonFormBase<JsonSchemaEnum>).schema;
        }

        private onInput(value: any, clickEvent: MouseEvent): void {
            clickEvent.preventDefault();
            const newValue = this.isSelected(value)
                ? this.value.filter((x) => x !== value)
                : [ ...this.value, value ];
            this.$emit('input', newValue);
        }

        private isSelected(value: any): boolean {
            return this.value.includes(value);
        }

        private buildOptionFromValue(value: any): MappingOption {
            return { value, description: value, uniqueIdentifier: value };
        }
    }
</script>
