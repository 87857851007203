<template>
    <div class="PolicyInfo">
        <div class="PolicyInfo__bodyBasicInfo">
            <span class="PolicyInfo__bodySectionTitle">INSURED</span>
            <PolicyRecord
                title="Name"
                :value="coverage.policy.insured"
                :evaluation-error="getEvaluationResult('policy/insured')"
                @goToCriterion="goToCriterion"
                @grantExceptionToCriterion="grantExceptionToCriterion"
                @removeExceptions="removeExceptions"
            />
            <PolicyRecord
                title="Status"
                :value="coverageStatus ? 'Active' : 'Inactive'"
                :evaluation-error="getEvaluationResult('details/coverageStatus')"
                @goToCriterion="goToCriterion"
                @grantExceptionToCriterion="grantExceptionToCriterion"
                @removeExceptions="removeExceptions"
            />
            <PolicyRecord
                title="Currency"
                :value="currencyLabel"
                :evaluation-error="getEvaluationResult('policy/currency')"
                @goToCriterion="goToCriterion"
                @grantExceptionToCriterion="grantExceptionToCriterion"
                @removeExceptions="removeExceptions"
            />
        </div>
        <div
            v-if="producerDetails.length > 0"
            data-test-id="PolicyInfo__producerSection"
            class="PolicyInfo__bodyBasicInfo grid grid-cols-5"
        >
            <span class="PolicyInfo__bodySectionTitle col-span-1">PRODUCER</span>
            <div class="grid grid-cols-4 gap-y-4 col-span-4">
                <PolicyRecord
                    v-for="producerDetail in producerDetails"
                    :key="producerDetail.title"
                    :class="{ 'col-span-4': producerDetail.title === 'Producer Address' }"
                    :value="producerDetail.value"
                    :title="producerDetail.title"
                />
            </div>
        </div>
        <PolicyInfoCarrierInfo
            v-if="isShowingCarrierInfo"
            class="PolicyInfo__bodyBasicInfo"
            :carrier="coverage.policy.carrier"
            :get-evaluation-result="getEvaluationResult"
            @go-to-criterion="goToCriterion"
            @grant-exception-to-criterion="grantExceptionToCriterion"
            @remove-exceptions="removeExceptions"
        />
        <PolicyInfoOtherCellsInfo
            :evaluation-errors="evaluationErrors"
            :coverage="coverage"
            :limits="limits"
            :evaluation-results="evaluationResults"
            :get-evaluation-result="getEvaluationResult"
            @go-to-criterion="goToCriterion"
            @grant-exception-to-criterion="grantExceptionToCriterion"
            @remove-exceptions="removeExceptions"
        />
        <JsonSchemaView
            :id="`#/${coverage.coverageType}/details`"
            class="PolicyInfo__jsonSchemaView PolicyInfo__singleCoverageFields"
            :schema="singleCoverageFields"
            :value="values"
            :evaluation-errors="evaluationErrors"
            @goToCriterion="goToCriterion"
            @grantExceptionToCriterion="grantExceptionToCriterion"
            @removeExceptions="removeExceptions"
        />
        <JsonSchemaView
            :id="`#/${coverage.coverageType}/details/endorsements`"
            class="PolicyInfo__jsonSchemaView PolicyInfo__singleCoverageEndorsementsFields"
            :schema="singleCoverageEndorsementsFields"
            :value="endorsementsFieldsValues"
            :evaluation-errors="evaluationErrors"
            @goToCriterion="goToCriterion"
            @grantExceptionToCriterion="grantExceptionToCriterion"
            @removeExceptions="removeExceptions"
        />
        <JsonSchemaView
            v-if="numberOfMultiPropsCoverageFields > 0"
            :id="`#/${coverage.coverageType}/details`"
            class="PolicyInfo__jsonSchemaView PolicyInfo__multiPropsCoverageFields"
            :schema="multiPropsCoverageFields"
            :value="values"
            :evaluation-errors="evaluationErrors"
            @goToCriterion="goToCriterion"
            @grantExceptionToCriterion="grantExceptionToCriterion"
            @removeExceptions="removeExceptions"
        />
        <JsonSchemaView
            v-if="numberOfEndorsementsMultiCoverageFields > 0"
            :id="`#/${coverage.coverageType}/details/endorsements`"
            class="PolicyInfo__jsonSchemaView PolicyInfo__multiCoverageEndorsementsFields"
            :schema="multiCoverageEndorsementsFields"
            :value="endorsementsFieldsValues"
            :evaluation-errors="evaluationErrors"
            :start-index="numberOfMultiPropsCoverageFields + 1"
            @goToCriterion="goToCriterion"
            @grantExceptionToCriterion="grantExceptionToCriterion"
            @removeExceptions="removeExceptions"
        />
        <div
            v-if="multiFieldCoverageCriteria.length > 0 || coverage.endorsements.length > 0"
            class="PolicyInfo__multiCoverageInfoHeader"
        >
            <span class="PolicyInfo__bodySectionTitle">COVERAGE CRITERIA SPANNING MULTIPLE FIELDS</span>
            <div
                v-tooltip="multiCoverageTooltipDescription"
                class="PolicyInfo__hintIcon"
            >
                <FontAwesomeIcon :icon="faQuestionCircle" />
            </div>
        </div>
        <EndorsementsView
            :endorsements="coverage.endorsements"
            :evaluation-error="getEvaluationResult('details/endorsements')"
            @goToCriterion="goToCriterion"
            @grantExceptionToCriterion="grantExceptionToCriterion"
            @removeExceptions="removeExceptions"
        />
        <MultiFieldCriterion
            v-for="(criterion, index) in multiFieldCoverageCriteria"
            :key="criterion.name"
            :criterion="criterion"
            :index="index + coverage.endorsements.length + 1"
            v-on="$listeners"
        />
        <CollateralList
            v-if="collaterals.length > 0"
            :coverage-type="coverage.coverageType"
            :collaterals="collaterals"
            :collateral-entities="collateralEntities"
            :evaluation-errors="evaluationErrors"
            @grant-evaluate-entity-exception="grantEvaluateEntityException"
            @remove-exceptions="removeExceptions"
        />
        <PolicyInfoNonExtraction
            :get-evaluation-result="getEvaluationResult"
            :non-extraction-results="nonExtractionResults"
            @grant-exception-to-criterion="grantExceptionToCriterion"
            @remove-exceptions="removeExceptions"
        />
    </div>
</template>
<script lang="ts">
    import { PropType } from 'vue';
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import isNil from 'lodash/isNil';
    import omitBy from 'lodash/omitBy';
    import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        InsuranceCoverageModel,
        InsuranceInsuredCoverage,
        InsuranceInsuredCoverageDetails,
        NonExtractionResults,
    } from '@evidentid/rpweb-api-client/types';
    import { CollateralEntity } from '@evidentid/rpweb-api-client/models/CollateralEntity.model';
    import {
        InsuranceExceptionInput,
        InsuranceEvaluationResult,
    } from '@evidentid/insurance-facing-lib/models/insured-details';
    import { getEnumDefaultDisplayValue } from '@/utils/getEnumDefaultDisplayValue';
    import { BrokerInfo } from '@/modules/dashboard/types';
    import { CategorizedEnumLabels } from '@/modules/dashboard/models/CategorizedEnumLabels.model';
    import { EnumCategories } from '@/modules/dashboard/models/EnumCategories.model';
    import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';

    import { InsuredMultiFieldCriterion } from '@/modules/insured-details/types';
    import { FIELD_NOT_AVAILABLE_MESSAGE } from '@/modules/insured-details/constant';
    import { CoverageFieldCollateral } from '@/modules/insured-details/models/CoverageFieldCollateral.model';
    import {
        combineSpecialCoverageFieldValues,
    } from '@/modules/insured-details/utils/combineSpecialCoverageFieldValues/combineSpecialCoverageFieldValues';
    import { currencyFormatter } from '@/modules/insured-details/utils/currencyFormatter';
    import { getBasicSchemaByValue } from '@/modules/insured-details/utils/jsonSchemaUtilities';
    import {
        transformValuesWithIsPresent,
    } from '@/modules/insured-details/utils/transformValuesWithIsPresent/transformValuesWithIsPresent';
    import CollateralList from '@/modules/insured-details/components/CollateralList/CollateralList.vue';
    import EndorsementsView from '@/modules/insured-details/components/EndorsementsView/EndorsementsView.vue';
    import EvaluationError from '@/modules/insured-details/components/EvaluationError/EvaluationError.vue';
    import MultiFieldCriterion from '@/modules/insured-details/components/MultiFieldCriterion/MultiFieldCriterion.vue';
    import PolicyRecord from '@/modules/insured-details/components/PolicyRecord/PolicyRecord.vue';
    import { JsonSchemaView } from '@/modules/insured-details/components/JsonSchemaView';
    import {
        getEvaluationResultsFromEvaluationErrors,
        getMultiFieldCoverageCriteria, parseFieldValue,
        shouldOmitFieldsForRendering,
    } from '../../utils';
    import PolicyInfoCarrierInfo from '../PolicyInfoCarrierInfo/PolicyInfoCarrierInfo.vue';
    import PolicyInfoNonExtraction from '..//PolicyInfoNonExtraction/PolicyInfoNonExtraction.vue';
    import PolicyInfoOtherCellsInfo from '../PolicyInfoOtherCellsInfo/PolicyInfoOtherCellsInfo.vue';
    import { isShowingCarrierInfo } from '../PolicyInfoCarrierInfo/utils';
    import { typeOfInsuranceAttributes } from '../PolicyInfoOtherCellsInfo/utils';
    import {
        omitMultiPropFieldsFromSchema, pickMultiPropFieldsFromSchema, getProducerInfo, getBrokerInfo, shouldBeOmitted,
    } from './utils';

    @Component({
        components: {
            EndorsementsView,
            EvaluationError,
            FontAwesomeIcon,
            PolicyRecord,
            JsonSchemaView,
            MultiFieldCriterion,
            CollateralList,
            PolicyInfoCarrierInfo,
            PolicyInfoOtherCellsInfo,
            PolicyInfoNonExtraction,
        },
    })
    export default class PolicyInfo extends Vue {
        @Prop({ type: Object, required: true })
        private coverage!: InsuranceInsuredCoverage;

        @Prop({ type: Object })
        private coverageModel?: InsuranceCoverageModel;

        @Prop({ type: Object, default: null })
        private brokerInfo!: BrokerInfo | null;

        @Prop({ type: Object, default: () => ({}) })
        private evaluationErrors!: Record<string, FieldEvaluationResultError>;

        @Prop({ type: Array, default: () => [] })
        private evaluationResults!: InsuranceEvaluationResult[];

        @Prop({ type: Array, default: () => [] })
        private allCoverages!: InsuranceInsuredCoverageDetails[];

        @Prop({ type: Array as PropType<CollateralEntity[]>, default: () => [] })
        private collateralEntities!: CollateralEntity[];

        @Prop({ type: Object as PropType<NonExtractionResults>, default: undefined })
        private nonExtractionResults?: NonExtractionResults;

        private faQuestionCircle = faQuestionCircle;
        private multiCoverageTooltipDescription: string = 'Any criteria within this coverage that contains multiple fields are shown here ' +
            '(i.e. General Aggregate Limit covered by Umbrella Excess).';

        private fieldsToHide = [
            'coverageStatus', 'endorsements', 'collateral', 'brokerName', 'producerName',
            'producerAddress', 'producerContactName', 'producerEmail', 'producerTelephone',
        ];

        public get limits(): {
            name: string;
            value: number | string | unknown;
            evaluationError: FieldEvaluationResultError | null;
        }[] {
            return this.coverage
                ? [ ...Object.entries(this.coverage.details),
                    ...Object.entries(this.coverage.details.endorsements || {}) ]
                    .filter(([ key ]) => this.isLimit(key))
                    .filter(([ key, value ]) =>
                        !isNil(value) || this.evaluationErrors[`#/${this.coverage.coverageType}/details/${key}`])
                    .map(([ key, value ]) => ({
                        name: key,
                        value: typeof value === 'number'
                            ? this.localCurrencyFormatter.format(value)
                            : value || FIELD_NOT_AVAILABLE_MESSAGE,
                        evaluationError: this.evaluationErrors[`#/${this.coverage.coverageType}/details/${key}`]
                            || this.evaluationErrors[`#/${this.coverage.coverageType}/details/endorsements/${key}`]
                            || null,
                    })) || []
                : [];
        }

        private get categorizedEnumLabels(): CategorizedEnumLabels {
            return this.$store.state.dashboard.categorizedEnumLabels;
        }

        private get values(): Record<string, any> {
            const _values = Object.entries(this.coverage.details)
                .reduce<Record<string, any>>(
                    (result, [ key, value ]) => {
                        const shouldOmitByInsuranceTypeOrMultiField = typeOfInsuranceAttributes.includes(key) ||
                            Boolean(this.multiFieldCriteriaFieldMap[key]);

                        if (!shouldOmitFieldsForRendering(
                            key, this.fieldsToHide, shouldOmitByInsuranceTypeOrMultiField,
                        )) {
                            result[key] = parseFieldValue(key, value, {
                                coverage: this.coverage,
                                coverageModel: this.coverageModel,
                                evaluationResults: this.evaluationResults,
                                evaluationErrors: this.evaluationErrors,
                                multiFieldCriteria: this.multiFieldCoverageCriteria,
                                categorizedEnumLabels: this.categorizedEnumLabels,
                            });
                        }
                        return result;
                    }, {});
            return transformValuesWithIsPresent(omitBy(_values, (v, k) => this.limitsKeys.includes(k)));
        }

        private get multiFieldCriteriaFieldMap(): Record<string, unknown> {
            return this.multiFieldCoverageCriteria.reduce((acc, item) => (
                { ...acc, ...item.fields }
            ), {});
        }

        private get multiFieldCoverageCriteria(): InsuredMultiFieldCriterion[] {
            return getMultiFieldCoverageCriteria(
                this.evaluationResults, this.evaluationErrors, this.allCoverages, this.categorizedEnumLabels,
            );
        }

        private get coverageStatus(): boolean {
            return this.coverage.details.coverageStatus ?? false;
        }

        private get endorsementsFieldsValues(): Record<string, any> {
            if (!this.coverage.details.hasOwnProperty('endorsements')) {
                return {};
            }

            let _endorsementsFieldsValues = {
                ...Object.entries(this.coverage.details.endorsements).reduce((acc, [ key, value ]) => {
                    const shouldOmitKey = shouldBeOmitted(key, value, this.evaluationResults) ||
                        Boolean(this.multiFieldCriteriaFieldMap[key]);

                    if (shouldOmitKey) {
                        return acc;
                    }

                    acc[key] = getEnumDefaultDisplayValue(key, value, this.categorizedEnumLabels);
                    return acc;
                }, {} as Record<string, unknown>),
                ...this.nullEndorsementsFieldsWithErrors,
            };
            _endorsementsFieldsValues = combineSpecialCoverageFieldValues(_endorsementsFieldsValues);
            return omitBy(
                _endorsementsFieldsValues,
                (v, k) => this.limitsKeys.includes(k),
            );
        }

        private get nullEndorsementsFieldsWithErrors() {
            return Object.entries(this.coverage.details.endorsements || {})
                .reduce((acc, [ key, value ]) => {
                    if (
                        value === null &&
                        this.evaluationErrors[`#/${this.coverage.coverageType}/details/endorsements/${key}`] &&
                        !this.multiFieldCriteriaFieldMap[key]
                    ) {
                        acc[key] = FIELD_NOT_AVAILABLE_MESSAGE;
                    }
                    return acc;
                }, {} as any);
        }

        private get producerDetails(): { title: string, value: string }[] {
            const producerData = getProducerInfo(this.coverage);
            const brokerData = getBrokerInfo(this.brokerInfo);
            return producerData
                .concat(brokerData)
                .filter(({ value }) => value) as { title: string, value: string }[];
        }

        private get collaterals(): CoverageFieldCollateral[] {
            return this.coverage.details.collateral || [];
        }

        private get currencyLabel(): string {
            return getEnumDefaultDisplayValue(
                EnumCategories.currency,
                this.coverage.policy.currency,
                this.categorizedEnumLabels,
            ) as string;
        }

        private get localCurrencyFormatter(): Intl.NumberFormat {
            const currency = this.coverage?.policy.currency;
            return currencyFormatter(currency);
        }

        private get limitsKeys(): string[] {
            return this.limits.map<string>((limit) => limit.name);
        }

        private get singleCoverageFields() {
            return omitMultiPropFieldsFromSchema(getBasicSchemaByValue(this.values));
        }

        private get singleCoverageEndorsementsFields() {
            return omitMultiPropFieldsFromSchema(getBasicSchemaByValue(this.endorsementsFieldsValues));
        }

        private get multiPropsCoverageFields() {
            return pickMultiPropFieldsFromSchema(getBasicSchemaByValue(this.values));
        }

        private get multiCoverageEndorsementsFields() {
            return pickMultiPropFieldsFromSchema(getBasicSchemaByValue(this.endorsementsFieldsValues));
        }

        private get numberOfMultiPropsCoverageFields(): number {
            return Object.keys(this.values).filter((key) =>
                this.multiPropsCoverageFields.properties.hasOwnProperty(key)).length;
        }

        private get numberOfEndorsementsMultiCoverageFields(): number {
            return Object.keys(this.values).filter((key) =>
                this.multiCoverageEndorsementsFields.properties.hasOwnProperty(key)).length;
        }

        private isLimit(key: string) {
            return key.toLowerCase().endsWith('limit') || key.toLowerCase().includes('singlelimit')
                || key === 'deductible';
        }

        private goToCriterion(coverageCriteriaGroupId: string): void {
            this.$emit('goToCriterion', coverageCriteriaGroupId, this.coverage.coverageType);
        }

        private grantExceptionToCriterion(criterionId: string): void {
            this.$emit('grantExceptionToCriterion', criterionId);
        }

        private grantEvaluateEntityException(exception: InsuranceExceptionInput[]): void {
            this.$emit('grantEvaluateEntityException', exception);
        }

        private removeExceptions(exceptionIds: string[]): void {
            this.$emit('removeExceptions', exceptionIds);
        }

        private getEvaluationResult(url: string): FieldEvaluationResultError | null {
            return getEvaluationResultsFromEvaluationErrors(url, this.evaluationErrors, this.coverage);
        }

        private get isShowingCarrierInfo(): boolean {
            return isShowingCarrierInfo(this.coverage.policy.carrier, this.getEvaluationResult);
        }
    }
</script>
