<template>
    <DeactivationConfirmationModal :insureds="insureds" :saving="saving" @input="onAction" />
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import LoadingModal from '@/components/LoadingModal.vue';
    import { InsuranceInsured } from '@evidentid/rpweb-api-client/types';
    import { InsuredPatch, PatchOperationType } from '@evidentid/rpweb-api-client';
    import DeactivationConfirmationModal
        from '@/modules/insured-management/components/DeactivationConfirmationModal/DeactivationConfirmationModal.vue';

    @Component({
        components: { LoadingModal, DeactivationConfirmationModal },
    })

    export default class DeactivateInsuredProcedure extends Vue {
        @Prop({ type: String, default: '' })
        private rpName!: string;

        @Prop({ type: Array, default: () => [] })
        private insureds!: InsuranceInsured[];

        @Prop({ type: Boolean, default: false })
        private isBulk!: boolean;

        private opened: boolean = true;
        private saving: boolean = false;

        private onAction(yes: boolean) {
            yes ? this.deactivateInsured() : this.close();
        }

        private close(): void {
            this.opened = false;
            this.$emit('abort');
        }

        private async deactivateInsured() {
            const { rpName, insureds, isBulk } = this;
            this.saving = true;
            await this.$store.actions.insuredManagement.patchInsureds({
                rpName,
                isBulk,
                patch: insureds.map((insured) => ({
                    id: insured.id,
                    active: {
                        op: PatchOperationType.replace,
                        newValue: false,
                    },
                } as InsuredPatch)),
            });
            this.$emit('finish', this.insureds);
        }
    }
</script>
