import { RelyingPartyRequestDetails } from '@evidentid/rpweb-api-client/types';
import { DocumentAttributeRegexType } from '@/modules/insured-certificates/models/DocumentAttributeRegexType.model';
import { getDocumentAttributeRegex } from '@/modules/insured-certificates/services/getDocumentAttributeRegex';

export function aggregateDocumentsFromRprs(
    rprs: RelyingPartyRequestDetails[],
    patternType: DocumentAttributeRegexType,
): Record<string, Record<string, any[]>> {
    const regex = getDocumentAttributeRegex(patternType);
    return rprs.reduce(
        (accum, rpr) => {
            const contents: any[] = [];
            const docPerAttribute = rpr.attributes.reduce((accum, attr) => {
                if (regex.test(attr.type) && attr.value?.content) {
                    return { ...accum, [attr.type]: attr.value.content };
                }
                return accum;
            }, {});
            return { ...accum, [rpr.id]: docPerAttribute };
        }, {},
    );
}
