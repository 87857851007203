<template>
    <Preview
        class="ActionEmailPreview"
        :template="template"
        :variables="variables"
    />
</template>

<script lang="ts">
    import { Component, Vue, Prop } from '@evidentid/vue-property-decorator';
    import { Preview } from '@evidentid/dashboard-commons/components/Preview';
    import { formatSupportHoursSettings } from '@/utils/formatSupportHours';

    const emailTemplateOpening = `
    <table width="100%" bgcolor="#E5E5E5" border="0" cellpadding="0" cellspacing="0" role="presentation">
        <!--WHITE WRAPPER STARTS-->
        <tr>
            <td class="tp0" align="center" style="padding-top:12px;">
                <table class="fullwidth" width="600" align="center" cellpadding="0" cellspacing="0" border="0" style="max-width:100%;" role="presentation" bgcolor="#ffffff">
                    <tr>
                        <td bgcolor="#f4f4f4" height="34" align="center" style="font-family:Arial,sans-serif; font-size:0px; color:#4B4B4B;"> </td>
                    </tr>
                    <tr>
                        <td align="center" style="font-family:Arial,sans-serif; font-size:14px; color:#4B4B4B; padding-top:12px;">
                            <img src="{wordmarkUrl}" alt="Logo image for {displayName}" border="0" style="max-width: 580px; max-height: 15em; display:inline-block;"/>
                        </td>
                    </tr>
    `;

    const emailTemplateEnding = `
                </table>
            <td/>
        </tr>
    </table>
    `;

    const emailTemplateFooter = `
    <tr>
        <td bgcolor="#4F5861" align="center">
            <table width="90%" align="center" cellpadding="0" cellspacing="0" border="0" style="max-width:100%;"
                role="presentation">
                <tr>
                    <td valign="top" style="padding:20px 0px 20px;">
                        <table width="100%" align="center" cellpadding="0" cellspacing="0" border="0"
                            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;">
                            <tr>
                                <td width="105" align="left" valign="top"
                                    style="font-family:Arial,sans-serif; font-size:10px; line-height:14px; font-weight:normal; color:#4B4B4B;">
                                    <p style="padding:0; margin:0px 0px 5px; font-size:8px; line-height:12px; font-weight:normal; color:#ffffff;">
                                        Powered by</p>
                                    <p style="padding:0; margin:0;"><a
                                        href="https://www.evidentid.com/?utm_source=product-email&utm_medium=email&utm_campaign=ep001"
                                        target="_blank"><img
                                        src="https://privacy.evidentid.com/misc-images/evident_logo_white.png"
                                        width="105" alt="Evident Logo" border="0"
                                        style="display:block; max-width:100%;" /></a></p>
                                </td>
                                <td align="right" valign="bottom"
                                    style="font-family:Arial,sans-serif; font-size:10px; line-height:14px; font-weight:normal; color:#000000; padding-left:20px;">
                                    <p style="padding:0; margin:0px 0px 8px; font-size:10px; line-height:14px; font-weight:normal; color:#ffffff;">
                                        <a href="https://www.evidentid.com/support/?utm_source=product-email&utm_medium=email&utm_campaign=ep001"
                                            target="_blank"
                                            style="color:#ffffff; text-decoration:none;">EvidentID.com</a>
                                        <a href="https://www.evidentid.com/contact-sales/?utm_source=product-email&utm_medium=email&utm_campaign=ep001"
                                            target="_blank" style="color:#ffffff; text-decoration:none;">Request a
                                            Demo</a>
                                    </p>
                                    <p style="padding:0; margin:0px 0px 5px; font-size:10px; line-height:14px; font-weight:normal; color:#CACFD3;">
                                        P.O. Box 19119 Atlanta GA, 31126</p>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
    `;

    const emailTemplateContact = `
    <!-- contact -->
    <tr>
        <td bgcolor="#ffffff" align="center" style="">
            <table width="90%" align="center" cellpadding="0" cellspacing="0" border="0" style="max-width:100%;" role="presentation">
                <tr>
                    <td align="left" style="font-family:Arial,sans-serif; font-size:18px; line-height:24px; font-weight:bold; color:#23313A; padding:30px 0px 15px; border-bottom:1px solid #CACFD3;">Contact { displayName }</td>
                </tr>
                <tr>
                    <td align="center" style="padding:8px 0px 22px;">
                        <table width="100%" align="center" cellpadding="0" cellspacing="0" border="0" style="max-width:100%;" role="presentation">
                            <tr>
                                <th class="td_block" width="52%" valign="top">
                                    <table width="100%" align="center" cellpadding="0" cellspacing="0" border="0" style="max-width:100%;" role="presentation">
                                        <!-- email -->
                                        <tr>
                                            <td width="22" align="left" valign="top" style="padding:8px 0px;">
                                                <p style="padding:0; margin:2px 2px 0px;"><img src="https://privacy.evidentid.com/misc-images/envelope.png" alt="" border="0" style="max-width:100%: display:inline-block;"/>
                                                </p>
                                            </td>
                                            <td align="left" valign="top" style="font-family:Arial,sans-serif; font-size:14px; line-height:20px; color:#000000; font-weight:normal; padding:8px 0px;">: <a href="mailto:{ supportEmail }" style="color:#000000; text-decoration:none;">{ supportEmail }</a></td>
                                        </tr>
                                        <!--/ email -->
                                        <!-- web -->
                                        <tr>
                                            <td width="22" align="left" valign="top" style="padding:8px 0px;">
                                                <p style="padding:0; margin:2px 0px 0px;">
                                                    <img src="https://privacy.evidentid.com/misc-images/laptop.png" alt="" border="0" style="max-width:100%: display:inline-block;" />
                                                </p>
                                            </td>
                                            <td align="left" valign="top" style="font-family:Arial,sans-serif; font-size:14px; line-height:20px; color:#000000; font-weight:normal; padding:8px 0px;">: <a href="{ supportUrl }" target="_blank" style="color:#000000; text-decoration:none;">{ supportUrlTitle }</a></td>
                                        </tr>
                                        <!--/ web -->
                                    </table>
                                </th>
                                <th class="mhide" width="2%" valign="top"></th>
                                <th class="td_block" width="46%" valign="top">
                                    <table width="100%" align="center" cellpadding="0" cellspacing="0" border="0" style="max-width:100%;" role="presentation">
                                        <!-- phone -->
                                        <tr>
                                            <td width="22" align="left" valign="top" style="padding:8px 0px;">
                                                <p style="padding:0; margin:2px 0px 0px;">
                                                    <img src="https://privacy.evidentid.com/misc-images/Phone.png" alt="" border="0" style="max-width:100%: display:inline-block;"/>
                                                </p>
                                            </td>
                                            <td align="left" valign="top" style="font-family:Arial,sans-serif; font-size:14px; line-height:20px; color:#000000; font-weight:normal; padding:8px 0px;">: <a href="tel:{ supportPhone }" style="color:#000000; text-decoration:none;">{ supportPhone }</a></td>
                                        </tr>
                                        <!--/ phone -->
                                        <!-- hours -->
                                        <tr>
                                            <td width="22" align="left" valign="top" style="padding:8px 0px;">
                                                <p style="padding:0; margin:2px 0px 0px;">
                                                    <img src="https://privacy.evidentid.com/misc-images/clock.png" alt="" border="0" style="max-width:100%: display:inline-block;"/>
                                                </p>
                                            </td>
                                            <td align="left" valign="top" style="font-family:Arial,sans-serif; font-size:14px; line-height:20px; color:#000000; font-weight:normal; padding:8px 0px;">: { supportDaysOfWeek }, { supportStartTime }-{ supportEndTime } { supportTimezone }</td>
                                        </tr>
                                        <!--/ hours -->
                                    </table>
                                </th>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
    <!--/ contact -->
    `;

    const emailTemplateHeader = `
    <tr>
        <td bgcolor="#ffffff" align="center" style="padding:20px 0px;">
            <table width="90%" align="center" cellpadding="0" cellspacing="0" border="0" style="max-width:100%;" role="presentation">

                <tr>
                    <td style="padding:0; margin:0px 0px 10px; font-family:Arial,sans-serif; font-size:14px; line-height:20px; font-weight:normal; color:#4b4b4b; text-align: left;">

                        <p style="padding:0; margin:0px 0px 5px; font-family:Arial,sans-serif; font-size:28px; line-height:36px; font-weight:bold; color:#333333; text-align: center;">New Insurance Verification</p>
                        <p style="padding:0; margin:0px 0px 15px; font-family:Arial,sans-serif; font-size:18px; line-height:26px; font-weight:normal; color:#4b4b4b; text-transform:uppercase; text-align: center;">{displayName} has requested insurance documents</p>
                        <p style="padding:0; margin:0px 0px 10px; font-family:Arial,sans-serif; font-size:14px; line-height:20px; font-weight:normal; color:#4b4b4b;">{displayName} has partnered with Evident, a trusted insurance verification software, to ensure you have the right coverage to do business. Please upload ALL COI(s) by clicking the button below. You will be taken to Evident’s portal to quickly drag and drop your document(s).</p>

                        <!-- requirements -->
                        <p style="padding:0; margin:0; font-family:Arial,sans-serif; font-size:14px; line-height:20px; font-weight:normal; color:#4b4b4b; text-align: center;">View <a href="{coiRequirementsLink}" target="_blank" style="font-weight:bold; color:{primaryColor}; text-decoration:underline;">{displayName} Insurance Requirements</a>.</p>
                        <!--/ requirements -->

                    </td>
                </tr>
                <tr>
                    <td align="center" style="padding-top:20px;">
                        <table class="fullwidth" align="center" cellpadding="0" cellspacing="0" border="0" style="max-width:100%;" role="presentation">
                            <tr>
                                <td align="center">
                                    <table align="center" cellpadding="0" cellspacing="0" border="0" style="max-width:100%;" role="presentation">
                                        <tr>
                                            <td bgcolor="{primaryColor}" align="center" style="font-family:Arial,sans-serif; font-size:16px; line-height:19px; font-weight:bold; color:{secondaryColor}; text-transform:uppercase; mso-padding-alt:12px 40px; border-radius:5px;"><a href="#" target="_blank" style="color:{secondaryColor}; text-decoration:none; display:block; padding:12px 40px; border-radius:5px;">Upload Certificate(s) of Insurance</a></td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td align="center" style="padding-top:20px;">
                        <p style="padding:0; margin:0; font-family:Arial,sans-serif; font-size:14px; line-height:20px; font-weight:normal; color:#4b4b4b;"><a href="https://evidentid.wistia.com/medias/h121crj1mq?wvideo=h121crj1m" target="_blank" style="font-weight:bold; color:{primaryColor}; text-decoration:underline;">Watch What to Expect</a> in Evident’s Verification Process.</p>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
    `;

    const emailTemplateFulfillment = `
    <!-- fulfillment -->
    <tr>
        <td bgcolor="#F4F4F4" align="center" style="padding:15px 0px;">
            <table width="90%" align="center" cellpadding="0" cellspacing="0" border="0" style="max-width:100%;" role="presentation">
                <tr>
                    <th class="td_block" width="75%" valign="middle" style="padding:10px 0px;">
                        <table class="fullwidth" width="100%" align="center" cellpadding="0" cellspacing="0" border="0" role="presentation">
                            <tr>
                                <td width="51" align="left" valign="middle">
                                    <img src="{fulfillmentConfiguration.logoUri}" width="51" alt="One click icon" border="0" style="max-width:100%;"/>
                                </td>
                                <!-- {=fulfillmentConfiguration.text} "=" is a special flag recognized in the Preview Component to treat as html string-->
                                <td align="left" valign="top" style="padding:0px 0px 0px 20px; font-family:Arial,sans-serif; font-size:14px;">{=fulfillmentConfiguration.text}</td>
                            </tr>
                        </table>
                    </th>
                    <th class="mhide" width="1%" valign="middle" style="padding:10px 0px;" align="center"></th>
                    <th class="td_block" width="24%" valign="middle" style="padding:10px 0px;" align="center">
                        <table width="136" align="center" cellpadding="0" cellspacing="0" border="0" style="max-width:100%;" role="presentation">
                            <tr>
                                <td align="center" style="border-radius:5px; border:2px solid {fulfillmentConfiguration.primaryColor}; mso-padding-alt:15px 0px;">
                                    <a href="{fulfillmentConfiguration.fulfillmentUrl}" target="_blank" style="font-family:Arial,sans-serif; font-size:14px; line-height:18px; font-weight:bold; color:{fulfillmentConfiguration.secondaryColor}; text-decoration:none; display:block; padding:15px 0px;">{fulfillmentConfiguration.buttonLabel}</a>
                                </td>
                            </tr>
                        </table>
                    </th>
                </tr>
            </table>
        </td>
    </tr>
    <!--/ fulfillment -->
    `;

    const emailTemplateStyles = `
        <link
            href="https://fonts.googleapis.com/css?family=Roboto:400,600,700&amp;display=swap"
            rel="stylesheet"
        >
        <style type="text/css">
            body {-webkit-text-size-adjust:100%; text-size-adjust:100%;}
            .ReadMsgBody {width:100%;}
            .ExternalClass {width:100%;}
            img {-ms-interpolation-mode:bicubic;}
            a[x-apple-data-detectors] {color:inherit !important; text-decoration:none !important; font-size:inherit !important; font-family:inherit !important; font-weight:inherit !important; line-height:inherit !important;}
            .whitelink a {color:#ffffff; text-decoration:none;}
            @media only screen and (max-width: 599px) {
                .fullwidth {width:100% !important;}
                .w80pct {width:80% !important;}
                .mhide {display:none !important;}
                .sidepad {padding-left:20px !important;padding-right:20px !important;}
                .tc {text-align:center !important;}
                .tl {text-align:left !important;}
                .tr {text-align:right !important;}
                .img_center {margin:0 auto !important;}
                .tab_center {float:none !important; margin:0 auto !important;}
                .tp0 {padding-top:0px !important;}
                .tp25 {padding-top:25px !important;}
                .td_block {width:100% !important;display:block !important;}
            }
            @media only screen and (max-width: 599px) {
                .fullwidth {width:100% !important;}
                .w80pct {width:80% !important;}
                .mhide {display:none !important;}
                .sidepad {padding-left:20px !important;padding-right:20px !important;}
                .tc {text-align:center !important;}
                .tl {text-align:left !important;}
                .tr {text-align:right !important;}
                .img_center {margin:0 auto !important;}
                .tab_center {float:none !important; margin:0 auto !important;}
                .tp0 {padding-top:0px !important;}
                .tp25 {padding-top:25px !important;}
                .td_block {width:100% !important;display:block !important;}
            }
        </style>
    `;

    // INFO: for dynamic template, we need to ensure the template coming from api has "=" prefix to template variables
    // that contains html string. it's a special flag that Preview component will use to determine whether render
    // as html or text. for example <td>{=fulfillmentConfiguration.text}</td>
    const emailTemplate = `
        ${emailTemplateStyles}
        ${emailTemplateOpening}
        ${emailTemplateHeader}
        ${emailTemplateFulfillment}
        ${emailTemplateContact}
        ${emailTemplateFooter}
        ${emailTemplateEnding}
    `;

    function hideBlock(template: string, name: string): string {
        const escapedName = name.replace(/[^a-zA-Z0-9]/g, (char) => `\\${char}`);
        const regex = new RegExp(`<!--\\s*${escapedName}\\s*-->[^]+?<!--/\\s*${escapedName}\\s*-->`, 'g');
        return template.replace(regex, '');
    }

    function displayBlocks(template: string, map: Record<string, boolean>): string {
        const hiddenBlocks = Object.keys(map).filter((name) => !map[name]);
        return hiddenBlocks.reduce((result, name) => hideBlock(result, name), template);
    }

    @Component({
        components: {
            Preview,
        },
    })
    export default class ActionEmailPreview extends Vue {
        @Prop({ type: Object })
        private value: any;

        @Prop({ type: Object, default: () => ({}) })
        private defaults: any;

        private get template(): string {
            const {
                supportPhone, supportUrlTitle, supportUrl,
                supportEmail, coiRequirementsLink,
                fulfillmentConfiguration,
                supportDaysOfWeek, supportStartTime,
                supportEndTime, supportTimezone,
            } = this.variables;
            // supportDaysOfWeek initially array of numbers, it then parsed into string.
            // we need to check its emptiness for both array and string.
            const hasDaysOfWeek = Array.isArray(supportDaysOfWeek)
                ? supportDaysOfWeek.length > 0
                : Boolean(supportDaysOfWeek);
            return displayBlocks(emailTemplate, {
                contact: Boolean((
                    supportPhone ||
                    (supportUrl && supportUrlTitle) ||
                    supportEmail)),
                hours: Boolean(supportStartTime),
                email: Boolean(supportEmail),
                phone: Boolean(supportPhone),
                web: Boolean(supportUrl && supportUrlTitle),
                requirements: Boolean(coiRequirementsLink),
                fulfillment: Boolean(fulfillmentConfiguration.enabled),
            });
        }

        private get variables(): any {
            const data = { ...this.defaults, ...this.value };
            return {
                ...data,
                ...formatSupportHoursSettings(data),
            };
        }
    }
</script>
