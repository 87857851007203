<template>
    <Alert class="CoverageCriteriaWarningMessage" rounded>
        <EidIcon
            class="CoverageCriteriaWarningMessage__icon"
            :icon-src="icon"
            :svg-props="{color: alertWarningColor}"
            alt="icon"
        />
        <slot />
    </Alert>
</template>

<script lang="ts">
    import { Component, Vue } from '@evidentid/vue-property-decorator';
    import Alert from '@evidentid/dashboard-commons/components/Alert/Alert.vue';
    import exclamationCircle from '@/modules/insured-details/assets/exclamation-circle.svg';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { tailwindColors } from '@/styles/variables/tailwind-colors/tailwindColors';

    @Component({
        components: {
            Alert,
            EidIcon,
        },
    })
    export default class CoverageCriteriaWarningMessage extends Vue {
        private icon = exclamationCircle;
        private alertWarningColor = tailwindColors.eidWarningYellow.DEFAULT;
    }
</script>
