import { Vue } from '@evidentid/vue-property-decorator';
import CriterionBooleanInput
    from '@/modules/decisioning-criteria/components/CoverageCriterionInputComponents/CriterionBooleanInput.vue';
import CriterionTextInput
    from '@/modules/decisioning-criteria/components/CoverageCriterionInputComponents/CriterionTextInput.vue';
import CriterionNumberInput
    from '@/modules/decisioning-criteria/components/CoverageCriterionInputComponents/CriterionNumberInput.vue';
import CriterionEnumInput
    from '@/modules/decisioning-criteria/components/CoverageCriterionInputComponents/CriterionEnumInput.vue';
import CriterionArrayInput
    from '@/modules/decisioning-criteria/components/CoverageCriterionInputComponents/CriterionArrayInput.vue';
import CriterionObjectInput
    from '@/modules/decisioning-criteria/components/CoverageCriterionInputComponents/CriterionObjectInput.vue';
import CriterionCheckboxInput
    from '@/modules/decisioning-criteria/components/CoverageCriterionInputComponents/CriterionCheckboxInput.vue';
import CriterionCurrencyInput
    from '@/modules/decisioning-criteria/components/CoverageCriterionInputComponents/CriterionCurrencyInput.vue';
import CriterionDaysInput
    from '@/modules/decisioning-criteria/components/CoverageCriterionInputComponents/CriterionDaysInput.vue';
import CriterionMultiEnumInput
    from '@/modules/decisioning-criteria/components/CoverageCriterionInputComponents/CriterionMultiEnumInput.vue';
import JsonForm from '@evidentid/json-schema/interfaces/JsonForm';
import { JsonSchemaType } from '@evidentid/json-schema/interfaces/JsonSchema';
import { InsuranceSchemaDisplayFormat } from '@evidentid/rpweb-api-client/types';
import { getJsonSchemaCompFunction } from '@/utils/getJsonSchemaCustomCompFuntion';
import CriterionMonthsInput
    from '@/modules/decisioning-criteria/components/CoverageCriterionInputComponents/CriterionMonthsInput.vue';

const customFormElementsMap: Record<string, Vue.Component> = {
    [JsonSchemaType.boolean]: CriterionBooleanInput,
    [JsonSchemaType.string]: CriterionTextInput,
    [JsonSchemaType.number]: CriterionNumberInput,
    [JsonSchemaType.array]: CriterionArrayInput,
    [JsonSchemaType.object]: CriterionObjectInput,
    [InsuranceSchemaDisplayFormat.checkbox]: CriterionCheckboxInput,
    [InsuranceSchemaDisplayFormat.currency]: CriterionCurrencyInput,
    [InsuranceSchemaDisplayFormat.days]: CriterionDaysInput,
    [InsuranceSchemaDisplayFormat.months]: CriterionMonthsInput,
    [InsuranceSchemaDisplayFormat.insuredField]: CriterionEnumInput,
    enum: CriterionEnumInput,
    multi_enum: CriterionMultiEnumInput,
};

export function getCriterionJsonSchemaComponent(): (form: JsonForm) => Vue.Component {
    return getJsonSchemaCompFunction(customFormElementsMap);
}
