<template>
    <div class="EndorsementsView">
        <!-- TODO(PRODUCT-17638): until further notice, this is to display eva error when no endorsements collected -->
        <div v-if="Object.entries(additionalInsuredEndorsements).length === 0 && evaluationError">
            <div class="EndorsementsView__body">
                <div class="EndorsementsView__header">
                    <span class="EndorsementsView__headerName">
                        Additional Insured Endorsements
                    </span>
                    <EvaluationError
                        :evaluation-error="emptyError"
                        v-on="$listeners"
                    />
                </div>
                <div class="EndorsementsView__records">
                    <div class="EndorsementsView__record">
                        <div class="EndorsementsView__title">
                            Additional Insured Value
                        </div>
                        <div class="EndorsementsView__valueWithError">
                            <div class="EndorsementsView__value" />
                        </div>
                    </div>
                    <div class="EndorsementsView__record">
                        <div class="EndorsementsView__title">
                            Form number(s)
                        </div>
                        <div class="EndorsementsView__valueWithError">
                            <div class="EndorsementsView__value" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-for="([key, value], index) in Object.entries(additionalInsuredEndorsements)"
            v-else
            :key="key"
            class="EndorsementsView__endorsement"
        >
            <span class="EndorsementsView__index" :class="additionalIndexClass">
                {{ startIndex + index }}
            </span>
            <div class="EndorsementsView__body">
                <div class="EndorsementsView__header">
                    <span class="EndorsementsView__headerName">
                        Additional Insured Endorsements
                    </span>
                    <!--TODO(PRODUCT-17638): temp fix using generic error until further decision-->
                    <EvaluationError
                        v-if="evaluationError"
                        :evaluation-error="genericError"
                        v-on="$listeners"
                    />
                </div>
                <div class="EndorsementsView__records">
                    <div class="EndorsementsView__record">
                        <div class="EndorsementsView__title">
                            Additional Insured Value
                        </div>
                        <div class="EndorsementsView__valueWithError">
                            <div class="EndorsementsView__value">
                                {{ key }}
                            </div>
                        </div>
                    </div>
                    <div class="EndorsementsView__record">
                        <div class="EndorsementsView__title">
                            Form number(s)
                        </div>
                        <div class="EndorsementsView__valueWithError">
                            <div class="EndorsementsView__value">
                                {{ value }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import {
        InsuranceAdditionalInsuredEndorsement,
        InsuranceAdditionalInsuredEndorsementType,
    } from '@evidentid/rpweb-api-client/types';
    import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
    import EvaluationError from '../EvaluationError/EvaluationError.vue';

    @Component({
        components: { EvaluationError },
    })
    export default class EndorsementsView extends Vue {
        @Prop({ type: Array, default: [] })
        private endorsements !: InsuranceAdditionalInsuredEndorsement[];

        @Prop({ type: Number, default: 1 })
        private startIndex !: number;

        @Prop({ type: Object, default: null })
        private evaluationError!: FieldEvaluationResultError | null;

        private get additionalInsuredEndorsements(): Record<string, string> {
            return this.endorsements.reduce((acc, endorsement) => {
                if (endorsement.endorsementType === InsuranceAdditionalInsuredEndorsementType.additionalInsured
                    && endorsement.details) {
                    const additionalInsuredName = endorsement.details.additionalInsuredName || 'BLANKET';
                    acc[additionalInsuredName] =
                        `${endorsement.formNumber}${additionalInsuredName in acc ? `, ${acc[additionalInsuredName]}` : ''}`;
                }
                return acc;
            }, {} as Record<string, string>);
        }

        private get additionalIndexClass(): string | null {
            if (!this.evaluationError) {
                return null;
            }
            return this.evaluationError.complianceStatusMetadata.length > 0
                ? 'EndorsementsView--exception'
                : 'EndorsementsView--error';
        }

        private get emptyError() {
            return {
                ...this.evaluationError,
                message: 'Coverage information not present on COI',
            };
        }

        private get genericError() {
            return {
                ...this.evaluationError,
                message: 'If the blanket additional insured endorsement is not present, then the configured additional insured must be named for required in the criterion endorsement categories',
            };
        }
    }
</script>
