import { JsonSchemaObject, JsonSchemaType } from '@evidentid/json-schema/interfaces/JsonSchema';
import { InsuranceWebhookType } from '@evidentid/insurance-facing-lib/models/api-settings';

export function buildWebhookTableRowSchema(webhookTypes: InsuranceWebhookType[]): JsonSchemaObject {
    return {
        type: JsonSchemaType.object,
        properties: {
            hookTypeId: {
                description: 'Select an Event',
                enum: webhookTypes.map((hookType) => hookType._id),
                enumLabels: webhookTypes.map((hookType) => hookType.hookLabel),
            },
            url: {
                type: JsonSchemaType.string,
                description: 'Enter a webhook URL for this event',
                format: 'url',
                minLength: 1,
            },
        },
        required: [ 'hookTypeId', 'url' ],
    } as JsonSchemaObject;
}
