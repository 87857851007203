<template>
    <div class="CoverageTypeList">
        <div class="CoverageTypeList__header">
            <strong>Coverage Types</strong>
            <Button
                v-if="!disableEvaluationRule"
                tag="button"
                class="CoverageTypeList__button CoverageTypeList__addRuleButtonContent"
                @click="toggleEvaluationRuleModal"
            >
                <template v-if="hasEvaluationRule">
                    Edit Evaluation Rule
                </template>
                <template v-else>
                    <span class="CoverageTypeList__plusIcon">+</span>
                    Add Evaluation Rule
                </template>
            </Button>
        </div>
        <div
            class="CoverageTypeList__content"
            :class="{'CoverageTypeList__content--empty':coverageTypeList.length === 0}"
        >
            <CoverageCriteriaWarningMessage
                v-if="hasDeprecatedCriteria"
                class="CoverageTypeList__alert"
            >
                One or more coverage types have deprecated coverage criteria.
            </CoverageCriteriaWarningMessage>
            <CoverageCriteriaDangerMessage
                v-if="isInvalidCriteriaMessage"
                class="CoverageTypeList__alert"
            >
                One or more coverage types could not be validated. Please check for errors.
            </CoverageCriteriaDangerMessage>
            <p v-if="coverageTypeList.length === 0 && !addingCoverageType">
                To begin configuring your coverage criteria. add your first coverage type
            </p>
            <CoverageTypeCard
                v-for="(coverageType,index) of sortedCoverageTypeList"
                :key="index"
                :has-deprecated-criteria="hasDeprecatedCriteriaByCoverageType(coverageType)"
                :has-invalid-references="isReferenceStartsWithIsInvalid(coverageType)"
                :coverage-type="coverageType"
                :title="getCoverageTypeLabel(coverageType)"
                :criteria-count="getCriteriaCount(coverageType)"
                :selected="coverageType === selectedCoverageType"
                :is-coverage-type-in-evaluation-rule="isCoverageTypeInEvaluationRule(coverageType)"
                @delete="deleteCoverageType"
                @click="selectCoverage(coverageType)"
            />
            <div v-if="addingCoverageType">
                <Expandable ref="coverageTypeSelector" legacy-mode>
                    <template #button>
                        <div class="CoverageTypeList__selectTypeBox">
                            <span>Select Coverage Type</span>
                            <FontAwesomeIcon :icon="faCaretDown" />
                        </div>
                    </template>
                    <Menu spaced>
                        <MenuLink
                            v-for="(coveragesType, index) of availableCoverageTypes"
                            class="CoverageTypeList__coverageTypeMenu"
                            :class="{'CoverageTypeList__coverageTypeMenu--hover': currentlyHoveredIndex === index}"
                            :key="index"
                            :icon="getCoverageTypeIcon(coveragesType)"
                            :label="getCoverageTypeLabel(coveragesType)"
                            @mouseenter.native="setCurrentHoverIndex(index)"
                            @mouseleave.native="setCurrentHoverIndex(-1)"
                            @click="addNewCoverageType(coveragesType)"
                        />
                    </Menu>
                </Expandable>
            </div>
            <div
                v-else-if="!addingCoverageType && availableCoverageTypes.length > 0"
            >
                <Button
                    tag="button"
                    class="CoverageTypeList__button CoverageTypeList__addCoverageButtonContent"
                    @click="startAddCoverageType"
                >
                    <span class="CoverageTypeList__plusIcon">+</span>
                    Add Coverage Type
                </Button>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import { PropType } from 'vue';
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import difference from 'lodash/difference';
    import sortBy from 'lodash/sortBy';
    import {
        InsuranceCoverageCriteriaGroup,
        InsuranceCoverageCriterion,
        InsuranceCoverageCriterionTemplate,
        InsuranceCoverageModel,
    } from '@evidentid/rpweb-api-client/types';
    import { InsuranceCoverageType } from '@evidentid/insurance-facing-lib/models/insured-details';
    import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import Dropdown from '@evidentid/dashboard-commons/components/Dropdown/Dropdown.vue';
    import { Expandable } from '@evidentid/dashboard-commons/components/Expandable';
    import { Menu, MenuLink } from '@evidentid/dashboard-commons/components/Menu';
    import { getCoverageTypeIcon } from '@/modules/insured-details/utils/insuredCoverage';
    import CoverageTypeCard from '@/modules/decisioning-criteria/components/CoverageTypeCard/CoverageTypeCard.vue';
    import CoverageCriteriaWarningMessage
        from '@/modules/decisioning-criteria/components/CoverageCriteriaWarningMessage/CoverageCriteriaWarningMessage.vue';
    import {
        GetCoverageTypeLabel, IsReferenceStartsWithIsInvalid,
    } from '@/modules/decisioning-criteria/components/CreateEditCoverageCriteriaGroup/types';
    import CoverageCriteriaDangerMessage
        from '@/modules/decisioning-criteria/components/CoverageCriteriaDangerMessage/CoverageCriteriaDangerMessage.vue';

    type HasDeprecatedCriteriaByCoverageType = (coverageType: InsuranceCoverageType) => boolean;
    type IsCoverageTypeInEvaluationRule = (coverageType: InsuranceCoverageType) => boolean;

    @Component({
        components: {
            Button,
            CoverageTypeCard,
            Dropdown,
            Expandable,
            FontAwesomeIcon,
            FormInput,
            FormElement,
            Menu,
            MenuLink,
            CoverageCriteriaWarningMessage,
            CoverageCriteriaDangerMessage,
        },
    })
    export default class CoverageTypeList extends Vue {
        @Prop({ type: Object, default: null })
        private group!: InsuranceCoverageCriteriaGroup | null;

        @Prop({ type: Array, default: () => [] })
        private coverageTypes!: InsuranceCoverageType[];

        @Prop({ type: Array, default: () => [] })
        private coverageModels!: InsuranceCoverageModel[];

        @Prop({ type: Object, default: () => ({}) })
        private criterionTemplatesByType!: Record<string, InsuranceCoverageCriterionTemplate[]>;

        @Prop({ type: Object, default: () => ({}) })
        private criteriaByType!: Record<string, InsuranceCoverageCriterion[]>;

        @Prop({ type: String, default: null })
        private selectedCoverageType!: string | null;

        @Prop({ type: Boolean, default: false })
        private hasDeprecatedCriteria!: boolean;

        @Prop({ type: Function as PropType<HasDeprecatedCriteriaByCoverageType>, required: true })
        private hasDeprecatedCriteriaByCoverageType!: HasDeprecatedCriteriaByCoverageType;

        @Prop({ type: Function as PropType<GetCoverageTypeLabel>, required: true })
        private getCoverageTypeLabel!: GetCoverageTypeLabel;

        @Prop({ type: Boolean as PropType<boolean>, default: false })
        private disableEvaluationRule!: boolean;

        @Prop({ type: Boolean as PropType<boolean>, default: false })
        private hasEvaluationRule!: boolean;

        @Prop({ type: Function as PropType<IsCoverageTypeInEvaluationRule>, required: true })
        private isCoverageTypeInEvaluationRule!: IsCoverageTypeInEvaluationRule;

        @Prop({ type: Array as PropType<string[]>, default: () => [] })
        private invalidReferences!: string[];

        @Prop({ type: Function as PropType<IsReferenceStartsWithIsInvalid>, required: true })
        private isReferenceStartsWithIsInvalid!: IsReferenceStartsWithIsInvalid;

        private addingCoverageType: boolean = false;
        private faCaretDown = faChevronDown;
        private currentlyHoveredIndex: number = -1;
        private getCoverageTypeIcon = getCoverageTypeIcon;

        private get isInvalidCriteriaMessage(): boolean {
            return this.invalidReferences.length > 0;
        }

        private get coverageTypeList(): InsuranceCoverageType[] {
            return Object.keys(this.criteriaByType) as InsuranceCoverageType[];
        }

        private get sortedCoverageTypeList(): InsuranceCoverageType[] {
            return sortBy([ ...this.coverageTypeList ], this.getCoverageTypeLabel);
        }

        private get sortedCoverageTypes(): InsuranceCoverageType[] {
            return sortBy([ ...this.coverageTypes ], this.getCoverageTypeLabel);
        }

        private get availableCoverageTypes(): InsuranceCoverageType[] {
            return difference(this.sortedCoverageTypes, this.sortedCoverageTypeList);
        }

        private startAddCoverageType(): void {
            this.addingCoverageType = true;
        }

        private addNewCoverageType(coverageType: InsuranceCoverageType): void {
            this.addingCoverageType = false;
            this.currentlyHoveredIndex = -1;
            this.$emit('add', coverageType);
        }

        private setCurrentHoverIndex(index: number): void {
            this.currentlyHoveredIndex = index;
        }

        private deleteCoverageType(coverageType: InsuranceCoverageType): void {
            this.$emit('delete', coverageType);
        }

        private selectCoverage(coverageType: InsuranceCoverageType): void {
            this.$emit('select', coverageType);
        }

        private getCriteriaCount(type: InsuranceCoverageType): number {
            return this.criteriaByType[type]?.length || 0;
        }

        private toggleEvaluationRuleModal() {
            this.$emit('toggle-evaluation-rule-modal');
        }
    }
</script>
