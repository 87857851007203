import { InsuranceInsuredField } from '@evidentid/rpweb-api-client/types';
import { JsonSchemaObject } from '@evidentid/json-schema/interfaces/JsonSchema';
import { isObject } from '@evidentid/json-schema/schemaChecks';

function getObjectPropKey(field: InsuranceInsuredField): string[] {
    const getObjChildKey = (key: string) => `${field.key}.${key}`;
    return ('propertiesOrder' in field.schema &&
        field.schema.propertiesOrder &&
        field.schema.propertiesOrder.length > 0)
        ? field.schema.propertiesOrder.map(getObjChildKey)
        : Object.keys((field.schema as JsonSchemaObject).properties)
            .map(getObjChildKey);
}

export function getFlattenedInsuredFieldKeys(insuredFields: InsuranceInsuredField[]): string[] {
    const keys = [] as string[];
    insuredFields.forEach((field) => {
        if (isObject(field.schema)) {
            keys.push(...getObjectPropKey(field));
        } else {
            keys.push(field.key);
        }
    });
    return keys;
}
