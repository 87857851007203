import { BasicAssignmentRule } from '@/modules/decisioning-criteria/types';
import { RulesLogic } from 'json-logic-js';
import {
    InsuranceSchema,
    InsuranceInsuredField,
    InsuranceSchemaDisplayFormat,
} from '@evidentid/rpweb-api-client/types';
import { isCollateralInsuredField } from '@/utils/isCollateralInsuredField';

// We need to migrate equality operator for all RPs that already uses decisioning criteria.
function migrateOperator(operator: string): string {
    switch (operator) {
        case '===':
            return '==';
        case '!==':
            return '!=';
        default:
            return operator;
    }
}

function encodeRule(rule: BasicAssignmentRule, insuredFields?: InsuranceInsuredField[]): RulesLogic {
    // [complex ruleField] special case for now, in the future we should handle as generic array/object as when needed
    const collateralsKey = insuredFields?.find(isCollateralInsuredField)?.key || null;
    if (collateralsKey && rule.insuredFieldKey === `${collateralsKey}[items].category`) {
        return {
            some: [
                { var: [ `insuredFields.${collateralsKey}` ] },
                { [rule.operator]: [ { var: [ 'category' ] }, rule.value ] },
            ],
        } as RulesLogic;
    }
    return { [rule.operator]: [ { var: [ `insuredFields.${rule.insuredFieldKey}` ] }, rule.value ] } as RulesLogic;
}

function decodeJsonLogic(jsonLogic: any): BasicAssignmentRule {
    // [complex ruleField] treat collateral (array of object) as special case for now.
    // revisit and support it generically when needed
    if ('some' in jsonLogic) {
        // "some" structure
        // -> [ field, childRuleAndValue]
        // -> [ { var: ['insuredFieldRootName'] }, { ==: [ {var: ['childFieldName']}, 'fieldValue'] } ]
        const topLevelKey = jsonLogic.some[0].var[0].replace('insuredFields.', '');
        const operator = Object.keys(jsonLogic.some[1])[0];
        const childFieldAndValue = Object.values(jsonLogic.some[1] as Record<string, any>)[0];
        const fieldName = childFieldAndValue[0].var[0];
        const fieldValue = childFieldAndValue[1];
        return {
            operator: migrateOperator(operator),
            insuredFieldKey: `${topLevelKey}[items].${fieldName}`,
            value: fieldValue,
        } as BasicAssignmentRule;
    }
    return {
        operator: migrateOperator(Object.keys(jsonLogic)[0]),
        insuredFieldKey: Object.values(jsonLogic as Object)[0][0].var[0].replace('insuredFields.', ''),
        value: Object.values(jsonLogic as Object)[0][1],
    };
}

export function convertToBasicAssignmentRules(rulesLogic: any): BasicAssignmentRule[][] {
    if (typeof rulesLogic === 'object') {
        if (Object.keys(rulesLogic).length === 1 && 'or' in rulesLogic) {
            return Object.values(rulesLogic.or)
                .filter((value) => value && Object.keys(value as Object).every((key) => key === 'and'))
                .map((value: any) => value.and)
                .map((value) => value.map(decodeJsonLogic));
        } else if (Object.keys(rulesLogic).length === 1 && 'and' in rulesLogic) {
            return [ Object.values(rulesLogic.and).map(decodeJsonLogic) ];
        }
    }
    return [];
}

export function encodeAssignmentRules(
    rules: BasicAssignmentRule[][], insuredFields?: InsuranceInsuredField[],
): RulesLogic | null {
    if (rules.length === 0) {
        return null;
    }

    if (rules.length === 1) {
        return {
            and: rules[0].map((x) => encodeRule(x, insuredFields)),
        } as RulesLogic;
    }

    return {
        or: rules.map((basicRules) => ({
            and: basicRules.map((x) => encodeRule(x, insuredFields)),
        })),
    } as RulesLogic;
}
